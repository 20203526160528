import { useMemo, useState } from 'react'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { Lock, LockClock } from '@mui/icons-material'
import { add, parseISO } from 'date-fns'
import { LoadingButton } from '@mui/lab'

import { BrandColor } from '~/config/theme'
import { voucherTimeoutMinutes } from '~/constants/reward'
import { useAppDispatch } from '~/redux/store'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { activateVoucher } from '~/redux/features/myRewards/actions'
import { RewardState, type ActiveReward } from '~/types/reward/ActiveReward'
import type { Reward } from '~/types/reward/Reward'

import RewardListItemHeader from '../../RewardListItemHeader'

import RewardBarcode from './RewardBarcode'

interface RewardCardProps {
  reward: Reward
  activeRewards: ActiveReward[]
  isExpired: boolean
}

const RewardStateBlock: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Box sx={{ display: 'flex', flexDirection: 'column', height: 110, mb: 1, borderRadius: 2, alignItems: 'center', justifyContent: 'center', background: BrandColor.BUTTON_GREY }}>
    {children}
  </Box>
}

const RewardCard: React.FC<RewardCardProps> = ({ reward, activeRewards, isExpired }) => {
  const dispatch = useAppDispatch()
  const [confirming, setConfirming] = useState(false)
  const [activating, setActivating] = useState(false)
  const voucherState = useMemo(() => {
    if (!reward) return RewardState.Open

    if (reward.activatedAt) {
      const activatedAt = parseISO(reward.activatedAt)
      const voucherActivationDuration = reward.prize?.voucherActivationDuration ?? { minutes: voucherTimeoutMinutes }
      if (add(activatedAt, voucherActivationDuration) > new Date()) {
        return RewardState.Activated
      }

      return RewardState.ActivatedExpired
    }

    if (isExpired) return RewardState.Expired

    if (activeRewards && activeRewards?.length > 0) {
      const matchingActiveRewards = activeRewards.find((activeReward) => {
        if (parseISO(activeReward.lockedUntil) > new Date()) {
          return activeReward.sku === reward?.prize.SKU
        }

        return false
      })

      if (matchingActiveRewards) {
        return RewardState.Locked
      }
    }

    return RewardState.Open
  }, [reward, activeRewards])

  const handleReveal = async (): Promise<void> => {
    try {
      setActivating(true)
      await dispatch(activateVoucher({ reward })).unwrap()
      dispatch(addNotification({ type: 'info', message: 'Other vouchers of this type are now locked until the timeout for this voucher has completed.' }))
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'There was an error activating the voucher, please try again later.' }))
    } finally {
      setConfirming(false)
      setActivating(false)
    }
  }

  const renderState = (): React.ReactNode => {
    switch (voucherState) {
      case RewardState.Expired:
        return null
      case RewardState.ActivatedExpired:
        return <RewardStateBlock>
          <LockClock fontSize='large' />
          <Typography>Vouchers can only be viewed once</Typography>
        </RewardStateBlock>
      case RewardState.Locked:
        return <RewardStateBlock>
          <Lock fontSize='large' />
          <Typography>Only one voucher can be revealed at a time</Typography>
        </RewardStateBlock>
      case RewardState.Activated:
        if (reward?.prize?.voucherCode) {
          return <Box sx={{ overflow: 'hidden', py: 1 }}>
            <RewardBarcode claimId={reward.submissionId} text={reward.prize.voucherCode} />
          </Box>
        }
        break
      default:
        return <Button
          variant='contained'
          sx={{ width: '100%', height: 110, mb: 1, fontSize: 20 }}
          onClick={() => { setConfirming(true) }}
        >
          Reveal Voucher
        </Button>
    }
  }

  return (
    <Box>
      <RewardListItemHeader reward={reward} />
      {renderState()}
      <Dialog open={confirming} onClose={() => { setConfirming(false) }}>
        <Box sx={{ p: 2, maxWidth: 400 }}>
          <Typography variant='h6' gutterBottom>Redeem Voucher</Typography>
          <Typography variant='body1' gutterBottom>You are about to redeem your voucher, please note you will only have 30 minutes to use this after you redeem.</Typography>

          <Button variant='outlined' sx={{ my: 1 }} disabled={activating} onClick={() => { setConfirming(false) }} fullWidth>Cancel</Button>
          <LoadingButton variant='contained' loading={activating} onClick={handleReveal} fullWidth>Reveal</LoadingButton>
        </Box>
      </Dialog>
    </Box>
  )
}

export default RewardCard
