export enum RecyclableScanStep {
  CHECKLIST = 'CHECKLIST',
  PRESCRIPTION = 'PRESCRIPTION',
  SCAN = 'SCAN',
  CONFIRM = 'CONFIRM',
  DEFINE_RECYCLABLE = 'DEFINE_RECYCLABLE',
  RESULT = 'RESULT',
}

export interface RecyclableScanConditionalStep {
  step: RecyclableScanStep
  name: string
  condition?: (recyclable: RecyclableScan) => boolean
}

export interface RecyclableScan {
  [key: string]: any
  confirmed: boolean
  prescription: boolean | null
  photo: {
    imageKey: string | null
    storageURI: string | null
  }
  scan: RecyclableScanResult | null
  product: string
  brand: string
  category: string
  form: string
}

export interface ValidatedRecyclableScan {
  storageURI: string
  scan: RecyclableScanResult
  brand: string
  category: string
  form: string
}

export interface RecyclableScanResult {
  shortId: string
  productId: string | null
  productName: string | null
  parentCategory: string | null
  brand: string | null
  category: string | null
  form: string | null
  imageKey: string
  result: string
  duplicate: boolean
  duplicateCount: number
  duplicateImages: string[]
  lowPixelCount: boolean
  score: number
  match: boolean
}

export interface RecyclableTags {
  brand: string
  category: string
  form: string
}
