import type { S2RProduct } from '~/types/recyclable/S2RProduct'
import type { Recyclable } from '~/types/recyclable/Recyclable'
import type { RecyclableScanResult, RecyclableTags } from '~/types/recyclable/recyclableScan'
import type { RecyclableType } from '~/types/recyclable/RecyclableType'
import type { CreateRecyclableResponse } from '~/types/recyclable/response/CreateRecyclableResponse'

import SubmissionProcessorService from './SubmissionProcessorService'

class RecyclableService extends SubmissionProcessorService {
  public async createRecyclable (promotionId: string, storageURI: string, scanResult: RecyclableScanResult, tags: RecyclableTags): Promise<CreateRecyclableResponse> {
    const body = {
      campaignId: promotionId,
      storageURI,
      scanResult,
      tags,
    }

    const response = await this.request('s2r/bank', {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async scan (promotionId: string, imageKey: string, init?: { signal?: AbortSignal }): Promise<RecyclableScanResult> {
    const response = await this.request('s2r/scan', {
      method: 'POST',
      body: JSON.stringify({ imageKey, promotionId }),
      ...init,
    })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getRecyclableTypes (promotionId: string, init?: { signal?: AbortSignal }): Promise<RecyclableType[]> {
    const response = await this.publicRequest(`s2r/${promotionId}/recyclable-types`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getRecyclableBrands (promotionId: string, init?: { signal?: AbortSignal }): Promise<string[]> {
    const response = await this.request(`s2r/${promotionId}/brands`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getProducts (promotionId: string, init?: { signal?: AbortSignal }): Promise<S2RProduct[]> {
    const response = await this.publicRequest(`s2r/${promotionId}/products`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getRecyclables (promotionId: string, init?: { signal?: AbortSignal }): Promise<Recyclable[]> {
    const response = await this.request(`bank?campaignId=${promotionId}`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async deleteRecyclable (recyclableId: string, init?: { signal?: AbortSignal }): Promise<string> {
    const response = await this.request(`bank?bankableId=${encodeURIComponent(recyclableId)}`, {
      method: 'DELETE',
      signal: init?.signal,
    })

    return response.status === 204 ? recyclableId : await Promise.reject(response)
  }
}

export default new RecyclableService()
