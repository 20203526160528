import { useMemo } from 'react'
import { AppBar, Badge, BottomNavigation, BottomNavigationAction, Box, Container, IconButton, Link, Paper, styled, Toolbar, Tooltip, Typography } from '@mui/material'
import { matchPath, Link as RouterLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import application from '~/config/application'
import { BrandColor } from '~/config/theme'
import UserIcon from '~/components/Icons/UserIcon'
import HomeIcon from '~/components/Icons/HomeIcon'
import ScanIcon from '~/components/Icons/ScanIcon'
import StarIcon from '~/components/Icons/StarIcon'
import RecyclablesIcon from '~/components/Icons/RecyclablesIcon'
import { ROUTE_ACCOUNT, ROUTE_HOME, ROUTE_DEPOSITS, ROUTE_REWARDS, ROUTE_RECYCLABLES, ROUTE_PRIZES, ROUTE_DEPOSIT } from '~/routes/Routes'
import type { RootState } from '~/redux/reducers/root'

import Logo from '~/assets/images/recycle-at-boots-text-blue.svg'

import NavigationSchemeSelector from './NavigationSchemeSelector/NavigationSchemeSelector'

interface PageProps {
  children: React.ReactNode
  route?: string
}

const PageBox = styled(Box)`
  min-height: calc(100vh - calc(100vh - 100%));
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  min-width: 285px;
  flex-grow: 1;
`

export const Page: React.FC<PageProps> = ({ children, route }) => {
  const user = useSelector((state: RootState) => state.auth.user)
  const rewards = useSelector((state: RootState) => state.myRewards.rewards)
  const hasNewReward = useMemo(() => rewards.some((reward) => !reward.viewedAt), [rewards])
  const location = useLocation()
  const navigationValue = useMemo(() => {
    switch (location.pathname) {
      case ROUTE_HOME: return 0
      case ROUTE_RECYCLABLES: return 1
      case ROUTE_DEPOSIT: return 2
      case ROUTE_REWARDS:
      case ROUTE_PRIZES: return 3
      case ROUTE_DEPOSITS: return 4
    }
  }, [location.pathname])
  const isAccountPath = matchPath('/account/*', location.pathname)

  return <PageBox sx={{
    display: 'flex',
    flexDirection: 'column',
  }}>
    <AppBar elevation={0} position='static' sx={{
      zIndex: 2,
      mt: 1,
    }}>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Link component={RouterLink} to={ROUTE_HOME} sx={{ display: 'flex' }}>
            <Box
              component="img"
              sx={{
                position: 'relative',
                height: { xxs: 35, xs: 40, sm: 50, md: 50, lg: 55 },
                mr: 2,
                transition: 'all 0.2s ease-in-out',
              }}
              alt={application.name}
              src={Logo}
            />
          </Link>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', color: 'black' }}>
            <Typography variant="h6" sx={{ display: { xxs: 'none', md: 'inline-block' }, fontSize: 15, maxWidth: '175px' }} noWrap>{user?.firstname ? `Hi ${user.firstname}` : ''}</Typography>
            <Tooltip title="View Account">
              <IconButton component={RouterLink} to={ROUTE_ACCOUNT} sx={{ ml: 1, p: 0, border: 'none' }}>
                <UserIcon sx={{ fontSize: 40, color: isAccountPath ? BrandColor.ACCENT_BLUE : BrandColor.BLACK }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <Box display={'flex'} flexDirection={'column'} flexGrow={1} sx={{
      maxHeight: { xxs: '100%', md: 'none' },
      pb: { xxs: '85px', xs: '95px' },
      overflowY: { xxs: 'scroll', md: 'auto' },
      overflowX: 'hidden',
    }}>
      <Container maxWidth='md' sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minWidth: '230px' }}>
        {children}
      </Container>
    </Box>
    <Paper elevation={0} sx={{ position: 'fixed', top: 'unset', bottom: 0, width: '100%', zIndex: 999, pb: 2, background: 'none', minWidth: '285px' }}>
      <Container maxWidth='md' sx={{ position: 'relative', height: { xxs: 60, xs: 65 }, zIndex: 2 }}>
        <BottomNavigation value={navigationValue} sx={{ height: '100%', alignItems: 'center', borderRadius: 20, px: 2 }}>
          <BottomNavigationAction component={RouterLink} to={ROUTE_HOME} label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction component={RouterLink} to={ROUTE_RECYCLABLES} label="Recyclables" icon={<RecyclablesIcon />} />
          <BottomNavigationAction component={RouterLink} to={ROUTE_DEPOSIT} label="Deposit" icon={<ScanIcon />} />
          {application.prizeDraw
            ? <BottomNavigationAction component={RouterLink} to={ROUTE_PRIZES} label="Prizes" icon={<StarIcon />} />
            : <BottomNavigationAction component={RouterLink} to={ROUTE_REWARDS} label="Rewards" icon={<Badge variant="dot" color='primary' invisible={!hasNewReward}><StarIcon /></Badge>} />
          }
          <NavigationSchemeSelector />
        </BottomNavigation>
      </Container>
      <Box sx={{ zIndex: 1, pointerEvents: 'none', position: 'absolute', bottom: -1, left: 0, width: '100%', height: 'calc(100% + 4rem)', background: 'linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 65%)' }} />
    </Paper>
  </PageBox>
}
