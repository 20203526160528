export const isFirefox = (): boolean => !!navigator.userAgent.match(/Firefox/)

export const firefoxVersion = (): number | null => {
  const match = navigator.userAgent.match(/Firefox\/(\d+)/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

export const isEdge = (): boolean => !!navigator.userAgent.match(/Edge/)

export const edgeVersion = (): number | null => {
  const match = navigator.userAgent.match(/Edge\/(\d+)/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

export const isSafari = (): boolean => !!navigator.userAgent.match(/Safari/)

export const safariVersion = (): number | null => {
  const match = navigator.userAgent.match(/Version\/(\d+)/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

export const isChrome = (): boolean => !!navigator.userAgent.match(/Chrome/)

export const chromeVersion = (): number | null => {
  const match = navigator.userAgent.match(/Chrome\/(\d+)/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

export const isIos = (): boolean => !!navigator.userAgent.match(/iPhone|iPad|iPod/)

export const iosVersion = (): number | null => {
  const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

export const isAndroid = (): boolean => !!navigator.userAgent.match(/Android/)

export const androidVersion = (): number | null => {
  const match = navigator.userAgent.match(/Android (\d+)\.(\d+)\.?(\d+)?/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return null
}

export const isMobile = (): boolean => isIos() || isAndroid()

export const isDesktop = (): boolean => !isMobile()

export const isWindows = (): boolean => !!navigator.userAgent.match(/Windows/)

export const isMac = (): boolean => !!navigator.userAgent.match(/Macintosh/)

export const isLinux = (): boolean => !!navigator.userAgent.match(/Linux/)

export const browserInfo = {
  isFirefox: isFirefox(),
  firefoxVersion: firefoxVersion(),
  isEdge: isEdge(),
  edgeVersion: edgeVersion(),
  isSafari: isSafari(),
  safariVersion: safariVersion(),
  isChrome: isChrome(),
  chromeVersion: chromeVersion(),
  isIos: isIos(),
  iosVersion: iosVersion(),
  isAndroid: isAndroid(),
  androidVersion: androidVersion(),
  isMobile: isMobile(),
  isDesktop: isDesktop(),
  isWindows: isWindows(),
  isMac: isMac(),
  isLinux: isLinux(),
}
