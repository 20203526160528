import Bugsnag from '@bugsnag/js'
import { Box, CircularProgress, Typography } from '@mui/material'
import { startsWith } from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { getPromotionSchemes } from '~/helpers/promotion'
import { updatePromotion } from '~/redux/features/auth/authSlice'
import { onExtenalRecyclingPointScan } from '~/redux/features/deposits/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import type { RootState } from '~/redux/reducers/root'
import { useAppDispatch } from '~/redux/store'
import { ROUTE_DEPOSIT } from '~/routes/Routes'
import { isAbortError, isNotifiable } from '~/types/guards/errors'

const RecyclingPointScanView: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const userSchemes = useSelector((state: RootState) => state.auth.schemes)

  const fetchRecyclingPoint = async (recyclingPointId: string): Promise<void> => {
    const prefix = recyclingPointId.match(/^[A-Z]+/i)?.[0]
    const promotionSchemes = getPromotionSchemes((promotion) => userSchemes.map(scheme => scheme.id).includes(promotion.id))

    if (prefix) {
      const promotionScheme = promotionSchemes.find(promotionScheme => {
        return startsWith(prefix, promotionScheme.scheme.recyclingPointPrefix)
      })

      if (promotionScheme) {
        Scan2RecyclePromotion.setPromotion(promotionScheme.promotion.id)
        dispatch(updatePromotion({ promotionId: promotionScheme.promotion.id, schemeName: promotionScheme.scheme.name }))
      } else {
        dispatch(addNotification({ type: 'error', message: 'Not a participating scheme' }))
        navigate(ROUTE_DEPOSIT)
        return
      }
    }

    try {
      await dispatch(onExtenalRecyclingPointScan({ promotionId: Scan2RecyclePromotion.id, recyclingPointId })).unwrap()
      dispatch(addNotification({ type: 'success', message: 'Recycling point scanned' }))
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
      if (!isAbortError(error)) dispatch(addNotification({ type: 'error', message: 'Unable to fetch recycling point' }))
    } finally {
      navigate(ROUTE_DEPOSIT)
    }
  }

  useEffect(() => {
    if (userSchemes.length > 0) {
      const params = new URLSearchParams(location.search)
      const recyclingPointId = params.get('depositBinId')

      if (recyclingPointId) {
        void fetchRecyclingPoint(recyclingPointId)
      } else {
        dispatch(addNotification({ type: 'error', message: 'Unable to fetch recycling point' }))
        navigate(ROUTE_DEPOSIT)
      }
    }
  }, [userSchemes])

  return <>
    <Helmet>
      <title>Recycling Point | Recycle at Boots</title>
    </Helmet>
    <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <CircularProgress sx={{ mb: 2 }} />
      <Typography variant="body1">Loading...</Typography>
    </Box>
  </>
}

export default RecyclingPointScanView
