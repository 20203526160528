import { Button, Link } from '@mui/material'

import application from '~/config/application'
import type { User } from '~/types/user/User'

const newLine = '%0D%0A'
const createEmailBody = (userId?: string): string => `
Please action this request to delete my Recycle at Boots account.

I confirm that I have filled in the below and have supplied all necessary information to remove my account and understand the account can not be recovered once this request has been sent.

Full Name:
Email Address:
Advantage Card Number:
The reason for requesting account deletion:
Would you like all of your data to be deleted? (Yes/No)

-------------------------------------

User ID: ${userId}
`.trim().replaceAll('\n', newLine)

const DeleteButton: React.FC<{ user: User | null }> = ({ user }) => {
  if (!user) return null

  return <Button
    LinkComponent={Link}
    href={`mailto:${application.email}?subject=Request Account Deletion&body=${createEmailBody(user?.userId)}`}
    fullWidth
  >
    Request Account Deletion
  </Button>
}

export default DeleteButton
