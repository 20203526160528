import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import AppStoreBlock from '~/components/AppStore/AppStoreBlock'
import { BrandColor } from '~/config/theme'
import { ROUTE_FAQS, ROUTE_LOGIN, ROUTE_PRIVACY, ROUTE_SIGN_UP, ROUTE_TERMS } from '~/routes/Routes'

import GradientSlopes from '~/assets/images/landing-gradient-slopes.svg'
import LogoInverse from '~/assets/images/recycle-at-boots-white.svg'
import { appEnabled } from '~/helpers/nativeApp'

const LandingView: React.FC = () => {
  return <>
    <Helmet>
      <title>Recycle & Get Rewarded! | Recycle at Boots</title>
    </Helmet>
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: 'rgb(0,159,227)',
      background: 'linear-gradient(172deg, rgba(0,159,227,1) 0%, rgba(59,128,195,1) 42%, rgba(29,113,184,1) 54%, rgba(29,113,184,1) 100%)',
      boxShadow: '0px 4px 4px rgba(0,0,0,0.1)',
    }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', pointerEvents: 'none' }}>
        <Box component="img" src={GradientSlopes} sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', objectFit: 'cover', objectPosition: 'left' }} />
      </Box>
      <Container maxWidth="lg" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 0, flexGrow: 1 }}>
          <Box component="img" src={LogoInverse} sx={{ display: 'block', width: { xs: 200, sm: 300 }, mb: 4 }} />
          <Typography variant="body1" sx={{ color: BrandColor.WHITE }}>Powered by Scan2Recycle&trade;</Typography>
          {appEnabled() && <Box sx={{ mt: 2 }}>
            <AppStoreBlock />
          </Box>}
        </Box>
        <Box sx={{ mb: 5, mt: { xxs: 2, md: 4 }, width: '100%' }}>
          <Button component={RouterLink} to={ROUTE_SIGN_UP} variant="outlinedInverse" sx={{ flexGrow: 1, mb: 2, color: '#fff' }} fullWidth>Sign Up</Button>
          <Button component={RouterLink} to={ROUTE_LOGIN} variant="contained" sx={{ flexGrow: 1 }} fullWidth>Sign In</Button>
        </Box>
      </Container>
      <Box sx={{ width: '100%', py: 2 }}>
        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 0 }}>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            divider={null}
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Link component={RouterLink} to={ROUTE_FAQS} variant="body2" sx={{ textDecoration: 'none', color: BrandColor.WHITE }}>FAQs</Link>
            <Link component={RouterLink} to={ROUTE_TERMS} variant="body2" sx={{ textDecoration: 'none', color: BrandColor.WHITE }}>Terms</Link>
            <Link component={RouterLink} to={ROUTE_PRIVACY} variant="body2" sx={{ textDecoration: 'none', color: BrandColor.WHITE }}>Privacy</Link>
          </Stack>
        </Container>
      </Box>
    </Box>
  </>
}

export default LandingView
