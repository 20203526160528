import { Box, Button, CircularProgress, LinearProgress, Typography, linearProgressClasses, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { dinero, toDecimal } from 'dinero.js'

import { BrandColor, theme } from '~/config/theme'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import type { RootState } from '~/redux/reducers/root'
import { useAppDispatch } from '~/redux/store'
import { ROUTE_HOME } from '~/routes/Routes'
import DonationService from '~/services/DonationService'
import type { DonationFundraiser } from '~/types/donation/DonationFundraiser'
import { isErrorResponse } from '~/types/guards/errors'
import { localCurrencyFormatter } from '~/helpers/currency'

const DonationLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    borderRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: BrandColor.PINK,
  },
}))

const FundraiserView: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [fundraiser, setFundraiser] = useState<DonationFundraiser | null>(null)
  const promotionId = useSelector((state: RootState) => state.auth.promotionId)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    void fetchFundraiser()
  }, [promotionId])

  const fetchFundraiser = async (): Promise<void> => {
    try {
      const response = await DonationService.getFundraiser(Scan2RecyclePromotion.id)
      setFundraiser(response)
    } catch (error) {
      if (isErrorResponse(error) && error?.status === 404) {
        navigate(ROUTE_HOME)
      } else {
        dispatch(addNotification({ message: 'An error occurred while fetching the fundraiser' }))
      }
    } finally {
      setLoading(false)
    }
  }

  const getPercentageComplete = (): number => {
    return fundraiser ? (fundraiser.fundraiser.amount / fundraiser.fundraiser.fundraiserLimit) * 100 : 0
  }

  const getFundraiserAmount = (amount?: number): string => {
    const fundraiserAmount = dinero({ amount: amount ?? 0, currency: Scan2RecyclePromotion.currency })
    return toDecimal(fundraiserAmount, localCurrencyFormatter)
  }

  const getFundraiserLimit = (): string => {
    const fundraiserLimit = dinero({ amount: fundraiser?.fundraiser.fundraiserLimit ?? 0, currency: Scan2RecyclePromotion.currency })
    return toDecimal(fundraiserLimit, localCurrencyFormatter).replace('.00', '')
  }

  const getSafeBrand = (brand: string): string => {
    return brand.replace(/[^a-zA-Z0-9]/g, '-').toLocaleLowerCase()
  }

  if (loading) return <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box>

  return (
    <>
      <Typography variant="h4" gutterBottom>Charity Donations</Typography>
      {fundraiser?.fundraiser?.banner && <Box component="picture" sx={{ mb: 2 }}>
        <source srcSet={`/assets/images/fundraisers/webp/${fundraiser?.fundraiser?.banner}.webp 1x, /assets/images/fundraisers/webp/${fundraiser?.fundraiser?.banner}@2x.webp 2x`} />
        <source srcSet={`/assets/images/fundraisers/png/${fundraiser?.fundraiser?.banner}.png 1x, /assets/images/fundraisers/png/${fundraiser?.fundraiser?.banner}@2x.png 2x`} />
        <Box component="img" src={`/assets/images/fundraisers/png/${fundraiser?.fundraiser?.banner}.png`} sx={{ width: '100%' }} />
      </Box>}
      <DonationLinearProgress variant="determinate" value={getPercentageComplete()} sx={{ background: BrandColor.PINK, mb: 1 }} />
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Typography sx={{ color: theme.palette.common.black, fontWeight: 600 }}>{getFundraiserAmount(fundraiser?.fundraiser.amount)}</Typography>
        <Typography sx={{ color: theme.palette.common.black, fontWeight: 600, mx: 1 }}>/</Typography>
        <Typography>{getFundraiserLimit()}</Typography>
      </Box>
      {fundraiser?.charities?.map((charity, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
            <Box component="img" src={`/assets/images/brands/${getSafeBrand(charity.charity)}.svg`} sx={{ width: 150 }} />
            <Typography>{getFundraiserAmount(charity.amount)} raised</Typography>
          </Box>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>{charity.charity}</Typography>
          <Typography>{charity.description}</Typography>
        </Box>
      ))}
      <Button component={RouterLink} to={ROUTE_HOME} variant="contained" color="primary" fullWidth>Back</Button>
    </>
  )
}

export default FundraiserView
