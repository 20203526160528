export const findClosestLocale = (locales: string[]): string => {
  if (!Array.isArray(locales)) return locales
  const browserLocale = navigator.language
  const localeMatch = locales.find(locale => locale === browserLocale)
  const countryMatch = locales.find(locale => locale.split('-').pop() === browserLocale)
  const languageMatch = locales.find(locale => locale.split('-').shift() === browserLocale)

  if (localeMatch) return localeMatch
  if (countryMatch) return countryMatch
  if (languageMatch) return languageMatch

  return locales[0]
}
