import { useState } from 'react'
import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { MapTwoTone } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { LngLat, supported } from 'mapbox-gl'
import Bugsnag from '@bugsnag/js'

import RecyclableList from '~/views/Recyclable/MyRecyclables/RecyclableList'
import Map from '~/components/Map/Map'
import { pluralise } from '~/helpers/text'
import markerImage from '~/assets/images/map/recycling-point.svg'
import type { DepositInformation } from '~/types/deposit/customerDeposit'
import { isNotifiable } from '~/types/guards/errors'
import type { Recyclable } from '~/types/recyclable/Recyclable'
import type { MapLayer } from '~/types/map/Map'

interface ConfirmStepProps {
  deposit: DepositInformation
  recyclables: Recyclable[]
  onNextStep: (values?: Partial<DepositInformation>) => Promise<void>
  onCreateDeposit: () => Promise<void>
  onPreviousStep: () => void
}

const ConfirmStep: React.FC<ConfirmStepProps> = ({ deposit, recyclables, onNextStep, onCreateDeposit, onPreviousStep }) => {
  const [depositing, setDepositing] = useState(false)
  let recyclingPointLayer: MapLayer | null = null

  if (deposit.recyclingPoint) {
    recyclingPointLayer = {
      id: 'recycling-point',
      pins: [{
        coordinates: new LngLat(deposit.recyclingPoint.longitude, deposit.recyclingPoint.latitude),
        title: deposit.recyclingPoint.storeName,
      }],
      icon: {
        name: 'marker',
        source: markerImage,
      },
    }
  }

  const initiateDeposit = async (): Promise<void> => {
    setDepositing(true)

    try {
      await onCreateDeposit()
      void onNextStep()
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
      setDepositing(false)

      // TODO: Handle Errors
      // Network specific error as they will be in store
      // error.message.includes('Network Error)
      // Generic error
    }
  }

  const handleDeposit = (): void => {
    void initiateDeposit()
  }

  return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
    {supported() && deposit.location &&
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, position: 'relative', left: { xxs: -16, md: 0 }, width: { xxs: 'calc(100% + 32px)', md: '100%' } }}>
        <Map
          center={new LngLat(deposit.recyclingPoint?.longitude ?? 0, deposit.recyclingPoint?.latitude ?? 0)}
          layers={recyclingPointLayer ? [recyclingPointLayer] : []}
          userLocation={new LngLat(deposit.location.longitude, deposit.location.latitude)}
          sx={{ minHeight: 200, flexGrow: 1, mb: 2 }}
        />
      </Box>
    }
    {!supported() && deposit.location &&
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', position: 'relative', mt: 6, left: { xxs: -16, md: 0 }, width: { xxs: 'calc(100% + 32px)', md: '100%' } }}>
          <MapTwoTone sx={{ fontSize: 80, marginX: 'auto' }}/>
          <Typography variant="h5">{deposit.recyclingPoint?.storeName}</Typography>
          <Typography variant="h5">{deposit.recyclingPoint?.address}</Typography>
        </Box>
      }
    <Box>
      <Typography variant="h5">Depositing {recyclables.length} {pluralise('item', recyclables.length)}</Typography>
    </Box>
    <Box sx={{ height: 0, flexGrow: 1, overflow: 'auto', my: 2 }}>
      <RecyclableList recyclables={recyclables} fetched={true} fetching={false} />
    </Box>
    <Box sx={{ pt: 1 }}>
      <LoadingButton variant="contained" loading={depositing} onClick={handleDeposit} fullWidth sx={{ mb: 1 }}>Deposit</LoadingButton>
      <Button variant="contained" color="secondary" onClick={onPreviousStep} disabled={depositing} fullWidth>Back</Button>
    </Box>
  </Box>
}

export default ConfirmStep
