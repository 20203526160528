import { type RecyclableScanConditionalStep, RecyclableScanStep } from '~/types/recyclable/recyclableScan'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ScanFlow } from '~/enum/ScanFlow'

import application from './application'

export const recyclableScanSteps: RecyclableScanConditionalStep[] = [
  { step: RecyclableScanStep.CHECKLIST, name: 'Checklist' },
  {
    step: RecyclableScanStep.PRESCRIPTION,
    name: 'Prescription',
    condition: (recyclable) => {
      return Scan2RecyclePromotion.scheme.shortName === 'Blister' && recyclable.prescription === null
    },
  },
  { step: RecyclableScanStep.SCAN, name: 'Scan' },
  { step: RecyclableScanStep.CONFIRM, name: 'Confirm', condition: (recyclable) => { return Boolean(recyclable.scan?.match) } },
  {
    step: RecyclableScanStep.DEFINE_RECYCLABLE,
    name: 'Define Recyclable',
    condition: (recyclable) => {
      switch (application.scanFlow) {
        case (ScanFlow.Category): return !recyclable.brand && !recyclable.category && (!recyclable.form || recyclable.prescription !== null)
        case (ScanFlow.Product): return !recyclable.product && !recyclable.brand && !recyclable.category && !recyclable.form
      }
    },
  },
  { step: RecyclableScanStep.RESULT, name: 'Result' },
]
