import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RecyclingPoint } from '~/types/deposit/RecyclingPoint'
import type { Recyclable } from '~/types/recyclable/Recyclable'

import { onExtenalRecyclingPointScan } from './actions'

interface DepositState {
  items: Recyclable[]
  recyclingPoint: {
    point: RecyclingPoint | null
    scannedAt: number | null
  }
}

const initialState: DepositState = {
  items: [],
  recyclingPoint: {
    point: null,
    scannedAt: null,
  },
}

export const depositSlice = createSlice({
  name: 'recyclable',
  initialState,
  reducers: {
    addItemToDeposit: (state, action: PayloadAction<Recyclable>) => {
      state.items = state.items.concat(action.payload)
    },
    removeItemFromDeposit: (state, action) => {
      state.items = state.items.filter(item => item.bankableId !== action.payload.bankableId)
    },
    removeAllItemsFromDeposit: (state) => {
      state.items = []
    },
    resetDeposit: (state) => {
      state.items = []
      state.recyclingPoint = {
        point: null,
        scannedAt: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onExtenalRecyclingPointScan.fulfilled, (state, action) => {
      state.recyclingPoint = {
        point: action.payload,
        scannedAt: (new Date()).valueOf(),
      }
    })
  },
})

export const { addItemToDeposit, removeItemFromDeposit, removeAllItemsFromDeposit, resetDeposit } = depositSlice.actions

export default depositSlice.reducer
