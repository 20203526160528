import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Tab, LinearProgress } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import deposit from '~/config/deposit'
import { BrandColor, getSchemeColor, theme } from '~/config/theme'
import { ApprovedIcon, PendingIcon, RecyclableStatus, RejectedIcon } from '~/constants/recyclable'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ROUTE_ADD_RECYCLABLE } from '~/routes/Routes'
import type { RecyclablesByStatus } from '~/types/recyclable/RecyclablesByStatus'
import type { Recyclable } from '~/types/recyclable/Recyclable'

import RecyclableList from './RecyclableList'
interface MyRecyclablesProps {
  recyclables: RecyclablesByStatus
  selectedRecyclables: RecyclablesByStatus
  fetching: boolean
  fetched: boolean
  onRecyclableClick: (recyclable: Recyclable) => void
  onDeleteClick: (status: RecyclableStatus) => Promise<void>
}

const getDefaultBankScreen = (): RecyclableStatus => {
  const screen = new URLSearchParams(window.location.search).get('status')

  switch (screen) {
    case 'tagged':
      return RecyclableStatus.TAGGED
    case 'rejected':
      return RecyclableStatus.REJECTED
  }

  return RecyclableStatus.ACCEPTED
}

const MyRecyclables: React.FC<MyRecyclablesProps> = ({
  recyclables,
  selectedRecyclables,
  fetching,
  fetched,
  onRecyclableClick,
  onDeleteClick,
}) => {
  const [activeTab, setActiveTab] = useState<RecyclableStatus>(getDefaultBankScreen())
  const isDeleting = activeTab !== RecyclableStatus.ACCEPTED || selectedRecyclables[activeTab].length > 0
  const colors = getSchemeColor(Scan2RecyclePromotion.scheme.shortName)

  const handleTabChange = (_: any, value: RecyclableStatus): void => {
    setActiveTab(value)
  }

  const handleDeleteClick = (): void => {
    void onDeleteClick(activeTab)
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, mt: 1 }}>
    <TabContext value={activeTab}>
      <TabList
        onChange={handleTabChange}
        aria-label="recyclable states"
        sx={{ width: '100%', mb: 1 }}
        TabIndicatorProps={{ children: <span />, className: `${activeTab}TabIndicator` }}
      >
        <Tab icon={<ApprovedIcon sx={{ stroke: colors.recyclable.status.accepted.color, color: colors.recyclable.status.accepted.color, strokeWidth: 1 }} />} label='Approved' value={RecyclableStatus.ACCEPTED} aria-label="accepted" sx={{ '&.Mui-selected': { color: colors.recyclable.status.accepted.color } }} />
        {deposit.allowedRecyclableStatus === RecyclableStatus.ACCEPTED && <Tab icon={<PendingIcon sx={{ stroke: colors.recyclable.status.tagged.color, color: colors.recyclable.status.tagged.color, strokeWidth: 1 }} />} label='Pending' value={RecyclableStatus.TAGGED} aria-label="tagged" sx={{ '&.Mui-selected': { color: colors.recyclable.status.tagged.color } }} />}
        <Tab icon={<RejectedIcon sx={{ stroke: colors.recyclable.status.rejected.color, color: colors.recyclable.status.rejected.color, strokeWidth: 1 }} />} value={RecyclableStatus.REJECTED} label='Rejected' aria-label="rejected" sx={{ '&.Mui-selected': { color: colors.recyclable.status.rejected.color } }} />
      </TabList>

      <Box sx={{ display: 'flex', flexDirection: 'column', height: 0, flexGrow: 1, overflow: 'auto', position: 'relative', left: { xxs: -16, sm: -24, md: 0 }, px: { xxs: 2, sm: 4, md: 0 }, width: `calc(100% + ${theme.spacing(1)})`, marginLeft: -1 }}>
        <TabPanel value={RecyclableStatus.ACCEPTED} sx={{ flexDirection: 'column', p: 0, flexGrow: 1 }}>
          <RecyclableList recyclables={recyclables.accepted} selectedRecyclables={selectedRecyclables.accepted} fetching={fetching} fetched={fetched} onRecyclableClick={onRecyclableClick} />
        </TabPanel>
        {deposit.allowedRecyclableStatus === RecyclableStatus.ACCEPTED && <TabPanel value={RecyclableStatus.TAGGED} sx={{ flexDirection: 'column', px: 0, flexGrow: 1 }}>
          <RecyclableList recyclables={recyclables.tagged} selectedRecyclables={selectedRecyclables.tagged} fetching={fetching} fetched={fetched} onRecyclableClick={onRecyclableClick} />
        </TabPanel>}
        <TabPanel value={RecyclableStatus.REJECTED} sx={{ flexDirection: 'column', p: 0, flexGrow: 1 }}>
          <RecyclableList recyclables={recyclables.rejected} selectedRecyclables={selectedRecyclables.rejected} fetching={fetching} fetched={fetched} onRecyclableClick={onRecyclableClick} />
        </TabPanel>
      </Box>
    </TabContext>

    <Box sx={{ display: 'flex', flexShrink: 0, mt: 2, justifyContent: 'space-between', alignItems: 'center', position: 'relative', height: 35 }}>
      {fetching && <LinearProgress sx={{ height: 3, position: 'absolute', top: -5, left: { xxs: -17, sm: 0 }, width: { xxs: 'calc(100% + 34px)', sm: '100%' } }}/>}
      <Button
        variant="contained" color="secondary" onClick={handleDeleteClick} fullWidth disabled={selectedRecyclables[activeTab].length === 0}
        sx={{ background: '#FF6666', color: BrandColor.WHITE, transition: 'all 0.5s ease', position: 'absolute', top: '50%', left: 0, height: 0, opacity: 0, overflow: 'hidden', transform: 'translateY(-50%)', pointerEvents: 'none', zIndex: 1, py: 0, ...(isDeleting ? { height: '35px', pointerEvents: 'all', zIndex: 2, opacity: 1 } : {}) }}>
          Delete
      </Button>
      <Button
        to={ROUTE_ADD_RECYCLABLE} component={RouterLink} variant="contained" color="primary" fullWidth
        sx={{ transition: 'all 0.5s ease', position: 'absolute', top: '50%', left: 0, height: 0, opacity: 0, overflow: 'hidden', transform: 'translateY(-50%)', pointerEvents: 'none', zIndex: 1, py: 0, ...(activeTab === RecyclableStatus.ACCEPTED && !isDeleting ? { height: '35px', pointerEvents: 'all', zIndex: 2, opacity: 1 } : {}) }}
      >
        Add Recyclable
      </Button>
    </Box>
  </Box>
}

export default MyRecyclables
