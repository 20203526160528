import Bugsnag from '@bugsnag/js'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import type { RecyclableStatus } from '~/constants/recyclable'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { resolveRecyclableActionStatus } from '~/helpers/recyclable'
import { pluralise } from '~/helpers/text'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { deleteRecyclables, getRecyclables } from '~/redux/features/recyclables/actions'
import { addItemToSelectedRecyclables, removeItemFromSelectedRecyclables } from '~/redux/features/recyclables/recyclableSlice'
import type { RootState } from '~/redux/reducers/root'
import { useAppDispatch } from '~/redux/store'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import type { Recyclable } from '~/types/recyclable/Recyclable'

import MyRecyclables from './MyRecyclables'

const MyRecyclablesView: React.FC = () => {
  const { recyclables, fetching, fetched } = useSelector((state: RootState) => state.recyclable)
  const selectedRecyclables = useSelector((state: RootState) => state.recyclable.selected)
  const abort = useRef(new AbortController())
  const dispatch = useAppDispatch()

  useEffect(() => {
    void fetchRecyclables()

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const fetchRecyclables = async (): Promise<void> => {
    try {
      await dispatch(getRecyclables({ promotionId: Scan2RecyclePromotion.id, init: { signal: abort.current.signal } })).unwrap()
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(addNotification({ type: 'error', message: 'Unable to fetch recyclables' }))
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  const handleRecyclableClick = (recyclable: Recyclable): void => {
    const status = resolveRecyclableActionStatus(recyclable)

    if (selectedRecyclables[status].find(item => item.bankableId === recyclable.bankableId)) {
      dispatch(removeItemFromSelectedRecyclables(recyclable))
    } else {
      dispatch(addItemToSelectedRecyclables(recyclable))
    }
  }

  const handleDeleteClick = async (status: RecyclableStatus): Promise<void> => {
    if (selectedRecyclables[status].length === 0) {
      dispatch(addNotification({ message: 'Select at least one recyclable to delete' }))
      return
    }

    try {
      const recyclableIds = selectedRecyclables[status].filter(item => item.status === status).map(item => item.bankableId)
      const response = await dispatch(deleteRecyclables({ recyclableIds, status })).unwrap()
      const failedCount: number = response.failedRecyclableIds.length

      if (failedCount > 0) {
        dispatch(addNotification({ type: 'error', message: `Unable to delete ${failedCount} ${pluralise('recyclable', failedCount)}` }))
      }
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
      if (!isAbortError(error)) dispatch(addNotification({ type: 'error', message: 'Unable to delete recyclables' }))
    }
  }

  return <>
    <Helmet>
      <title>My Recyclables | Recycle at Boots</title>
    </Helmet>
    <MyRecyclables
      recyclables={recyclables}
      selectedRecyclables={selectedRecyclables}
      fetching={fetching}
      fetched={fetched}
      onRecyclableClick={handleRecyclableClick}
      onDeleteClick={handleDeleteClick}
    />
  </>
}

export default MyRecyclablesView
