import type { StorageAccessLevel } from '@aws-amplify/storage'
import Bugsnag from '@bugsnag/js'
import { Box, CircularProgress, type SxProps } from '@mui/material'
import { Storage } from 'aws-amplify'
import { useEffect, useRef, useState } from 'react'
import { Blurhash } from 'react-blurhash'

import { isNotifiable } from '~/types/guards/errors'

interface S3ImageProps {
  imageKey?: string | null
  blurhash?: string
  level?: StorageAccessLevel
  sx?: SxProps
}

const S3Image: React.FC<S3ImageProps> = ({ imageKey, level = 'public', sx, blurhash }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [uri, setUri] = useState<any>(null)
  const cancelled = useRef<boolean>(false)

  useEffect(() => {
    void getImage()
  }, [imageKey])

  useEffect(() => {
    return () => {
      cancelled.current = true
    }
  }, [])

  const loadImage = (uri: string): void => {
    const img = new Image()
    img.src = uri
    img.onload = () => {
      if (!cancelled.current) {
        setIsLoading(false)
      }
    }
  }

  const getImage = async (): Promise<void> => {
    if (!imageKey) return

    try {
      const uri = await Storage.get(imageKey, {
        level,
        cacheControl: 'max-age=31536000',
        // set to one day to handle skewed clocks
        expires: 86400,
      })
      if (!cancelled.current) {
        setUri(uri)
        loadImage(uri)
      }
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
      if (!cancelled.current) setIsLoading(false)
    }
  }

  if (isLoading) {
    if (blurhash) {
      return <Box sx={sx}>
        <Blurhash
          hash={blurhash}
          width="100%"
          height="100%"
          resolutionX={32}
          resolutionY={32}
        />
      </Box>
    }

    return <CircularProgress />
  }

  return <Box key={imageKey} component="img" src={uri} sx={sx} />
}

export default S3Image
