import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { z } from 'zod'
import { Box, Breadcrumbs, Divider, Link, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { ROUTE_ACCOUNT } from '~/routes/Routes'
import { PasswordField } from '~/components/TextFields/PasswordField'
import { createMuiRegister } from '~/helpers/hookForm'

interface FormValues {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

interface PasswordProps {
  isLoading: boolean
  onSave: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  oldPassword: z.string({
    required_error: 'Current Password is required',
  }).min(8, 'Current Password is required'),
  newPassword: z.string({
    required_error: 'Password is required',
  })
    .min(8, 'Must be at least 8 characters')
    .regex(/[a-z]+/, { message: 'Must have at least one lowercase character' })
    .regex(/[A-Z]+/, { message: 'Must have at least one uppercase character' })
    .regex(/\d+/, { message: 'Must have at least one number' })
    .regex(/^[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`\\]+$/, { message: 'Password is using an invalid character' }),
  confirmPassword: z.string({
    required_error: 'Confirm Password is required',
  }),
}).strict().refine((values) => values.newPassword === values.confirmPassword, {
  message: 'Passwords must match',
  path: ['confirmPassword'],
})

const Password: React.FC<PasswordProps> = ({ isLoading, onSave }) => {
  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  return <>
    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >Personal Details</Typography>
    </Breadcrumbs>

    <Box component="form" noValidate onSubmit={handleSubmit(onSave)}>
      <Typography variant="h4" sx={{ my: 2 }}>Change Password</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>In order to change your password your current password is required.</Typography>
      <PasswordField
        muiRegister = {muiRegister('oldPassword')}
        id="oldPassword"
        label="Current Password"
        autoComplete="current-password"
        error={Boolean(errors.oldPassword)}
        helperText={errors.oldPassword?.message}
      />
      <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
      <PasswordField
        muiRegister = {muiRegister('newPassword')}
        id="newPassword"
        label="New Password"
        autoComplete="new-password"
        error={Boolean(errors.newPassword)}
        helperText={errors.newPassword?.message}
        />
      <PasswordField
        muiRegister = {muiRegister('confirmPassword')}
        id="confirmPassword"
        label="Confirm New Password"
        autoComplete="new-password"
        error={Boolean(errors.confirmPassword)}
        helperText={errors.confirmPassword?.message}
      />
      <Box sx={{ display: 'flex', justifyContent: 'right', my: 2 }}>
        <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ width: { xxs: '100%', md: 'auto' } }}>Save</LoadingButton>
      </Box>
    </Box>
  </>
}

export default Password
