import RejectedIcon from '@mui/icons-material/CloseRounded'
import PendingIcon from '@mui/icons-material/MoreHorizRounded'
import ApprovedIcon from '@mui/icons-material/CheckRounded'

export { PendingIcon, ApprovedIcon, RejectedIcon }

export enum RecyclableStatus {
  TAGGED = 'tagged',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export const RecyclableStatusConfig = {
  [RecyclableStatus.TAGGED]: {
    label: 'Tagged',
    icon: PendingIcon,
  },
  [RecyclableStatus.ACCEPTED]: {
    label: 'Accepted',
    icon: ApprovedIcon,
  },
  [RecyclableStatus.REJECTED]: {
    label: 'Rejected',
    icon: RejectedIcon,
  },
}
