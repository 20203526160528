import { useMemo } from 'react'
import { Autocomplete, Box, Button, Chip, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { matchSorter } from 'match-sorter'

import type { RecyclableScan, RecyclableTags } from '~/types/recyclable/recyclableScan'
import type { RecyclableType } from '~/types/recyclable/RecyclableType'

interface FormValues {
  category: string
}

interface DefineCategoryProps {
  definition: RecyclableTags
  recyclable: RecyclableScan
  recyclableTypes: RecyclableType[]
  loadingCategories: boolean
  creating: boolean
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
  onPreviousStep: () => void
}

const validationSchema = z.object({
  category: z.string({
    required_error: 'Category is required',
  }).min(1, {
    message: 'Category is required',
  }),
}).strict()

const filterOptions = (options: string[], { inputValue }: { inputValue: string }): string[] => matchSorter(options, inputValue)

const DefineCategory: React.FC<DefineCategoryProps> = ({ definition, recyclable, recyclableTypes, loadingCategories, creating, onNextStep, onPreviousStep }) => {
  const categories = useMemo(() => {
    return [...recyclableTypes.map(({ subcategory }) => subcategory)]
  }, [recyclableTypes])

  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      category: definition.category ?? '',
    },
    mode: 'all',
  })

  const selectCategory = async (category: string): Promise<void> => {
    setValue('category', category)
  }

  const handleCategorySelect = (_: React.SyntheticEvent<Element, Event>, value: string[]): void => {
    const category = value ? value.pop() ?? '' : ''

    void selectCategory(category)
  }

  watch('category')

  return <Box component="form" noValidate onSubmit={handleSubmit(onNextStep)} >
    <Box sx={{ mb: 2 }}>
      <Chip label={`Brand: ${definition.brand}`} color="info" />
    </Box>
    <Autocomplete
      disablePortal
      multiple
      id="category"
      options={categories}
      value={getValues('category') ? [getValues('category')] : []}
      filterOptions={filterOptions}
      loading={loadingCategories}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Category"
          placeholder="Type to search categories"
          error={Boolean(errors.category)}
          helperText={errors.category?.message}
          required
        />
      )}
      onChange={handleCategorySelect}
    />
    <Box sx={{ display: { xxs: 'block', sm: 'flex' }, flexDirection: 'row-reverse', width: '100%', mt: 2 }}>
      <LoadingButton type="submit" variant="contained" color="primary" loading={creating} fullWidth>{recyclable.form ? 'Submit' : 'Next'}</LoadingButton>
      <Button type="button" variant="contained" color="secondary" onClick={onPreviousStep} sx={{ mt: { xxs: 1, sm: 0 }, mr: { sm: 1 } }} disabled={creating} fullWidth>Change Brand</Button>
    </Box>
  </Box>
}

export default DefineCategory
