import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { CalendarToday, ExpandMore } from '@mui/icons-material'
import { format } from 'date-fns'

import { BrandColor } from '~/config/theme'
import type { Reward } from '~/types/reward/Reward'

import RewardInstructions from './DonationInstructions'

interface DonationInformationProps {
  reward: Reward
}

const DonationInformation: React.FC<DonationInformationProps> = ({ reward }) => {
  return (
    <Box sx={{
      transition: 'all 0.5s ease',
      overflow: 'hidden',
    }}>
      <Box sx={{ mt: 1 }}>
        {<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff', p: 1, borderRadius: 1, mb: 0 }}>
          <CalendarToday sx={{ mr: 1, width: 15, color: BrandColor.ACCENT_BLUE }} />
          <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
            Received on
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
              {format(new Date(reward.createdAt), "PP 'at' HH:mm a")}
            </Typography>
          </Box>
        </Box>}
      </Box>
      <Box>
        {reward.prize.instructions && <Accordion sx={{ background: 'none', boxShadow: 'none', mt: 1 }}>
          <AccordionSummary expandIcon={<ExpandMore />} id={`reward-redeem-header-${reward.SK}`} aria-controls={`reward-redeem-content-${reward.SK}`}>
            <Typography variant="caption" sx={{ fontWeight: 500 }}>How To Redeem</Typography>
          </AccordionSummary>
          <AccordionDetails id={`reward-redeem-content-${reward.SK}`} sx={{ pt: 0 }}>
            <RewardInstructions reward={reward} />
          </AccordionDetails>
        </Accordion>}
      </Box>
    </Box>
  )
}

export default DonationInformation
