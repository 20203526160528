import { Box, Breadcrumbs, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

import RouterLink from '~/components/Link/RouterLink'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import type { LocationState } from '~/types/location/Location'
import type { Deposit } from '~/types/deposit/Deposit'

import DepositsList from './DepositList'

interface MyDepositsProps {
  deposits: Deposit[]
  fetching: boolean
  fetched: boolean
  hasMore: boolean
  loadMore: () => Promise<void>
}

const MyDeposits: React.FC<MyDepositsProps> = ({
  deposits,
  fetching,
  fetched,
  hasMore,
  loadMore,
}) => {
  const location = useLocation()

  return <>
    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <RouterLink href={ROUTE_ACCOUNT}>Account</RouterLink>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >My Deposits</Typography>
    </Breadcrumbs>}
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <DepositsList deposits={deposits} fetching={fetching} fetched={fetched} loadMore={loadMore} hasMore={hasMore} />
    </Box>
  </>
}

export default MyDeposits
