import { SvgIcon, type SxProps } from '@mui/material'

interface BoxIconProps {
  sx?: SxProps
}

const BoxIcon: React.FC<BoxIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 23.99998 20' sx={sx}>
    <path d="m23.8557,8.36478l-1.7038-3.42668c-.1468-.29523-.3931-.51157-.6798-.64203l.0114-.00309L12.8423.18351c-.5171-.24589-1.1244-.24462-1.6403.00347L3.35216,3.9615c-.64527.31023-1.18072.79642-1.53921,1.39759L.09561,8.23854c-.36007.60385.36993,1.26369.97604.88215l1.00968-.63554v5.85905c0,.6326.36519,1.2033.95292,1.4894l8.17855,3.9813c.2533.1233.5313.1851.8095.1851.2756,0,.5517-.0605.8037-.1819l8.2994-4.0019c.6043-.2919.9797-.8763.9797-1.5252v-3.5458l1.0528-.4835c.7364-.3387,1.0493-1.18969.6978-1.89692Zm-9.2442,3.53162l-1.1445-2.24756,7.0523-3.19766,1.1058,2.22408-7.0136,3.22114Zm4.2615-6.98371l-6.8518,3.10655V1.60279l6.8518,3.3099Zm-14.63938,1.38728l6.71148,3.14161v7.92632l-6.71148-3.2671v-7.80083Zm15.71918,7.76933l-6.8554,3.3058v-3.7964c.2824.3782.7376.5952,1.2083.5952.2138,0,.4309-.0446.6358-.1387l5.0113-2.3015v2.3356Z"/>
  </SvgIcon>
}

export default BoxIcon
