import { Auth } from 'aws-amplify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Bugsnag from '@bugsnag/js'

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (): Promise<void> => {
    await Auth.signOut()
    Bugsnag.setUser(undefined)
  },
)
