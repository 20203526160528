import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import RecyclableService from '~/services/RecyclableService'
import SearchableList from '~/components/Lists/SearchableList'
import { isAbortError } from '~/types/guards/errors'
import type { S2RProduct } from '~/types/recyclable/S2RProduct'
import { getPromotionSchemes } from '~/helpers/promotion'
import { promotions } from '~/config/promotion'
import { type RootState } from '~/redux/reducers/root'

const Products: React.FC = () => {
  const [products, setProducts] = useState<S2RProduct[]>([])
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false)
  const [params] = useSearchParams()
  const schemes = useMemo(() => getPromotionSchemes((promotion) => {
    return promotions.map(promotion => promotion.id).includes(promotion.id)
  }), [])
  const abort = useRef<AbortController>(new AbortController())
  const promotion = useRef<string>(Scan2RecyclePromotion.id)
  const userPromotion = useSelector((state: RootState) => state.auth?.promotionId)
  const initiated = useRef<boolean>(false)

  useEffect(() => {
    void initiate()

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (initiated.current) {
      promotion.current = userPromotion ?? Scan2RecyclePromotion.id
      void fetchProducts()
    }
  }, [userPromotion])

  const initiate = async (): Promise<void> => {
    if (params.get('scheme')) {
      const scheme = schemes.find((scheme) => scheme.scheme.shortName === params.get('scheme'))

      if (scheme) {
        promotion.current = scheme.promotion.id
      }
    }

    void fetchProducts()
  }

  const fetchProducts = async (): Promise<void> => {
    if (!loadingProducts) setLoadingProducts(true)

    try {
      const response = await RecyclableService.getProducts(Scan2RecyclePromotion.id, { signal: abort.current.signal })
      setProducts(response)
      setLoadingProducts(false)
    } catch (error) {
      // TODO: handle error
      // Display an error on the define category step
      // and allow the customer to try and load the brands as likely a network error
      if (!isAbortError(error)) setLoadingProducts(false)
    } finally {
      initiated.current = true
    }
  }

  return <SearchableList
    items={products.map(({ product, forms }) => ({ item: product, tags: forms }))}
    loading={loadingProducts}
    listHeight={500}
  />
}

export default Products
