import 'mapbox-gl/dist/mapbox-gl.css'

import { useEffect, useRef, useState } from 'react'
import mapboxgl, { type LngLat, Map as MapBoxMap } from 'mapbox-gl'
import type { Feature, Geometry, GeoJsonProperties } from 'geojson'
import { Box, type SxProps } from '@mui/material'

import { mapBoxKey } from '~/config/mapbox'
import type { GeoPoint } from '~/types/location/GeoPoint'
import type { MapLayer, MapStyle } from '~/types/map/Map'

import { defaultStyle } from './constants/style'
import { addLayers } from './helpers/addLayers'
import { addListeners } from './helpers/addListeners'

const getUnclusteredLayerIds = (layers: MapLayer[], userLocation: boolean): string[] => {
  return [...(layers.map(layer => layer.id)), ...(userLocation ? ['user'] : [])]
}

interface MapProps {
  center: LngLat
  layers?: MapLayer[]
  userLocation?: LngLat
  sx?: SxProps
  zoom?: number
  cluster?: boolean
  interactive?: boolean
  onMoveEnd?: (finalPoint: GeoPoint) => void
  visibleLayers?: string[]
  style?: MapStyle
  popup?: { lng: number, lat: number, html: string } | null
}

/**
 * Map component
 * @param {LngLat} center - The initial center point of the map.
 * @param {MapLayer[]} layers - The layers which will be displayed on the map. Each layer has a unique id, the contained pins, and optionally an icon which will be displayed for each pin in that layer.
 * @param {LngLat} userLocation - The user's location, if available. This will be displayed as a pin on the map on its own layer.
 * @param {SxProps} sx - The sx props passed to the map container.
 * @param {number} zoom - The initial zoom level of the map.
 * @param {boolean} cluster - Whether or not to cluster the pins on the map. If a userLocation is provided, it will not be clustered.
 * @param {boolean} interactive - Whether or not the map should be interactive. If true, the map will have zoom and pan controls.
 * @param {function} onMoveEnd - Callback function called when the map is moved. The callback function is passed the final center point of the map.
 * @param {string[]} visibleLayers - The names of the layers which should be visible on the map. This is used to filter the pins which are displayed on the map.
 * @param {MapStyle} style - Contains options used to determine the display of the map.
 * @returns {JSX.Element}
 */
const Map: React.FC<MapProps> = ({
  center,
  layers = [],
  userLocation,
  sx,
  zoom = 15,
  cluster = false,
  interactive = false,
  visibleLayers = getUnclusteredLayerIds(layers, !!userLocation),
  onMoveEnd,
  style = defaultStyle,
}) => {
  const mapContainer = useRef<HTMLDivElement>(null)
  const map = useRef<MapBoxMap>()
  // Used to ensure the map isn't modified before it's finished loading
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState<GeoJSON.FeatureCollection<GeoJSON.Geometry>>({
    type: 'FeatureCollection',
    features: [],
  })
  const unclusteredLayerIds = getUnclusteredLayerIds(layers, !!userLocation)

  const addDataToMap = (map: MapBoxMap): void => {
    const source = map.getSource('source')
    const filteredData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: data?.type,
      features: data?.features.filter(feature => visibleLayers.includes(feature.properties?.layer as string)),
    }
    if (source?.type === 'geojson') {
      source.setData(filteredData)
    }

    const disabledSource = map.getSource('disabled-source')
    const disabledData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: data?.type,
      features: data?.features.filter(feature => !visibleLayers.includes(feature.properties?.layer as string)),
    }
    if (disabledSource?.type === 'geojson') {
      disabledSource.setData(disabledData)
    }
  }

  const handleMapLoad = (map: MapBoxMap): void => {
    if (onMoveEnd) {
      map.on('moveend', () => {
        if (map) {
          const currentCenter = map.getCenter()
          onMoveEnd({
            longitude: currentCenter.lng,
            latitude: currentCenter.lat,
          })
        }
      })
    }

    // Create a single array from all the pins from all the layers - to be used for the map source
    const features: Array<Feature<Geometry, GeoJsonProperties>> = layers.flatMap(layer => layer.pins.map(pin => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: pin.coordinates.toArray(),
        },
        properties: {
          title: pin.title,
          layer: layer.id,
        },
      }
    }))

    setData({
      type: 'FeatureCollection',
      features,
    })

    if (interactive && map) {
      map.addControl(new mapboxgl.NavigationControl())
      map.touchPitch.disable()
    }

    void addLayers(map, {
      layers,
      userLocation,
      cluster,
      style,
      features,
    })

    addListeners(map, {
      unclusteredLayerIds,
      cluster,
    })

    setIsLoaded(true)
  }

  useEffect(() => {
    mapboxgl.accessToken = mapBoxKey

    if (!mapContainer.current || !!map.current) return

    map.current = new MapBoxMap({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center,
      zoom,
      interactive,
    })

    map.current.once('load', () => {
      if (map.current) {
        handleMapLoad(map.current)
      }
    })
  }, [])

  useEffect(() => {
    if (isLoaded) {
      map.current?.setCenter(center)
      map.current?.setZoom(zoom)
    }
  }, [center])

  // When the filter changes, update which pins are shown
  useEffect(() => {
    if (map.current && isLoaded) addDataToMap(map.current)
  }, [visibleLayers, data])

  return <Box ref={mapContainer} sx={sx} />
}

export default Map
