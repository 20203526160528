import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import RecyclingBoxLoader from '~/components/Loaders/RecyclingBoxLoader'
import { Page } from '~/components/Page/Page'

interface AuthenticatedRouteGuardProps {
  isAuthenticating: boolean
  isAuthenticated: boolean
}

const AuthenticatedRouteGuard: React.FC<AuthenticatedRouteGuardProps> = ({ isAuthenticating, isAuthenticated }) => {
  const location = useLocation()
  const abort = useRef(new AbortController())

  useEffect(() => {
    return () => {
      if (abort.current) abort.current.abort()
    }
  }, [])

  if (isAuthenticating) {
    return <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <RecyclingBoxLoader sx={{ width: '50px', height: '50px' }} />
    </Box>
  }

  return isAuthenticated
    ? <Page route={location.pathname}><Outlet /></Page>
    : <Navigate to="/login" replace state={{ from: location }} />
}

export default AuthenticatedRouteGuard
