import { useState } from 'react'

import type { Prize } from '~/types/Prize'
import type { Reward } from '~/types/reward/Reward'

import RewardSelectCard from './RewardSelectCard'
import RewardSelectModal from './RewardSelectModal'

interface RewardSelectProps {
  reward: Reward
  selections: Prize[]
  fetchRewardSelections: (claimId: string) => Promise<void>
  onRewardSelect: (reward: Reward, prizeId: string) => Promise<void>
}

const RewardSelect: React.FC<RewardSelectProps> = ({ reward, selections, fetchRewardSelections, onRewardSelect }) => {
  const [open, setOpen] = useState(false)

  const handleFetchRewardSelections = async (): Promise<void> => {
    await fetchRewardSelections(reward.submissionId)
  }

  const handleSelection = async (prizeId: string): Promise<void> => {
    await onRewardSelect(reward, prizeId)
  }

  const handleCancelSelect = (): void => {
    setOpen(false)
  }

  return <>
    <RewardSelectCard reward={reward} onOpen={setOpen} />
    <RewardSelectModal rewards={selections} open={open} fetchRewardSelections={handleFetchRewardSelections} onSelect={handleSelection} onCancel={handleCancelSelect} />
  </>
}

export default RewardSelect
