import { Box, Button, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { theme } from '~/config/theme'
import BoxIcon from '~/components/Icons/BoxIcon'
import RecyclablesIcon from '~/components/Icons/RecyclablesIcon'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ROUTE_DEPOSIT, ROUTE_RECYCLABLES } from '~/routes/Routes'

const AddRecyclableLimit: React.FC = () => {
  return <Box>
    <Typography variant="h5" gutterBottom>Recyclable Limit</Typography>
    <Typography variant="body1" gutterBottom>You are allowed up to {Scan2RecyclePromotion.maxActiveRecyclables} recyclables in your account.</Typography>
    <Typography variant="body1" gutterBottom>Please do any of the following to free up recyclable space:</Typography>
    <List>
      {/* @ts-expect-error ListItemButton override not working for link component */}
      <ListItemButton LinkComponent={Link} sx={{ background: theme.palette.grey[100], mb: 1.5, borderRadius: 2 }} to={`${ROUTE_DEPOSIT}`}>
        <ListItemAvatar sx={{ minWidth: 40 }}>
          <BoxIcon />
        </ListItemAvatar>
        <ListItemText
          primary="Make a deposit at your local recycling point"
          secondary="By depositing your recyclables, you will free up space in your account"
          primaryTypographyProps={{ sx: { mb: 1 } }}
        />
      </ListItemButton>
      {/* @ts-expect-error ListItemButton override not working for link component */}
      <ListItemButton LinkComponent={Link} sx={{ background: theme.palette.grey[100], mb: 1.5, borderRadius: 2 }} to={`${ROUTE_RECYCLABLES}?status=rejected`}>
        <ListItemAvatar sx={{ minWidth: 40 }}>
          <RecyclablesIcon />
        </ListItemAvatar>
        <ListItemText
          primary="Delete rejected recyclables"
          secondary="By deleting rejected recyclables, you will free up space for new recyclables"
          primaryTypographyProps={{ sx: { mb: 1 } }}
        />
      </ListItemButton>
    </List>
    <Button component={Link} to={`${ROUTE_RECYCLABLES}`} variant="contained" color="primary" fullWidth>View Recyclables</Button>
  </Box>
}

export default AddRecyclableLimit
