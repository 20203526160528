import { Box } from '@mui/material'

import type { UserPromotionStatistics } from '~/types/user/UserPromotionStatistics'

import HomeStats from './elements/HomeStats'

interface HomeFooterProps {
  statistics?: UserPromotionStatistics
}

/**
 * HomeFooter shows a reward banner if the user has a valid reward
 * If the user doesn't have a valid reward AND their screensize is large enough, it shows the HomeStats component
 */
export const HomeFooter: React.FC<HomeFooterProps> = ({
  statistics,
}) => {
  return <Box sx={{ display: { xxs: 'flex', mt: 1 } }}>
    <HomeStats
      depositCount={statistics?.totals.deposits ?? 0}
      recyclableCount={statistics?.totals.recyclables ?? 0}
      lastDepositAt={statistics?.lastItem.deposit}
      lastRecyclableAt={statistics?.lastItem.recyclable}
      donations={statistics?.donations}
    />
  </Box>
}
