import type { Transformer } from '@dinero.js/core'
import { GBP, EUR, type Currency } from '@dinero.js/currencies'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

export const getSymbolByCurrency = (currency: Currency<number>): string => {
  switch (currency.code) {
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    default:
      return '£'
  }
}

export const getCurrencyByCode = (code: string): Currency<number> => {
  switch (code) {
    case 'GBP':
      return GBP
    case 'EUR':
      return EUR
    default:
      return GBP
  }
}

export const currencyFormatter: Transformer<number, string, string> = ({ value, currency }): string => {
  return `${getSymbolByCurrency(currency)}${value}`
}

export const localCurrencyFormatter: Transformer<number, string, string> = ({ value, currency }): string => {
  const formatter = new Intl.NumberFormat(Scan2RecyclePromotion.locale, {
    style: 'currency',
    currency: currency.code,
  })
  return formatter.format(Number(value))
}
