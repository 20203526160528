import { AppBar, Box, Container, IconButton, Link, Toolbar, Tooltip } from '@mui/material'
import { Link as RouterLink, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import UserIcon from '~/components/Icons/UserIcon'
import application from '~/config/application'
import type { RootState } from '~/redux/reducers/root'
import { ROUTE_ACCOUNT, ROUTE_HOME } from '~/routes/Routes'

import Logo from '~/assets/images/recycle-at-boots-text-blue.svg'

const PublicPage: React.FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

  return <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '300px' }}>
    <AppBar elevation={0} position='static' sx={{
      zIndex: 2,
      mt: 1,
    }}>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Link component={RouterLink} to={ROUTE_HOME}>
            <Box
              component="img"
              sx={{
                position: 'relative',
                height: { xxs: 35, xs: 40, sm: 50, md: 50 },
                mr: 2,
                transition: 'all 0.2s ease-in-out',
              }}
              alt={application.name}
              src={Logo}
            />
          </Link>
          {isAuthenticated && <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', color: 'black' }}>
            <Tooltip title="View Account">
              <IconButton component={RouterLink} to={ROUTE_ACCOUNT} sx={{ ml: 1, p: 0, border: 'none' }}>
                <UserIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
    <Box>
      <Container component="main" maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', minWidth: '300px', pb: 2 }}>
        <Outlet />
      </Container>
    </Box>
  </Box>
}

export default PublicPage
