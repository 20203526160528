import { SvgIcon, type SxProps } from '@mui/material'

interface HomeIconProps {
  sx?: SxProps
}

const HomeIcon: React.FC<HomeIconProps> = () => {
  return <SvgIcon viewBox='0 0 22.99997 21'>
    <path d="m22.5359,9.25328L12.4359.35149c-.5316-.46865-1.3402-.46865-1.8718,0L.46405,9.25328c-.95007.83752-.34378,2.37872.93593,2.37872h1.69903v8.4155c0,.5245.43796.9525.96077.9525h5.44036v-4.1691c0-.6903.56506-1.2425,1.27156-1.2425h1.4555c.7065,0,1.2716.5522,1.2716,1.2425v4.1691h5.4404c.5369,0,.9607-.428.9607-.9525v-8.4155h1.7001c1.2797,0,1.886-1.5412.9359-2.37872Z"/>
  </SvgIcon>
}

export default HomeIcon
