import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import RecyclableService from '~/services/RecyclableService'
import SearchableList from '~/components/Lists/SearchableList'
import type { RecyclableType } from '~/types/recyclable/RecyclableType'
import { isAbortError } from '~/types/guards/errors'
import { getPromotionSchemes } from '~/helpers/promotion'
import { promotions } from '~/config/promotion'
import { type RootState } from '~/redux/reducers/root'

const Categories: React.FC = () => {
  const [recyclableTypes, setRecyclableTypes] = useState<RecyclableType[]>([])
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
  const [params] = useSearchParams()
  const schemes = useMemo(() => getPromotionSchemes((promotion) => {
    return promotions.map(promotion => promotion.id).includes(promotion.id)
  }), [])
  const abort = useRef<AbortController>(new AbortController())
  const promotion = useRef<string>(Scan2RecyclePromotion.id)
  const userPromotion = useSelector((state: RootState) => state.auth?.promotionId)
  const initiated = useRef<boolean>(false)

  useEffect(() => {
    void initiate()

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (initiated.current) {
      promotion.current = userPromotion ?? Scan2RecyclePromotion.id
      void fetchRecyclableTypes()
    }
  }, [userPromotion])

  const initiate = async (): Promise<void> => {
    if (params.get('scheme')) {
      const scheme = schemes.find((scheme) => scheme.scheme.shortName === params.get('scheme'))

      if (scheme) {
        promotion.current = scheme.promotion.id
      }
    }

    void fetchRecyclableTypes()
  }

  const fetchRecyclableTypes = async (): Promise<void> => {
    if (!loadingCategories) setLoadingCategories(true)

    try {
      const response = await RecyclableService.getRecyclableTypes(promotion.current, { signal: abort.current.signal })
      setRecyclableTypes(response)
      setLoadingCategories(false)
    } catch (error) {
      if (!isAbortError(error)) setLoadingCategories(false)
    } finally {
      initiated.current = true
    }
  }

  return <SearchableList
    items={recyclableTypes.map(({ subcategory, forms }) => ({ item: subcategory, tags: forms }))}
    loading={loadingCategories}
    listHeight={500}
  />
}

export default Categories
