import { SvgIcon, type SxProps } from '@mui/material'

interface RecyclingIconProps {
  sx?: SxProps
}

const RecyclingIcon: React.FC<RecyclingIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 167.95 169.03' sx={sx}>
    <path d="M59.92,122.94l-32.45-.09,12-19.93,1,5.1A11,11,0,0,0,62,103.86L56.63,76A15.12,15.12,0,0,0,38.94,64L10.67,69.48a11,11,0,0,0,4.16,21.57l4.56-.88L2.68,118a19,19,0,0,0,3.66,23.87,19.51,19.51,0,0,0,13,4.75l40.54.11h0a11.88,11.88,0,0,0,0-23.75Z"/><path d="M50.62,60.71A11.91,11.91,0,0,0,67.17,56.8L84.24,28.39l13.7,23.06-4.13-1.16a11,11,0,1,0-6,21.13l27.29,7.71a14.86,14.86,0,0,0,4.1.57,15.13,15.13,0,0,0,14.54-11L141.61,41a11,11,0,0,0-21.14-6L119,40.34,100.51,9.25A18.71,18.71,0,0,0,84.32,0h0A18.75,18.75,0,0,0,68.09,9.16L46.81,44.55A11.82,11.82,0,0,0,50.62,60.71Z"/><path d="M165.33,118.41l-19-31.94A11.88,11.88,0,0,0,125.94,98.6l14.58,24.56-27-.08,3.49-3.3a11,11,0,0,0-15.09-16l-20.6,19.49a15.12,15.12,0,0,0-.59,21.36l19.78,20.91a10.91,10.91,0,0,0,7.67,3.43h.31a10.87,10.87,0,0,0,7.54-3,11,11,0,0,0,.43-15.52l-3.46-3.67,35.5.1h0a19.4,19.4,0,0,0,12.93-4.68A19,19,0,0,0,165.33,118.41Z"/>
  </SvgIcon>
}

export default RecyclingIcon
