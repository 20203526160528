import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { hasLocationEnabled } from '~/helpers/geolocation/geolocation'

import LocationServices from './LocationServices'

const LocationServicesView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [locationEnabled, setLocationEnabled] = useState(false)

  const checkLocation = async (): Promise<void> => {
    if (!isLoading) setIsLoading(true)
    const status = await hasLocationEnabled()

    setLocationEnabled(status)
    setIsLoading(false)
  }

  useEffect(() => {
    void checkLocation()
  }, [])

  return <>
    <Helmet>
      <title>Account - Location Services | Recycle at Boots</title>
    </Helmet>
    <LocationServices isLoading={isLoading} locationEnabled={locationEnabled} checkLocation={checkLocation} />
  </>
}

export default LocationServicesView
