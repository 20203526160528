import { Box, Button, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { ROUTE_REWARDS, ROUTE_HOME } from '~/routes/Routes'

import ThankYou from '~/assets/images/thank-you.svg'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

const ResultStep: React.FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
    <Box sx={{ maxWidth: '500px' }}>
      <Box component="img" src={ThankYou} sx={{ display: 'block', ml: 'auto', mr: 'auto', mb: 5 }}/>
      <Typography variant="h5" gutterBottom sx={{ width: '100%', textAlign: 'center', fontWeight: '700', mb: 4 }}>Thank You!</Typography>
      {Scan2RecyclePromotion.scheme.shortName === 'Blister'
        ? <>
          <Typography variant="body2" gutterBottom>Thank you for recycling your empty blister packs with Boots.</Typography>
          <Typography variant="body2" gutterBottom>Please check your reward account, if successful, you will see your reward.</Typography>
          <Typography variant="body2" sx={{ mb: { xxs: 4, md: 6, lg: 8 } }}>If unsuccessful you will have received an email stating the reason.</Typography>
        </>
        : <Typography variant="body2" sx={{ mb: { xxs: 4, md: 6, lg: 8 } }}>Thank you for recycling your packaging. We are just verifying your deposit.</Typography>
      }
      <Button variant="contained" component={RouterLink} to={ROUTE_HOME} fullWidth>Home</Button>
      <Button variant="contained" color="secondary" component={RouterLink} to={ROUTE_REWARDS} sx={{ mt: 1 }} fullWidth>View Rewards</Button>
    </Box>
  </Box>
)

export default ResultStep
