import { Box, Card, CardMedia, Grid, Typography } from '@mui/material'

import { CircleIcon, GreenCheckIcon, RedCancelIcon } from './CircleIcons'

interface OtherSymbol {
  image: string
  circleIcon?: CircleIcon
}

interface OtherSymbolsSectionProps {
  title: string
  otherSymbols: OtherSymbol[]
}

const OtherSymbolsSection: React.FC<OtherSymbolsSectionProps> = ({ title, otherSymbols }) => {
  const renderCircleIcon = (circleIcon?: CircleIcon): JSX.Element => {
    if (!circleIcon || circleIcon === CircleIcon.BOTH) return <></>

    switch (circleIcon) {
      case CircleIcon.CHECK:
        return <GreenCheckIcon sx={{ top: 12, right: 12, position: 'absolute' }} />
      case CircleIcon.CROSS:
        return <RedCancelIcon sx={{ top: 12, right: 12, position: 'absolute' }} />
    }
  }

  return <>
    <Typography variant="h6" component="h2" sx={{ mt: 1 }} gutterBottom>
      {title}
    </Typography>
    <Grid container paddingBottom={4} justifyContent={{ xxs: 'center', md: 'flex-start' }} spacing={2}>
      {otherSymbols.map(otherSymbol => {
        return <Grid item>
          <Card elevation={0} sx={{ minWidth: 200, height: 200, flexGrow: 1, position: 'relative', overflow: 'visible' }}>
            <Box style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CardMedia src={otherSymbol.image} sx={{ width: 80 }} component="img" />
            </Box>
            {renderCircleIcon(otherSymbol.circleIcon)}
          </Card>
        </Grid>
      })}
    </Grid>
  </>
}

export default OtherSymbolsSection
