import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'
import lottie from 'lottie-web/build/player/lottie_light'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

const ScanningLoader: React.FC = () => {
  const element = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (element.current) {
      const animation = Scan2RecyclePromotion.scheme.shortName === 'Blister' ? 'scanning-blister.json' : 'scanning.json'
      lottie.loadAnimation({
        container: element.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: `assets/animations/${animation}`,
      })
    }
  }, [])

  return <Box sx={{ display: 'flex', height: 0, flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
    <Box ref={element} sx={{ flexGrow: 1 }} />
  </Box>
}

export default ScanningLoader
