import { Box, Button, Chip, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import S3Image from '~/components/Images/S3Image'
import { RecyclableNotification } from '~/components/Notifications/RecyclableNotification'
import { ROUTE_RECYCLABLES } from '~/routes/Routes'
import type { CreateRecyclableResponse } from '~/types/recyclable/response/CreateRecyclableResponse'

interface ResultStepProps {
  recyclableResponse: CreateRecyclableResponse | null
  onReset: () => void
  onAddRepeat: () => void
}

const ResultStep: React.FC<ResultStepProps> = ({ recyclableResponse, onReset, onAddRepeat }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: 0 }}>
    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', mb: 2 }}>
      <S3Image imageKey={recyclableResponse?.imageKey} sx={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%', borderRadius: 1 }} />
    </Box>
    <Box sx={{ display: 'flex', mb: 0.5, alignItems: 'center' }}>
      <Typography variant='h6' gutterBottom sx={{ fontSize: 17, fontWeight: '900' }}>{recyclableResponse?.productName}</Typography>
      <Chip label={recyclableResponse?.form} sx={{ ml: 1 }} />
    </Box>
    {recyclableResponse?.status && <RecyclableNotification status={recyclableResponse.status} statusReason={recyclableResponse.statusReason} sx={{ minHeight: 60, width: '100%', my: 2 }}/>}
    <Box sx={{ mt: 2 }}>
      <Button variant='contained' color='primary' onClick={onReset} fullWidth>Add a Different Item</Button>
      <Button variant='contained' color='primary' onClick={onAddRepeat} fullWidth sx={{ mt: 1 }}>Add Another of this Item</Button>
      <Button component={RouterLink} variant='contained' color='secondary' to={ROUTE_RECYCLABLES} fullWidth sx={{ mt: 1 }}>View Recyclables</Button>
    </Box>
  </Box>
)

export default ResultStep
