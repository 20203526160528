import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { z } from 'zod'
import { useSelector } from 'react-redux'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { PasswordField } from '~/components/TextFields/PasswordField'
import { login } from '~/redux/features/auth/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import type { AWSException } from '~/types/errors/AWSException'
import type { RootState } from '~/redux/reducers/root'
import { createMuiRegister } from '~/helpers/hookForm'

interface VerifyAccessProps {
  children: React.ReactNode
  onSuccessfulVerification?: () => void
}

const validationSchema = z.object({
  email: z.string({
    required_error: 'Email is required',
  }).email('Email is invalid'),
  password: z.string({
    required_error: 'Password is required',
  }),
}).strict()

interface FormValues {
  email: string
  password: string
}

const VerifyAccess: React.FC<VerifyAccessProps> = ({ children, onSuccessfulVerification }) => {
  const [initialCheckComplete, setInitialCheckComplete] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [verified, setVerified] = useState(false)
  const dispatch = useAppDispatch()
  const lastLoginAt = useSelector((state: RootState) => state.auth.lastLoginAt)
  const user = useSelector((state: RootState) => state.auth.user)

  const verifyUser = async (values: FormValues): Promise<void> => {
    const { email, password } = values

    if (user?.email?.toLowerCase() !== email.toLowerCase()) {
      dispatch(addNotification({
        type: 'error',
        message: 'Provided email does not match the email used on initial sign-in',
      }))

      return
    }

    setLoading(true)

    try {
      await dispatch(login({
        email,
        password,
      })).unwrap()

      setLoading(false)
      setVerified(true)
      onSuccessfulVerification?.()
    } catch (error) {
      switch ((error as AWSException).name) {
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          dispatch(addNotification({
            type: 'error',
            message: 'Incorrect email or password',
          }))
          break
        default:
          dispatch(addNotification({
            type: 'error',
            message: 'Sorry, there was a problem verifying your user',
          }))
      }

      setLoading(false)
    }
  }

  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  useEffect(() => {
    void checkIfUserRequiresVerification()
  }, [])

  const checkIfUserRequiresVerification = async (): Promise<void> => {
    const now = (new Date()).getSeconds()
    const hourInSeconds = 3600

    if (lastLoginAt && ((now - lastLoginAt) < hourInSeconds)) {
      setVerified(true)
      onSuccessfulVerification?.()
    }

    setInitialCheckComplete(true)
  }

  if (!initialCheckComplete) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
      <CircularProgress />
    </Box>
  }

  if (!verified) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(verifyUser)}>
        <Typography variant="h4" align="center" gutterBottom>Verify it's you</Typography>
        <Typography align="center" gutterBottom>It has been a while since you logged in, please login to access your account.</Typography>
        <TextField
          {...muiRegister('email')}
          fullWidth
          id="email"
          name="email"
          label="Email"
          margin="normal"
          autoComplete="username"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          required
        />
        <PasswordField
          muiRegister={muiRegister('password')}
          id="password"
          label="Password"
          autoComplete="current-password"
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ mt: 2 }}>Submit</LoadingButton>
      </Box>
    </Box>
  }

  return <>{children}</>
}

export default VerifyAccess
