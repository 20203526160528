import { Auth } from 'aws-amplify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Bugsnag from '@bugsnag/js'

import UserService from '~/services/UserService'
import type { User, UserSchemes } from '~/types/user/User'
import type { CreateUserPayload } from '~/types/user/request/CreateUserPayload'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { getLocalStorageItem, setLocalStorageItem } from '~/helpers/localStorage'
import { type CognitoUserOutput } from '~/types/cognitoUser/CognitoUser'

export const checkIfAuthenticated = createAsyncThunk(
  'auth/checkIfAuthenticated',
  async () => {
    const user: CognitoUserOutput = await Auth.currentAuthenticatedUser()
    const currentPromotion = getLocalStorageItem(`s2r-promotion-${user.attributes.sub}`)
    const promotionId = currentPromotion ?? user?.attributes['custom:campaignId']

    if (promotionId) {
      Scan2RecyclePromotion.setPromotion(promotionId)
    }

    Bugsnag.setUser(user.attributes.sub)

    return user.attributes
  },
)

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string, password: string }) => {
    const user: CognitoUserOutput = await Auth.signIn(email, password)

    await UserService.S2RCheckAppVersion()

    Scan2RecyclePromotion.setPromotion(user.attributes['custom:campaignId'])

    Bugsnag.setUser(user.attributes.sub)

    return user.attributes
  },
)

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (user: Omit<CreateUserPayload, 'userId'> & { password: string }): Promise<void> => {
    const { email, password, campaignId, ...rest } = user
    const mainPromotion = Scan2RecyclePromotion.parentId ?? campaignId

    Scan2RecyclePromotion.setPromotion(campaignId)

    const cognitoResponse = await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: user.firstName,
        family_name: user.lastName,
        'custom:campaignId': campaignId,
      },
    })

    const cognitoId = cognitoResponse.userSub

    await UserService.createUser(mainPromotion, {
      userId: cognitoId,
      campaignId: mainPromotion,
      email,
      ...rest,
    })
  },
)

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignUp',
  async ({ username, code }: { username: string, code: string }) => {
    await Auth.confirmSignUp(username, code.replace(/\s/g, ''))
  },
)

export const resendSignUpCode = createAsyncThunk(
  'auth/resendSignUpCode',
  async (username: string) => {
    await Auth.resendSignUp(username)
  },
)

export const getUser = createAsyncThunk(
  'auth/getPromotionalUser',
  async ({ promotionId, userId, signal }: { promotionId: string, userId: string, signal?: AbortSignal }): Promise<User> => {
    return await UserService.getUser(promotionId, userId, { signal })
  },
)

export const getUserSchemes = createAsyncThunk<UserSchemes, { userId: string, signal?: AbortSignal }>(
  'auth/getUserSchemes',
  async ({ userId, signal }) => {
    const schemes = await UserService.getUserSchemes({ signal })

    if (!schemes.find((scheme) => scheme.id === Scan2RecyclePromotion.id)) {
      Scan2RecyclePromotion.setPromotion(schemes[0].id)
      setLocalStorageItem(`s2r-promotion-${userId}`, schemes[0].id)
    }

    return schemes
  },
)

export const updateUserSchemes = createAsyncThunk<UserSchemes, { userId: string, schemes: UserSchemes }>(
  'auth/updateUserSchemes',
  async ({ userId, schemes }) => {
    await UserService.updateUserSchemes(schemes)

    const persistedPromotion = getLocalStorageItem(`s2r-promotion-${userId}`)
    const currentPromotion = Scan2RecyclePromotion.id

    if (!schemes.find((scheme) => scheme.id === currentPromotion)) {
      Scan2RecyclePromotion.setPromotion(schemes[0].id)
    }

    if (persistedPromotion && !schemes.find((scheme) => scheme.id === persistedPromotion)) {
      setLocalStorageItem(`s2r-promotion-${userId}`, schemes[0].id)
    }

    return schemes
  },
)
