import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import CameraPermissions from './CameraPermissions'

const CameraPermissionsView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [cameraPermissionsEnabled, setCameraPermissionsEnabled] = useState(false)
  let stream: MediaStream

  const checkCameraPermissions = async (): Promise<void> => {
    if (!isLoading) setIsLoading(true)
    const constraints = {
      video: {
        facingMode: 'environment',
      },
      audio: false,
    }

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints)
      setCameraPermissionsEnabled(true)
    } catch (_) {
      setCameraPermissionsEnabled(false)
    }
    setIsLoading(false)
  }

  const deactivateCamera = (): void => {
    if (stream) {
      stream.getTracks().forEach((track) => { track.stop() })
    }
  }

  useEffect(() => {
    void checkCameraPermissions()
    return deactivateCamera
  }, [])

  return <>
    <Helmet>
      <title>Account - Camera Permissions | Recycle at Boots</title>
    </Helmet>
    <CameraPermissions isLoading={isLoading} cameraPermissionsEnabled={cameraPermissionsEnabled} checkCameraPermissions={() => { void checkCameraPermissions() }} />
  </>
}

export default CameraPermissionsView
