import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'

import S3Image from '~/components/Images/S3Image'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import application from '~/config/application'
import { DEFINITION_STEPS_MAP } from '~/config/definitionSteps'
import RecyclableService from '~/services/RecyclableService'
import { DefinitionStep } from '~/enum/DefinitionStep'
import { ScanFlow } from '~/enum/ScanFlow'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import type { RecyclableScan } from '~/types/recyclable/recyclableScan'
import type { RecyclableType } from '~/types/recyclable/RecyclableType'
import type { S2RProduct } from '~/types/recyclable/S2RProduct'

import DefineBrand from './DefineBrand'
import DefineCategory from './DefineCategory'
import DefineForm from './DefineForm'
import DefineProduct from './DefineProduct'

interface DefineRecyclableStepProps {
  recyclable: RecyclableScan
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
}

const { scanFlow } = application

const DEFINITION_STEPS = DEFINITION_STEPS_MAP[scanFlow]

const initialState = {
  brand: '',
  category: '',
  form: '',
  product: '',
}

const DefineRecyclableStep: React.FC<DefineRecyclableStepProps> = ({ recyclable, onNextStep }) => {
  const [step, setStep] = useState<DefinitionStep>(DEFINITION_STEPS[0])
  const [recyclableBrands, setRecyclableBrands] = useState<string[]>([])
  const [recyclableTypes, setRecyclableTypes] = useState<RecyclableType[]>([])
  const [products, setProducts] = useState<S2RProduct[]>([])
  const [creatingRecyclable, setCreatingRecyclable] = useState<boolean>(false)
  const [loadingBrands, setLoadingBrands] = useState<boolean>(true)
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true)
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true)
  const [definition, setDefinition] = useState({ ...initialState })
  const abort = useRef<AbortController>(new AbortController())
  const dispatch = useAppDispatch()

  useEffect(() => {
    switch (scanFlow) {
      case ScanFlow.Category:
        void fetchRecyclableBrands()
        void fetchRecyclableTypes()
        break
      case ScanFlow.Product:
        void fetchProducts()
        break
    }

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const fetchRecyclableBrands = async (): Promise<void> => {
    if (!loadingBrands) setLoadingBrands(true)

    try {
      const response = await RecyclableService.getRecyclableBrands(Scan2RecyclePromotion.id, abort.current)

      setRecyclableBrands(response)
      setLoadingBrands(false)
    } catch (error) {
      // TODO: handle error
      // Display an error on the define brand step
      // and allow the customer to try and load the brands as likely a network error
      if (!isAbortError(error)) setLoadingBrands(false)
    }
  }

  const fetchRecyclableTypes = async (): Promise<void> => {
    if (!loadingCategories) setLoadingCategories(true)

    try {
      const response = await RecyclableService.getRecyclableTypes(Scan2RecyclePromotion.id, { signal: abort.current.signal })
      setRecyclableTypes(response)
      setLoadingCategories(false)
    } catch (error) {
      // TODO: handle error
      // Display an error on the define category step
      // and allow the customer to try and load the brands as likely a network error
      if (!isAbortError(error)) setLoadingCategories(false)
    }
  }

  const fetchProducts = async (): Promise<void> => {
    if (!loadingProducts) setLoadingProducts(true)

    try {
      const response = await RecyclableService.getProducts(Scan2RecyclePromotion.id, { signal: abort.current.signal })
      setProducts(response)
      setLoadingProducts(false)
    } catch (error) {
      // TODO: handle error
      // Display an error on the define category step
      // and allow the customer to try and load the brands as likely a network error
      if (!isAbortError(error)) setLoadingProducts(false)
    }
  }

  const gotoPreviousDefinitionStep = (): void => {
    const currentStepIndex = DEFINITION_STEPS.indexOf(step)
    const previousStep = DEFINITION_STEPS[currentStepIndex - 1]

    setStep(previousStep)
  }

  const gotoNextDefinitionStep = async (values?: Partial<RecyclableScan>): Promise<void> => {
    const currentStepIndex = DEFINITION_STEPS.indexOf(step)
    const nextStep = DEFINITION_STEPS[currentStepIndex + 1]
    let isLastStep = currentStepIndex === DEFINITION_STEPS.length - 1
    const definitionWithNewValues = values ? { ...definition, ...values } : definition
    setDefinition(definitionWithNewValues)

    if (nextStep === DefinitionStep.DEFINE_FORM && recyclable.form) {
      isLastStep = true
      definitionWithNewValues.form = recyclable.form
    }

    if (!isLastStep) {
      setStep(nextStep)
    } else {
      try {
        setCreatingRecyclable(true)
        await onNextStep(definitionWithNewValues)
      } catch (error) {
        if (isNotifiable(error)) {
          Bugsnag.notify(error)
        }

        dispatch(addNotification({ type: 'error', message: 'Unable to create recyclable' }))
      } finally {
        setCreatingRecyclable(false)
      }
    }
  }

  const renderDefinitionForm = (): JSX.Element => {
    switch (step) {
      case DefinitionStep.DEFINE_PRODUCT:
        return <DefineProduct definition={definition} recyclable={recyclable} products={products} loadingProducts={loadingProducts} creating={creatingRecyclable} onNextStep={gotoNextDefinitionStep} />
      case DefinitionStep.DEFINE_BRAND:
        return <DefineBrand definition={definition} recyclableBrands={recyclableBrands} loadingBrands={loadingBrands} onNextStep={gotoNextDefinitionStep} />
      case DefinitionStep.DEFINE_CATEGORY:
        return <DefineCategory definition={definition} recyclable={recyclable} recyclableTypes={recyclableTypes} loadingCategories={loadingCategories} creating={creatingRecyclable} onNextStep={gotoNextDefinitionStep} onPreviousStep={gotoPreviousDefinitionStep} />
      case DefinitionStep.DEFINE_FORM:
        return <DefineForm definition={definition} recyclableTypes={recyclableTypes} products={products} onNextStep={gotoNextDefinitionStep} creating={creatingRecyclable} onPreviousStep={gotoPreviousDefinitionStep} depositFlow={scanFlow}/>
    }
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: 0 }}>
    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', background: '#000', mb: 2 }}>
      <S3Image imageKey={recyclable.photo.imageKey} sx={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
    </Box>
    {renderDefinitionForm()}
  </Box>
}

export default DefineRecyclableStep
