import { Accordion, AccordionDetails, AccordionSummary, Box, List, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { parseISO } from 'date-fns'

import TermsListItem from '../components/TermsListItem'

const BootsMigration: React.FC = () => {
  const termsWithinDisplayWindow = (): boolean => {
    const now = new Date()
    const displayWindowEnd = parseISO('2024-05-20')

    return now <= displayWindowEnd
  }

  if (!termsWithinDisplayWindow()) return

  return <Accordion sx={{ '&:before': { display: 'none' } }}>
    <AccordionSummary expandIcon={<ExpandMore />}>Boots Migration Reward Terms</AccordionSummary>
    <AccordionDetails>
      <List component="ol" sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          From 25th March 2024 until 30th April 2024, you can receive a 50 extra Advantage Card points voucher when you sign up to our the Recycle at Boots App and successfully deposit your first 5 items through the App*
        </TermsListItem>
      </List>
      <Box>
        <Typography variant='caption' gutterBottom>*Voucher valid for 3 days including the day it is issued and must be redeemed in a participating store, please see below for scheme full terms and conditions. Latest redemption date is 2nd May 2024. The voucher will only be issued once and can only be redeemed in a single transaction.</Typography>
        <Typography variant='caption'>Normal Advantage Card Terms and Conditions apply.</Typography>
      </Box>
    </AccordionDetails>
  </Accordion>
}

export default BootsMigration
