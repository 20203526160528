import type { RecyclingPoint } from '~/types/deposit/RecyclingPoint'

import SubmissionProcessorService from './SubmissionProcessorService'

class RecyclingPointService extends SubmissionProcessorService {
  public async getRecyclingPoint (promotionId: string, recyclingPointId: string, init?: { signal?: AbortSignal }): Promise<RecyclingPoint> {
    const response = await this.request(`bin?depositBinId=${recyclingPointId}&campaignId=${promotionId}`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getRecyclingPointsForPromotion (promotionId: string, init?: { signal?: AbortSignal }): Promise<RecyclingPoint[]> {
    const response = await this.publicRequest(`s2r/recycling-points?promotionId=${promotionId}`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getRecyclingPointsForPromotions (promotionIds: string[], init?: { signal?: AbortSignal }): Promise<RecyclingPoint[]> {
    const response = await this.publicRequest(`s2r/recycling-points?promotionIds=${JSON.stringify(promotionIds)}`, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }
}

export default new RecyclingPointService()
