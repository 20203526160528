import type { PrizeType } from '~/enum/prize/PrizeType'

export interface ActiveReward {
  rewardId: string
  sku: string
  type?: PrizeType
  activatedAt: string
  lockedUntil: string
}

export enum RewardState {
  Open = 'OPEN',
  Locked = 'LOCKED',
  Activated = 'ACTIVATED',
  Expired = 'EXPIRED',
  ActivatedExpired = 'ACTIVATED_EXPIRED',
}
