import { SvgIcon, type SxProps } from '@mui/material'

interface RecyclablesIconProps {
  sx?: SxProps
}

const RecyclablesIcon: React.FC<RecyclablesIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 19.66699 21.33057' sx={sx}>
    <path d="m18.66699,7.23682c-.55273,0-1,.44775-1,1v10.58496c0,.28027-.24023.50879-.53516.50879H2.53516c-.29492,0-.53516-.22852-.53516-.50879v-10.58496c0-.55225-.44727-1-1-1s-1,.44775-1,1v10.58496c0,1.38379,1.1377,2.50879,2.53516,2.50879h14.59668c1.39746,0,2.53516-1.125,2.53516-2.50879v-10.58496c0-.55225-.44727-1-1-1Z"/>
    <path d="m4.81744,18.15149h10.51996c.77002,0,1.40002-.63,1.40002-1.40002v-5.33002c0-.76996-.63-1.39996-1.40002-1.39996h-2.46997v-4.29004c0-.89996-.72998-1.63-1.62-1.63h-.51404V1.57422c0-.86816-.70605-1.57422-1.57422-1.57422h-2.25c-.86816,0-1.57422.70605-1.57422,1.57422v2.52722h-.51752c-.89001,0-1.62.73004-1.62,1.63v10.80005c0,.89996.72998,1.62,1.62,1.62Zm9.72998-2.17004h-6.47998v-1.54999h6.47998v1.54999Zm0-3.70996v.76996h-6.47998v-.76996h6.47998ZM7.33496,2h1.39844v2.10144h-1.39844v-2.10144Z"/>
  </SvgIcon>
}

export default RecyclablesIcon
