import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { Provider } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@mui/material'
import { registerSW } from 'virtual:pwa-register'

import store from './redux/store'

import application from './config/application'
import { amplify } from './config/aws'
import { theme } from './config/theme'

import NotificationContainer from '@components/Notifications/NotificationContainer'
import { router } from '@routes/router'

import './App.scss'

registerSW({ immediate: true })

Bugsnag.start({
  apiKey: application.bugSnagKey,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['development', 'staging', 'production'],
  releaseStage: application.stage,
})

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

Amplify.configure(amplify)

const App: React.FC = () => {
  const AppElements = (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <NotificationContainer />
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  )

  if (ErrorBoundary) {
    return (
      <ErrorBoundary>
        {AppElements}
      </ErrorBoundary>
    )
  }

  return AppElements
}

export default App
