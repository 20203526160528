import { Box, Chip, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'

import type { PromotionSchemes } from '~/types/promotion/Promotion'

interface RecyclingPointSelectProps {
  validSchemes: PromotionSchemes
  visibleSchemes: string[]
  onSelectChange?: (selectedSchemeNames: string[]) => void
}

const RecyclingPointSelect: React.FC<RecyclingPointSelectProps> = ({
  validSchemes,
  onSelectChange,
  visibleSchemes,
}) => {
  const handleSelectChange = (event: SelectChangeEvent<string[]>): void => {
    const value = event.target.value
    const currentSchemes = typeof value === 'string' ? value.split(',') : value
    if (onSelectChange) {
      onSelectChange(currentSchemes)
    }
  }

  return (
    <FormControl fullWidth margin='normal'>
      <InputLabel id="recycling-point-select-label">Filter</InputLabel>
      <Select
        labelId='recycling-point-select-label'
        id='recycling-point-select'
        multiple
        fullWidth
        value={visibleSchemes}
        onChange={handleSelectChange}
        label='Filter'
        renderValue={(selected) =>
          <Box sx={{ display: 'flex' }}>
            {selected.map((promotionId) => (
              <Chip key={promotionId} label={validSchemes.find(({ promotion: { id } }) => id === promotionId)?.scheme.name ?? ''} sx={{ mx: 0.5 }} />
            ))}
          </Box>
        }
      >
        {
          validSchemes.map(({ promotion: { id }, scheme: { name } }) => (
            <MenuItem
              key={id}
              value={id}
            >
              {name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default RecyclingPointSelect
