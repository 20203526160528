import { Box, Link } from '@mui/material'

import AppStore from '~/assets/images/native/app-store.svg'
import GooglePlay from '~/assets/images/native/google-play.svg'
import application from '~/config/application'
import { androidAppEnabled, iosAppEnabled } from '~/helpers/nativeApp'

const AppStoreBlock: React.FC = () => {
  return <Box>
    {iosAppEnabled() && <Link href={`https://apps.apple.com/gb/app/recycle-at-boots/id${application.appleAppId}`}><Box component='img' src={AppStore} sx={{ width: 'auto', height: 35, mx: 1 }} /></Link>}
    {androidAppEnabled() && <Link href={`https://play.google.com/store/apps/details?id=${application.playstoreAppId}`}><Box component='img' src={GooglePlay} sx={{ width: 'auto', height: 35, mx: 1 }} /></Link>}
  </Box>
}

export default AppStoreBlock
