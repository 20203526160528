import type { LngLat, Map } from 'mapbox-gl'
import type { Feature, Geometry, GeoJsonProperties } from 'geojson'

import type { MapLayer, MapStyle } from '~/types/map/Map'
import { BrandColor } from '~/config/theme'

import { defaultStyle } from '../constants/style'

interface addLayersOptions {
  userLocation?: LngLat
  layers: MapLayer[]
  cluster: boolean
  style: MapStyle
  features: Array<Feature<Geometry, GeoJsonProperties>>
}

export const addLayers = async (
  map: Map | undefined,
  {
    userLocation,
    layers,
    cluster,
    style,
    features,
  }: addLayersOptions,
): Promise<void> => {
  if (!map) return

  map.addSource('source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
    cluster,
    clusterMaxZoom: style.clusterOptions?.maxZoom ?? defaultStyle.clusterOptions.maxZoom,
    clusterRadius: style.clusterOptions?.radius ?? defaultStyle.clusterOptions.radius,
  })

  map.addSource('disabled-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
    cluster,
    clusterMaxZoom: style.clusterOptions?.maxZoom ?? defaultStyle.clusterOptions.maxZoom,
    clusterRadius: style.clusterOptions?.radius ?? defaultStyle.clusterOptions.radius,
  })

  // Ensure we only load each icon once
  let uniqueIcons: string[] = []
  for (const layer of layers) {
    if (layer.icon && !uniqueIcons.includes(layer.icon.name)) {
      uniqueIcons = [...uniqueIcons, layer.icon.name]

      if (layer.icon) {
        const icon = new Image(70, 70)
        icon.src = layer.icon.source
        await new Promise((resolve) => { icon.onload = resolve })
        map.addImage(layer.icon.name, icon, { pixelRatio: 2 })
        if (layer.icon.disabledSource) {
          const icon = new Image(70, 70)
          icon.src = layer.icon.disabledSource
          await new Promise((resolve) => { icon.onload = resolve })
          map.addImage(`disabled-${layer.icon.name}`, icon, { pixelRatio: 2 })
        }
      }
    }

    if (!map.getLayer(layer.id)) {
      map.addLayer({
        id: layer.id,
        type: 'symbol',
        source: 'source',
        filter: ['!has', 'point_count'],
        layout: {
          'icon-image': layer.icon?.name ?? (style.defaultIcon ?? defaultStyle.defaultIcon),
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
        },
      })
    }

    if (!map.getLayer(`disabled-${layer.id}`)) {
      map.addLayer({
        id: `disabled-${layer.id}`,
        type: 'symbol',
        source: 'disabled-source',
        filter: ['!has', 'point_count'],
        layout: {
          'icon-image': layer.icon?.name ? `disabled-${layer.icon.name}` : (style.defaultIcon ?? defaultStyle.defaultIcon),
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
        },
      })
    }
  }

  if (cluster) {
    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'source',
      filter: ['has', 'point_count'],
      paint: style.clusterPaint ?? defaultStyle.clusterPaint,
    })

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'source',
      filter: ['has', 'point_count'],
      layout: style.clusterCountLayout ?? defaultStyle.clusterCountLayout,
      paint: style.clusterCountPaint ?? defaultStyle.clusterCountPaint,
    })
  }

  // If the user's location is available, add it to the map as its own source and layer - this is so that it can be styled differently and is excluded from clustering
  if (userLocation) {
    map.addSource('user-source', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: userLocation.toArray(),
        },
        properties: {
          layer: 'user',
        },
      },
    })

    map.addLayer({
      id: 'user',
      type: 'circle',
      source: 'user-source',
      paint: {
        'circle-color': '#4285f4',
        'circle-radius': 5,
        'circle-stroke-color': BrandColor.WHITE,
        'circle-stroke-width': 2,
      },
    })
  }
}
