import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { clearNotifications, removeNotification } from '~/redux/features/notifications/notificationSlice'
import type { RootState } from '~/redux/reducers/root'
import { useAppDispatch } from '~/redux/store'

import type { Notification } from '~/types/notifications/Notification'

import NotificationItem from './NotificationItem'

const NotificationContainer: React.FC = () => {
  const updating = useRef(false)
  const dispatch = useAppDispatch()
  const notifications = useSelector((state: RootState) => state.notification.notifications)
  const [notification, setNotification] = useState<Notification | null>(null)

  const handleNotificationUpdate = (): void => {
    if (!updating.current && notifications.length > 0) {
      if (notification === null || (notification !== null && notifications[0].id !== notification.id)) {
        updating.current = true
        setNotification(notifications[0])
        if (notification) dispatch(clearNotifications(notifications[0].id))
      }
    } else {
      setNotification(null)
    }
  }

  useEffect(() => {
    handleNotificationUpdate()
  }, [notifications])

  useEffect(() => {
    updating.current = false
    handleNotificationUpdate()
  }, [notification])

  const handleClose = (id: string): void => {
    dispatch(removeNotification(id))
  }

  return <NotificationItem notification={notification} onClose={handleClose} />
}

export default NotificationContainer
