import type { Currency } from 'dinero.js'

import { promotions } from '~/config/promotion'
import { schemes } from '~/config/schemes'
import { getCurrencyByCode } from '~/helpers/currency'
import type { Promotion, Scheme } from '~/types/promotion/Promotion'

class Scan2RecyclePromotion {
  private promotion: Promotion
  private activeScheme: Scheme

  constructor (promotionId?: string) {
    this.promotion = promotionId ? this.findPromotion(promotionId) : this.getPromotionByNavigator()
    this.activeScheme = this.getScheme()
  }

  get id (): string {
    return this.promotion.id
  }

  get parentId (): string | undefined {
    return this.promotion.parentId
  }

  get country (): string {
    return this.promotion.country
  }

  get currency (): Currency<number> {
    const currency = getCurrencyByCode(this.promotion.currency)

    return currency
  }

  get locale (): string {
    // TODO: Find a better way to get the correct locale
    return this.promotion.locales[0]
  }

  get locales (): string[] {
    return this.promotion.locales
  }

  get minDepositItems (): number {
    return this.promotion.minDepositItems
  }

  get maxDepositItems (): number | undefined {
    return this.promotion.maxDepositItems
  }

  get maxActiveRecyclables (): number | undefined {
    return this.promotion.maxActiveRecyclables
  }

  get scheme (): Scheme {
    return this.activeScheme
  }

  public setPromotion (promotionId: string): this {
    this.promotion = this.findPromotion(promotionId)
    this.activeScheme = this.getScheme()

    return this
  }

  private getPromotionByNavigator (): Promotion {
    const promotionByLocale = promotions.find(promotion => {
      return promotion.locales.findIndex(locale => {
        return locale === navigator.language
      }) !== -1
    })
    if (promotionByLocale) return promotionByLocale

    const promotionByCountry = promotions.find(promotion => {
      return promotion.locales.findIndex(locale => {
        const [, country] = locale.split('-')
        return country && navigator.language.includes(country)
      }) !== -1
    })
    if (promotionByCountry) return promotionByCountry

    const promotionByLanguage = promotions.find(promotion => {
      return promotion.locales.findIndex(locale => {
        const [language] = locale.split('-')
        return language && navigator.language.includes(language)
      }) !== -1
    })
    if (promotionByLanguage) return promotionByLanguage

    return promotions[0]
  }

  private findPromotion (promotionId: string): Promotion {
    const promotion = promotions.find(promotion => promotion.id === promotionId)

    if (!promotion) throw new Error('Promotion not found')

    return promotion
  }

  private getScheme (): Scheme {
    const scheme = schemes.find(scheme => scheme.promotionIds.includes(this.promotion.id))

    if (!scheme) throw new Error('Scheme not found')

    return scheme
  }
}

export default new Scan2RecyclePromotion()
