import Bugsnag from '@bugsnag/js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { promotions } from '~/config/promotion'
import type { SchemeSelectorFormValues } from '~/components/Form/User/SchemeSelectorForm'
import { getPromotionSchemes } from '~/helpers/promotion'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { getUserSchemes, updateUserSchemes } from '~/redux/features/auth/actions'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import { isNotifiable } from '~/types/guards/errors'

import Schemes from './Schemes'

const SchemesView: React.FC = () => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  const userDefaultPromotion = promotions.find(promotion => promotion.id === cognitoUser?.campaignId)
  const validSchemes = getPromotionSchemes((promotion) => promotion.country === userDefaultPromotion?.country)
  const userSchemes = useSelector((state: RootState) => state.auth.schemes)
  const dispatch = useAppDispatch()

  useEffect(() => {
    void fetchUserSchemes()
  }, [])

  const fetchUserSchemes = async (): Promise<void> => {
    if (cognitoUser) {
      try {
        await dispatch(getUserSchemes({ userId: cognitoUser.userId })).unwrap()
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
        dispatch(addNotification({ type: 'error', message: 'Failed to fetch schemes' }))
      } finally {
        setIsFetching(false)
      }
    }
  }

  const save = async (values: SchemeSelectorFormValues): Promise<void> => {
    if (cognitoUser) {
      try {
        setIsUpdating(true)

        const schemes = validSchemes
          .filter(scheme => values.schemes.includes(scheme.promotion.id))
          .sort((a, b) => a.scheme.priority - b.scheme.priority)
          .map(scheme => ({ id: scheme.promotion.id, name: scheme.scheme.name }))

        await dispatch(updateUserSchemes({ userId: cognitoUser.sub, schemes })).unwrap()
        dispatch(addNotification({ type: 'success', message: 'Saved' }))
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
        dispatch(addNotification({ type: 'error', message: 'Failed to update schemes' }))
      } finally {
        setIsUpdating(false)
      }
    }
  }

  return <>
    <Helmet>
      <title>Account - Schemes | Recycle at Boots</title>
    </Helmet>
    <Schemes validSchemes={validSchemes} userSchemes={userSchemes} isFetching={isFetching} isUpdating={isUpdating} onSubmit={save} />
  </>
}

export default SchemesView
