import { Box } from '@mui/material'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { type RecyclableScan, RecyclableScanStep } from '~/types/recyclable/recyclableScan'
import type { CreateRecyclableResponse } from '~/types/recyclable/response/CreateRecyclableResponse'

import AddRecyclableLimit from './AddRecyclableLimit'
import ChecklistStep from './Steps/ChecklistStep'
import BlisterChecklistStep from './Steps/BlisterChecklistStep'
import PrescriptionStep from './Steps/PrescriptionStep'
import ConfirmScanStep from './Steps/ConfirmScanStep'
import DefineRecyclableStep from './Steps/DefineRecyclableStep/DefineRecyclableStep'
import ResultStep from './Steps/ResultStep'
import ScanStep from './Steps/ScanStep'

interface AddRecyclableProps {
  step: RecyclableScanStep
  recyclable: RecyclableScan
  recyclableLimit: boolean
  recyclableResponse: CreateRecyclableResponse | null
  flowId: string
  onNextStep: (values?: Partial<RecyclableScan>, id?: string) => Promise<void>
  onReset: () => void
  onAddRepeat: () => void
}

const AddRecyclable: React.FC<AddRecyclableProps> = ({ step, recyclable, recyclableLimit, recyclableResponse, onNextStep, onReset, onAddRepeat, flowId }) => {
  const renderStep = (): JSX.Element => {
    if (recyclableLimit) {
      return <AddRecyclableLimit />
    }

    switch (step) {
      case RecyclableScanStep.CHECKLIST:
        return Scan2RecyclePromotion.scheme.shortName === 'Blister' ? <BlisterChecklistStep onNextStep={onNextStep} /> : <ChecklistStep onNextStep={onNextStep}/>
      case RecyclableScanStep.PRESCRIPTION:
        return <PrescriptionStep onNextStep={onNextStep} />
      case RecyclableScanStep.CONFIRM:
        return <ConfirmScanStep recyclable={recyclable} onNextStep={onNextStep} />
      case RecyclableScanStep.DEFINE_RECYCLABLE:
        return <DefineRecyclableStep recyclable={recyclable} onNextStep={onNextStep} />
      case RecyclableScanStep.RESULT:
        return <ResultStep recyclableResponse={recyclableResponse} onReset={onReset} onAddRepeat={onAddRepeat}/>
    }

    return <ScanStep onNextStep={onNextStep} flowId={flowId} />
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pt: 1 }}>
    {renderStep()}
  </Box>
}

export default AddRecyclable
