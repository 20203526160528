import { Box, Grid, Typography } from '@mui/material'

import { CircleIcon, GreenCheckIcon, RedCancelIcon } from './CircleIcons'

interface RecyclingSymbol {
  image: string
  circleIcon: CircleIcon
}

interface RecyclingSymbolsSectionProps {
  title: string
  recyclingSymbols: RecyclingSymbol[]
}

const RecyclingSymbolsSection: React.FC<RecyclingSymbolsSectionProps> = ({ title, recyclingSymbols }) => {
  const renderCircleIcon = (circleIcon: CircleIcon): JSX.Element => {
    switch (circleIcon) {
      case CircleIcon.CHECK:
        return <GreenCheckIcon sx={{ position: 'absolute', bottom: -12, right: -12 }} />
      case CircleIcon.CROSS:
        return <RedCancelIcon sx={{ position: 'absolute', bottom: -12, right: -12 }} />
      case CircleIcon.BOTH:
        return <>
          <RedCancelIcon sx={{ position: 'absolute', bottom: -12, right: 87.5 }} />
          <GreenCheckIcon sx={{ position: 'absolute', bottom: -12, right: -12 }} />
        </>
    }
  }

  return <>
    <Typography variant="h6" sx={{ mt: 1 }} gutterBottom>
      {title}
    </Typography>
    <Grid container paddingBottom={2} spacing={2}>
      {recyclingSymbols.map(recyclingSymbol => {
        return <Grid item sx={{ position: 'relative' }}>
          <Box component="img" src={recyclingSymbol.image} sx={{ width: recyclingSymbol.circleIcon === CircleIcon.BOTH ? 200 : 100 }} />
          {renderCircleIcon(recyclingSymbol.circleIcon)}
        </Grid>
      })}
    </Grid>
  </>
}

export default RecyclingSymbolsSection
