import { formatISO } from 'date-fns'
import { Box, Button, FormGroup, FormHelperText, Typography } from '@mui/material'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import clean from '~/assets/images/checklist/clean.svg'
import hardToRecycle from '~/assets/images/checklist/hardToRecycle.svg'
import nonHazardous from '~/assets/images/checklist/nonHazardous.svg'
import { setLocalStorageItem } from '~/helpers/localStorage'
import type { RecyclableScan } from '~/types/recyclable/recyclableScan'
import LabelWithContentCheckbox from '~/components/Form/Checkbox/LabelWithContentCheckbox'

interface FormValues {
  clean: boolean
  safe: boolean
  hardToRecycle: boolean
}

interface ChecklistStepProps {
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
}

const validationSchema = z.object({
  clean: z.literal(true, {
    errorMap: () => ({ message: 'Your recyclable must be clean & empty' }),
  }),
  safe: z.literal(true, {
    errorMap: () => ({ message: 'We cannot recycle hazardous materials' }),
  }),
  hardToRecycle: z.literal(true, {
    errorMap: () => ({ message: 'Your recyclable must be hard to recycle' }),
  }),
}).strict()

const ListIcon: React.FC<{ image: string }> = ({ image }) => {
  return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 40, mr: 2, flexShrink: 0 }}>
    <Box component='img' sx={{ width: '100%' }} src={image}/>
  </Box>
}

const ChecklistStep: React.FC<ChecklistStepProps> = ({ onNextStep }) => {
  const handleNext = (): void => {
    setLocalStorageItem('lastSeenRecyclableChecklist', formatISO(new Date()))

    void onNextStep()
  }

  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      clean: false,
      safe: false,
      hardToRecycle: false,
    },
    mode: 'onChange',
  })

  watch(['hardToRecycle', 'safe', 'clean'])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event?.target.name as any, event?.target.checked)
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(handleNext)} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Typography variant="h5" gutterBottom>Recyclable Preparation</Typography>
      <Typography variant="body2" sx={{ fontSize: { sm: 20 }, mb: { sm: 4, xxs: 2 } }} gutterBottom>Before you scan your items, please make sure they follow the below criteria.</Typography>
      <FormGroup sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: { xxs: 'space-evenly', md: 'center' } }}>
        <LabelWithContentCheckbox
          icon={<ListIcon image={hardToRecycle} />}
          name='hardToRecycle'
          label="Your eligible item is hard to recycle"
          content="Your recyclable must be hard to recycle, meaning it cannot be recycled via local recycling channels"
          checked={getValues('hardToRecycle')}
          value={true}
          onChange={handleChange}
        />
        <LabelWithContentCheckbox
          icon={<ListIcon image={nonHazardous} />}
          name='safe'
          label="Your eligible hard to recycle item is non-hazardous"
          content="Your recyclable must be non-hazardous, meaning it cannot contain items that are marked with a hazardous symbol or cause contamination in the recycling process"
          checked={getValues('safe')}
          value={true}
          onChange={handleChange}
        />
        <LabelWithContentCheckbox
          icon={<ListIcon image={clean} />}
          name='clean'
          label="Your eligible hard to recycle item is clean and empty"
          content="Your recyclable must be clean and completely used before you recycle it with us"
          checked={getValues('clean')}
          value={true}
          onChange={handleChange}
        />
        {Object.keys(errors).length > 0 && <FormHelperText error>Please confirm you have acknowleged the above</FormHelperText>}
      </FormGroup>

      <Box sx={{ width: '100%', mt: 2 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>Next</Button>
      </Box>
    </Box>
  )
}

export default ChecklistStep
