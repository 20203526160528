import { Box, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Link, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import application from '~/config/application'
import { LINK_PRIVACY_POLICY } from '~/constants/externalLinks'
import { ROUTE_PRIVACY, ROUTE_TERMS } from '~/routes/Routes'

interface FormValues {
  informationOptIn: boolean
  terms: boolean
  clientTerms: boolean
  locationServices: boolean
}

interface AgreementsProps {
  isLoading: boolean
  onNextStep: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  informationOptIn: z.boolean(),
  terms: z.literal(true, {
    errorMap: () => ({ message: 'You must agree to the Scan2Recycle & Metrisk terms and conditions' }),
  }),
  clientTerms: z.literal(true, {
    errorMap: () => ({ message: `You must agree to the ${application.client} terms and conditions` }),
  }),
  locationServices: z.literal(true, {
    errorMap: () => ({ message: 'You must agree to the location services' }),
  }),
}).strict()

const Agreements: React.FC<AgreementsProps> = ({ isLoading, onNextStep }) => {
  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      informationOptIn: false,
      terms: false,
      clientTerms: false,
      locationServices: false,
    },
    mode: 'onChange',
  })

  watch(['informationOptIn', 'terms', 'clientTerms', 'locationServices'])

  return (
    <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={handleSubmit(onNextStep)}>
      <Box>
        <Typography variant="h4" gutterBottom>Terms & Privacy</Typography>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>Location Services</Typography>

        <Typography variant="body1" gutterBottom>
          It is a condition of participation that location services are enabled on the device(s) used for Banking and Depositing.
        </Typography>

        <Typography variant="body1" gutterBottom>Non-continuous geolocation (this Application):</Typography>

        <Typography variant="body1" gutterBottom>
          This Application may collect, use, and share user location data in order to provide location-based services.
        </Typography>

        <Typography variant="body1" gutterBottom>
          The geographic location is determined in a manner that isn't continuous, and allows the application to
          detect the position automatically.
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <FormGroup sx={{ my: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              name="terms"
              checked={getValues('terms')}
              onChange={() => { setValue('terms', !getValues('terms')) }}
              required
            />}
          label={<>I confirm that I have read and agree to the <Link href={ROUTE_TERMS} target="_blank">Scan2Recycle Terms & Conditions</Link> and <Link href={ROUTE_PRIVACY} target="_blank">Metrisk Privacy Policy</Link></>}
        />
        <FormHelperText error sx={{ mb: 2 }}>{errors.terms?.message}</FormHelperText>
        <FormControlLabel
          control={
            <Checkbox
              name="clientTerms"
              checked={getValues('clientTerms')}
              onChange={() => { setValue('clientTerms', !getValues('clientTerms')) }}
              required
            />}
          label={<>I confirm that I have read and agree to the <Link href={LINK_PRIVACY_POLICY} target="_blank">{application.client} Privacy Policy</Link></>}
        />
        <FormHelperText error sx={{ mb: 2 }}>{errors.clientTerms?.message}</FormHelperText>
        <FormControlLabel
          control={
            <Checkbox
              name="locationServices"
              checked={getValues('locationServices')}
              onChange={() => { setValue('locationServices', !getValues('locationServices')) }}
              required
            />}
          label="I am aware that location services must be enabled for the purpose of Banking and Depositing while using the Scan2Recycle service"
        />
        <FormHelperText error sx={{ mb: 2 }}>{errors.locationServices?.message}</FormHelperText>
        <FormControlLabel
          control={
            <Checkbox
              name="informationOptIn"
              checked={getValues('informationOptIn')}
              onChange={() => { setValue('informationOptIn', !getValues('informationOptIn')) }}
            />}
          label={`${application.client} would like to send you relevant information and offers by email and digital communications. If you don't already receive these and would like to, please tick the box. You can unsubscribe at any time`}
          sx={{ mb: 2 }}
        />
      </FormGroup>
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        sx={{ mt: 2 }}>SIGN UP</LoadingButton>
    </Box>
  )
}

export default Agreements
