import type { Prize } from '~/types/Prize'
import type { PrizeDraw } from '~/types/prizeDraw/PrizeDraw'

import SubmissionProcessorService from './SubmissionProcessorService'

class PrizeService extends SubmissionProcessorService {
  public async getPrizeDraws (promotionId: string, init?: { nextToken?: string | null, signal?: AbortSignal }): Promise<PrizeDraw[]> {
    const path = `s2r/promotion/${promotionId}/prizedraws?limit=2`
    const response = await this.request(path, { signal: init?.signal })

    return response.status === 200 ? (await response.json()).items : await Promise.reject(response)
  }

  public async getPrizesForDraw (promotionId: string, prizeDrawId: string, init?: { nextToken?: string | null, signal?: AbortSignal }): Promise<PrizeDraw[]> {
    const path = `s2r/promotion/${promotionId}/prize-draw/${prizeDrawId}/prizes`
    const response = await this.request(path, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getRewardSelections (promotionId: string, claimId: string): Promise<Prize[]> {
    const response = await this.request(`s2r/promotion/${promotionId}/reward/selections`, {
      method: 'POST',
      body: JSON.stringify({ claimId }),
    })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async setRewardSelection (promotionId: string, rewardId: string, prizeSelectionId: string): Promise<null> {
    const response = await this.request(`s2r/promotion/${promotionId}/reward/choice`, {
      method: 'POST',
      body: JSON.stringify({
        prizeSelectionId,
        entity: 's2rReward',
        entityId: rewardId,
      }),
    })

    return response.status === 204 ? null : await Promise.reject(response)
  }
}

export default new PrizeService()
