import { Box, Checkbox, MenuItem, Typography } from '@mui/material'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import type { PromotionScheme } from '~/types/promotion/Promotion'

interface NavigationSchemeMenuItemProps {
  scheme: PromotionScheme
  onSelect: (scheme: PromotionScheme) => void
}

const NavigationSchemeMenuItem: React.FC<NavigationSchemeMenuItemProps> = ({ scheme, onSelect }) => {
  const handleClick = (): void => {
    if (Scan2RecyclePromotion.id !== scheme.promotion.id) {
      onSelect(scheme)
    }
  }

  return <MenuItem
    key={scheme.promotion.id}
    onClick={handleClick}
    sx={{ width: 'auto', background: 'white', borderRadius: 2, mx: 1, mb: 1, '&:last-child': { mb: 0 } }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 35, height: 35, background: scheme.scheme.icon.color, color: 'white', borderRadius: 6, mr: 2 }}>
      <scheme.scheme.icon.component />
    </Box>
    <Box>
      <Typography variant="body2">{scheme.scheme.name}</Typography>
    </Box>
    <Checkbox
      color={'black'}
      checked={Scan2RecyclePromotion.id === scheme.promotion.id}
      sx={{ position: 'relative', right: -10, ml: 'auto' }} />
  </MenuItem>
}

export default NavigationSchemeMenuItem
