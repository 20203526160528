import { SvgIcon, type SxProps } from '@mui/material'

interface FlagIconProps {
  sx?: SxProps
}

const FlagCircleIcon: React.FC<FlagIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 171.57996 171.58002' sx={sx}>
    <path d='M146.46,25.12a85.82533,85.82533,0,1,0,25.12,60.66A85.55618,85.55618,0,0,0,146.46,25.12ZM136.71,86.7,76.14,102.54a8.39829,8.39829,0,0,1-4.25-16.25l50.24-13.14L62.89,48.19v85.44a8.4,8.4,0,0,1-16.8,0V42.73a13.1754,13.1754,0,0,1,18.3-12.14l74.1,31.22a13.17518,13.17518,0,0,1-1.78,24.89Z'/>
  </SvgIcon>
}

export default FlagCircleIcon
