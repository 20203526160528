import { SvgIcon, type SxProps } from '@mui/material'

interface RecyclingPointIconProps {
  sx?: SxProps
}

const RecyclingPointIcon: React.FC<RecyclingPointIconProps> = ({ sx }) => {
  return <SvgIcon viewBox="0 0 120.87823 134.01998" sx={sx}>
    <polygon fill='#757575' points="96.77288 15.95327 96.77288 33.73341 110.72299 33.73341 96.77288 15.95327"/>
    <path fill='#757575' d="m86.23117,3.58425c0-.56235-.14048-1.08952-.3513-1.54634-.56235-1.19461-1.8273-2.03791-3.23295-2.03791H6.74664l26.42464,33.73341h53.05989V3.58425Z"/>
    <path fill='#757575' d="m0,130.43616c0,2.00274,1.61626,3.58382,3.61921,3.58382h23.01604V42.483L0,8.4682v121.96796Z"/>
    <path fill='#757575' d="m119.68362,45.18878l-.03517-.03517c-.5973-.56235-1.44082-.87848-2.3193-.87848H37.17697v89.74486h80.15218c1.96778,0,3.54908-1.58109,3.54908-3.51391V47.8242c0-1.05434-.45682-2.00274-1.19461-2.63543Z"/>
  </SvgIcon>
}

export default RecyclingPointIcon
