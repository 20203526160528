import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import RequestPasswordReset, { type FormValues as FormValuesRequest } from './RequestPasswordReset'
import ResetPassword, { type FormValues as FormValuesReset } from './ResetPassword'

const ForgotPasswordView: React.FC = () => {
  const [resetEmail, setResetEmail] = useState<string>('')
  const [step, setStep] = useState<'request' | 'reset'>('request')

  const requestPasswordReset = async (values: FormValuesRequest): Promise<void> => {
    const { email } = values

    await Auth.forgotPassword(email)

    setResetEmail(email)
    setStep('reset')
  }

  const resetPassword = async (values: FormValuesReset): Promise<void> => {
    const { code, password } = values

    await Auth.forgotPasswordSubmit(resetEmail, code, password)
  }

  const renderStep = (): JSX.Element => {
    switch (step) {
      case 'request':
        return <RequestPasswordReset onRequestReset={requestPasswordReset} />
      case 'reset':
        return <ResetPassword onResetPassword={resetPassword} />
    }
  }

  return <>
    <Helmet>
      <title>Forgot Password | Recycle at Boots</title>
    </Helmet>
    {renderStep()}
  </>
}

export default ForgotPasswordView
