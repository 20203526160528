import { Box, Typography } from '@mui/material'

import { BrandColor, getSchemeColor } from '~/config/theme'
import { RecyclableStatusConfig, type RecyclableStatus } from '~/constants/recyclable'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

interface StatusProps {
  onClick: () => void
  recyclableCount: number
  status: RecyclableStatus
}

export const Status: React.FC<StatusProps> = ({ onClick, status, recyclableCount }) => {
  const { icon: Icon, label } = RecyclableStatusConfig[status]
  const colors = getSchemeColor(Scan2RecyclePromotion.scheme.shortName)

  return (
    <Box
      sx={{
        backgroundColor: BrandColor.LIGHT_GREY,
        borderRadius: 2,
        display: 'flex',
        flexGrow: 1,
        p: 2,
        flexDirection: 'column',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      }}>
        <Icon sx={{
          stroke: colors.recyclable.status[status].color,
          strokeWidth: 1,
          color: colors.recyclable.status[status].color,
        }} />
      </Box>
      <Typography variant='h1' sx={{
        textAlign: 'center',
        color: BrandColor.BLACK,
        fontWeight: 600,
        fontSize: {
          xxs: 30,
          xs: 50,
          sm: 60,
          md: 70,
          lg: 80,
        },
      }}>
        {recyclableCount}
      </Typography>
      <Typography variant='h4' sx={{
        textAlign: 'center',
        color: BrandColor.BLACK,
        fontWeight: 600,
        fontSize: {
          xxs: 12,
          xs: 14,
          sm: 18,
          md: 22,
        },
      }}>
        { label }
      </Typography>
    </Box>
  )
}
