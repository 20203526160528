import Bugsnag from '@bugsnag/js'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Auth } from 'aws-amplify'

import VerifyAccess from '~/components/Auth/VerifyAccess'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { signOut } from '~/redux/features/auth/signOut'
import { updateUser } from '~/redux/features/auth/authSlice'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import UserService from '~/services/UserService'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import type { User as PromotionalUser } from '~/types/user/User'
import type { UpdateUserPayload } from '~/types/user/request/UpdateUserPayload'

import PersonalDetails from './PersonalDetails'
import { Loading } from './Loading'
import { FailedState } from './FailedState'

const PersonalDetailsView: React.FC = () => {
  const [user, setUser] = useState<PromotionalUser | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  const dispatch = useAppDispatch()
  const promotionId = Scan2RecyclePromotion.parentId ?? Scan2RecyclePromotion.id

  const fetchUser = async (): Promise<void> => {
    if (!isFetching) setIsFetching(true)

    if (!cognitoUser) {
      await dispatch(signOut()).unwrap()
      return
    }

    try {
      const user = await UserService.getUser(
        promotionId,
        cognitoUser.userId,
      )
      setUser(user)
    } catch (error) {
      if (!isAbortError(error)) dispatch(addNotification({ message: 'Unable to fetch user', type: 'error' }))

      if (isNotifiable(error)) {
        Bugsnag.notify(error)
      }
    } finally {
      setIsFetching(false)
    }
  }

  const save = async (values: UpdateUserPayload): Promise<void> => {
    if (cognitoUser) {
      try {
        setIsLoading(true)
        await UserService.updateUser(promotionId, cognitoUser.userId, values)
        await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
          given_name: values.firstName,
          family_name: values.lastName,
        })
        dispatch(updateUser({ firstname: values.firstName }))
        dispatch(addNotification({ type: 'success', message: 'Saved' }))
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
        dispatch(addNotification({ type: 'error', message: 'Failed to update personal details' }))
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleFetchUser = (): void => {
    void fetchUser()
  }

  return <>
    <Helmet>
      <title>Account - Personal Details | Recycle at Boots</title>
    </Helmet>
    <VerifyAccess onSuccessfulVerification={handleFetchUser}>
      {
        isFetching
          ? <Loading />
          : user
            ? <PersonalDetails user={user} isSaving={isLoading} onSave={save} />
            : <FailedState handleFetchUser={handleFetchUser} />
      }
    </VerifyAccess>
  </>
}

export default PersonalDetailsView
