import { Box, Breadcrumbs, Link, Typography, Button, CircularProgress } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { Link as RouterLink } from 'react-router-dom'

import { ROUTE_ACCOUNT, ROUTE_FAQS } from '~/routes/Routes'
import { BrandColor } from '~/config/theme'

interface LocationServicesProps {
  isLoading: boolean
  locationEnabled: boolean
  checkLocation: () => Promise<void>
}

const LocationServices: React.FC<LocationServicesProps> = ({ isLoading, locationEnabled, checkLocation }) => {
  const handleCheckLocation = (): void => {
    void checkLocation()
  }

  const renderLoading = (): JSX.Element => {
    return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: 500, ml: 'auto', mr: 'auto' }}>
      <CircularProgress />
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant='h6'>Checking Location Services</Typography>
      </Box>
    </Box>
  }

  const renderStatus = (): JSX.Element => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 800, mt: 1 }}>
        <Typography variant='h4' gutterBottom>Location Services</Typography>
        <Typography variant='body1'>Please ensure your location services are enabled and use the below to refresh your current location. We will never be able to use your current location without your permission and only whilst this app is in use.</Typography>

        <Typography variant='h5' sx={{ mt: 2 }} gutterBottom>Status</Typography>
        <Box sx={{ display: 'flex', borderStyle: 'solid', borderWidth: 2, width: '100%', borderRadius: 2, borderColor: '#555', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', pl: 2, pt: 1.5, pb: 1.5 }}>
            <LocationOnIcon sx={{ width: 35, height: 35, fill: '#555' }}/>
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant='h6' sx={{ color: '#555' }}>{locationEnabled ? 'Enabled' : 'Disabled'}</Typography>
            </Box>
          </Box>
          {locationEnabled
            ? <DoneIcon sx={{ color: BrandColor.GREEN, width: 35, height: 35, mt: 'auto', mb: 'auto', mr: 2 }}/>
            : <CloseIcon sx={{ color: '#f00', width: 35, height: 35, mt: 'auto', mb: 'auto', mr: 2 }}/>
          }
        </Box>

        {!locationEnabled && <>
            <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>Location not enabled?</Typography>
            <Typography variant='body1' gutterBottom>Check our <Link component={RouterLink} to={ROUTE_FAQS}>FAQs</Link> for more advice on enabling location services or contact us via your account page.</Typography>
          </>
        }

        <Box sx={{ display: 'flex', justifyContent: 'left', my: 2 }}>
          {locationEnabled
            ? <Button variant='contained' component={RouterLink} to={ROUTE_ACCOUNT} fullWidth>Back to Account</Button>
            : <Button variant='contained' onClick={handleCheckLocation} fullWidth>Check Service Status</Button>
          }
        </Box>
      </Box>
    )
  }

  return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', mt: 2 }}>
    <Breadcrumbs aria-label='breadcrumb'>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color='text.primary'
      >Location Services</Typography>
    </Breadcrumbs>

    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {isLoading ? renderLoading() : renderStatus()}
    </Box>
  </Box>
}

export default LocationServices
