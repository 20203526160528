import { SvgIcon, type SxProps } from '@mui/material'

interface TickIconProps {
  sx?: SxProps
}

const TickCircleIcon: React.FC<TickIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 171.57484 171.57455' sx={sx}>
    <path d='M146.45806,25.11664a85.83462,85.83462,0,1,0,25.11678,60.65878A85.54866,85.54866,0,0,0,146.45806,25.11664Zm-10.9473,40.42342-57.17542,57.1757a12.33433,12.33433,0,0,1-17.13134,0L36.06293,97.57541A11.81237,11.81237,0,0,1,52.7685,80.87056L69.78182,97.88345l49.02337-49.04838a11.81242,11.81242,0,0,1,16.70557,16.705Z'/>
  </SvgIcon>
}

export default TickCircleIcon
