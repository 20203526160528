import { promotions } from '~/config/promotion'
import { schemes } from '~/config/schemes'
import type { Promotion, PromotionSchemes } from '~/types/promotion/Promotion'

export const getPromotionSchemes = (predicate: (promotion: Promotion) => boolean): PromotionSchemes => {
  return promotions.reduce<PromotionSchemes>((acc, promotion) => {
    if (predicate(promotion)) {
      const scheme = schemes.find(scheme => scheme.promotionIds.includes(promotion.id))

      if (scheme) {
        return [
          ...acc,
          {
            promotion,
            scheme,
          },
        ]
      }
    }

    return [
      ...acc,
    ]
  }, [])
}
