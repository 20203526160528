import type { Ref } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import type { FieldValues, Path, RegisterOptions, UseFormRegisterReturn } from 'react-hook-form/dist/types'

export type ResolvedMuiRegister = Omit<UseFormRegisterReturn, 'ref'> & { inputRef: Ref<HTMLInputElement> }
export type MuiRegister<T extends FieldValues> = (name: Path<T>, options?: RegisterOptions<T, Path<T>>) => ResolvedMuiRegister

const createMuiRegister = <T extends FieldValues>(register: UseFormRegister<T>): MuiRegister<T> => {
  return (name: Path<T>, options?: RegisterOptions<T, Path<T>>): Omit<UseFormRegisterReturn, 'ref'> & { inputRef: Ref<HTMLInputElement> } => {
    const { ref, ...rest } = register(name, options)
    return {
      inputRef: ref,
      ...rest,
    }
  }
}

export { createMuiRegister }
