import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import type { RecyclableStatus } from '~/constants/recyclable'
import { browserInfo } from '~/helpers/browser'
import { ROUTE_ADD_RECYCLABLE, ROUTE_DEPOSIT, ROUTE_RECYCLABLES } from '~/routes/Routes'
import type { RecyclablesByStatus } from '~/types/recyclable/RecyclablesByStatus'
import type { UserPromotionStatistics } from '~/types/user/UserPromotionStatistics'

import { Statuses } from './elements/Statuses'
import { Deposit } from './actionBanners/Deposit'
import { Recycle } from './actionBanners/Recycle'
import { HomeFooter } from './HomeFooter'

interface HomeProps {
  recyclables: RecyclablesByStatus
  givenName?: string
  statistics?: UserPromotionStatistics
}

const Home: React.FC<HomeProps> = ({
  recyclables,
  givenName,
  statistics,
}) => {
  const navigate = useNavigate()

  const handleRecyclableClick = (status: RecyclableStatus): void => {
    navigate({
      pathname: ROUTE_RECYCLABLES,
      search: `?status=${status}`,
    })
  }

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'flex-end',
      mt: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      pb: browserInfo.isIos && browserInfo.isSafari && browserInfo.safariVersion && browserInfo.safariVersion <= 12 ? 8 : 0,
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        flexGrow: 1,
      }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xxs: 20, sm: 40 },
            display: { xxs: 'none', xs: 'block' },
            mt: 'auto',
          }}
          gutterBottom
        >
          <b>{givenName}</b>, welcome back
        </Typography>
        <Box sx={{
          display: 'flex',
          width: '100%',
          my: 1,
          mr: { md: 'unset', lg: 2 },
        }}>
          <Statuses
            recyclables={recyclables}
            onClick={handleRecyclableClick}
          />
        </Box>
          <Box
            sx={{
              my: 1,
              flexShrink: 1,
              flexGrow: 2,
              display: 'flex',
              position: 'relative',
              width: '100%',
              flexBasis: {
                xs: 100,
                sm: 150,
                md: 200,
              },
              maxHeight: {
                xs: 160,
                md: 300,
              },
            }}
          >
            <Recycle
              onClick={() => {
                navigate({ pathname: ROUTE_ADD_RECYCLABLE })
              }}
              lastRecyclableAt={statistics?.lastItem.recyclable}
            />
          </Box>
          <Box
            sx={{
              my: 1,
              flexShrink: 1,
              flexGrow: 1,
              display: 'flex',
              position: 'relative',
              width: '100%',
              flexBasis: {
                sm: 150,
                md: 200,
              },
              maxHeight: {
                xs: 180,
                md: 300,
              },
              '&:visible:last-child': {
                mb: 0,
              },
            }}
          >
            <Deposit
              onClick={() => {
                navigate({ pathname: ROUTE_DEPOSIT })
              }}
              lastDepositAt={statistics?.lastItem.deposit}
            />
          </Box>
        </Box>
        <HomeFooter
          statistics={statistics}
        />
    </Box>
  )
}

export default Home
