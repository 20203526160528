export const humanReadableBytes = (bytes: number, si: boolean = false): string => {
  const unit = si ? 1000 : 1024
  const i = Math.floor(Math.log(bytes) / Math.log(unit))
  const cleanBytes = Number((bytes / Math.pow(unit, i)).toFixed(2)) * 1
  return cleanBytes.toString() + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const readBlobAsArrayBuffer = async (blob: Blob): Promise<string | ArrayBuffer> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsArrayBuffer(blob)

    reader.onerror = () => { reject(new Error('Failed to load array buffer')) }
    reader.onloadend = () => {
      if (reader.result === null) {
        reject(new Error('Failed to load array buffer'))
      } else {
        resolve(reader.result)
      }
    }
  })
}
