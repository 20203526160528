import { Accordion, AccordionDetails, AccordionSummary, Link, List, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import application from '~/config/application'
import { ROUTE_RECYCLABLE_TYPES } from '~/routes/Routes'

import TermsListItem from '../components/TermsListItem'

export const UKTerms: React.FC = () => (
  <Accordion sx={{ '&:before': { display: 'none' } }}>
    <AccordionSummary expandIcon={<ExpandMore />}>Boots Health & Beauty Recycling UK</AccordionSummary>
    <AccordionDetails>
      <List component="ol" sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          The Recycle at {application.client} Scheme (“the Scheme”) is operated by {application.clientRegisteredName} (“{application.client}”) using Scan2Recycle technology and is aimed exclusively at recycling 'hard to recycle' beauty, health, wellness product packaging which cannot typically be recycled through household kerbside collections, for examples products that are too small (such as travel minis), made of composite materials (eyeshadow palettes, lipsticks, floss dispensers) or from unrecyclable materials (toothbrushes, toothpaste tubes).
        </TermsListItem>
        <TermsListItem>
          Widely recyclable card and paper packaging, glass jars, aluminium packaging, shampoo and conditioner bottles, shower gel plastic bottles etc. that can be recycled via household waste recycling channels are excluded from the Scheme. Please check your local council for details.
        </TermsListItem>
        <TermsListItem>
          The Scheme rewards Boots Advantage Card members with a total of 500 Advantage Card points when you spend £10 or more for recycling a minimum of five Qualifying Items (“the Reward”). Details of the voucher Terms & Conditions can be found <Link href="https://www.boots.com/webapp/wcs/stores/servlet/CategoryDisplay?storeId=11352&amp;categoryId=1602601&amp;catalogId=28501&amp;newPreviewSession=true&amp;p13n_test=true&amp;p13n_testcontext=0">here</Link> coupon number 32.
        </TermsListItem>
        <TermsListItem>
          Details of the Boots Advantage Card Terms & Conditions can be found by clicking <Link href="https://www.boots.com/floating-editorial/editorial-legal/terms-conditions/advantage-card-terms-and-conditions">here</Link>.
        </TermsListItem>
        <TermsListItem>
          {application.client} reserve the right to determine which items qualify for the Scheme within the meaning of a qualifying item (“Qualifying Item”) and in the event of a dispute, {application.client} decision is final.
        </TermsListItem>
      </List>
      <Typography variant="h6" gutterBottom>Qualifying Items</Typography>
      <List component="ol" start={6} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          <Typography variant="body1" gutterBottom>For the avoidance of doubt, a Qualifying Item must be:</Typography>
          <List component="ul" sx={{ listStyle: 'lower-alpha', paddingLeft: 2 }} dense>
            <TermsListItem>
              Used and as empty as possible;
            </TermsListItem>
            <TermsListItem>
              Whole, complete and in its original unaltered form as purchased. If a product is dismantled into component parts, or items such as brushes and sponges are removed and submitted as separate items, they will not qualify. Outer cardboard boxes or protective plastic foil wrapping are not required for an item to be considered whole;
            </TermsListItem>
            <TermsListItem>
              Individual items from multipacks cannot be used as qualifying Items. These items must be submitted as part of the multipack originally purchased, for example, dental picks, interdental brushes, small make-up applicators etc. These must be uploaded as a complete set to be considered as one Qualifying Item.
            </TermsListItem>
          </List>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            The full list of Qualifying Items is detailed{' '} <Link href={ROUTE_RECYCLABLE_TYPES}>here</Link> if they are in hard to recycle packaging.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Other hard to recycle items sold in {application.client} (except for the exclusion list below), can be deposited in the recycling boxes and will be recycled but will not be rewarded.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1" gutterBottom>For health and safety reasons, we cannot accept licensed medicines and any products or packaging that are labelled with a hazardous or dangerous materials symbol including, but not limited to, the following:</Typography>
          <Typography variant="body1">Aerosol cans, perfume bottles, nail polish bottles, permanent or temporary hair dyes in any type of packaging, brow and eyelash tints, safety razors and razor blades, disposable razors and razor heads, used PPE (disposable masks, gloves and visors), blister packs and foils, any electrical items such as hairdryers and straighteners, any medical devices, electrical or not, food packaging of any type, including crisp packets.</Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1" gutterBottom>For health and safety reasons, we cannot accept licensed medicines and any products or packaging that are labelled with a hazardous or dangerous materials symbol including, but not limited to, the following:</Typography>
          <List component="ul" sx={{ listStyle: 'lower-alpha', paddingLeft: 2 }} dense>
            <TermsListItem>
              <Typography variant="body1">
                Not beauty, health or wellness
              </Typography>
            </TermsListItem>
            <TermsListItem>
              <Typography variant="body1">
                Not used or as empty as possible
              </Typography>
            </TermsListItem>
            <TermsListItem>
              <Typography variant="body1">
                Non-Qualifying Items or which can be widely recycled
              </Typography>
            </TermsListItem>
          </List>
        </TermsListItem>
      </List>
      <Typography variant="h6" gutterBottom>General Recycle at {application.client} Scheme Rules</Typography>
      <List component="ol" start={11} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          In order to take part in the Scheme and qualify for the Reward, you must first create an account on {application.client}.Scan2Recycle.com (“Account Holder”).
        </TermsListItem>
        <TermsListItem>
          It is a condition of participation that location services are enabled on the device(s) used for Banking and Depositing.
        </TermsListItem>
        <TermsListItem>
          Deposits must only be made in participating stores using a valid QR code located on the official recycling box.
        </TermsListItem>
        <TermsListItem>
          Take a picture of the product (“Scan”) the items you want to recycle (“Bank”). The picture must be of the front of whole product with the brand and product type clearly shown. Only one image per product must be uploaded. There is no requirement to upload images of barcodes or the back of products.
        </TermsListItem>
        <TermsListItem>
          Label (“Tag”) the items by selecting the Brand, then Product Type. If you cannot find your category or type of product in the drop-down list, you will not be able to Bank it, as it is not a Qualifying Item and it will not count towards your 5 items required for the Reward.
        </TermsListItem>
        <TermsListItem>
          Banked items must be validated before being deposited. The validation will only take place during working hours (Monday to Friday 9am to 5pm, excluding public holidays “Working Day”). Items Banked out of hours will be validated by close of play on the next Working Day. Items Banked during a Working Day will be validated by the same time on the next Working Day. For example, an item banked at 12:30pm on a Monday will be validated by 12:30pm on a Tuesday. An item banked at 7:30pm Friday will be validated by 5.00pm the following Monday.
        </TermsListItem>
        <TermsListItem>
          Once Banked items have been verified you will be able to Deposit the items. Notification of the status of your items will be detailed in the 'Banked' section of your Boots Scan2Recycle Account.
        </TermsListItem>
        <TermsListItem>
          You must then bring at least 5 Qualifying items to any of the 700 participating stores (full list below) and deposit them in the boxes provided (“Deposit”). You will find the boxes located near our No7 counters.
        </TermsListItem>
        <TermsListItem>
          Each box has a unique QR code on the front or wings, which must be scanned to confirm the location of the Deposit.
        </TermsListItem>
        <TermsListItem>
          Once you have confirmed the Deposit by scanning the QR code on the box, and deposited your Qualifying Items, the Reward will be issued in accordance with the Terms &amp; Conditions.
        </TermsListItem>
        <TermsListItem>
          The Reward will be issued as a barcode and displayed in your {application.client} Scan2Recycle account in the 'Rewards' section.
        </TermsListItem>
        <TermsListItem>
          In order to claim the reward, you must make a total purchase of £10 or more in one transaction in a participating store. The barcode must be scanned at a store No7 till point (from the screen of your mobile phone) together with your Advantage Card and the products being purchased in order to claim the reward.
        </TermsListItem>
        <TermsListItem>
          Temporary Advantage Cards are not accepted.
        </TermsListItem>
        <TermsListItem>
          Registrations for Boots Scan2Recycle are strictly limited to one per person, and the registered details must match the Boots Scan2Recycle account holder's Boots Advantage Card.
        </TermsListItem>
        <TermsListItem>
          The Reward cannot be used at a self-check-out till.
        </TermsListItem>
        <TermsListItem>
          The Reward must be claimed within 3 days, including the day it was issued, at a participating store, subject to store opening times. For example, if the barcode was issued on a Monday, it must be used by Wednesday at midnight.
        </TermsListItem>
        <TermsListItem>
          Each Reward can only be used once. A maximum of 8 rewards per account will be issued in any calendar month. All subsequent deposits will be accepted but not rewarded.
        </TermsListItem>
        <TermsListItem>
          Only the customer to which the Boots Scan2Recycle account and Advantage Card are registered can claim the Reward.
        </TermsListItem>
        <TermsListItem>
          Only one Reward can be claimed per calendar day, regardless of how many items have been recycled over the 5 items threshold.
        </TermsListItem>
        <TermsListItem>
          Unused Reward barcodes that have expired will not be honoured or replaced.
        </TermsListItem>
        <TermsListItem>
          If you do not have an Advantage Card you can still use the recycling point, but you will not receive a Reward.
        </TermsListItem>
        <TermsListItem>
          No cash or other alternatives are available.
        </TermsListItem>
        <TermsListItem>
        Items once Banked cannot be submitted again for a further Reward as these are counted as part of the previous Deposit. Items cannot be removed from the recycle bin and resubmitted - these will be classed as duplicate (“Duplicate”).
        </TermsListItem>
        <TermsListItem>
          You can cancel your {application.client} Scan2Recycle account at any time by emailing: <Link href={`mailto:${application.email}`}>{application.email}</Link> or contacting {application.client} Scan2Recycle Customer Care Team.
        </TermsListItem>
      </List>
      <Typography variant="h6" gutterBottom>Amending or Withdrawal of the Scheme</Typography>
      <List component="ol" start={35} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          Boots reserves the right to amend or withdraw this Scheme at any time giving registered participants 14-day notice to their registered email address.
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">Boots can take such action it considers appropriate, including removing or suspending a customer's Boots Scan2Recycle account and any Boots Advantage Card account held by the customer along with any points accrued, if it has reason to believe there has been:</Typography>
          <List component="ol" sx={{ listStyle: 'lower-alpha', paddingLeft: 2 }} dense>
            <TermsListItem>
              <Typography variant="body1">
                Abuse or misuse of the Scheme;
              </Typography>
            </TermsListItem>
            <TermsListItem>
              <Typography variant="body1">
                Activity or conduct which would give rise to a breach of the Scheme's Terms and Conditions on the part of the {application.client} Scan2Recycle Account Holder;
              </Typography>
            </TermsListItem>
            <TermsListItem>
              <Typography variant="body1">
                Activity or conduct which would give rise to a breach of the Boots Advantage Card Terms and Conditions or any associated clubs and offers.
              </Typography>
            </TermsListItem>
          </List>
        </TermsListItem>
        <TermsListItem>
          For the avoidance of doubt, any decision {application.client} makes on such an issue is final.
        </TermsListItem>
      </List>
      <Typography variant="h6" gutterBottom>Activity or conduct which would give rise to a breach of the Scheme Terms and Conditions include but are not limited to:</Typography>
      <List component="ol" start={38} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          Repeated failure to adhere to the Scheme's Terms and Conditions;
        </TermsListItem>
        <TermsListItem>
          Uploading product images that appear to have been taken from in-store shelf;
        </TermsListItem>
        <TermsListItem>
          Uploading parts of product packaging and not the complete whole product packaging;
        </TermsListItem>
        <TermsListItem>
          Multiple accounts or Boots Advantage Card registrations being used per individual;
        </TermsListItem>
        <TermsListItem>
          Uploading duplicate images or images of the same product multiple times;
        </TermsListItem>
        <TermsListItem>
          Entering product descriptions ('Tagging') manually (from the drop-down list) that are inaccurate and do not reflect the image uploaded;
        </TermsListItem>
        <TermsListItem>
          Uploading same images or combination of products by separate accounts;
        </TermsListItem>
        <TermsListItem>
          Using a QR code that is not a {application.client} Scan2Recycle code, on an official collection box, in a participating location.
        </TermsListItem>
      </List>
      <Typography variant="body1" gutterBottom>Participating stores can be found at: <Link href="https://www.boots.com/boots-recycling-scheme" target="_blank">https://www.boots.com/boots-recycling-scheme</Link></Typography>
      <Typography variant="body1">Participating stores reserve the right to remove the Recycle Bin due to health and safety concerns.</Typography>
    </AccordionDetails>
  </Accordion>
)
