import { add, formatDistanceToNowStrict, parseISO, sub } from 'date-fns/esm'
import { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'

import { voucherTimeoutMinutes } from '~/constants/reward'
import { BrandColor } from '~/config/theme'
import type { Reward } from '~/types/reward/Reward'

interface RewardTimeoutProps {
  reward: Reward
}

export const RewardTimeout: React.FC<RewardTimeoutProps> = ({ reward }) => {
  const [timeRemaining, setTimeRemaining] = useState<string>('')
  const [isActive, setIsActive] = useState(false)
  const interval = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }

    interval.current = setInterval(() => {
      const activatedAt = reward.activatedAt && parseISO(reward.activatedAt)
      const voucherTimeoutDuration = reward.prize?.voucherActivationDuration ?? { minutes: voucherTimeoutMinutes }
      const expiryTime = sub(new Date(), voucherTimeoutDuration)

      if (activatedAt && expiryTime < activatedAt) {
        setIsActive(true)
        setTimeRemaining(formatDistanceToNowStrict(add(activatedAt, voucherTimeoutDuration)))
      } else {
        setIsActive(false)
        if (interval.current) clearInterval(interval.current)
      }
    })

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      interval.current = null
    }
  }, [reward, reward.activatedAt])

  if (!isActive) return null

  return <Box sx={{ mt: 0, mb: 1 }}>
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: 5, backgroundColor: BrandColor.WHITE, p: 2, mr: 2, height: 4 }}>
        <Box sx={{ borderRadius: 100, width: 7, backgroundColor: BrandColor.GREEN, height: 7, mt: 'auto', mb: 'auto', mr: 1 }} />
        <Typography>Active</Typography>
      </Box>
      <Typography style={{ marginTop: 'auto', marginBottom: 'auto', flex: 1 }}>Redeem voucher before the time expires</Typography>
    </Box>
    <Box sx={{ display: 'flex', borderRadius: 1, justifyContent: 'center', alignItems: 'center', my: 1, width: '100%', backgroundColor: BrandColor.WHITE, py: 4 }}>
      <Typography variant='h6'>{timeRemaining}</Typography>
    </Box>
  </Box>
}
