import { createSlice } from '@reduxjs/toolkit'
import { orderBy, uniqBy } from 'lodash'
import { add, formatISO, parseISO } from 'date-fns'

import { voucherTimeoutMinutes } from '~/constants/reward'
import { PrizeType } from '~/enum/prize/PrizeType'
import type { ActiveReward } from '~/types/reward/ActiveReward'
import type { Reward } from '~/types/reward/Reward'

import { activateVoucher, getMyRewards } from './actions'

interface RewardState {
  rewards: Reward[]
  nextToken: string | null
  fetched: boolean
  fetching: boolean
  activeRewards: ActiveReward[]
}

const initialState: RewardState = {
  rewards: [],
  nextToken: null,
  fetched: false,
  fetching: false,
  activeRewards: [],
}

const getActiveReward = (reward: Reward): ActiveReward | undefined => {
  if (reward.activatedAt) {
    const activatedAt = parseISO(reward.activatedAt)
    const activeExpiry = add(activatedAt, reward.prize.voucherActivationDuration ?? { minutes: voucherTimeoutMinutes })

    if (activeExpiry > new Date()) {
      return {
        rewardId: reward.rewardId,
        type: reward?.prize.type,
        sku: reward?.prize.SKU,
        activatedAt: reward.activatedAt,
        lockedUntil: formatISO(activeExpiry),
      }
    }
  }
}

export const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    resetNextToken: (state) => {
      state.rewards = []
      state.nextToken = null
      state.fetched = false
    },
    removeActiveReward: (state, action) => {
      state.activeRewards = state.activeRewards.filter((item) => item.rewardId !== action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyRewards.pending, (state) => {
      state.fetching = true
    })
    builder.addCase(getMyRewards.fulfilled, (state, action) => {
      if (action.payload.initialFetch) {
        state.rewards = orderBy(action.payload.response.items, ['createdAt'], ['desc'])
      } else {
        state.rewards = uniqBy(orderBy(state.rewards.concat(action.payload.response.items), ['createdAt'], ['desc']), (item) => item.rewardId)
      }

      state.activeRewards = state.activeRewards.concat(action.payload.response.items
        .map((reward) => getActiveReward(reward))
        .filter((item) => item) as ActiveReward[])

      state.fetched = true
      state.fetching = false
      state.nextToken = action.payload.initialFetch && state.nextToken ? state.nextToken : action.payload.response.nextToken
    })
    builder.addCase(getMyRewards.rejected, (state) => {
      state.fetching = false
      state.fetched = true
    })
    builder.addCase(activateVoucher.fulfilled, (state, action) => {
      state.rewards = state.rewards.map((item) => {
        if (item.rewardId === action.payload.rewardId) {
          item.activatedAt = action.payload.activatedAt

          if (item?.prize.type === PrizeType.VOUCHER) {
            const activeReward = getActiveReward(item)

            if (activeReward && !state.activeRewards.some((activeReward) => activeReward.rewardId === item.rewardId)) {
              state.activeRewards.push(activeReward)
              item.prize.voucherUntil = activeReward.lockedUntil
            }
          }
        }

        return item
      })
    })
  },
})

export const { resetNextToken, removeActiveReward } = rewardSlice.actions

export default rewardSlice.reducer
