import { dinero, toDecimal } from 'dinero.js'
import { Box, Typography } from '@mui/material'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import type { Reward } from '~/types/reward/Reward'
import { currencyFormatter } from '~/helpers/currency'

interface DonationAmountProps {
  reward: Reward
}

const DonationAmount: React.FC<DonationAmountProps> = ({ reward }) => {
  const donation = dinero({ amount: reward?.prize?.amount, currency: Scan2RecyclePromotion.currency })

  return (
    <Box sx={{ width: '100%', background: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4, mt: 2 }}>
      <Typography variant='h3'>{toDecimal(donation, currencyFormatter)}</Typography>
    </Box>
  )
}

export default DonationAmount
