import type { GeoPoint } from '../location/GeoPoint'
import type { Recyclable } from '../recyclable/Recyclable'

import type { RecyclingPoint } from './RecyclingPoint'

export enum DepositStep {
  LOCATION = 'LOCATION',
  SCAN_RECYCLING_POINT = 'SCAN_RECYCLING_POINT',
  SELECT_RECYCLABLES = 'SELECT_RECYCLABLES',
  CONFIRM = 'CONFIRM',
  RESULT = 'RESULT',
}

export interface DepositInformation {
  location: GeoPoint | null
  recyclingPoint: RecyclingPoint | null
  qrcode: Blob | null
}

export interface ValidatedDepositInformation {
  recyclingPoint: RecyclingPoint
  binScannedAt: Date
  scannedBinCode: {
    uploadStatus: string
    external: boolean
    uri?: string
    key?: string
  }
  clickTime: number
  recyclables: Recyclable[]
  location: GeoPoint | null
  locationServicesEnabled: boolean
  hasBestLocation: boolean
}
