import { Link, List, ListItem, Typography } from '@mui/material'
import { format } from 'date-fns'
import Markdown from 'markdown-to-jsx'

import RouterLink from '~/components/Link/RouterLink'
import { BrandColor } from '~/config/theme'
import type { Reward } from '~/types/reward/Reward'

const TermsListItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ListItem>
    <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
      {children}
    </Typography>
  </ListItem>
)

const RewardTerms: React.FC<{ reward: Reward, expiryDate: Date | null }> = ({ reward, expiryDate }) => {
  const ExpiryDate: React.FC<{ children: string[] }> = ({ children }) => {
    if (!expiryDate || !children) return null

    return <Typography variant='caption' gutterBottom sx={{ display: 'block' }}>
      {children
        ? children.map(child => {
          if (typeof child === 'string') {
            return child.replace('{expiryDate}', format(expiryDate, 'dd/MM/yyyy HH:mm'))
          }

          return child
        })
        : ''}
    </Typography>
  }

  return reward.prize?.terms
    ? <Markdown
          children={reward.prize.terms}
          options={{
            overrides: {
              p: {
                component: Typography,
                props: {
                  variant: 'caption',
                  gutterBottom: true,
                  sx: { display: 'block' },
                },
              },
              a: Link,
              ul: {
                component: List,
                props: {
                  dense: true,
                },
              },
              li: TermsListItem,
              RouterLink: {
                component: RouterLink,
              },
              ExpiryDate,
            },
          }} />
    : <>
        {expiryDate && <Typography component="p" variant="caption" gutterBottom>
          Offer can be used in the same transaction as other coupons and offers unless otherwise stated. Valid until {format(expiryDate, 'dd/MM/yyyy HH:mm')}
        </Typography>}
        <Typography component="p" variant="caption" gutterBottom>
          Boots can take such action it considers appropriate, including removing or suspending a customer's Recycle at Boots account and any Boots Advantage Card account held by the customer along with any points accrued, if it has reason to believe there has been:
        </Typography>
        <List dense>
          <ListItem>
            <Typography component="p" variant="caption">Abuse or misuse of the Scheme;</Typography>
          </ListItem>
          <ListItem>
            <Typography component="p" variant="caption">Activity or conduct which would give rise to a breach of the Scheme's Terms and Conditions on the part of the Recycle at Boots Account Holder;</Typography>
          </ListItem>
          <ListItem>
            <Typography component="p" variant="caption">Activity or conduct which would give rise to a breach of the Boots Advantage Card Terms and Conditions or any associated clubs and offers.</Typography>
          </ListItem>
        </List>
        <Typography component="p" variant="caption">For the avoidance of doubt, any decision Boots makes on such an issue is final.</Typography>
    </>
}

export default RewardTerms
