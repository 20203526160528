import { useState, useEffect, useRef, useMemo } from 'react'
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs, CircularProgress, Link, Typography } from '@mui/material'
import Bugsnag from '@bugsnag/js'

import application from '~/config/application'
import RecyclingPointService from '~/services/RecyclingPointService'
import { getCurrentLocation } from '~/helpers/geolocation/geolocation'
import { getDistanceBetween2GeographicPoints } from '~/helpers/geolocation/getDistanceBetweenGeographicPoints'
import { getPromotionSchemes } from '~/helpers/promotion'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import { promotions } from '~/config/promotion'
import { DistanceUnits } from '~/types/DistanceUnits'
import { isNotifiable } from '~/types/guards/errors'
import type { RootState } from '~/redux/reducers/root'
import type { PromotionScheme } from '~/types/promotion/Promotion'
import type { GeoPoint } from '~/types/location/GeoPoint'
import type { LocationState } from '~/types/location/Location'
import type { MapRecyclingPoint } from '~/types/deposit/RecyclingPoint'

import RecylingPointSelect from './RecyclingPointSelect'
import RecyclingPointMap from './RecyclingPointMap'
import { RecyclingPointList } from './RecyclingPointList'

// The default center point for the map, currently set to center of The UK
const defaultCenterPoint = {
  latitude: 54.10346243699088,
  longitude: -4.584019373628285,
}

const sortRecyclingPointsByDistance = (points: MapRecyclingPoint[], centerPoint: GeoPoint): MapRecyclingPoint[] => {
  const { latitude, longitude } = centerPoint

  return points.sort((a, b) => {
    const distanceA = Math.sqrt(Math.pow(a.latitude - latitude, 2) + Math.pow(a.longitude - longitude, 2))
    const distanceB = Math.sqrt(Math.pow(b.latitude - latitude, 2) + Math.pow(b.longitude - longitude, 2))

    return distanceA - distanceB
  })
}

const isValidScheme = (userSchemeIds: string[], promotionScheme: PromotionScheme, routeScheme?: string | null): boolean => {
  return userSchemeIds.includes(promotionScheme.promotion.id) || (promotionScheme.scheme.shortName === routeScheme)
}

export const RecyclingPointsView: React.FC = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [userLocation, setUserLocation] = useState<GeoPoint | undefined>(undefined)
  const mapCenter = useRef<GeoPoint>(defaultCenterPoint)
  const [point, setPoint] = useState<MapRecyclingPoint | undefined>(undefined)
  const [params] = useSearchParams()

  const [recyclingPoints, setRecyclingPoints] = useState<MapRecyclingPoint[]>([])
  const [sortedRecyclingPoints, setSortedRecyclingPoints] = useState<MapRecyclingPoint[]>([])

  const { schemes: userSchemes, isAuthenticated } = useSelector((state: RootState) => state.auth)
  const userSchemeIds = userSchemes.map((scheme) => scheme.id)
  const schemes = useMemo(() => getPromotionSchemes((promotion) => {
    return promotions.map(promotion => promotion.id).includes(promotion.id)
  }), [userSchemes])

  const [availableSchemes, setAvailableSchemes] = useState<PromotionScheme[]>([])
  const [visibleSchemeIds, setVisibleSchemeIds] = useState<string[]>([])
  const selectableSchemes = availableSchemes.filter((promotionScheme) => isValidScheme(userSchemeIds, promotionScheme, params.get('scheme')))

  const setMapCenter = (centerPoint: GeoPoint): void => {
    mapCenter.current = centerPoint
  }

  const setMapPoint = (recyclingPoint: MapRecyclingPoint): void => {
    setPoint(recyclingPoint)
    setMapCenter({ latitude: recyclingPoint.latitude, longitude: recyclingPoint.longitude })
  }

  const fetchAvailableSchemes = (): void => {
    const availableSchemes = schemes
    const filteredSchemes = availableSchemes.filter(promotionScheme => {
      if (!isAuthenticated) return true

      return isValidScheme(userSchemeIds, promotionScheme, params.get('scheme'))
    })
    setAvailableSchemes((!isAuthenticated || application.displayAllRecyclingPoints) ? availableSchemes : filteredSchemes)
    setVisibleSchemeIds(filteredSchemes.map(scheme => scheme.promotion.id))
  }

  const fetchRecyclingPoints = async (): Promise<void> => {
    setLoading(true)

    try {
      const availablePoints = await RecyclingPointService.getRecyclingPointsForPromotions(availableSchemes.map(scheme => scheme.promotion.id))
      setRecyclingPoints(availablePoints)
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
    } finally {
      setLoading(false)
    }
  }

  const getLocation = async (): Promise<void> => {
    const { coordinates } = await getCurrentLocation()
    if (coordinates) {
      const { latitude, longitude } = coordinates

      mapCenter.current = {
        latitude,
        longitude,
      }

      setUserLocation({
        latitude,
        longitude,
      })
    }
  }

  useEffect(() => {
    void getLocation()
  }, [])

  useEffect(() => {
    if (schemes.length > 0) {
      fetchAvailableSchemes()
    }
  }, [schemes])

  useEffect(() => {
    if (availableSchemes.length > 0) {
      void fetchRecyclingPoints()
    }
  }, [availableSchemes])

  // When the recycling points change, sort them by distance from the center point
  useEffect(() => {
    if (recyclingPoints.length > 0) {
      setSortedRecyclingPoints(sortRecyclingPointsByDistance(recyclingPoints, mapCenter.current))
    }
  }, [recyclingPoints])

  const updateDistanceFromUser = (userLocation: GeoPoint, points: MapRecyclingPoint[]): MapRecyclingPoint[] => {
    return points.map((point) => {
      const distance = getDistanceBetween2GeographicPoints(userLocation, {
        latitude: point.latitude,
        longitude: point.longitude,
      }, DistanceUnits.MILES)
      return {
        ...point,
        distanceFromUser: distance,
      }
    })
  }

  useEffect(() => {
    if (userLocation && recyclingPoints?.some((point) => !point.distanceFromUser)) {
      setRecyclingPoints(current => updateDistanceFromUser(userLocation, current))
    }
  }, [userLocation, recyclingPoints])

  const handleMapMoveEnd = (centerPoint: GeoPoint): void => {
    // The below line addresses an odd mobile bug with mapbox
    if (defaultCenterPoint.latitude === centerPoint.latitude && defaultCenterPoint.longitude === centerPoint.longitude) return

    setSortedRecyclingPoints((current) => {
      const currentRecyclingPointOrder = current.map((point) => point.depositBinId)
      const newSortedRecyclingPoints = sortRecyclingPointsByDistance(current, centerPoint)

      // Only save the new points if the order of the points has changed -- this stops infinite rerender loops
      if (newSortedRecyclingPoints.some((point, index) => point.depositBinId !== currentRecyclingPointOrder[index])) {
        return [...newSortedRecyclingPoints]
      }
      return current
    })
  }

  const handleSelectChange = (selectedSchemeNames: string[]): void => {
    setVisibleSchemeIds(selectedSchemeNames)
  }

  if (loading) {
    return <>
      <Helmet>
        <title>Recycling Points | Recycle at Boots</title>
      </Helmet>

      {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
        <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          Recycling Points
        </Typography>
      </Breadcrumbs>}

      <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>Recycling Points</Typography>

      <CircularProgress sx={{ mx: 'auto', mt: 4 }}/>
    </>
  }

  return <>
    <Helmet>
      <title>Recycling Points | Recycle at Boots</title>
    </Helmet>

    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >
        Recycling Points
      </Typography>
    </Breadcrumbs>}
    <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>Recycling Points</Typography>
    <RecyclingPointMap
      schemes={availableSchemes}
      recyclingPoints={recyclingPoints}
      centerPoint={mapCenter.current}
      userLocation={userLocation}
      point={point}
      onMapMoveEnd={handleMapMoveEnd}
      visibleSchemeNames={visibleSchemeIds}
    />
    {selectableSchemes.length > 1 && <RecylingPointSelect
      validSchemes={selectableSchemes}
      onSelectChange={handleSelectChange}
      visibleSchemes={visibleSchemeIds}
    />}
    {recyclingPoints.length > 0 && <RecyclingPointList
      recyclingPoints={sortedRecyclingPoints}
      setMapPoint={setMapPoint}
      userLocation={userLocation}
      visibleSchemes={visibleSchemeIds}
    />}
  </>
}
