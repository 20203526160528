import { Box, Typography, Link, Breadcrumbs, CircularProgress } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import TermsOfferBanner from '~/components/Terms/OfferBanner/TermsOfferBanner'
import application from '~/config/application'
import type { RootState } from '~/redux/reducers/root'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import type { LocationState } from '~/types/location/Location'

import { UKTerms } from '~/views/Web/Terms/parts/UK'
import { IETerms } from '~/views/Web/Terms/parts/IE'
import { BlisterUKTerms } from '~/views/Web/Terms/parts/BlisterUK'

const TermsView: React.FC = () => {
  const location = useLocation()
  const isAuthenticating = useSelector((state: RootState) => state.auth.isAuthenticating)

  if (isAuthenticating) {
    return <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  }

  return <>
    <Helmet>
      <title>Terms & Conditions | Recycle at Boots</title>
    </Helmet>
    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >Terms & Conditions</Typography>
    </Breadcrumbs>}
    <Box>
      <Box>
        <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>
          Terms & Conditions
        </Typography>
        <TermsOfferBanner />
        <Typography variant="h6">RECYCLE AT {application.client.toUpperCase()} - CUSTOMER TERMS & CONDITIONS</Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <UKTerms />
        <IETerms />
        <BlisterUKTerms />
      </Box>
    </Box>
  </>
}

export default TermsView
