import { SvgIcon, type SxProps } from '@mui/material'

interface StarIconProps {
  sx?: SxProps
}

const StarIcon: React.FC<StarIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 21 19.99996' sx={sx}>
    <path d="m10.5,16.7494l5.0923,3.0692c.9326.5625,2.0738-.269,1.8284-1.3206l-1.3498-5.7716,4.5034-3.88856c.8221-.70923.3803-2.05432-.6995-2.13992l-5.9267-.50135L11.6289.74285c-.4172-.99047-1.8406-.99047-2.25781,0l-2.31916,5.4415-5.92676.50135c-1.07982.08559-1.52157,1.43068-.69943,2.13991l4.50335,3.88849-1.34977,5.7717c-.24542,1.0516.89576,1.8831,1.82833,1.3206l5.09235-3.057Z"/>
  </SvgIcon>
}

export default StarIcon
