import { DefinitionStep } from '~/enum/DefinitionStep'
import { ScanFlow } from '~/enum/ScanFlow'

export const DEFINITION_STEPS_MAP: Record<ScanFlow, DefinitionStep[]> = {
  [ScanFlow.Category]: [
    DefinitionStep.DEFINE_BRAND,
    DefinitionStep.DEFINE_CATEGORY,
    DefinitionStep.DEFINE_FORM,
  ],
  [ScanFlow.Product]: [
    DefinitionStep.DEFINE_PRODUCT,
    DefinitionStep.DEFINE_FORM,
  ],
}
