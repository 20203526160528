import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { isToday } from 'date-fns'

import RecycleBannerPng from '~/assets/images/home/png/bottles.png'
import RecycleBannerPng2x from '~/assets/images/home/png/bottles@2x.png'
import RecycleBannerWebp from '~/assets/images/home/webp/bottles.webp'
import RecycleBannerWebp2x from '~/assets/images/home/webp/bottles@2x.webp'
import BlisterBannerPng from '~/assets/images/home/png/blister.png'
import BlisterBannerPng2x from '~/assets/images/home/png/blister@2x.png'
import BlisterBannerWebp from '~/assets/images/home/webp/blister.webp'
import BlisterBannerWebp2x from '~/assets/images/home/webp/blister@2x.webp'
import { BrandColor, getSchemeColor } from '~/config/theme'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

interface RecycleProps {
  lastRecyclableAt?: string | null
  onClick: () => void
}

export const Recycle: React.FC<RecycleProps> = ({ onClick, lastRecyclableAt }) => {
  const hasAddedRecyclableToday = !!lastRecyclableAt && isToday(new Date(lastRecyclableAt))
  const isXXS = useMediaQuery('(max-width: 280px)')
  const colors = getSchemeColor(Scan2RecyclePromotion.scheme.shortName)

  return (
    <Box sx={{ display: 'flex', background: colors.recyclable.background, borderRadius: 4, width: '100%', cursor: 'pointer' }} onClick={onClick}>
      <Grid container>
        <Grid item xxs={8} sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', padding: 2.5 }}>
            <Typography sx={{
              color: '#fff',
              fontWeight: 600,
              textAlign: 'left',
              fontSize: { sm: 24, md: 30 },
              mb: 2,
              pl: 1,
            }}>
              { hasAddedRecyclableToday
                ? 'Recycle more items'
                : 'Recycle your items'
              }
            </Typography>
            <Box sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', borderRadius: 6, background: colors.recyclable.backgroundContrast ?? 'rgba(255,255,255,0.15)', py: 1, px: 1.5, pr: 3 }}>
              <AddCircleIcon fontSize='small' sx={{ color: BrandColor.WHITE }} />
              <Typography sx={{
                color: '#fff',
                fontWeight: '600',
                fontSize: {
                  xxs: 12,
                  sm: 14,
                  md: 18,
                },
                position: 'relative',
                ml: { xxs: 1 },
              }}>
                Add {isXXS ? '' : 'Recyclable'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xxs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            padding: 2,
            paddingLeft: 0,
            height: 0,
          }}>
            {Scan2RecyclePromotion.scheme.shortName === 'Blister'
              ? <Box
                  component='picture'
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: { xxs: '110px', sm: '130px' },
                  }}
                >
                  <source srcSet={`${BlisterBannerWebp2x} 2x, ${BlisterBannerWebp} 1x`} type='image/webp' />
                  <source srcSet={`${BlisterBannerPng2x} 2x, ${BlisterBannerPng} 1x`} type='image/png' />
                  <Box component="img" src={BlisterBannerPng} alt='Blister Banner' sx={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
              : <Box
                  component='picture'
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: { xxs: '110px', sm: '130px' },
                  }}
                >
                  <source srcSet={`${RecycleBannerWebp2x} 2x, ${RecycleBannerWebp} 1x`} type='image/webp' />
                  <source srcSet={`${RecycleBannerPng2x} 2x, ${RecycleBannerPng} 1x`} type='image/png' />
                  <Box component="img" src={RecycleBannerPng} alt='Recyclable Banner' sx={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
              }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
