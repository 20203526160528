import { Box, Breadcrumbs, Link, Typography, CircularProgress, Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { Link as RouterLink } from 'react-router-dom'

import { ROUTE_ACCOUNT } from '~/routes/Routes'
import { BrandColor } from '~/config/theme'
import CameraIcon from '~/components/Icons/CameraIcon'
import LockIcon from '~/components/Icons/LockIcon'
import AAIcon from '~/components/Icons/AAIcon'

interface CameraPermissionsProps {
  isLoading: boolean
  cameraPermissionsEnabled: boolean
  checkCameraPermissions: () => void
}

const CameraPermissions: React.FC<CameraPermissionsProps> = ({ isLoading, cameraPermissionsEnabled, checkCameraPermissions }) => {
  const renderLoading = (): JSX.Element => {
    return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: 500, ml: 'auto', mr: 'auto' }}>
      <CircularProgress />
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant='h6'>Checking Camera Permissions</Typography>
      </Box>
    </Box>
  }

  const colour = '#555'

  const renderStatus = (): JSX.Element => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 800, mt: 2 }}>
        <Typography variant="h4" gutterBottom>Camera Status</Typography>
        <Typography variant="body1">Please ensure you have camera access enabled, the current status is displayed below.</Typography>

        <Typography variant="h5" sx={{ mt: 2 }} gutterBottom>Status</Typography>
        <Box sx={{ display: 'flex', borderStyle: 'solid', borderWidth: 2, width: '100%', borderRadius: 2, borderColor: colour, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', pl: 2, pt: 1.5, pb: 1.5 }}>
            <CameraIcon sx={{ width: 35, height: 35, fill: colour }}/>
            <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant='h6' sx={{ color: colour }}>{cameraPermissionsEnabled ? 'Enabled' : 'Disabled'}</Typography>
            </Box>
          </Box>
          {cameraPermissionsEnabled
            ? <DoneIcon sx={{ color: BrandColor.GREEN, width: 35, height: 35, mt: 'auto', mb: 'auto', mr: 2 }}/>
            : <CloseIcon sx={{ color: '#f00', width: 35, height: 35, mt: 'auto', mb: 'auto', mr: 2 }}/>
          }
        </Box>
        {!cameraPermissionsEnabled && <>
            <Typography variant="h6" sx={{ mt: 3, fontWeight: '600' }}>Need some help?</Typography>
            <Typography variant="body1" sx={{ mt: 2, fontWeight: '500' }}>Android:</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>Click <LockIcon sx={{ color: '#999', width: 16, height: 16 }} /> {'>'} Permissions {'>'} Tap Camera Permission and set to Allow or tap reset permissions to be prompted next time</Typography>

            <Typography variant="body1" sx={{ mt: 2, fontWeight: '500' }}>IOS:</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}> Click <AAIcon sx={{ color: '#555', width: 16, height: 16 }} /> {'>'} Website Settings {'>'} Tap Camera Permission and set to Allow, you can also reset the permissions within Settings</Typography>
          </>
        }
        <Box sx={{ display: 'flex', justifyContent: 'left', my: 2 }}>
          {cameraPermissionsEnabled
            ? <Button variant='contained' component={RouterLink} to={ROUTE_ACCOUNT} fullWidth>Back to Account</Button>
            : <Button variant='contained' onClick={checkCameraPermissions} fullWidth>Check Camera Status</Button>
          }
        </Box>
      </Box>
    )
  }

  return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', mt: 2 }}>
    <Breadcrumbs aria-label='breadcrumb'>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color='text.primary'
      >Camera Status</Typography>
    </Breadcrumbs>

    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {isLoading ? renderLoading() : renderStatus()}
    </Box>
  </Box>
}

export default CameraPermissions
