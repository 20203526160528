import { Link, Typography } from '@mui/material'
import Markdown from 'markdown-to-jsx'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ROUTE_TERMS } from '~/routes/Routes'
import RouterLink from '~/components/Link/RouterLink'
import type { Reward } from '~/types/reward/Reward'
import { getSymbolByCurrency } from '~/helpers/currency'

const RewardInstructions: React.FC<{ reward: Reward }> = ({ reward }) => {
  return reward.prize?.instructions
    ? <Markdown
        children={reward.prize.instructions}
        options={{
          overrides: {
            p: {
              component: Typography,
              props: {
                variant: 'caption',
                gutterBottom: true,
                sx: { display: 'block' },
              },
            },
            a: Link,
            RouterLink: {
              component: RouterLink,
            },
          },
        }} />
    : <>
      <Typography component="p" variant="caption" gutterBottom>
        Please present this barcode at the checkout till, along with your Boots Advantage card and your
        points will be added on when you spend {getSymbolByCurrency(Scan2RecyclePromotion.currency)}10 or more in one transaction.
      </Typography>
      <Typography component="p" variant="caption" gutterBottom>
        The voucher must be claimed within 3 days (incl. day of issue), in a participating store, subject
        to store opening times. For full terms and conditions please
        <Link href="https://www.boots.com/webapp/wcs/stores/servlet/CategoryDisplay?storeId=11352&categoryId=1602601&catalogId=28501&newPreviewSession=true&p13n_test=true&p13n_testcontext=0">
          click here
        </Link>{' '}
        and see Coupon: Offer 32.
      </Typography>
      <Typography component="p" variant="caption">
        This barcode voucher can only be used by the Recycle at Boots account holder to which it was awarded, and can only be used in one transaction, any misuse of the voucher will be deemed a breach of the <RouterLink href={ROUTE_TERMS}>Terms and Conditions</RouterLink>.
      </Typography>
    </>
}

export default RewardInstructions
