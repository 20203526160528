import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'

import RecyclablesIcon from '~/components/Icons/RecyclablesIcon'
import type { Recyclable } from '~/types/recyclable/Recyclable'

import RecyclableListItem from './RecyclableListItem'

interface RecyclableListProps {
  recyclables: Recyclable[]
  selectedRecyclables?: Recyclable[]
  fetching: boolean
  fetched: boolean
  onRecyclableClick?: (recyclable: Recyclable) => void
}

const RecyclableList: React.FC<RecyclableListProps> = ({ recyclables, selectedRecyclables, fetching, fetched, onRecyclableClick }) => {
  if (fetching && recyclables.length === 0) {
    return <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
      <CircularProgress />
    </Box>
  }

  if (fetched && recyclables.length === 0) {
    return <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4, color: '#bbb' }}>
      <RecyclablesIcon sx={{ width: 50, height: 50, mb: 2 }} />
      <Typography>No items found</Typography>
    </Box>
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}>
    {recyclables.map((item, index) => <RecyclableListItem
      key={item.bankableId}
      recyclable={item}
      selectedRecyclables={selectedRecyclables}
      isFirst={index === 0}
      isLast={index === recyclables.length - 1}
      onRecyclableClick={onRecyclableClick}
    />)}
  </Box>
}

export default RecyclableList
