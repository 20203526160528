import type { Reward } from '~/types/reward/Reward'

import SubmissionProcessorService from './SubmissionProcessorService'

class RewardService extends SubmissionProcessorService {
  public async getRewards (promotionId: string, init?: { nextToken?: string | null, signal?: AbortSignal }): Promise<{
    items: Reward[]
    nextToken: string
  }> {
    const path = init?.nextToken ? `rewards?campaignId=${promotionId}&nextToken=${init.nextToken}` : `rewards?campaignId=${promotionId}`
    const response = await this.request(path, { signal: init?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getNewRewardCount (options?: { signal: AbortSignal }): Promise<number> {
    const response = await this.request('new-rewards', { signal: options?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async setRewardViewed (rewardId: string): Promise<null> {
    const response = await this.request('reward-view', {
      method: 'PUT',
      body: JSON.stringify({ rewardId }),
    })

    return response.status === 204 ? null : await Promise.reject(response)
  }

  public async setRewardActivated (rewardId: string): Promise<{
    activatedAt: string
    voucherUntil: string
  }> {
    const response = await this.request('s2r/reward-activate', {
      method: 'PUT',
      body: JSON.stringify({ rewardId }),
    })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }
}

export default new RewardService()
