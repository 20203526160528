import { Box, Breadcrumbs, CircularProgress, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import SchemeSelectorForm from '~/components/Form/User/SchemeSelectorForm'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import type { PromotionSchemes } from '~/types/promotion/Promotion'
import type { UserSchemes } from '~/types/user/User'

interface SchemesProps {
  validSchemes: PromotionSchemes
  userSchemes: UserSchemes
  isFetching: boolean
  isUpdating: boolean
  onSubmit: (values: { schemes: string[] }) => void
}

const Schemes: React.FC<SchemesProps> = ({ validSchemes, userSchemes, isFetching, isUpdating, onSubmit }) => {
  const userSchemeIds = userSchemes?.map(scheme => scheme.id) ?? []

  return <>
    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >Schemes</Typography>
    </Breadcrumbs>

    {isFetching
      ? <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>
      : <Box sx={{ mt: 2 }}>
        <Typography variant='body1' gutterBottom>Please check the boxes below to opt-in, or uncheck to opt-out of our recycling schemes.</Typography>
        <SchemeSelectorForm
          schemes={validSchemes}
          userSchemes={userSchemeIds}
          loading={isUpdating}
          submitLabel='Save'
          onSubmit={onSubmit}
        />
      </Box>
    }
  </>
}

export default Schemes
