import { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Backdrop, BottomNavigationAction, Box, CircularProgress, Divider } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { theme } from '~/config/theme'
import application from '~/config/application'
import BoxIcon from '~/components/Icons/BoxIcon'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { getPromotionSchemes } from '~/helpers/promotion'
import type { RootState } from '~/redux/reducers/root'
import { useAppDispatch } from '~/redux/store'
import { updatePromotion } from '~/redux/features/auth/authSlice'
import { ROUTE_DEPOSITS } from '~/routes/Routes'
import type { PromotionScheme } from '~/types/promotion/Promotion'

import NavigationSchemeMenu from './NavigationSchemeMenu'

const NavigationSchemeSelector: React.FC = () => {
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)
  const { schemes: userSchemes, promotionId: activeUserPromotion } = useSelector((state: RootState) => state.auth)
  const schemes = useMemo(() => getPromotionSchemes((promotion) => {
    return userSchemes.map(scheme => scheme.id).includes(promotion.id)
  }), [userSchemes])
  const dispatch = useAppDispatch()
  const showDeposits = !application.useSchemeSelector || (!application.alwaysShowSchemeSelector && schemes.length === 1)
  const promotion = useMemo(() => {
    if (activeUserPromotion) {
      Scan2RecyclePromotion.setPromotion(activeUserPromotion)
    }

    return Scan2RecyclePromotion
  }, [activeUserPromotion])

  const handleMenuToggle = (): void => {
    setOpen(!open)
  }

  const handleMenuClose = (): void => {
    setOpen(false)
  }

  const handleSchemeClick = (scheme: PromotionScheme): void => {
    if (application.useSchemeSelector) {
      Scan2RecyclePromotion.setPromotion(scheme.promotion.id)
      void dispatch(updatePromotion({ promotionId: scheme.promotion.id, schemeName: scheme.scheme.name }))
      setOpen(false)
    }
  }

  const renderState = (): JSX.Element => {
    if (schemes.length === 0) {
      return <BottomNavigationAction
        icon={<Box><CircularProgress size={20} color='inherit' /></Box>}
      />
    }

    if (showDeposits) {
      return <BottomNavigationAction component={RouterLink} to={ROUTE_DEPOSITS} label="Deposits" icon={<BoxIcon />} />
    }

    return <>
      <NavigationSchemeMenu anchorEl={anchorEl} open={open} schemes={schemes} onClose={handleMenuClose} onSelectScheme={handleSchemeClick} />
      <Backdrop open={open} />
      <BottomNavigationAction
        id='scheme-menu-toggle'
        ref={anchorEl}
        onClick={handleMenuToggle}
        sx={{ width: 50 }}
        icon={<Box sx={{ position: 'relative', display: 'flex' }}>
          <Box sx={{ top: -4, width: 30, height: 30, borderRadius: 20, background: promotion.scheme.icon.color, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} />
          <promotion.scheme.icon.component
            sx={{
              position: 'relative',
              zIndex: 2,
              color: promotion.scheme.icon.selectorColor,
            }}
          />
        </Box>}
        label={promotion.scheme.shortName}
      />
    </>
  }

  return <>
    {!showDeposits && <Divider orientation='vertical' sx={{ background: theme.palette.grey[700], mx: 1, height: '70%', width: '1px' }} />}
    {renderState()}
  </>
}

export default NavigationSchemeSelector
