import type { Middleware } from 'redux'
import type { Action } from '@reduxjs/toolkit'

import { getLocalStorageItem, setLocalStorageItem } from '~/helpers/localStorage'
import type { RootState } from '~/redux/reducers/root'

import { addNotification } from './notificationSlice'

const getPreviousCounts = (beforeAccepted: number, beforeRejected: number): { accepted: number, rejected: number } => {
  const lastSessionCounts = {
    accepted: 0,
    rejected: 0,
  }
  const recyclablesCache = getLocalStorageItem('recyclableCounts')

  if (recyclablesCache) {
    const recyclables = JSON.parse(recyclablesCache)
    lastSessionCounts.accepted = recyclables.accepted
    lastSessionCounts.rejected = recyclables.rejected
  }

  return {
    accepted: Math.max(lastSessionCounts.accepted, beforeAccepted),
    rejected: Math.max(lastSessionCounts.rejected, beforeRejected),
  }
}

const updateRecyclableCache = (accepted: number, rejected: number): void => {
  setLocalStorageItem('recyclableCounts', JSON.stringify({
    accepted,
    rejected,
  }))
}

export const notificationMiddleware: Middleware = store => next => action => {
  if ((action as Action).type === 'recyclables/getRecyclables/fulfilled') {
    const beforeState: RootState = store.getState()
    const previousCounts = getPreviousCounts(
      beforeState.recyclable.recyclables.accepted.length,
      beforeState.recyclable.recyclables.rejected.length,
    )

    const result = next(action)
    const afterState: RootState = store.getState()
    const acceptedDifference = afterState.recyclable.recyclables.accepted.length - previousCounts.accepted
    const rejectedDifference = afterState.recyclable.recyclables.rejected.length - previousCounts.rejected

    if (acceptedDifference > 0 || rejectedDifference > 0) {
      let message = ''
      if (acceptedDifference > 0) message += `${acceptedDifference} ${acceptedDifference > 1 ? 'recyclables have' : 'recyclable has'} been accepted`
      if (acceptedDifference > 0 && rejectedDifference > 0) message += ' and '
      if (rejectedDifference > 0) message += `${rejectedDifference} ${rejectedDifference > 1 ? 'recyclables have' : 'recyclable has'} been rejected`

      store.dispatch(addNotification({
        type: acceptedDifference > 0 ? (rejectedDifference > 0 ? 'info' : 'success') : 'error',
        message,
        origin: { vertical: 'top', horizontal: 'right' },
        duration: 10000,
      }))

      updateRecyclableCache(afterState.recyclable.recyclables.accepted.length, afterState.recyclable.recyclables.rejected.length)
    }

    return result
  }

  if ((action as Action).type === 'recyclables/deleteRecyclables/fulfilled') {
    const result = next(action)
    const afterState: RootState = store.getState()
    updateRecyclableCache(afterState.recyclable.recyclables.accepted.length, afterState.recyclable.recyclables.rejected.length)

    return result
  }

  return next(action)
}
