import { Box, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ErrorIcon from '@mui/icons-material/Error'

interface FailedStateProps {
  handleFetchUser: () => void
}

export const FailedState: React.FC<FailedStateProps> = ({ handleFetchUser }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1, color: '#666' }}>
    <ErrorIcon sx={{ width: 50, height: 50 }} />
    <Typography variant="body1" sx={{ textAlign: 'center' }}>Unable to fetch details</Typography>
    <LoadingButton variant="contained" onClick={handleFetchUser} sx={{ mt: 2 }}>Retry</LoadingButton>
  </Box>
)
