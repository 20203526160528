import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGN_UP } from '~/routes/Routes'
import { PasswordField } from '~/components/TextFields/PasswordField'
import { createMuiRegister } from '~/helpers/hookForm'
import AppStoreBlock from '~/components/AppStore/AppStoreBlock'
import { appEnabled } from '~/helpers/nativeApp'

export interface FormValues {
  email: string
  password: string
}

interface SignInProps {
  isLoading: boolean
  onSignIn: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Email is invalid'),
  password: z.string().min(1, 'Password is required'),
}).strict()

const SignIn: React.FunctionComponent<SignInProps> = ({ isLoading, onSignIn }) => {
  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  return <Box>
    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(onSignIn)}>
      <TextField
        {...muiRegister('email')}
        fullWidth
        id="email"
        name="email"
        label="Email"
        margin="normal"
        autoComplete="username"
        error={Boolean(errors.email)}
        helperText={errors.email?.message}
      />
      <PasswordField
        muiRegister={muiRegister('password')}
        id="password"
        label="Password"
        autoComplete="current-password"
        error={Boolean(errors.password)}
        helperText={errors.password?.message}
      />
      <Box sx={{ textAlign: 'right' }}>
        <Link component={RouterLink} to={ROUTE_FORGOT_PASSWORD} variant="body2">
          Forgot password?
        </Link>
      </Box>
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        sx={{ mt: 2 }}>Sign In</LoadingButton>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
      <Typography>
        Don't have an account?
      </Typography>
      <Link component={RouterLink} to={ROUTE_SIGN_UP} variant="button">
        Sign Up
      </Link>
    </Box>
    {appEnabled() && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <AppStoreBlock />
    </Box>}
  </Box>
}

export default SignIn
