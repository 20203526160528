import { Box } from '@mui/material'

import { BrandColor } from '~/config/theme'
import type { Reward } from '~/types/reward/Reward'

import RewardListItemHeader from '../../RewardListItemHeader'

import DonationAmount from './DonationAmount'
import DonationInformation from './DonationInformation'

interface DonationProps {
  reward: Reward
}

const Donation: React.FC<DonationProps> = ({ reward }) => {
  return <Box sx={{ p: 2, background: BrandColor.LIGHT_GREY, borderRadius: 2 }}>
    <RewardListItemHeader reward={reward} brand='boots' />
    <DonationAmount reward={reward} />
    <DonationInformation reward={reward} />
  </Box>
}

export default Donation
