import { useMemo, useState } from 'react'
import { Box, Button, TablePagination, Typography } from '@mui/material'
import { Map } from '@mui/icons-material'

import type { MapRecyclingPoint } from '~/types/deposit/RecyclingPoint'
import type { GeoPoint } from '~/types/location/GeoPoint'
import { schemes } from '~/config/schemes'
import { BrandColor } from '~/config/theme'

interface RecyclingPointListProps {
  recyclingPoints: MapRecyclingPoint[]
  visibleSchemes: string[]
  userLocation?: GeoPoint
  setMapPoint: (point: MapRecyclingPoint) => void
}

export const RecyclingPointList: React.FC<RecyclingPointListProps> = ({ recyclingPoints, visibleSchemes, setMapPoint, userLocation }) => {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const filteredRecyclingPoints = useMemo(() => {
    return recyclingPoints.filter(({ campaignId }) => visibleSchemes.includes(campaignId))
  }, [recyclingPoints, visibleSchemes])

  const handleClick = (recyclingPoint: MapRecyclingPoint): void => {
    setPage(0)
    setMapPoint(recyclingPoint)
    window.scrollTo(0, 0)
  }

  const getSchemeIconById = (campaignId: string): JSX.Element | null => {
    const scheme = schemes.find(scheme => scheme.promotionIds.some(promotionId => promotionId === campaignId))
    if (scheme) {
      return <>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 30, height: 30, background: scheme.icon.color, color: 'white', borderRadius: 6, mb: 0.5 }}>
          <scheme.icon.component sx={{ fontSize: 18 }} />
        </Box>
        <Box sx={{ ml: 'auto', mr: 'auto' }}>
          <Typography sx={{ textAlign: 'center', fontSize: 8, fontWeight: 600, color: '#000000' }}>{scheme.shortName}</Typography>
        </Box>
      </>
    }

    return null
  }

  return <>
    {filteredRecyclingPoints.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((recyclingPoint) => (
      <Box
        key={recyclingPoint.depositBinId}
        sx={{ backgroundColor: '#f6f6f6', display: 'flex', flexDirection: 'row', py: 1, px: 2, mt: 1, borderRadius: 5 }}>
        <Box sx={{ mr: 4, mt: 'auto', mb: 'auto' }}>
          {getSchemeIconById(recyclingPoint.campaignId)}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography variant="body2" sx={{ color: '#000000' }}>{recyclingPoint.storeName}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 'auto' }}>
            <Typography variant="body2" sx={{ color: BrandColor.BLUE, fontWeight: 600, mt: 'auto' }}>{recyclingPoint.distanceFromUser && `${recyclingPoint.distanceFromUser.toFixed(0)} Miles`}</Typography>
            <Button
              variant='text'
              size='small'
              startIcon={<Map />}
              onClick={() => { handleClick(recyclingPoint) }}
              sx={{ p: 0, color: BrandColor.BLUE, fontSize: 14 }}
            >
              View
            </Button>
          </Box>
        </Box>
      </Box>
    ))}
    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={filteredRecyclingPoints.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(_, page) => { setPage(page) }}
      onRowsPerPageChange={(event) => { setRowsPerPage(Number(event.target.value)) }}
      sx={{ mt: 1, mb: 2 }}
    />
  </>
}
