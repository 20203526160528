import { Box, Divider, Grid, Link, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import PasswordIcon from '@mui/icons-material/Password'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CookieIcon from '@mui/icons-material/Cookie'
import FAQIcon from '@mui/icons-material/LiveHelp'
import HowToVote from '@mui/icons-material/HowToVote'
import TermsIcon from '@mui/icons-material/Gavel'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import { Link as RouterLink } from 'react-router-dom'

import RecyclingIcon from '~/components/Icons/RecyclingIcon'
import { ROUTE_ACCOUNT_DETAILS, ROUTE_ACCOUNT_PASSWORD, ROUTE_FAQS, ROUTE_ACCOUNT_LOCATION_SERVICES, ROUTE_TERMS, ROUTE_RECYCLABLE_TYPES, ROUTE_RECYCLING_SYMBOLS, ROUTE_PRIVACY, ROUTE_ACCOUNT_CAMERA_PERMISSIONS, ROUTE_ACCOUNT, ROUTE_RECYCLING_POINTS, ROUTE_DEPOSITS, ROUTE_ACCOUNT_SCHEMES } from '~/routes/Routes'
import CameraIcon from '~/components/Icons/CameraIcon'
import RecyclingPointIcon from '~/components/Icons/RecyclingPointIcon'
import application from '~/config/application'
import BoxIcon from '~/components/Icons/BoxIcon'

interface AccountProps {
  signOut: () => Promise<void>
}

const Account: React.FC<AccountProps> = ({ signOut }) => {
  const handleSignOut = (): void => {
    void signOut()
  }

  const handleIub = (): void => {
    // @ts-expect-error due to IUBenda being on the global scope and not defined here
    window?._iub?.cs?.ui?.openPreferences()
  }

  return <>
    <Typography variant='h4' gutterBottom sx={{ mt: 2 }}>Account</Typography>

    <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item xxs={12} sm={6} md={4} sx={{ px: 2 }}>
        <Typography variant='h6'>Settings</Typography>
        <List>
          <ListItemButton component={RouterLink} to={ROUTE_ACCOUNT_DETAILS}>
            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
            <ListItemText primary='Personal Details' />
          </ListItemButton>
          <ListItemButton component={RouterLink} to={ROUTE_ACCOUNT_LOCATION_SERVICES}>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <ListItemText primary='Location Services' />
          </ListItemButton>
          <ListItemButton component={RouterLink} to={ROUTE_ACCOUNT_CAMERA_PERMISSIONS}>
            <ListItemIcon><CameraIcon /></ListItemIcon>
            <ListItemText primary='Camera Permissions' />
          </ListItemButton>
          <ListItemButton component={RouterLink} to={ROUTE_ACCOUNT_PASSWORD}>
            <ListItemIcon><PasswordIcon /></ListItemIcon>
            <ListItemText primary='Password' />
          </ListItemButton>
        </List>
      </Grid>
      <Grid item xxs={12} sm={6} md={4} sx={{ px: 2 }}>
        <Typography variant='h6'>Recycling</Typography>
        <List>
          <ListItemButton component={RouterLink} to={ROUTE_DEPOSITS} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><BoxIcon /></ListItemIcon>
            <ListItemText primary='Deposits' />
          </ListItemButton>
          {application.useSchemeSelector && <ListItemButton component={RouterLink} to={ROUTE_ACCOUNT_SCHEMES} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><HowToVote /></ListItemIcon>
            <ListItemText primary='Schemes' />
          </ListItemButton>}
        </List>
      </Grid>
      <Grid item xxs={12} sm={6} md={4} sx={{ px: 2 }}>
        <Typography variant='h6'>Help</Typography>
        <List>
          <ListItemButton component={RouterLink} to={ROUTE_FAQS} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><FAQIcon /></ListItemIcon>
            <ListItemText primary='FAQs' />
          </ListItemButton>
          <ListItemButton component={RouterLink} to={ROUTE_RECYCLABLE_TYPES} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><RecyclingIcon /></ListItemIcon>
            <ListItemText primary='Recycling Types' />
          </ListItemButton>
          <ListItemButton component={RouterLink} to={ROUTE_RECYCLING_SYMBOLS} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><RecyclingIcon /></ListItemIcon>
            <ListItemText primary='Recycling Symbols' />
          </ListItemButton>
          {application.hasRecyclingPointsRoute
            ? <ListItemButton component={RouterLink} to={ROUTE_RECYCLING_POINTS} state={{ from: ROUTE_ACCOUNT }}>
                <ListItemIcon><RecyclingPointIcon /></ListItemIcon>
                <ListItemText primary='Recycling Points' />
              </ListItemButton>
            : application.externalRecyclingPointsUrl
              ? <ListItemButton component={Link} href={application.externalRecyclingPointsUrl} target='_blank'>
                  <ListItemIcon><RecyclingPointIcon /></ListItemIcon>
                  <ListItemText primary='Recycling Points' />
                </ListItemButton>
              : null
          }
        </List>
      </Grid>
      <Grid item xxs={12} sm={6} md={12} sx={{ px: 2 }}>
        <Typography variant='h6'>Terms & Privacy</Typography>
        <List>
          <ListItemButton component={RouterLink} to={ROUTE_TERMS} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><TermsIcon /></ListItemIcon>
            <ListItemText primary='Terms' />
          </ListItemButton>
          <ListItemButton component={RouterLink} to={ROUTE_PRIVACY} state={{ from: ROUTE_ACCOUNT }}>
            <ListItemIcon><PrivacyTipIcon /></ListItemIcon>
            <ListItemText primary='Privacy' />
          </ListItemButton>
          <ListItemButton component={Link} onClick={handleIub}>
            <ListItemIcon><CookieIcon /></ListItemIcon>
            <ListItemText primary='Cookie Preferences' />
          </ListItemButton>
        </List>
      </Grid>
    </Grid>
    <Box>
      <Divider sx={{ my: 2 }} />
      <ListItemButton onClick={handleSignOut}>
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        <ListItemText primary='Sign Out' />
      </ListItemButton>
    </Box>
  </>
}

export default Account
