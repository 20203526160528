import application from '~/config/application'

export const appEnabled = (): boolean => {
  return application.enabledApps?.length > 0
}

export const bothAppsEnabled = (): boolean => {
  return application.enabledApps.split(',').length > 1
}

export const iosAppEnabled = (): boolean => {
  return application.enabledApps.includes('IOS')
}

export const androidAppEnabled = (): boolean => {
  return application.enabledApps.includes('ANDROID')
}
