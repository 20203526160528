import { Link, Typography } from '@mui/material'

import BeautyIcon from '~/components/Icons/schemes/BeautyIcon'
import BlisterIcon from '~/components/Icons/schemes/BlisterIcon'
import { ROUTE_RECYCLING_POINTS } from '~/routes/Routes'
import type { Scheme } from '~/types/promotion/Promotion'

import { BrandColor } from './theme'

export const schemes: Scheme[] = [
  {
    name: 'Health, Beauty and Wellness',
    shortName: 'Beauty',
    icon: {
      component: BeautyIcon,
      color: '#3d7cbe',
      selectorColor: BrandColor.WHITE,
    },
    description: <><Typography variant='body2' gutterBottom>
      Recycle at Boots Health, Beauty and Wellness scheme is for recycling your used and empty eligible health, beauty and wellness products for rewards!
    </Typography>
    <Typography variant="body2">
      Available nationwide in participating stores.
    </Typography>
    </>,
    promotionIds: [
      '06749741-6e5b-409a-999f-684345be3611',
      '31cd6ea1-38c7-4745-9efb-8f414221b97f',
      '281c4ac6-c559-4bf6-a5b9-69bdc019ec8d',
      'fd2ec1ab-9b9a-4cf5-b636-36f236f33224',
    ],
    priority: 1,
    requiresLoyaltyCard: true,
    recyclingPointPrefix: 'Boots',
  },
  {
    name: 'Blister Pack',
    shortName: 'Blister',
    icon: {
      component: BlisterIcon,
      color: BrandColor.MINT_GREEN,
      selectorColor: BrandColor.WHITE,
    },
    description: <>
      <Typography variant='body2' gutterBottom>Recycle at Boots Blister pack scheme is for recycling used and empty medicine blister packs (prescription and non-prescription) for rewards!</Typography>
      <Typography variant='body2' fontWeight={600}>Only available in selected stores, <Link href={`${ROUTE_RECYCLING_POINTS}?scheme=Blister`} target="_blank">check here</Link> for participating stores.</Typography>
    </>,
    promotionIds: ['d27dc83b-1bef-4751-b71b-278acc31127a'],
    priority: 2,
    requiresLoyaltyCard: false,
    recyclingPointPrefix: 'PharmacyBoots',
  },
]
