import { useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'

import S3Image from '~/components/Images/S3Image'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { isNotifiable } from '~/types/guards/errors'
import type { RecyclableScan } from '~/types/recyclable/recyclableScan'

interface ConfirmScanStepProps {
  recyclable: RecyclableScan
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
}

const ConfirmScanStep: React.FC<ConfirmScanStepProps> = ({ recyclable, onNextStep }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const confirm = async (): Promise<void> => {
    try {
      setIsLoading(true)

      if (!recyclable.scan?.brand || !recyclable.scan?.category || !recyclable.scan?.form) {
        throw new Error('Scan missing required fields')
      }

      await onNextStep({
        brand: recyclable.scan.brand,
        category: recyclable.scan.category,
        form: recyclable.scan.form,
        confirmed: true,
      })
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Unable to create recyclable' }))
      setIsLoading(false)

      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  const handleConfirmClick = (): void => {
    void confirm()
  }

  const handleFalsePositiveClick = (): void => {
    void onNextStep({ confirmed: false })
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: 0 }}>
    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', background: '#000', mb: 2 }}>
      <S3Image imageKey={recyclable.photo.imageKey} sx={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
    </Box>
    <Typography variant="h6" gutterBottom>Is this your product?</Typography>
    <Typography variant="body1" gutterBottom>{recyclable.scan?.productName}</Typography>
    <Box sx={{ mt: 2 }}>
      <LoadingButton variant="contained" color="primary" loading={isLoading} onClick={handleConfirmClick} fullWidth sx={{ mb: 1 }}>Yes</LoadingButton>
      <Button variant="contained" color="secondary" onClick={handleFalsePositiveClick} disabled={isLoading} fullWidth>No</Button>
    </Box>
  </Box>
}

export default ConfirmScanStep
