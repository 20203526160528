import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { CalendarToday, ExpandMore } from '@mui/icons-material'
import { add, differenceInDays, format, parseISO } from 'date-fns'

import { BrandColor } from '~/config/theme'
import { voucherTimeoutMinutes } from '~/constants/reward'
import { pluralise } from '~/helpers/text'
import type { Reward } from '~/types/reward/Reward'

import RewardTerms from './RewardTerms'
import RewardInstructions from './RewardInstructions'
import { RewardTimeout } from './RewardTimeout'

interface RewardInfoProps {
  reward: Reward
  awardedDate: Date | null
  expiryDate: Date | null
  isExpired: boolean
}

const RewardInformation: React.FC<RewardInfoProps> = ({ reward, awardedDate, expiryDate, isExpired }) => {
  const daysToExpire = expiryDate ? differenceInDays(expiryDate, new Date()) : 0
  const voucherActivationDuration = reward.prize?.voucherActivationDuration ?? { minutes: voucherTimeoutMinutes }
  const activatedExpiry = reward.activatedAt ? add(parseISO(reward.activatedAt), voucherActivationDuration) : null

  const getExpirationMessage = (): string => {
    if (daysToExpire === 0) {
      return 'Expiring Today'
    }

    return `Expires in ${daysToExpire} ${pluralise('day', daysToExpire)}`
  }

  const renderExpiryBlock = (): React.ReactNode => {
    let expired = isExpired

    if (reward.activatedAt && activatedExpiry) {
      if (activatedExpiry > new Date()) {
        return <RewardTimeout reward={reward} />
      } else {
        expired = true
      }
    }

    if (!expiryDate) return null

    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: expired ? '#ccc' : BrandColor.ACCENT_BLUE, borderRadius: 1, py: 0.5 }}>
      <Typography variant='body1' sx={{ fontWeight: 500, fontSize: 14, color: !expired ? '#ffffff' : BrandColor.BLACK }}>
        {expired ? 'Expired' : getExpirationMessage()}
      </Typography>
    </Box>
  }

  return (
    <Box sx={{
      transition: 'all 0.5s ease',
      overflow: 'hidden',
    }}>
      {renderExpiryBlock()}
      <Box sx={{ my: 1 }}>
        {awardedDate && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff', px: 1, borderRadius: 1, mb: expiryDate ? 0.5 : 0 }}>
          <CalendarToday sx={{ mr: 1, width: 15, color: BrandColor.ACCENT_BLUE }} />
          <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
            Received on
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
              {format(awardedDate, "PP 'at' HH:mm a")}
            </Typography>
          </Box>
        </Box>}
        {expiryDate && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff', px: 1, borderRadius: 1 }}>
          <CalendarToday sx={{ mr: 1, width: 15, color: BrandColor.ACCENT_BLUE }} />
          <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
            {new Date() < (activatedExpiry ?? expiryDate) ? 'Expires on' : 'Expired on'}
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
              {format(activatedExpiry ?? expiryDate, "PP 'at' HH:mm a")}
            </Typography>
          </Box>
        </Box>}
        {reward.activatedAt && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff', px: 1, borderRadius: 1, mt: 0.5 }}>
          <CalendarToday sx={{ mr: 1, width: 15, color: BrandColor.ACCENT_BLUE }} />
          <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
            Activated on
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
              {format(parseISO(reward.activatedAt), "PP 'at' HH:mm a")}
            </Typography>
          </Box>
        </Box>}
      </Box>
      <Box>
        {!isExpired && reward.prize.instructions && <Accordion sx={{ background: 'none', boxShadow: 'none' }}>
          <AccordionSummary expandIcon={<ExpandMore />} id={`reward-redeem-header-${reward.SK}`} aria-controls={`reward-redeem-content-${reward.SK}`}>
            <Typography variant="caption" sx={{ fontWeight: 500 }}>How To Redeem</Typography>
          </AccordionSummary>
          <AccordionDetails id={`reward-redeem-content-${reward.SK}`} sx={{ pt: 0 }}>
            <RewardInstructions reward={reward} />
          </AccordionDetails>
        </Accordion>}
        <Accordion sx={{ background: 'none', boxShadow: 'none' }}>
          <AccordionSummary expandIcon={<ExpandMore />} id={`reward-terms-header-${reward.SK}`} aria-controls={`reward-terms-content-${reward.SK}`}>
            <Typography variant="caption" sx={{ fontWeight: 500 }}>Terms</Typography>
          </AccordionSummary>
          <AccordionDetails id={`reward-terms-content-${reward.SK}`} sx={{ pt: 0 }}>
            <RewardTerms reward={reward} expiryDate={expiryDate} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default RewardInformation
