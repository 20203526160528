import { ScanFlow } from '~/enum/ScanFlow'

export default {
  name: 'Recycle at Boots',
  brand: 'Boots',
  client: 'Boots',
  clientRegisteredName: 'Boots UK Limited',
  email: 'boots@scan2recycle.com',
  csPhoneNumber: '+44 (0) 20 4506 8677',
  prizeDraw: false,

  // Environment
  stage: import.meta.env.VITE_STAGE as string || '',

  // Buckets
  binScanS2RBucket: import.meta.env.VITE_BINSCAN_S2R_BUCKET as string ?? '',
  storageS2RBucket: import.meta.env.VITE_STORAGE_S2R_BUCKET as string || '',

  // AWS
  awsCognitoIdentityPoolID: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID as string || '',
  awsUserPoolsID: import.meta.env.VITE_AWS_USER_POOLS_ID as string || '',
  awsUserPoolsWebClientID: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID as string || '',

  // Enabled Apps
  enabledApps: import.meta.env.VITE_ENABLED_APPS as string || '',

  // Google
  googleApiKey: import.meta.env.VITE_S2R_GOOGLE_API_KEY as string || '',
  playstoreAppId: import.meta.env.VITE_PLAYSTORE_APP_ID as string || '',

  // Apple
  appleAppId: import.meta.env.VITE_APPLE_APP_ID as string || '',

  // Bugsnag
  bugSnagKey: import.meta.env.VITE_BUGSNAG_API_KEY as string || '',

  // Iubenda
  iubendaCookiePolicyId: import.meta.env.VITE_IUBENDA_COOKIE_POLICY_ID as string || '',
  iubendaSiteId: import.meta.env.VITE_IUBENDA_SITE_ID as string || '',

  // Umami
  umamiWebsiteId: import.meta.env.VITE_UMAMI_WEBSITE_ID as string || '',
  umamiUrl: import.meta.env.VITE_UMAMI_URL as string || '',

  // Release
  releaseVersion: import.meta.env.VITE_RELEASE_VERSION as string ?? null,
  clientVersion: import.meta.env.VITE_CLIENT_VERSION as string ?? null,

  // Scan Flow
  scanFlow: import.meta.env.VITE_SCAN_FLOW as ScanFlow ?? ScanFlow.Category,

  // Recycling Points
  hasRecyclingPointsRoute: import.meta.env.VITE_HAS_RECYCLING_POINTS_ROUTE === 'true',
  externalRecyclingPointsUrl: import.meta.env.VITE_EXTERNAL_RECYCLING_POINTS_URL === 'null' ? null : import.meta.env.VITE_EXTERNAL_RECYCLING_POINTS_URL as string,
  displayAllRecyclingPoints: import.meta.env.VITE_DISPLAY_ALL_RECYCLING_POINTS === 'true',

  // Scheme Selector
  useSchemeSelector: import.meta.env.VITE_USE_SCHEME_SELECTOR === 'true',
  alwaysShowSchemeSelector: import.meta.env.VITE_ALWAYS_SHOW_SCHEME_SELECTOR === 'true',
}
