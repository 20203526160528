import type GetUserClaimsParams from '~/types/claim/request/GetUserClaimsParams'
import type UserClaim from '~/types/claim/UserClaim'

import SubmissionProcessorService from './SubmissionProcessorService'

class ClaimService extends SubmissionProcessorService {
  public async getUserClaims (userId: string, params: GetUserClaimsParams, init?: { signal?: AbortSignal }): Promise<{ items: UserClaim[] }> {
    const query = this.buildQueryString(params)
    const path = `s2r/user/${userId}/claims${query}`
    const response = await this.request(path, { signal: init?.signal })

    return response.status === 200 ? (await response.json()) : await Promise.reject(response)
  }
}

export default new ClaimService()
