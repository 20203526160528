import SubmissionProcessorService from './SubmissionProcessorService'

import IsAppUserException from '~/exception/IsAppUserException'
import type { User, UserSchemes } from '~/types/user/User'
import type { UpdateUserPayload } from '~/types/user/request/UpdateUserPayload'
import type { CreateUserPayload } from '~/types/user/request/CreateUserPayload'
import type { UserPromotionStatistics } from '~/types/user/UserPromotionStatistics'

class UserService extends SubmissionProcessorService {
  public async createUser (promotionId: string, payload: CreateUserPayload): Promise<boolean> {
    const response = await this.publicRequest('s2r/user', {
      method: 'POST',
      body: JSON.stringify({
        userId: payload.userId,
        campaignId: promotionId,
        locale: payload.locale,
        email: payload.email,
        title: payload.title,
        firstname: payload.firstName,
        surname: payload.lastName,
        addressOne: payload.addressLineOne,
        addressTwo: payload.addressLineTwo,
        city: payload.city,
        county: payload.county,
        postcode: payload.postalCode,
        mobile: payload.mobileNo,
        gender: payload.gender,
        ageGroup: payload.ageGroup,
        loyaltyCardNo: payload.loyaltyCardNo,
        informationOptIn: payload.informationOptIn,
        schemes: payload.schemes,
      }),
    })

    return response.status === 204 ? true : await Promise.reject(response)
  }

  public async getUser (promotionId: string, userId: string, init?: { signal?: AbortSignal }): Promise<User> {
    const response = await this.request(`s2r/user?promotionId=${promotionId}&userId=${userId}`, init)

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async getUserSchemes (init?: { signal?: AbortSignal }): Promise<UserSchemes> {
    const response = await this.request('s2r/user/schemes', init)

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async updateUser (promotionId: string, userId: string, payload: UpdateUserPayload): Promise<boolean> {
    const response = await this.request('s2r/user', {
      method: 'PUT',
      body: JSON.stringify({
        userId,
        campaignId: promotionId,
        title: payload.title,
        firstname: payload.firstName,
        surname: payload.lastName,
        addressOne: payload.addressLineOne,
        addressTwo: payload.addressLineTwo,
        city: payload.city,
        county: payload.county,
        postcode: payload.postalCode,
        mobile: payload.mobileNo,
        gender: payload.gender,
      }),
    })

    return response.status === 204 ? true : await Promise.reject(response)
  }

  public async updateUserSchemes (schemes: UserSchemes): Promise<null> {
    const response = await this.request('s2r/user/schemes', {
      method: 'PUT',
      body: JSON.stringify({ schemes }),
    })

    return response.status === 204 ? null : await Promise.reject(response)
  }

  public async boardedUser (): Promise<null> {
    const response = await this.request('s2r/onboard', { method: 'PUT' })

    return response.status === 204 ? null : await Promise.reject(response)
  }

  public async getUserPromotionStatistics (promotionId: string, init?: { signal?: AbortSignal }): Promise<UserPromotionStatistics> {
    const response = await this.request(`s2r/user/promotion/${promotionId}/statistics`, init)

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }

  public async S2RCheckAppVersion (): Promise<any> {
    const response = await this.request('s2r/user/login/gate')

    if (response.status === 401) {
      throw new IsAppUserException()
    }

    return response.status === 204 ? null : await Promise.reject(response)
  }
}

export default new UserService()
