import { Box, Container, Link, Typography, styled } from '@mui/material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material'

import application from '~/config/application'
import { BrandColor } from '~/config/theme'

import FAQList, { type FAQListItem } from './FAQList'

const FAQContent = styled(Box)(({ theme }) => ({ padding: `${theme.spacing(2)} ${theme.spacing(2)}` }))

const NativeFAQView: React.FunctionComponent = () => {
  const contactUsBlock = (): FAQListItem => {
    return {
      type: 'block',
      content: <Box sx={{ mt: 2 }}>
        <Typography variant='h6'>Contact Us</Typography>
        <Typography variant='body1'>If you have any questions or concerns please contact us at:</Typography>
        <Typography variant='body1' sx={{ mt: 1 }}>Email: <Link href={`mailto:${application.email}`}>{application.email}</Link></Typography>
        <Typography variant='body1' sx={{ mb: 1 }}>Phone: <Link href={`tel:${application.csPhoneNumber}`}>{application.csPhoneNumber}</Link></Typography>
        <Typography variant='caption'>Monday to Friday 9am - 5pm excluding public and bank holidays (Standard UK geographic number, normal phone provider call rates apply)</Typography>
      </Box>,
    }
  }

  const renderListItem = (item: FAQListItem, index: number): React.ReactElement<any, string | React.JSXElementConstructor<any>> | null => {
    switch (item.type) {
      case 'block':
        return item.content
      case 'question':
        return <TreeItem key={index} nodeId={index.toString()} label={<Box sx={{ ml: 2 }}>{item.question}</Box>}>
          <FAQContent>{item.answer}</FAQContent>
        </TreeItem>
    }
  }

  return <Container>
    <TreeView
      defaultCollapseIcon={<ExpandLessIcon sx={{ color: BrandColor.MINT_GREEN }} />}
      defaultExpandIcon={<ExpandMoreIcon sx={{ color: BrandColor.MINT_GREEN }} />}
    >
      {[
        ...FAQList,
        contactUsBlock(),
      ].map((item, index) => renderListItem(item, index))}
    </TreeView>
  </Container>
}

export default NativeFAQView
