import { Link, Typography } from '@mui/material'
import Markdown from 'markdown-to-jsx'

import RouterLink from '~/components/Link/RouterLink'
import type { Reward } from '~/types/reward/Reward'

const RewardInstructions: React.FC<{ reward: Reward }> = ({ reward }) => {
  return <Markdown
    children={reward.prize.instructions}
    options={{
      overrides: {
        p: {
          component: Typography,
          props: {
            variant: 'caption',
            gutterBottom: true,
            sx: { display: 'block' },
          },
        },
        a: Link,
        RouterLink: {
          component: RouterLink,
        },
      },
    }} />
}

export default RewardInstructions
