import { useEffect, useMemo, useState } from 'react'
import { LngLat, supported } from 'mapbox-gl'
import { Box } from '@mui/material'

import Map from '~/components/Map/Map'
import marker from '~/assets/images/map/recycling-point.svg'
import disabledMarker from '~/assets/images/map/disabled-recycling-point.svg'
import { Loading } from '~/views/Account/PersonalDetails/Loading'
import { BrandColor } from '~/config/theme'
import { schemes } from '~/config/schemes'
import type { GeoPoint } from '~/types/location/GeoPoint'
import type { MapLayer, MapIcon, MapStyle } from '~/types/map/Map'
import type { MapRecyclingPoint } from '~/types/deposit/RecyclingPoint'
import type { PromotionSchemes } from '~/types/promotion/Promotion'

interface RecyclingPointMapProps {
  schemes: PromotionSchemes
  recyclingPoints: MapRecyclingPoint[]
  centerPoint: GeoPoint
  onMapMoveEnd: (centerPoint: GeoPoint) => void
  userLocation?: GeoPoint
  point?: MapRecyclingPoint
  visibleSchemeNames?: string[]
}

const icons: Array<{ promotionIds: string[], icon: MapIcon }> = [
  {
    promotionIds: schemes.reduce<string[]>((acc, scheme) => [...acc, ...scheme.promotionIds], []),
    icon: {
      name: 'marker',
      source: marker,
      disabledSource: disabledMarker,
    },
  },
]

const style: MapStyle = {
  clusterPaint: {
    'circle-color': BrandColor.BLUE,
    'circle-radius': 20,
  },
  clusterOptions: {
    maxZoom: 14,
    radius: 50,
  },
  clusterCountLayout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Poppins Regular'],
    'text-size': 16,
  },
  clusterCountPaint: {
    'text-color': BrandColor.WHITE,
  },
}

const RecyclingPointMap: React.FC<RecyclingPointMapProps> = ({
  schemes,
  recyclingPoints,
  centerPoint,
  onMapMoveEnd,
  userLocation,
  point,
  visibleSchemeNames,
}) => {
  const [layers, setLayers] = useState<MapLayer[]>([])
  const [userLngLat, setUserLngLat] = useState<LngLat>()
  const coordinates: LngLat = useMemo(() => LngLat.convert([centerPoint.longitude, centerPoint.latitude]), [centerPoint])
  // TODO: Would it be preferred to only finish loading the page once the map is ready?
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const mapLayers: MapLayer[] = schemes.map(scheme => {
      const pins = recyclingPoints.filter(point => point.campaignId === scheme.promotion.id).map(point => {
        return {
          coordinates: LngLat.convert([point.longitude, point.latitude]),
          title: point.storeName,
        }
      })

      const icon = icons.find(icon => icon.promotionIds.includes(scheme.promotion.id))?.icon

      return {
        id: scheme.promotion.id,
        pins,
        icon,
      }
    })

    if (userLocation) {
      setUserLngLat(LngLat.convert([userLocation.longitude, userLocation.latitude]))
    }

    setLayers(mapLayers)
    setIsLoading(false)
  }, [point, recyclingPoints, userLocation])

  if (supported()) {
    return <Box sx={{ pt: 2 }}>
      {
        isLoading
          ? <Loading/>
          : <Map
              center={coordinates}
              layers={layers}
              userLocation={userLngLat}
              sx={{ width: '100%', height: '400px' }}
              zoom={point ? 13 : userLocation ? 11 : 4}
              interactive={true}
              cluster={true}
              onMoveEnd={onMapMoveEnd}
              visibleLayers={visibleSchemeNames}
              style={style}
            />
      }
    </Box>
  }

  return null
}

export default RecyclingPointMap
