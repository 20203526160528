import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { promotionSwitchMiddleware } from '~/redux/features/auth/promotionSwitchMiddleware'
import { notificationMiddleware } from '~/redux/features/notifications/notificationMiddleware'
import rootReducer from '~/redux/reducers/root'

const store = configureStore({
  // devTools: true,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .prepend(notificationMiddleware)
    .prepend(promotionSwitchMiddleware.middleware),
})

export type AppDispatch = typeof store.dispatch
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store
