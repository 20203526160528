import { Box, ImageList, ImageListItem, Typography } from '@mui/material'
import { CalendarToday, LocationOn } from '@mui/icons-material'
import { parseISO, format } from 'date-fns'

import S3Image from '~/components/Images/S3Image'
import { DepositStatus } from '~/enum/DepositStatus'
import type { Deposit } from '~/types/deposit/Deposit'
import { BrandColor } from '~/config/theme'
import application from '~/config/application'
import RecyclablesIcon from '~/components/Icons/RecyclablesIcon'

interface DepositListItemProps {
  deposit: Deposit
}

const DepositListItem: React.FC<DepositListItemProps> = ({ deposit }) => {
  const date = parseISO(deposit.createdAt)
  const { depositItemsCount } = deposit

  const getFriendlyDepositStatus = (): JSX.Element => {
    let background: string = '#fbb86d'
    let label = 'Pending'

    background = '#55C6BD'

    switch (deposit.status) {
      case DepositStatus.RECEIVED:
        if (application.prizeDraw) {
          background = '#55C6BD'
          label = 'Entry'
        }
        break
      case DepositStatus.REJECTED:
        background = '#E972B1'
        label = 'Rejected'
        break
      case DepositStatus.WON:
        background = '#b6d76c'
        label = 'Reward'
        break
    }

    return <Box sx={{ p: 1, textAlign: 'center', background, color: BrandColor.WHITE, borderRadius: 1, ml: 'auto', fontWeight: 600 }}>{label}</Box>
  }

  return <Box sx={{ pb: { xxs: 2, sm: 0 } }}>
    <Box sx={{ p: 2, background: BrandColor.LIGHT_GREY, borderRadius: 2 }}>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center', pl: 0.5 }}>
            <CalendarToday sx={{ color: BrandColor.ACCENT_BLUE, width: 20, height: 20 }} />
            <Box sx={{ display: 'flex', width: 0, flexGrow: 1, alignItems: 'center', overflow: 'hidden' }}>
              <Typography variant='h6' sx={{ flexShirnk: 0, paddingLeft: 1, color: '#555', fontSize: 16 }} noWrap>
                {format(date, 'd MMM y')}
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: 1, width: 0, flexGrow: 1, color: '#888', fontSize: 12 }} noWrap>
                {format(date, 'H:mm')}
              </Typography>
              <RecyclablesIcon sx={{ color: BrandColor.ACCENT_BLUE, width: 20, height: 20, ml: 1 }} />
              <Typography variant='caption' sx={{ padding: 1, color: '#888', fontWeight: '500', flexShrink: 0 }}>{depositItemsCount}</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1, pl: 0.5 }}>
          <LocationOn sx={{ color: BrandColor.ACCENT_BLUE, width: 20 }}/>
          <Typography variant='caption' sx={{ paddingLeft: 1, color: '#888', flexGrow: 1 }} noWrap>
            {deposit.depositBinInfo.storeName}, {deposit.depositBinInfo.address}
          </Typography>
        </Box>

        <Box sx={{ position: 'relative', width: '100%', flexShrink: 0, mb: 1 }}>
          {getFriendlyDepositStatus()}
        </Box>
      </Box>
      <ImageList
        sx={{ display: deposit.depositItems.length === 1 ? 'flex' : 'block', width: '100%', minHeight: 200, overflow: 'hidden', margin: 0, borderRadius: 2 }}
        variant="masonry"
        cols={(depositItemsCount > 1) ? 2 : 1}
      >
        {deposit.depositItems.map((depositItem) => (
          <ImageListItem
            key={depositItem.bankableId}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, ':last-child': { mb: '0 !important' } }}
          >
            <S3Image imageKey={depositItem.imageKey} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} blurhash={depositItem.blurhash}/>
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  </Box>
}

export default DepositListItem
