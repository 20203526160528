import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { BrandColor } from '~/config/theme'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import type { RootState } from '~/redux/reducers/root'

const TermsOfferBanner: React.FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const offerStartDate = new Date('2023-10-01')
  const offerEndDate = new Date('2023-10-31')
  const offerIsActive = new Date() >= offerStartDate && new Date() <= offerEndDate

  if (!offerIsActive || Scan2RecyclePromotion.scheme.shortName !== 'Beauty' || !isAuthenticated || Scan2RecyclePromotion.country !== 'GB') return null

  return <Box sx={{ p: 4, background: BrandColor.ACCENT_BLUE, my: 2 }}>
    <Typography sx={{ color: 'white ' }} gutterBottom>From 1st October 2023 until 31st October 2023, you can receive 600 Advantage Card points when you spend £10 or more in-store.*</Typography>
    <Typography variant='caption' sx={{ color: 'white ' }}>*Voucher valid for 3 days including the day it is issued and must be redeemed in a participating store no later than 2nd November 2023, please see below for full terms and conditions. Latest redemption date is 2nd November 2023.</Typography>
  </Box>
}

export default TermsOfferBanner
