import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import deposit from '~/config/deposit'
import { RecyclableStatus } from '~/constants/recyclable'
import { resolveRecyclableActionStatus } from '~/helpers/recyclable'
import type { Recyclable } from '~/types/recyclable/Recyclable'
import type { RecyclablesByStatus } from '~/types/recyclable/RecyclablesByStatus'

import { deleteRecyclables, getRecyclables } from './actions'

interface RecyclableState {
  recyclables: RecyclablesByStatus
  selected: RecyclablesByStatus
  loading: string[]
  fetched: boolean
  fetching: boolean
}

const initialState: RecyclableState = {
  recyclables: {
    [RecyclableStatus.ACCEPTED]: [],
    [RecyclableStatus.TAGGED]: [],
    [RecyclableStatus.REJECTED]: [],
  },
  selected: {
    [RecyclableStatus.ACCEPTED]: [],
    [RecyclableStatus.TAGGED]: [],
    [RecyclableStatus.REJECTED]: [],
  },
  loading: [],
  fetched: false,
  fetching: false,
}

export const recyclableSlice = createSlice({
  name: 'recyclable',
  initialState,
  reducers: {
    addItemToSelectedRecyclables: (state, action: PayloadAction<Recyclable>) => {
      const status = resolveRecyclableActionStatus(action.payload)
      const index = state.selected[status].findIndex((item: Recyclable) => item.bankableId === action.payload.bankableId)
      if (index === -1) state.selected[status].push(action.payload)
    },
    removeItemFromSelectedRecyclables: (state, action: PayloadAction<Recyclable>) => {
      const status = resolveRecyclableActionStatus(action.payload)
      const index = state.selected[status].findIndex((item: Recyclable) => item.bankableId === action.payload.bankableId)
      state.selected[status].splice(index, 1)
    },
    clearSelectedRecyclables: (state) => {
      state.selected = { ...initialState.selected }
    },
    resetRecyclables: (state) => {
      state.recyclables = { ...initialState.recyclables }
      state.fetched = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecyclables.pending, (state) => {
      state.fetching = true
    })
    builder.addCase(getRecyclables.fulfilled, (state, action) => {
      const acceptedRecyclableStatuses = deposit.allowedRecyclableStatus === RecyclableStatus.TAGGED ? [RecyclableStatus.ACCEPTED, RecyclableStatus.TAGGED] : [RecyclableStatus.ACCEPTED]

      const taggedRecyclables = acceptedRecyclableStatuses.includes(RecyclableStatus.TAGGED)
        ? []
        : action.payload.filter((item) => [RecyclableStatus.TAGGED].includes(item.status))

      state.recyclables = {
        [RecyclableStatus.ACCEPTED]: action.payload.filter((item) => acceptedRecyclableStatuses.includes(item.status)),
        [RecyclableStatus.TAGGED]: taggedRecyclables,
        [RecyclableStatus.REJECTED]: action.payload.filter((item) => [RecyclableStatus.REJECTED].includes(item.status)),
      }

      state.fetched = true
      state.fetching = false
    })
    builder.addCase(getRecyclables.rejected, (state) => {
      state.fetching = false
      state.fetched = true
    })

    builder.addCase(deleteRecyclables.pending, (state, action) => {
      state.loading = [...new Set([...state.loading, ...action.meta.arg.recyclableIds])]
    })
    builder.addCase(deleteRecyclables.fulfilled, (state, action) => {
      state.recyclables[action.payload.status] = state.recyclables[action.payload.status].filter((item: Recyclable) => !action.payload.deletedRecyclableIds.includes(item.bankableId))
      state.selected[action.payload.status] = state.selected[action.payload.status].filter((item: Recyclable) => ![...action.payload.deletedRecyclableIds, ...action.payload.failedRecyclableIds].includes(item.bankableId))
      state.loading = state.loading.filter((item: string) => ![...action.payload.deletedRecyclableIds, ...action.payload.failedRecyclableIds].includes(item))
    })
  },
})

export const {
  addItemToSelectedRecyclables,
  removeItemFromSelectedRecyclables,
  clearSelectedRecyclables,
  resetRecyclables,
} = recyclableSlice.actions

export default recyclableSlice.reducer
