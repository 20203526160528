import { type SvgIconProps } from '@mui/material'

const RecyclingBoxLoader: React.FC<SvgIconProps> = (props) => {
  return <div className="App-loader">
    <div className="App-loader-shadow"></div>
    <div className="App-loader-box">
      <svg viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="box" clipPath="url(#clip0_40_34)">
          <path id="Vector" d="M4.02603 95.8629L92.2739 95.8629C94.3767 95.8629 96.0814 94.1582 96.0814 92.0554L96.0814 3.80756C96.0814 1.7047 94.3767 -7.77842e-06 92.2739 -7.96226e-06L4.02604 -1.56771e-05C1.92318 -1.5861e-05 0.218484 1.70469 0.218483 3.80755L0.218476 92.0554C0.218475 94.1582 1.92317 95.8629 4.02603 95.8629Z" fill="#C9A66F"/>
          <path id="Vector_2" d="M48.8001 23.3001C34.7168 23.3001 23.3 34.7168 23.3 48.8001C23.3 62.8834 34.7168 74.3001 48.8 74.3001C62.8833 74.3001 74.3 62.8834 74.3001 48.8001C74.3001 34.7168 62.8833 23.3001 48.8001 23.3001Z" fill="#F9F6EC"/>
          <path id="Vector_3" opacity="0.5" d="M43.6001 96.3001L43.6001 82.6901L45.8801 84.9601L48.1501 82.6901L50.4301 84.9601L52.7001 82.6901L52.7001 96.3001L43.6001 96.3001Z" fill="#F9F6EC"/>
          <path id="Vector_4" opacity="0.5" d="M45.8801 11.3401L43.6001 13.6101L43.6001 0.000106016L52.7001 0.000106812L52.7001 13.6101L50.4301 11.3401L48.1501 13.6101L45.8801 11.3401Z" fill="#F9F6EC"/>
          <path id="Vector_5" opacity="0.5" d="M96.3 52.7001L82.69 52.7001L84.96 50.4201L82.69 48.1501L84.96 45.8701L82.69 43.6001L96.3 43.6001L96.3 52.7001Z" fill="#F9F6EC"/>
          <path id="Vector_6" opacity="0.5" d="M11.3401 50.4201L13.6101 52.7001L0.000106812 52.7001L0.000107607 43.6001L13.6101 43.6001L11.3401 45.8701L13.6101 48.1501L11.3401 50.4201Z" fill="#F9F6EC"/>
          <g id="Swm9oX.tif">
            <path id="Vector_7" d="M56.3936 39.7991C57.1082 38.9263 57.8174 38.059 58.5484 37.1698C60.1861 38.5324 61.5094 40.2023 62.4351 42.0742C63.3608 43.9462 63.8687 45.9798 63.9271 48.0473L66.3 48.0473L66.3 48.0855C66.2587 48.133 66.2222 48.1842 66.191 48.2382C64.9036 50.4585 63.618 52.6823 62.3342 54.9098C62.3015 54.9644 62.2688 55.0135 62.2196 55.0899C60.8668 52.7388 59.5193 50.4094 58.161 48.0528L60.5286 48.0528C60.4686 46.4818 60.0708 44.9391 59.3596 43.5197C58.6485 42.1003 57.6391 40.8341 56.3936 39.7991V39.7991Z" fill="#4F9B34"/>
            <path id="Vector_8" d="M37.1697 39.0518C38.4585 37.4529 40.1012 36.1425 41.9817 35.2131C43.8622 34.2837 45.9347 33.7579 48.0526 33.6731L48.0526 31.3001L48.0853 31.3001C48.1399 31.3382 48.1835 31.3819 48.2381 31.4146C50.4638 32.6966 52.6876 33.9803 54.9097 35.2659C54.9642 35.2987 55.0188 35.3314 55.0897 35.3805C52.7386 36.7334 50.4092 38.0808 48.0526 39.4391L48.0526 37.0716C46.4441 37.1533 44.8733 37.5654 43.4485 38.2792C42.0236 38.993 40.7785 39.9917 39.799 41.2066C38.9317 40.492 38.0589 39.7828 37.1697 39.0518Z" fill="#4F9B34"/>
            <path id="Vector_9" d="M49.5583 58.1556L49.5583 60.5231C51.1655 60.4417 52.7351 60.0305 54.159 59.3176C55.5829 58.6047 56.8273 57.6072 57.8064 56.3936C58.6738 57.1083 59.5412 57.8174 60.4358 58.5429C59.1479 60.1402 57.5069 61.4496 55.6284 62.379C53.7499 63.3083 51.6797 63.8349 49.5638 63.9217L49.5638 66.3001C47.1962 64.9363 44.8614 63.5889 42.4994 62.2251C44.8614 60.8614 47.1962 59.5139 49.5583 58.1556Z" fill="#4F9B34"/>
            <path id="Vector_10" d="M31.3001 49.5584C32.6638 47.1963 34.0113 44.867 35.375 42.4994C36.7334 44.856 38.0808 47.1854 39.45 49.5584L37.077 49.5584C37.1391 51.1269 37.5373 52.6668 38.2474 54.0841C38.9574 55.5013 39.9643 56.7661 41.2065 57.801C40.4974 58.6739 39.7828 59.5412 39.0573 60.4303C37.4201 59.0684 36.0971 57.3995 35.1714 55.5285C34.2458 53.6575 33.7375 51.6249 33.6785 49.5583L31.3001 49.5584Z" fill="#4F9B34"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_40_34">
            <rect width="96.3001" height="96.3001" fill="white" transform="translate(96.3 96.3001) rotate(-180)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
}

export default RecyclingBoxLoader
