import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import Bugsnag from '@bugsnag/js'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import { getMyDeposits } from '~/redux/features/myDeposits/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

import MyDeposits from './MyDeposits'

const MyDepositsView: React.FC = () => {
  const { deposits, nextToken, fetching, fetched } = useSelector((state: RootState) => state.myDeposits)
  const abort = useRef(new AbortController())
  const dispatch = useAppDispatch()

  useEffect(() => {
    void fetchDeposits()

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const fetchDeposits = async (): Promise<void> => {
    if (fetching && fetched) return

    try {
      await dispatch(getMyDeposits({ promotionId: Scan2RecyclePromotion.id, init: { signal: abort.current.signal, ...(!fetched ? {} : { nextToken }) } })).unwrap()
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(addNotification({ type: 'error', message: 'Unable to fetch deposits' }))
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  return <>
    <Helmet>
      <title>My Deposits | Recycle at Boots</title>
    </Helmet>
    <MyDeposits
      deposits={deposits}
      fetching={fetching}
      fetched={fetched}
      loadMore={fetchDeposits}
      hasMore={!!nextToken}
    />
  </>
}

export default MyDepositsView
