import { RecyclableStatus } from '~/constants/recyclable'
import { DepositStep } from '~/types/deposit/customerDeposit'

export default {
  allowedRecyclableStatus: RecyclableStatus.ACCEPTED,
}

export const depositSteps = [
  DepositStep.LOCATION,
  DepositStep.SCAN_RECYCLING_POINT,
  DepositStep.SELECT_RECYCLABLES,
  DepositStep.CONFIRM,
  DepositStep.RESULT,
]
