export const ROUTE_LANDING = '/'

// Auth
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGN_UP = '/sign-up'
export const ROUTE_CONFIRM_SIGN_UP = '/confirm-sign-up'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'

// Pages
export const ROUTE_HOME = '/home'
export const ROUTE_ADD_RECYCLABLE = '/add-recyclable'
export const ROUTE_RECYCLABLES = '/recyclables'
export const ROUTE_DEPOSIT = '/deposit'
export const ROUTE_DEPOSITS = '/deposits'
export const ROUTE_REWARDS = '/rewards'
export const ROUTE_PRIZES = '/prizes'
export const ROUTE_ACCOUNT = '/account'
export const ROUTE_ACCOUNT_DETAILS = '/account/details'
export const ROUTE_ACCOUNT_PASSWORD = '/account/password'
export const ROUTE_ACCOUNT_SCHEMES = '/account/schemes'
export const ROUTE_ACCOUNT_LOCATION_SERVICES = '/account/location-services'
export const ROUTE_ACCOUNT_CAMERA_PERMISSIONS = '/account/camera-permissions'
export const ROUTE_RECYCLING_POINT_SCAN = '/bin'

// Public Pages
export const ROUTE_RECYCLABLE_TYPES = '/recyclable-types'
export const ROUTE_RECYCLING_SYMBOLS = '/recycling-symbols'
export const ROUTE_RECYCLING_POINTS = '/recycling-points'
export const ROUTE_FAQS = '/faqs'
export const ROUTE_TERMS = '/terms'
export const ROUTE_PRIVACY = '/privacy'

// Offers
export const ROUTE_OFFER_LOREAL = '/offers/loreal'

// Donations
export const ROUTE_DONATION_FUNDRAISER = '/donation/fundraiser'

// Web Views
export const ROUTE_NATIVE_TERMS = '/native/terms'
export const ROUTE_NATIVE_FAQ = '/native/faqs'
