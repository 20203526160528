import { Box, Grid, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { isToday } from 'date-fns'

import DepositBannerPng from '~/assets/images/home/png/deposit.png'
import DepositBannerPng2x from '~/assets/images/home/png/deposit@2x.png'
import DepositBannerWebp from '~/assets/images/home/webp/deposit.webp'
import DepositBannerWebp2x from '~/assets/images/home/webp/deposit@2x.webp'
import BlisterBannerPng from '~/assets/images/home/png/blister-deposit.png'
import BlisterBannerPng2x from '~/assets/images/home/png/blister-deposit@2x.png'
import BlisterBannerWebp from '~/assets/images/home/webp/blister-deposit.webp'
import BlisterBannerWebp2x from '~/assets/images/home/webp/blister-deposit@2x.webp'
import { BrandColor, getSchemeColor } from '~/config/theme'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

interface DepositProps {
  lastDepositAt?: string | null
  onClick: () => void
}

export const Deposit: React.FC<DepositProps> = ({ lastDepositAt, onClick }) => {
  const hasMadeDepositToday = !!lastDepositAt && isToday(new Date(lastDepositAt))
  const colors = getSchemeColor(Scan2RecyclePromotion.scheme.shortName)
  const offerStartDate = new Date('2023-10-01')
  const offerEndDate = new Date('2023-10-31')
  const offerIsActive = Scan2RecyclePromotion.country === 'GB' && Scan2RecyclePromotion.scheme.shortName === 'Beauty' && new Date() >= offerStartDate && new Date() <= offerEndDate

  const bannerTextStyle = {
    color: colors.deposit.color ?? BrandColor.WHITE,
    fontWeight: 600,
    textAlign: 'left',
    fontSize: { sm: 24, md: 30 },
  }

  const renderBannerContent = (): JSX.Element => {
    if (offerIsActive) {
      return <>
        <Typography sx={{ ...bannerTextStyle, fontSize: { xxs: 12, xs: 14, md: 17 } }}>600 points in October for Recycle Week 2023*</Typography>
        <Typography sx={{ color: bannerTextStyle.color, fontSize: { xxs: 10 } }}>*Spend and save voucher issued, T&Cs apply. Offer ends 31st Oct 2023</Typography>
      </>
    }

    return <>
      <Typography sx={bannerTextStyle}>
        { hasMadeDepositToday
          ? 'You deposited today'
          : 'Drop off items in-store'
        }
      </Typography>
      {Scan2RecyclePromotion.scheme.shortName === 'Blister' && <Typography variant='caption' sx={{ color: colors.deposit.color ?? BrandColor.WHITE }}>selected stores only</Typography>}
    </>
  }

  return (
    <Box sx={{ display: 'flex', background: colors.deposit.background, borderRadius: 4, width: '100%', cursor: 'pointer' }} onClick={onClick}>
      <Grid container>
        <Grid item xxs={8} sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', padding: 2.5 }}>
            <Box sx={{ mb: 2, pl: 1 }}>
              {renderBannerContent()}
            </Box>
            <Box sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', borderRadius: 6, background: colors.deposit.backgroundContrast ?? 'rgba(255,255,255,0.15)', py: 1, px: 1.5, pr: 3 }}>
              <AddCircleIcon fontSize='small' sx={{ color: BrandColor.WHITE }} />
              <Typography sx={{
                color: '#fff',
                fontWeight: '600',
                fontSize: {
                  xxs: 12,
                  sm: 14,
                  md: 18,
                },
                position: 'relative',
                ml: { xxs: 1 },
              }}>
                Deposit
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xxs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            padding: 2.5,
            paddingLeft: 0,
            height: 0,
          }}>
            {Scan2RecyclePromotion.scheme.shortName === 'Blister'
              ? <Box
                  component='picture'
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: { xxs: '110px', sm: '130px' },
                  }}
                >
                  <source srcSet={`${BlisterBannerWebp2x} 2x, ${BlisterBannerWebp} 1x`} type='image/webp' />
                  <source srcSet={`${BlisterBannerPng2x} 2x, ${BlisterBannerPng} 1x`} type='image/png' />
                  <Box component="img" src={BlisterBannerPng} alt='Blister Deposit Banner' sx={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
              : <Box
                  component='picture'
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: { xxs: '110px', sm: '130px' },
                  }}
                >
                  <source srcSet={`${DepositBannerWebp2x} 2x, ${DepositBannerWebp} 1x`} type='image/webp' />
                  <source srcSet={`${DepositBannerPng2x} 2x, ${DepositBannerPng} 1x`} type='image/png' />
                  <Box component="img" src={DepositBannerPng} alt='Deposit Banner' sx={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
