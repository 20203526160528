import type { DonationFundraiser } from '~/types/donation/DonationFundraiser'

import SubmissionProcessorService from './SubmissionProcessorService'

class DonationService extends SubmissionProcessorService {
  public async getFundraiser (promotionId: string, init?: { signal?: AbortSignal }): Promise<DonationFundraiser> {
    const path = `s2r/promotion/${promotionId}/donation/fundraiser`
    const response = await this.request(path, { signal: init?.signal })

    return response.status === 200 ? (await response.json()) : await Promise.reject(response)
  }
}

export default new DonationService()
