import { Box, ListItemButton, ListItemText, TextField, debounce, CircularProgress, Chip } from '@mui/material'
import { useMemo, useState } from 'react'
import { Virtuoso as List } from 'react-virtuoso'

import { BrandColor } from '~/config/theme'

interface SearchableItem {
  item: string
  tags: string[]
}

interface SearchableListProps {
  items: SearchableItem[]
  loading: boolean
  listHeight?: number
}

const SearchableList: React.FC<SearchableListProps> = ({ items, loading, listHeight }) => {
  const [searchDebounce, setSearchDebounce] = useState<string>('')

  const filteredItems = useMemo(() => {
    const normalisedSearch = searchDebounce.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').trim()
    let possibleItems = [...items]

    if (normalisedSearch) {
      possibleItems = possibleItems.filter(({ item }) => {
        if (normalisedSearch.length > 0) {
          return item.toLowerCase().replace(/[^a-zA-Z0-9]/g, '').includes(normalisedSearch || '')
        }

        return true
      })
    }

    return possibleItems
  }, [items, searchDebounce])

  const debouncedCategorySearch = debounce((value: string) => {
    setSearchDebounce(value)
  }, 10)

  const handleCategorySearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCategorySearch(event.target.value)
  }

  const renderCategory = (index: number): JSX.Element => {
    const { item, tags } = filteredItems[index]

    return <ListItemButton key={index} sx={{ backgroundColor: index % 2 === 1 ? '#ffffff' : '#f7f7f7' }} dense>
      <ListItemText primary={item} />
      <Box display='flex' flexWrap='wrap' justifyContent='flex-end' sx={{ mr: 1, width: '60%' }}>
        { tags.map((tag, tagIndex) => <Chip key={`${index}: ${tagIndex}`} label={tag} sx={{ m: 0.25, fontSize: 10, color: BrandColor.WHITE, backgroundColor: BrandColor.BLACK }} size='small' />) }
      </Box>
    </ListItemButton>
  }

  if (loading) {
    return <Box sx={{ display: 'flex', height: listHeight ?? '150px', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <CircularProgress sx={{ mb: 2 }} />
    </Box>
  }

  return <Box sx={{ width: '100%', p: 1, boxSizing: 'border-box', background: BrandColor.LIGHT_GREY }}>
    <TextField
      id="categorySearch"
      name="categorySearch"
      label="Search"
      placeholder="Type to filter the list below"
      value={searchDebounce}
      onChange={handleCategorySearchChange}
      size="small"
      margin='dense'
      fullWidth
      autoComplete="off"
      sx={{ background: '#ffffff', borderRadius: 2 }}
    />
    <Box sx={{ borderRadius: 1, mt: 1, height: listHeight ?? '150px' }}>
      <List totalCount={filteredItems.length} itemContent={(index) => renderCategory(index)} />
    </Box>
  </Box>
}

export default SearchableList
