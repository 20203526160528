import { useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Divider, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Bugsnag from '@bugsnag/js'

import { ROUTE_LOGIN } from '~/routes/Routes'
import { useAppDispatch } from '~/redux/store'
import { isAwsException, isNotifiable } from '~/types/guards/errors'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { PasswordField } from '~/components/TextFields/PasswordField'
import { createMuiRegister } from '~/helpers/hookForm'

export interface FormValues {
  code: string
  password: string
}

interface ResetPasswordProps {
  onResetPassword: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  code: z.string().min(1, 'Reset Code is required'),
  password: z.string({
    required_error: 'Password is required',
  })
    .min(8, 'Must be at least 8 characters')
    .regex(/[a-z]+/, { message: 'Must have at least one lowercase character' })
    .regex(/[A-Z]+/, { message: 'Must have at least one uppercase character' })
    .regex(/\d+/, { message: 'Must have at least one number' })
    .regex(/^[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`\\]+$/, 'Password is using an invalid character'),
}).strict()

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({ onResetPassword }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleReset = async (values: FormValues): Promise<void> => {
    setIsLoading(true)

    try {
      await onResetPassword(values)
      dispatch(addNotification({ message: 'Password updated' }))
      navigate(ROUTE_LOGIN)
    } catch (error) {
      if (isAwsException(error)) {
        switch (error.code) {
          case 'CodeMismatchException':
            dispatch(addNotification({ type: 'error', message: 'That code couldn\'t be verified, please try again' }))
            break
          case 'LimitExceededException':
            dispatch(addNotification({ type: 'error', message: 'Too many retries, please wait and try again later' }))
            break
          case 'ExpiredCodeException':
            dispatch(addNotification({ type: 'error', message: 'Your reset code has expired' }))
            break
          case 'InvalidParameterException':
            dispatch(addNotification({ type: 'error', message: 'Supplied password does not meet requirements' }))
            break
          case 'InvalidPasswordException':
            dispatch(addNotification({ type: 'error', message: error.message }))
            break
          default:
            dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
            if (isNotifiable(error)) Bugsnag.notify(error)
        }
      } else {
        dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
        if (isNotifiable(error)) Bugsnag.notify(error)
      }

      setIsLoading(false)
    }
  }

  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      code: '',
      password: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  return (
    <Box>
      <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={handleSubmit(handleReset)}>
        <Typography variant="h5">Verify Reset</Typography>
        <Typography gutterBottom>Please enter the verification code you received via email below.</Typography>
        <TextField
          {...muiRegister('code')}
          fullWidth
          id="code"
          label="Password Reset Code"
          margin="normal"
          error={Boolean(errors.code)}
          helperText={errors.code?.message}
        />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" gutterBottom>Reset Password</Typography>
        <Typography gutterBottom>Please enter a new password below.</Typography>
        <PasswordField
          muiRegister={muiRegister('password')}
          id="password"
          label="New Password"
          autoComplete='new-password'
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ mt: 2 }}>Update Password</LoadingButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
        <Link component={RouterLink} to={ROUTE_LOGIN} variant="body2">
          Back to Sign In
        </Link>
      </Box>
    </Box>
  )
}

export default ResetPassword
