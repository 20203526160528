import { Box, Button, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { BrandColor } from '~/config/theme'

const NotFound: React.FC = () => {
  return <>
    <Helmet>
      <title>Not Found | Recycle at Boots</title>
    </Helmet>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 40px)', p: 2 }}>
      <Box sx={{ flexShrink: 0, textAlign: 'center' }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h1" color={BrandColor.BLUE}>404</Typography>
          <Typography variant="body1" color={BrandColor.BLACK}>We can't seem to find the page you are looking for.</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Button component={RouterLink} variant='contained' to="/" sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
            Go Home
          </Button>
        </Box>
      </Box>
    </Box>
  </>
}

export default NotFound
