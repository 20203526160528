import { Grid } from '@mui/material'
import { format } from 'date-fns'

import { BrandColor } from '~/config/theme'
import { ROUTE_DEPOSITS, ROUTE_RECYCLABLES } from '~/routes/Routes'
import type { UserPromotionStatistics } from '~/types/user/UserPromotionStatistics'

import StatTile from './StatTile'

interface HomeStatsProps {
  depositCount?: number
  recyclableCount?: number
  lastDepositAt?: string | null
  lastRecyclableAt?: string | null
  donations?: UserPromotionStatistics['donations']
}

const HomeStats: React.FC<HomeStatsProps> = ({ depositCount, recyclableCount, lastDepositAt, lastRecyclableAt, donations }) => {
  return <Grid container columnSpacing={{ xxs: 2, sm: 4 }} sx={{ pt: 1 }}>
    <StatTile title="Total deposits" to={ROUTE_DEPOSITS} value={depositCount ?? 0} valueColor={BrandColor.PURPLE} subTitle="Last deposit" subValue={lastDepositAt ? format(new Date(lastDepositAt), 'do LLL yyyy') : '-'} />
    <StatTile title="Total recycled" to={ROUTE_RECYCLABLES} value={recyclableCount ?? 0} valueColor={BrandColor.PINK} subTitle="Last recyclable" subValue={lastRecyclableAt ? format(new Date(lastRecyclableAt), 'do LLL yyyy') : '-'} />
  </Grid>
}

export default HomeStats
