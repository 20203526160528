import { Popup, type Map } from 'mapbox-gl'

interface AddListenersOptions {
  unclusteredLayerIds: string[]
  cluster: boolean
}

export const addListeners = (map: Map, {
  unclusteredLayerIds,
  cluster,
}: AddListenersOptions): void => {
  // TODO: Avoid popup when clicking on a cluster which overlaps with the user's location
  // Create a popup when the user clicks on a pin
  map.on('click', unclusteredLayerIds, (e) => {
    if (e.features?.[0].geometry.type === 'Point') {
      const coordinates = e.features?.[0]?.geometry?.coordinates
      const title: string = `<p>${e.features?.[0].properties?.title}</p>`

      if (title && map) {
        new Popup({ offset: [0, -30] })
          .setLngLat({ lng: coordinates[0], lat: coordinates[1] })
          .setHTML(title)
          .addTo(map)
      }
    }
  })

  // Change the user's cursor when hovering over a pin which does something when clicked
  const clickableLayerIds = [...unclusteredLayerIds, ...(cluster ? ['clusters'] : [])]
  map.on('mouseenter', clickableLayerIds, (e) => {
    if (map) {
      map.getCanvas().style.cursor = 'pointer'
    }
  })
  map.on('mouseleave', clickableLayerIds, () => {
    if (map) {
      map.getCanvas().style.cursor = ''
    }
  })

  // Zooms in on a cluster when the user clicks on it
  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['clusters'],
    })

    const clusterId: number = features?.[0]?.properties?.cluster_id
    const source = map.getSource('source') as mapboxgl.GeoJSONSource
    source.getClusterExpansionZoom(
      clusterId,
      (err, zoom) => {
        if (err || features?.[0].geometry.type !== 'Point') return
        map.easeTo({
          center: features?.[0].geometry.coordinates as [lng: number, lat: number],
          zoom,
        })
      },
    )
  })
}
