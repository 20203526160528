import application from '~/config/application'

import Service from './Service'

import { submissionProcessorApi } from '~/config/aws'

class SubmissionProcessorService extends Service {
  constructor () {
    super(submissionProcessorApi)
  }

  protected getClientHeaders (): Record<string, string> {
    return {
      'Client-Platform-Os': 'web',
      'Client-Platform-Version': navigator?.userAgent ?? 'unknown',
      'Client-Version': application.clientVersion,
    }
  }
}

export default SubmissionProcessorService
