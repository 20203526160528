import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { getCurrentLocation, getGeoPoint, hasLocationEnabled } from '~/helpers/geolocation/geolocation'
import type { DepositInformation } from '~/types/deposit/customerDeposit'

interface LocationStepProps {
  onNextStep: (values?: Partial<DepositInformation>) => Promise<void>
}

const LocationStep: React.FC<LocationStepProps> = ({ onNextStep }) => {
  const [loading, setLoading] = useState(true)
  const [geoFailed, setGeoFailed] = useState(false)
  const attempts = useRef(0)

  useEffect(() => {
    void checkLocation()
  }, [])

  const checkLocation = async (): Promise<void> => {
    if (!loading) {
      setLoading(true)
    }

    const locationServicesActive = await hasLocationEnabled()

    if (!locationServicesActive) {
      setLoading(false)
    } else {
      try {
        const locationData = await getCurrentLocation()
        void onNextStep({ location: locationData?.coordinates ? getGeoPoint(locationData.coordinates, locationData.isGps) : null })
      } catch (error) {
        attempts.current = attempts.current + 1

        if (attempts.current >= 3) {
          void onNextStep({ location: null })
        }

        setGeoFailed(true)
        setLoading(false)
      }
    }
  }

  const handleRetry = (): void => {
    void checkLocation()
  }

  if (loading) {
    return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress sx={{ mb: 2 }} />
      <Typography variant="body1">Checking Location...</Typography>
    </Box>
  }

  if (geoFailed) {
    return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Typography variant="body1">Location Check failed.</Typography>
    <Button variant='contained' sx={{ mt: 2 }} onClick={handleRetry}>Retry</Button>
  </Box>
  }

  return <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Typography variant="body1">Location Services are disabled. Please enable Location Services in your device settings.</Typography>
  </Box>
}

export default LocationStep
