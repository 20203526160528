export enum FileUnitSize {
  Byte = 1,
  KiloByte = 1024,
  MegaByte = 1024 * 1024,
  GigaByte = 1024 * 1024 * 1024,
  TeraByte = 1024 * 1024 * 1024 * 1024,
  PetaByte = 1024 * 1024 * 1024 * 1024 * 1024,
  ExaByte = 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
  ZettaByte = 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
  YottaByte = 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
}
