import { SvgIcon, type SxProps } from '@mui/material'

interface AAIconProps {
  sx?: SxProps
}

const AAIcon: React.FC<AAIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 167.84277 121.93896' sx={sx}>
    <path d='M22.41113,62.40381h9.11914L53.13184,121.939H44.2959l-6.03809-17.832H14.71191L8.26758,121.939H0ZM35.66406,97.5415,26.626,71.23877,17.02148,97.5415Z'/>
    <path d='M104.92285,0h18.67676l44.24316,121.939h-18.0957L137.37891,85.41553H89.15137L75.95312,121.939H59.01953Zm27.14356,71.96826L113.55566,18.0957,93.88281,71.96826Z'/>
  </SvgIcon>
}

export default AAIcon
