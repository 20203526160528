import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { matchSorter } from 'match-sorter'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, TextField, Autocomplete } from '@mui/material'

import { createMuiRegister } from '~/helpers/hookForm'
import type { RecyclableScan, RecyclableTags } from '~/types/recyclable/recyclableScan'
import KeywordBrands, { keywordBrands } from '~/enum/KeywordBrands'

interface FormValues {
  brand: string
}

interface DefineBrandProps {
  definition: RecyclableTags
  recyclableBrands: string[]
  loadingBrands: boolean
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
}

const validationSchema = z.object({
  brand: z.string({ required_error: 'Brand is required' }).trim()
    .min(1, { message: 'Brand is required' })
    .max(30, { message: 'Brand cannot be longer than 30 characters' })
    .regex(/^[\p{N}\p{L}\s'.&!?:°\-\\/@+]+$/iu, { message: 'Brand can only contain letters and numbers' }),
}).strict()

const filterOptions = (options: string[], { inputValue }: { inputValue: string }): string[] => {
  const sortedArray = matchSorter(options, inputValue, {
    sorter: rankedItems => {
      return rankedItems.sort((a, b) => {
        return a.rank > b.rank ? -1 : 1
      })
    },
  })

  // Move keyword brands to the end of the results - they are always present in the results
  const filteredArray = sortedArray.filter(item => !keywordBrands.some(keyword => keyword === item))
  filteredArray.push(...keywordBrands)

  return filteredArray
}

const DefineBrand: React.FC<DefineBrandProps> = ({ definition, recyclableBrands, loadingBrands, onNextStep }) => {
  const [manualBrand, setManualBrand] = useState<boolean>(false)
  const brands = useMemo(() => {
    const keywordBrands = Object.values(KeywordBrands) as string[]
    const brands = [...recyclableBrands, ...keywordBrands]
    return [...new Set(brands)]
  }, [recyclableBrands])

  const { handleSubmit, getValues, setValue, setFocus, register, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      brand: definition.brand ?? '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (manualBrand) {
      setFocus('brand')
    }
  }, [manualBrand])

  const muiRegister = createMuiRegister<FormValues>(register)

  const selectBrand = async (brand: string): Promise<void> => {
    if ([KeywordBrands.Other, KeywordBrands.NotListed].some(keyword => keyword === brand)) {
      handleManualBrand()
    } else {
      setValue('brand', brand)
    }
  }

  const handleManualBrand = (): void => {
    setValue('brand', '')
    setManualBrand(!manualBrand)
  }

  const handleBrandSelect = (_: React.SyntheticEvent<Element, Event>, value: string[]): void => {
    const brand = value ? value.pop() ?? '' : ''

    void selectBrand(brand)
  }

  const brandRegister = muiRegister('brand')
  watch('brand')

  return <Box component="form" noValidate onSubmit={handleSubmit(onNextStep)} >
    {manualBrand
      ? <TextField
          {...brandRegister}
          name="brand"
          label="Brand"
          placeholder="Enter brand name"
          error={Boolean(errors.brand)}
          helperText={errors.brand?.message}
          fullWidth
          required
          inputProps={{
            maxLength: 30,
          }}
        />
      : <Autocomplete
          onChange={handleBrandSelect}
          disablePortal
          multiple
          id="brand"
          options={brands}
          value={getValues('brand') ? [getValues('brand')] : []}
          filterOptions={filterOptions}
          loading={loadingBrands}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Brand"
              placeholder="Type to search brands"
              error={Boolean(errors.brand)}
              helperText={errors.brand?.message}
              required
            />
          )}
        />
    }
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button sx={{ fontSize: 'small' }} onClick={handleManualBrand}>
        {manualBrand ? 'Select from list of brands?' : 'Brand Not listed?'}
      </Button>
    </Box>
    <Box sx={{ width: '100%', mt: 2 }}>
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Next
      </Button>
    </Box>
  </Box>
}

export default DefineBrand
