import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import application from '~/config/application'
import { LINK_PRIVACY_POLICY } from '~/constants/externalLinks'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import type { LocationState } from '~/types/location/Location'

export default (): JSX.Element => {
  const location = useLocation()

  useEffect(() => {
    const s = document.createElement('script')
    const tag = document.getElementsByTagName('script')[0]
    const p = document.getElementById('iubenda')

    s.id = 'iubenda'
    s.src = 'https://cdn.iubenda.com/iubenda.js'
    if (tag.parentNode) tag.parentNode.insertBefore(s, tag)

    return () => {
      if (p?.parentNode) p.parentNode.removeChild(p)
    }
  }, [])

  return <>
    <Helmet>
      <title>Privacy Policy | Recycle at Boots</title>
    </Helmet>
    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ my: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >Privacy Policy</Typography>
    </Breadcrumbs>}
    <Box sx={{ px: 2, mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 0 }}>Privacy Policy of <strong>{application.client}</strong> <Link href={LINK_PRIVACY_POLICY} target="_blank">click here</Link></Typography>
    </Box>
    <a href={`https://www.iubenda.com/privacy-policy/${application.iubendaCookiePolicyId}`} className="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a>
  </>
}
