import { Autocomplete, Box, Button, Chip, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { z } from 'zod'

import { ScanFlow } from '~/enum/ScanFlow'
import type { RecyclableScan, RecyclableTags } from '~/types/recyclable/recyclableScan'
import type { RecyclableType } from '~/types/recyclable/RecyclableType'
import type { S2RProduct } from '~/types/recyclable/S2RProduct'

interface FormValues {
  form: string
}

// Define Form will either be passed a list of recyclable types or a list of products, depending on application.depositFlow
// By having this combined type, it becomes possible to infer which props are required based on props.depositFlow
interface DefineFormBaseProps {
  definition: RecyclableTags
  recyclableTypes?: RecyclableType[]
  products?: S2RProduct[]
  depositFlow: ScanFlow
  creating?: boolean
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
  onPreviousStep: () => void
}

interface DefineFromCategoryProps extends DefineFormBaseProps {
  depositFlow: ScanFlow.Category
  recyclableTypes: RecyclableType[]
}

interface DefineFormProductProps extends DefineFormBaseProps {
  depositFlow: ScanFlow.Product
  definition: RecyclableTags & { product: string }
  products: S2RProduct[]
}

type DefineFormProps = DefineFromCategoryProps | DefineFormProductProps

const validationSchema = z.object({
  form: z.string({
    required_error: 'Form is required',
  }).min(1, {
    message: 'Form is required',
  }),
}).strict()

const DefineForm: React.FC<DefineFormProps> = ({ definition, recyclableTypes, products, depositFlow, creating, onNextStep, onPreviousStep }) => {
  const forms = useMemo(() => {
    if (depositFlow === ScanFlow.Product) return products.find(({ product }) => product === definition.product)?.forms ?? []
    return recyclableTypes.find((recyclableType) => recyclableType.subcategory === definition.category)?.forms ?? []
  }, [definition.category])

  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      form: definition.form ?? '',
    },
    mode: 'all',
  })

  const selectForm = async (form: string): Promise<void> => {
    setValue('form', form)
  }

  const handleFormSelect = (_: React.SyntheticEvent<Element, Event>, value: string[]): void => {
    const form = value ? value.pop() ?? '' : ''

    void selectForm(form)
  }

  const chips = (): JSX.Element[] => {
    switch (depositFlow) {
      case ScanFlow.Category:
        return [
          <Chip key="brand" label={`Brand: ${definition.brand}`} color="primary" sx={{ mr: 1 }} />,
          <Chip key="category" label={`Category: ${definition.category}`} color="primary" />,
        ]
      case ScanFlow.Product:
        return [
          <Chip key="product" label={`Product: ${definition.product}`} color="primary" />,
        ]
    }
  }

  watch('form')

  return <Box component="form" noValidate onSubmit={handleSubmit(onNextStep)}>
    <Box sx={{ mb: 2 }}>
      {chips()}
    </Box>
    <Autocomplete
      onChange={handleFormSelect}
      disablePortal
      multiple
      id="form"
      options={forms}
      value={getValues('form') ? [getValues('form')] : []}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Form"
          placeholder="Type to search forms"
          error={Boolean(errors.form)}
          helperText={errors.form?.message}
          required
        />
      )}
    />
    <Box sx={{ display: { xxs: 'block', sm: 'flex' }, flexDirection: 'row-reverse', width: '100%', mt: 2 }}>
      <LoadingButton type="submit" variant="contained" color="primary" loading={creating} fullWidth>Submit</LoadingButton>
      <Button type="button" variant="contained" color="secondary" onClick={onPreviousStep} sx={{ mt: { xxs: 1, sm: 0 }, mr: { sm: 1 } }} disabled={creating} fullWidth>
        {depositFlow === ScanFlow.Category && 'Change Category'}
        {depositFlow === ScanFlow.Product && 'Change Product'}
      </Button>
    </Box>
  </Box>
}

export default DefineForm
