import { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, styled, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CheckCircle, Camera } from '@mui/icons-material'
import Compressor from 'client-compress'
import Bugsnag from '@bugsnag/js'
import { v4 as uuid } from 'uuid'
import isJpg from 'is-jpg'

import { FileUnitSize } from '~/constants/file'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import RecyclableService from '~/services/RecyclableService'
import { isNotifiable } from '~/types/guards/errors'
import type { RecyclableScan } from '~/types/recyclable/recyclableScan'
import S3Service from '~/services/S3Service'
import { amplify } from '~/config/aws'
import { useAppDispatch } from '~/redux/store'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

import ScanningLoader from '../components/ScanningLoader'

const Input = styled('input')({
  display: 'none',
})

const UploadLoadingButton = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  height: '100%',
  width: '100%',
  backgroundColor: '#eee',
  border: 'none',
  cursor: 'pointer',
})

const acceptedFileTypes = [
  'image/jpg',
  'image/jpeg',
]

const validationSchema = z.object({
  recyclablePhoto: z.any({
    required_error: 'Please take a photo of the recyclable',
  }),
}).strict().refine(({ recyclablePhoto }) => recyclablePhoto instanceof File, {
  message: 'Please take a photo of the recyclable',
})

interface ScanStepProps {
  onNextStep: (values?: Partial<RecyclableScan>, id?: string) => Promise<void>
  flowId: string
}

interface ScanStepValues {
  recyclablePhoto: File | null
}

const ScanStep: React.FC<ScanStepProps> = ({ onNextStep, flowId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isScanning, setIsScanning] = useState(false)
  const [preview, setPreview] = useState<string>('')
  const dispatch = useAppDispatch()

  const { handleSubmit, setValue, formState, setError, getValues } = useForm<ScanStepValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const { errors } = formState

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview)
    }
  }, [])

  const handleNextStep = async (values: ScanStepValues): Promise<void> => {
    const currentFlowId = flowId
    if (!values.recyclablePhoto) {
      dispatch(addNotification({ type: 'error', message: 'Please take a photo of the recyclable' }))
      return
    }

    try {
      setIsScanning(true)
      const id = uuid()
      const filename = `${values.recyclablePhoto?.name.replace(/[^a-zA-Z0-9.]/g, '')}-${id}.jpg`
      const { imageKey, storageURI } = await S3Service.upload(values.recyclablePhoto, filename, amplify.Storage.AWSS3.bucket)

      const scan = await RecyclableService.scan(Scan2RecyclePromotion.id, imageKey)

      void onNextStep({
        photo: {
          imageKey,
          storageURI,
        },
        scan,
      }, currentFlowId)
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Unable to process recyclable' }))
      if (isNotifiable(error)) Bugsnag.notify(error)
      setIsScanning(false)
    }
  }

  const handleRetakePhoto = (): void => {
    setPreview('')
    setValue('recyclablePhoto', null)
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target.files) return

    if (event.target.files && event.target.files.length > 0) {
      const field = event.target.name as 'recyclablePhoto'

      setIsLoading(true)

      let file = event.target.files[0]

      try {
        const compressor = new Compressor({
          quality: 0.75,
          maxWidth: 3000,
          maxHeight: 3000,
        })
        const files = await compressor.compress([file])

        if (files?.[0]?.photo?.data) {
          file = new File([files[0].photo.data as BlobPart], file.name, { type: file.type })
        }
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
      }

      let error = false
      let errorMessage = ''

      // Check file type
      if (!acceptedFileTypes.includes(file.type)) {
        error = true
        errorMessage = `File type ${file.type} is not supported`
      }

      // This it to check a jpg uploaded from iOS devices isn't secretly a HEIC
      try {
        const fileBuffer = new Uint8Array(await file.arrayBuffer())
        if (!isJpg(fileBuffer)) {
          error = true
          errorMessage = 'Only jpg/jpeg photos are supported'
        }
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
      }

      // Check file size is less than 20MB
      if (file.size >= FileUnitSize.MegaByte * 20) {
        error = true
        errorMessage = 'File size must be less than 20MB'
      }

      if (error) {
        setValue(field, null)
        setError(field, { type: 'custom', message: errorMessage })
        setPreview('')
      } else {
        try {
          const preview = URL.createObjectURL(file)
          setPreview(preview)
        } catch (error) {
          if (isNotifiable(error)) Bugsnag.notify(error)
        }
        setValue(field, file)
      }

      setIsLoading(false)
    }
  }

  if (isScanning) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', height: 0, p: { md: 5 } }}>
      <ScanningLoader />
    </Box>
  }

  const recyclablePhotoIsSet = Boolean(getValues('recyclablePhoto'))

  return <Box component="form" noValidate onSubmit={handleSubmit(handleNextStep)} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: 0, overflow: 'hidden' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', transition: 'all 0.5s ease' }}>
      {isLoading && <CircularProgress />}
      {!isLoading && !getValues('recyclablePhoto') && <FormControl sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: 0, width: '100%', alignItems: 'top', overflow: 'hidden' }}>
        <Box component="label" htmlFor="recyclablePhoto" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Input type="file" name="recyclablePhoto" accept="image/jpg,image/jpeg" id="recyclablePhoto" onChange={(event) => { void handleChange(event) }} capture aria-errormessage="recyclablePhoto-error" />
          <UploadLoadingButton role="button">
            {getValues('recyclablePhoto') ? <CheckCircle sx={{ color: 'green' }} /> : <Camera sx={{ width: 50, height: 50 }} /> }
            <Typography variant="button">Tap here to take a photo</Typography>
          </UploadLoadingButton>
        </Box>
      </FormControl>}
      {!isLoading && getValues('recyclablePhoto') && <Box component="img" src={preview} alt="Recyclable Photo Preview" sx={{
        width: '100%',
        height: '100%',
        flexGrow: 1,
        backgroundColor: '#000',
        objectFit: 'contain',
      }} />}
    </Box>
    <Box sx={{ maxHeight: recyclablePhotoIsSet ? 50 : 0, overflow: 'hidden', transition: 'all 0.5s ease' }}>
      {errors.recyclablePhoto && <Box id="recyclablePhoto-error" sx={{ p: 1, background: '#cf0548', textAlign: 'center' }}>
        <Typography variant='body2' color='white' fontWeight={600}>{errors.recyclablePhoto.message}</Typography>
      </Box>}
    </Box>
    {<Box sx={{ maxHeight: recyclablePhotoIsSet ? 125 : 0, opacity: recyclablePhotoIsSet ? 1 : 0, display: 'flex', flexDirection: 'column', flexShrink: 0, justifyContent: 'flex-end', mt: recyclablePhotoIsSet ? 2 : 0, transition: 'all 0.5s ease', overflow: 'hidden' }}>
      <LoadingButton type="submit" variant="contained" disabled={!recyclablePhotoIsSet}>Next</LoadingButton>
      <Button type="button" variant="contained" color="secondary" disabled={!recyclablePhotoIsSet || isLoading} sx={{ mt: 1 }} onClick={handleRetakePhoto}>Retake Photo</Button>
    </Box>}
  </Box>
}

export default ScanStep
