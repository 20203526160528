import { Box } from '@mui/material'
import { isPast, parseISO } from 'date-fns'

import { BrandColor } from '~/config/theme'
import type { ActiveReward } from '~/types/reward/ActiveReward'
import type { Reward } from '~/types/reward/Reward'

import RewardCard from './RewardCard'
import RewardInformation from './RewardInformation'

interface VoucherProps {
  reward: Reward
  activeRewards: ActiveReward[]
}

const Voucher: React.FC<VoucherProps> = ({ reward, activeRewards }) => {
  const awardedDate = reward.createdAt ? parseISO(reward.createdAt) : null
  const expiryDate = reward.prize.voucherUntil ? parseISO(reward.prize.voucherUntil) : null

  const isExpired = expiryDate ? isPast(expiryDate) : false

  return <Box sx={{ p: 2, background: BrandColor.LIGHT_GREY, borderRadius: 2 }}>
    <RewardCard reward={reward} activeRewards={activeRewards} isExpired={isExpired} />
    <RewardInformation reward={reward} awardedDate={awardedDate} expiryDate={expiryDate} isExpired={isExpired} />
  </Box>
}

export default Voucher
