import type { NotifiableError } from '@bugsnag/js'

import type { AWSException, AWSExceptionV3 } from '../errors/AWSException'

export function isNotifiable (error: unknown): error is NotifiableError {
  if ((error as Error).name === 'AbortError') {
    return false
  }

  if ((error as Error).name === 'NetworkError') {
    return false
  }

  if (typeof error === 'string') {
    return true
  }

  if (error instanceof Error) {
    return true
  }

  if (error instanceof Object && (error as Error).name !== undefined) {
    return true
  }

  return false
}

export function isAwsExceptionV3 (error: unknown): error is AWSExceptionV3 {
  if (error instanceof Object && (error as AWSExceptionV3).Code !== undefined) {
    return true
  }

  return false
}

export function isAwsException (error: unknown): error is AWSException {
  if (error instanceof Object && (error as AWSException).code !== undefined) {
    return true
  }

  return false
}

export function isErrorResponse (error: unknown): error is Response {
  if (typeof error === 'object' && (error as Response).status !== undefined) {
    return true
  }

  return false
}

export function isAbortError (error: unknown): error is Error {
  return (error as Error).name === 'AbortError'
}
