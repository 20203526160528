import type { ValidatedDepositInformation } from '~/types/deposit/customerDeposit'
import type { Deposit } from '~/types/deposit/Deposit'

import SubmissionProcessorService from './SubmissionProcessorService'

class DepositService extends SubmissionProcessorService {
  public async createDeposit (promotionId: string, deposit: ValidatedDepositInformation): Promise<null> {
    const body = {
      campaignId: promotionId,
      depositBinId: deposit.recyclingPoint?.depositBinId,
      depositBinInfo: {
        ...deposit.recyclingPoint,
        binScannedAt: deposit.binScannedAt.valueOf(),
        binScanLocationLat: deposit.location?.latitude,
        binScanLocationLng: deposit.location?.longitude,
        hasBestBinScanLocation: deposit.hasBestLocation,
        scannedBinCode: deposit.scannedBinCode,
      },
      depositClickTime: deposit.clickTime,
      formSubmitTime: deposit.clickTime,
      bank: deposit.recyclables,
      binScanLocationLat: deposit.location?.latitude,
      binScanLocationLng: deposit.location?.longitude,
      depositLocationLat: deposit.location?.latitude,
      depositLocationLng: deposit.location?.longitude,
      locationServicesEnabled: deposit.locationServicesEnabled,
      hasBestDepositLocation: deposit.hasBestLocation,
      hasBestBinScanLocation: deposit.hasBestLocation,
    }

    const response = await this.request('deposit', {
      method: 'POST',
      body: JSON.stringify(body),
    })

    return response.status === 204 ? null : await Promise.reject(response)
  }

  public async getMyDeposits (promotionId: string, options?: { nextToken?: string | null, signal?: AbortSignal }): Promise<{
    items: Deposit[]
    nextToken: string
  }> {
    const path = options?.nextToken ? `deposits?campaignId=${promotionId}&nextToken=${options.nextToken}` : `deposits?campaignId=${promotionId}`
    const response = await this.request(path, { signal: options?.signal })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }
}

export default new DepositService()
