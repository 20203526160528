import { Menu } from '@mui/material'

import { theme } from '~/config/theme'
import type { PromotionScheme } from '~/types/promotion/Promotion'

import NavigationSchemeMenuItem from './NavigationSchemeMenuItem'

interface NavigationSchemeMenuProps {
  anchorEl: React.MutableRefObject<null>
  schemes: PromotionScheme[]
  open: boolean
  onSelectScheme: (scheme: PromotionScheme) => void
  onClose: () => void
}

const NavigationSchemeMenu: React.FC<NavigationSchemeMenuProps> = ({ anchorEl, schemes, open, onSelectScheme, onClose }) => {
  return <Menu
    id="scheme-menu"
    MenuListProps={{
      'aria-labelledby': 'scheme-menu-toggle',
    }}
    anchorEl={anchorEl.current}
    open={open}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    slotProps={{
      paper: {
        sx: {
          width: '100%',
          left: 0,
          right: 0,
          borderRadius: 4,
          backgroundColor: theme.palette.grey[100],
          mt: -2,
        },
      },
    }}
  >
    {schemes.map(scheme => <NavigationSchemeMenuItem key={scheme.promotion.id} scheme={scheme} onSelect={onSelectScheme} />)}
  </Menu>
}

export default NavigationSchemeMenu
