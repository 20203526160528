import { createAsyncThunk } from '@reduxjs/toolkit'

import DepositsService from '~/services/DepositService'
import type { Deposit } from '~/types/deposit/Deposit'

export const getMyDeposits = createAsyncThunk<{
  initialFetch: boolean
  response: {
    items: Deposit[]
    nextToken: string
  }
}, { promotionId: string, init?: { signal?: AbortSignal, nextToken?: string | null } }>(
  'myDeposits/getMyDeposits',
  async ({ promotionId, init }) => {
    const response = await DepositsService.getMyDeposits(promotionId, init)
    return {
      initialFetch: !init?.nextToken,
      response,
    }
  },
)
