import { createAsyncThunk } from '@reduxjs/toolkit'

import RewardService from '~/services/RewardService'
import type { Reward } from '~/types/reward/Reward'

export const getMyRewards = createAsyncThunk<{
  initialFetch: boolean
  response: {
    items: Reward[]
    nextToken: string
  }
}, { promotionId: string, init?: { signal?: AbortSignal, nextToken?: string | null } }>(
  'rewards/getRewards',
  async ({ promotionId, init }) => {
    const response = await RewardService.getRewards(promotionId, init)
    return {
      initialFetch: !init?.nextToken,
      response,
    }
  },
)

export const activateVoucher = createAsyncThunk<{
  rewardId: string
  activatedAt: string
  voucherUntil: string
}, { reward: Reward }>(
  'rewards/activateVoucher',
  async ({ reward }) => {
    const { activatedAt, voucherUntil } = await RewardService.setRewardActivated(reward.rewardId)

    return { rewardId: reward.rewardId, activatedAt, voucherUntil }
  },
)
