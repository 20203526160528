import { SvgIcon, type SxProps } from '@mui/material'

interface CameraIconProps {
  sx?: SxProps
}

const CameraIcon: React.FC<CameraIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 181.83 150.67' sx={sx}>
    <path d='M155.67,22.09H114.74L103.79,6.73A17.06,17.06,0,0,0,89.78,0H43A17.16,17.16,0,0,0,29.25,6.39L15.65,24.31A26.19,26.19,0,0,0,0,48.25v76.26a26.19,26.19,0,0,0,26.16,26.16H155.67a26.2,26.2,0,0,0,26.16-26.16V48.25A26.2,26.2,0,0,0,155.67,22.09Zm3.64,102.42a3.64,3.64,0,0,1-3.64,3.64H26.16a3.64,3.64,0,0,1-3.64-3.64V48.25a3.64,3.64,0,0,1,3.64-3.64H155.67a3.64,3.64,0,0,1,3.64,3.64Z'/>
    <path d='M90.91,48.73a37.65,37.65,0,1,0,37.65,37.65A37.69,37.69,0,0,0,90.91,48.73Zm0,52.78A15.13,15.13,0,1,1,106,86.38,15.15,15.15,0,0,1,90.91,101.51Z'/>
    <rect x='128.09' y='46.54' width='29.14' height='15.92' rx='2.18'/>
  </SvgIcon>
}

export default CameraIcon
