/**
 * These will always be present in the list of brands, regardless of user searching
 */
enum KeywordBrands {
  Unknown = 'Unknown',
  NoBrand = 'No Brand',
  Other = 'Other',
  NotListed = 'Not Listed',
}

export const keywordBrands = Object.values(KeywordBrands) as string[]

export default KeywordBrands
