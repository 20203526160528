import { useVersionCheck } from '@app/hooks/useVersionCheck'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import { BrandColor } from '~/config/theme'
import { getUserSchemes } from '~/redux/features/auth/actions'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'

import Logo from '~/assets/images/recycle-at-boots-white.svg'

const OnRouteChange = (): React.ReactNode => {
  const location = useLocation()
  const path = useRef(location.pathname)
  const isMostRecentVersion = useVersionCheck()
  const { isAuthenticated, cognitoUser } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const prompt = useRef<any>(null)
  const [showInstall, setShowInstall] = useState(false)

  /**
   * Clear the cache to ensure no remnants of the previoous build remain
   */
  const clearAllLocalCacheKeys = async (): Promise<void> => {
    if (caches?.keys) {
      const cacheKeys = await caches.keys()
      await Promise.all(cacheKeys.map(async (key) => await caches.delete(key)))
    }
  }

  const checkVersion = async (): Promise<void> => {
    if ((!isMostRecentVersion) && location.pathname !== path.current) {
      await clearAllLocalCacheKeys()

      window.location.replace(location.pathname)
    }
  }

  const scrollToTop = (): void => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleInstall)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstall)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && cognitoUser?.userId) {
      void dispatch(getUserSchemes({ userId: cognitoUser?.userId }))
    }
  }, [isAuthenticated])

  useEffect(() => {
    void checkVersion()
    scrollToTop()

    path.current = location.pathname
  }, [location.pathname])

  const handleInstall = async (e: any): Promise<void> => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault()
    // Stash the event so it can be triggered later
    prompt.current = e

    if (!e.platforms || (e.platforms.includes('android') || e.platforms.includes('play'))) {
      setShowInstall(true)
    }
  }

  const handleInstallClick = (): void => {
    if (prompt.current) {
      prompt.current.prompt()
      prompt.current.userChoice.then(() => {
        setShowInstall(false)
      })
    }
  }

  const handleDismissClick = (): void => {
    setShowInstall(false)
  }

  if (showInstall) {
    return <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2, background: 'black' }}>
      <Box component="img" src={Logo} sx={{ height: 40, display: 'block', mr: 2 }} />
      <Typography variant="body2" sx={{ color: BrandColor.WHITE }}>Switch up to the new app</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto' }}>
        <Button size='small' sx={{ color: BrandColor.WHITE }} onClick={handleInstallClick} fullWidth>Install</Button>
        <Button size='small' sx={{ color: BrandColor.WHITE }} onClick={handleDismissClick} fullWidth>Dismiss</Button>
      </Box>
    </Box>
  }

  return null
}

export default OnRouteChange
