import { Alert, Button, Snackbar, type SnackbarOrigin } from '@mui/material'

import type { Notification } from '~/types/notifications/Notification'

interface NotificationProps {
  notification: Notification | null
  onClose: (id: string) => void
}

const NotificationItem: React.FC<NotificationProps> = ({ notification, onClose }) => {
  const defaultAutoHideDuration = 6000

  const handleClose = (): void => {
    if (notification) onClose(notification?.id)
  }

  const getOrigin = (): SnackbarOrigin => {
    if (notification?.origin) return notification.origin

    return {
      vertical: 'top',
      horizontal: 'center',
    }
  }

  const snackbarProps = {
    key: notification?.id,
    open: notification !== null,
    onClose: handleClose,
    message: notification?.message,
    anchorOrigin: getOrigin(),
    autoHideDuration: notification?.duration ?? defaultAutoHideDuration,
    action: (<Button color="secondary" size="small" onClick={handleClose} sx={{ fontSize: 10, p: 0 }}>
      CLOSE
    </Button>),
  }

  if (notification?.type) {
    return (
      <Snackbar {...snackbarProps}>
        <Alert
          severity={notification?.type}
          sx={{ width: '100%' }}
          elevation={6}
          action={<Button color="inherit" size="small" onClick={handleClose}>
            CLOSE
          </Button>}>
          {notification?.message}
        </Alert>
      </Snackbar>
    )
  }

  return <Snackbar {...snackbarProps} />
}

export default NotificationItem
