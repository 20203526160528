import { LoadingButton, Masonry } from '@mui/lab'
import { Box, CircularProgress, Typography } from '@mui/material'

import BoxIcon from '~/components/Icons/BoxIcon'
import type { Deposit } from '~/types/deposit/Deposit'

import DepositListItem from './DepositListItem'

interface DepositListProps {
  deposits: Deposit[]
  fetching: boolean
  fetched: boolean
  hasMore: boolean
  loadMore: () => Promise<void>
}

const DepositList: React.FC<DepositListProps> = ({ deposits, fetching, fetched, loadMore, hasMore }) => {
  const handleLoadMore = (): void => {
    void loadMore()
  }

  if (!fetched && fetching) {
    return <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
      <CircularProgress />
    </Box>
  }

  if (fetched && deposits.length === 0) {
    return <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4, color: '#bbb' }}>
      <BoxIcon sx={{ width: 50, height: 50, mb: 2 }} />
      <Typography variant='h6' sx={{ color: '#bbb' }}>No deposits found</Typography>
    </Box>
  }

  return <Box sx={{ pt: 1.5, overflow: 'hidden' }}>
    <Masonry columns={{ xxs: 1, md: 2 }}>
      {deposits.map(item => <DepositListItem key={item.depositId} deposit={item} />)}
    </Masonry>
    {hasMore && (
      <Box component='span' sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton variant='contained' sx={{ width: { xxs: '100%', sm: '50%' } }} onClick={handleLoadMore} loading={fetching}>Load More</LoadingButton>
      </Box>
    )}
  </Box>
}

export default DepositList
