import { useEffect, useRef } from 'react'
import Bugsnag from '@bugsnag/js'
import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import JsBarcode from 'jsbarcode'

import { isNotifiable } from '~/types/guards/errors'
import { BrandColor } from '~/config/theme'

interface RewardBarcodeProps {
  text: string
  claimId: string
  bcid?: string
}

const BarcodeCanvas = styled('canvas')`
  max-width: 100%;
`

const RewardBarcode: React.FC<RewardBarcodeProps> = ({ text, claimId, bcid = 'EAN13' }) => {
  const el = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    try {
      if (el.current) {
        JsBarcode(el.current, text, {
          format: bcid,
          width: 10,
          height: 150,
          textAlign: 'center',
          fontSize: 20,
          flat: true,
          marginRight: 40,
          marginLeft: 40,
        })
      }
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }, [el.current])

  return <Box sx={{ background: BrandColor.WHITE, p: 1 }}>
    <Typography variant='body2' align='center' gutterBottom sx={{ fontSize: 12 }}>{claimId}</Typography>
    <BarcodeCanvas ref={el}></BarcodeCanvas>
  </Box>
}

export default RewardBarcode
