import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { Box, Breadcrumbs, Divider, FormControl, FormHelperText, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import { CUSTOMER_TITLES, CUSTOMER_GENDERS } from '~/constants/user'
import type { User } from '~/types/user/User'
import type { UpdateUserPayload } from '~/types/user/request/UpdateUserPayload'
import { createMuiRegister } from '~/helpers/hookForm'

import DeleteButton from './DeleteButton'

interface PersonalDetailsProps {
  user: User | null
  isSaving: boolean
  onSave: (payload: UpdateUserPayload) => Promise<void>
}

type FormValues = UpdateUserPayload & { customTitle: string }

const postcodeLabel = Scan2RecyclePromotion.country === 'IE' ? 'Eircode' : 'Postcode'

const validationSchema = z.object({
  title: z.string({
    required_error: 'Title is required',
  }).min(1, 'Title is required'),
  customTitle: z.string().max(20, 'Title should be no longer than 20 characters'),
  firstName: z.string({
    required_error: 'First Name is required',
  }).max(50, 'First Name should be no longer than 50 characters').min(1, 'First Name is required'),
  lastName: z.string({
    required_error: 'Last Name is required',
  }).max(50, 'Last Name should be no longer than 50 characters').min(1, 'Last Name is required'),
  addressLineOne: z.string({
    required_error: 'Address Line One is required',
  }).max(150, 'Address Line 1 should be no longer than 150 characters').min(1, 'Address Line One is required'),
  addressLineTwo: z.string().max(150, 'Address Line 2 should be no longer than 150 characters'),
  city: z.string({
    required_error: 'City is required',
  }).max(50, 'City should be no longer than 50 characters').min(1, 'City is required'),
  county: z.string({
    required_error: 'County is required',
  }).max(35, 'County should be no longer than 35 characters').min(1, 'County is required'),
  postalCode: z.string({
    required_error: `${postcodeLabel} is required`,
  }).max(10, `${postcodeLabel} should be no longer than 10 characters`).min(1, `${postcodeLabel} is required`),
  mobileNo: z.string({
    required_error: 'Mobile Number is required',
  }).max(20, 'Mobile Number should be no longer than 20 characters').regex(/^\+?[0-9]*$/, 'This is not a valid mobile number').min(1, 'Mobile Number is required'),
  gender: z.string().max(20, 'Gender should be no longer than 20 characters'),
}).strict().partial({
  customTitle: true,
  gender: true,
  addressLineTwo: true,
}).refine((values) => values.title !== 'Custom' || values.customTitle, {
  path: ['customTitle'],
  message: 'Title is required',
})

const PersonalDetails: React.FC<PersonalDetailsProps> = ({ user, isSaving, onSave }) => {
  const handleSave = (values: FormValues): void => {
    const { customTitle, ...rest } = values

    if (rest.title === 'Custom') {
      rest.title = customTitle.trim()
    }

    void onSave(rest)
  }

  const { handleSubmit, register, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: CUSTOMER_TITLES.includes(user?.title ?? '') ? user?.title : 'Custom',
      customTitle: !CUSTOMER_TITLES.includes(user?.title ?? '') ? user?.title : '',
      firstName: user?.firstname ?? '',
      lastName: user?.surname ?? '',
      addressLineOne: user?.addressOne ?? '',
      addressLineTwo: user?.addressTwo ?? '',
      city: user?.city ?? '',
      county: user?.county ?? '',
      postalCode: user?.postcode ?? '',
      mobileNo: user?.mobile ?? '',
      gender: user?.gender ?? '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  watch(['title', 'gender'])

  return <>
    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >Personal Details</Typography>
    </Breadcrumbs>

    <Box component="form" noValidate onSubmit={handleSubmit(handleSave)}>
      <Typography variant="h4" sx={{ my: 2 }}>Personal Details</Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel id="title-label">Title</InputLabel>
        <Select
          onChange={(e) => { setValue('title', e.target.value) }}
          value={getValues('title')}
          fullWidth
          id="title"
          label="Title"
          name='title'
          error={Boolean(errors.title)}
        >
            {CUSTOMER_TITLES.map(title => (<MenuItem key={title} value={title}>{title}</MenuItem>))}
        </Select>
        {errors.title && <FormHelperText error>{errors.title.message}</FormHelperText>}
      </FormControl>
      {getValues('title') === 'Custom' && (
        <FormControl fullWidth margin="normal">
          <TextField
            {...muiRegister('customTitle')}
            fullWidth
            id="customTitle"
            label="Custom Title"
            error={Boolean(errors.customTitle)}
          />
          {errors.customTitle && <FormHelperText error>{errors.customTitle?.message}</FormHelperText>}
        </FormControl>
      )}
      <TextField
        {...muiRegister('firstName')}
        fullWidth
        id="firstName"
        label="First Name"
        margin="normal"
        error={Boolean(errors.firstName)}
        helperText={errors.firstName?.message}
      />
      <TextField
        {...muiRegister('lastName')}
        fullWidth
        id="lastName"
        label="Last Name"
        margin="normal"
        error={Boolean(errors.lastName)}
        helperText={errors.lastName?.message}
      />
      <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
      <Typography variant="h6">Contact Details</Typography>
      <TextField
        {...muiRegister('addressLineOne')}
        fullWidth
        id="addressLineOne"
        label="Address Line #1"
        margin="normal"
        error={Boolean(errors.addressLineOne)}
        helperText={errors.addressLineOne?.message}
      />
      <TextField
        {...muiRegister('addressLineTwo')}
        fullWidth
        id="addressLineTwo"
        label="Address Line #2"
        margin="normal"
        error={Boolean(errors.addressLineTwo)}
        helperText={errors.addressLineTwo?.message}
      />
      <TextField
        {...muiRegister('city')}
        fullWidth
        id="city"
        label="City"
        margin="normal"
        error={Boolean(errors.city)}
        helperText={errors.city?.message}
      />
      <TextField
        {...muiRegister('county')}
        fullWidth
        id="county"
        label="County"
        margin="normal"
        error={Boolean(errors.county)}
        helperText={errors.county?.message}
      />
      <TextField
        {...muiRegister('postalCode')}
        fullWidth
        id="postalCode"
        label={Scan2RecyclePromotion.country === 'IE' ? 'Eircode' : 'Postcode'}
        margin="normal"
        error={Boolean(errors.postalCode)}
        helperText={errors.postalCode?.message}
      />
      <TextField
        {...muiRegister('mobileNo')}
        fullWidth
        id="mobileNo"
        label="Mobile Number"
        margin="normal"
        error={Boolean(errors.mobileNo)}
        helperText={errors.mobileNo?.message}
      />
      <FormControl fullWidth margin="normal">
          <InputLabel id="title-label">Gender</InputLabel>
          <Select
            onChange={(e) => { setValue('gender', e.target.value) }}
            value={getValues('gender')}
            fullWidth
            id="gender"
            label="Gender"
            name='gender'
            error={Boolean(errors.gender)}
          >
              {CUSTOMER_GENDERS.map(gender => (<MenuItem key={gender} value={gender}>{gender}</MenuItem>))}
          </Select>
          {errors.gender && <FormHelperText error>{errors.gender?.message}</FormHelperText>}
        </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'right', my: 2 }}>
        <LoadingButton variant="contained" type="submit" loading={isSaving} fullWidth>Save</LoadingButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DeleteButton user={user} />
      </Box>
    </Box>
  </>
}

export default PersonalDetails
