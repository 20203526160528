import React from 'react'
import { Container, Typography } from '@mui/material'

import application from '~/config/application'

import { UKTerms } from './parts/UK'
import { IETerms } from './parts/IE'
import { BlisterUKTerms } from './parts/BlisterUK'
import BootsMigration from './parts/BootsMigration'

const NativeTermsView: React.FC = () => {
  return <Container>
    <Typography variant='body2' gutterBottom>RECYCLE AT {application.client.toUpperCase()} - CUSTOMER TERMS & CONDITIONS</Typography>
    <BootsMigration />
    <UKTerms />
    <IETerms />
    <BlisterUKTerms />
  </Container>
}

export default NativeTermsView
