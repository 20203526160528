import { Box, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { createMuiRegister } from '~/helpers/hookForm'

export interface FormValues {
  code: string
}

interface ConfirmSignUpProps {
  email: string
  isLoading: boolean
  onResendCode: () => Promise<void>
  onConfirm: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  code: z.string({
    required_error: 'Confirmation Code is required',
  }).min(6, 'Confirmation Code must be at least 6 characters'),
}).strict()

const ConfirmSignUp: React.FC<ConfirmSignUpProps> = ({ email, isLoading, onResendCode, onConfirm }) => {
  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  const handleResendCode = (): void => {
    void onResendCode()
  }

  return (
    <Box sx={{ mt: 6, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>Verify Your Email</Typography>
      <Typography variant="body1" gutterBottom>We sent you a six-digit code to {email || 'your email'}. Please enter the code to verify your email address.</Typography>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(onConfirm)}>
        <TextField
          {...muiRegister('code')}
          fullWidth
          id="code"
          label="Confirmation Code"
          margin="normal"
          autoComplete="one-time-code"
          error={Boolean(errors.code)}
          helperText={errors.code?.message}
        />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ mt: 2 }}>Verify</LoadingButton>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
          <Typography>
            Didn't receive code?
          </Typography>
          <Link onClick={handleResendCode} sx={{ cursor: 'pointer' }}>
            Send code again
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfirmSignUp
