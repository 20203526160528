import { useEffect, useRef } from 'react'
import Bugsnag from '@bugsnag/js'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { signOut } from '~/redux/features/auth/signOut'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { ROUTE_LOGIN } from '~/routes/Routes'
import { isNotifiable } from '~/types/guards/errors'

import Account from './Account'

const AccountView: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const abort = useRef(new AbortController())

  useEffect(() => {
    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const handleSignOut = async (): Promise<void> => {
    try {
      await dispatch(signOut()).unwrap()
      navigate(ROUTE_LOGIN)
    } catch (error) {
      dispatch(addNotification({ type: 'error', message: 'Unable to sign out' }))

      if (isNotifiable(error)) {
        Bugsnag.notify(error)
      }
    }
  }

  return <>
    <Helmet>
      <title>Account | Recycle at Boots</title>
    </Helmet>
    <Account signOut={handleSignOut} />
  </>
}

export default AccountView
