import {
  Route,
} from 'react-router-dom'

import NativeFAQView from '~/views/Web/FAQ/NativeFAQView'
import NativeTermsView from '~/views/Web/Terms/NativeTermsView'

import { ROUTE_NATIVE_FAQ, ROUTE_NATIVE_TERMS } from './Routes'

const WebViewRoutes = <>
  <Route path={ROUTE_NATIVE_FAQ} element={<NativeFAQView />} />
  <Route path={ROUTE_NATIVE_TERMS} element={<NativeTermsView />} />
</>

export default WebViewRoutes
