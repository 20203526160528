import { useEffect, useState } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { Box, Link, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import Bugsnag from '@bugsnag/js'

import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import { signUp } from '~/redux/features/auth/actions'
import { storeSignUp } from '~/redux/features/auth/authSlice'

import { ROUTE_CONFIRM_SIGN_UP, ROUTE_LOGIN } from '~/routes/Routes'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import type { AWSException } from '~/types/errors/AWSException'
import { isNotifiable } from '~/types/guards/errors'
import type { CreateUserPayload } from '~/types/user/request/CreateUserPayload'

import CountrySelector from './Steps/CountrySelector'
import SchemeSelector from './Steps/SchemeSelector'
import EmailAndPassword from './Steps/EmailAndPassword'
import PersonalInformation from './Steps/PersonalInformation'
import Agreements from './Steps/Agreements'

enum STEP {
  COUNTRY_SELECT = 'COUNTRY_SELECT',
  SCHEME_SELECT = 'SCHEME_SELECT',
  EMAIL_AND_PASSWORD = 'EMAIL_AND_PASSWORD',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  AGREEMENTS = 'AGREEMENTS',
}

const STEP_ORDER = [
  STEP.COUNTRY_SELECT,
  STEP.SCHEME_SELECT,
  STEP.EMAIL_AND_PASSWORD,
  STEP.PERSONAL_INFORMATION,
  STEP.AGREEMENTS,
]

const SignUpView: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(STEP.COUNTRY_SELECT)
  const [isLoading, setIsLoading] = useState(false)
  const details = useSelector((state: RootState) => state.auth.signUp)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  const validateUser = (values: Partial<CreateUserPayload>): Omit<CreateUserPayload, 'userId'> & { password: string } => {
    const optionalFields = ['addressLineTwo', 'ageGroup', 'gender', 'informationOptIn']

    if (!Scan2RecyclePromotion.scheme.requiresLoyaltyCard) {
      optionalFields.push('loyaltyCardNo')
    }

    Object.entries(values).forEach(([key, value]) => {
      if (!optionalFields.includes(key) && !value) {
        throw new Error(`${key} is required`)
      }
    })

    return values as Omit<CreateUserPayload, 'userId'> & { password: string }
  }

  const handleSignUp = async (values?: Partial<CreateUserPayload>): Promise<void> => {
    setIsLoading(true)

    try {
      const validatedUserData = validateUser(values ? { ...details, ...values } : details)

      await dispatch(signUp(validatedUserData)).unwrap()

      navigate(ROUTE_CONFIRM_SIGN_UP)
    } catch (error) {
      let message = 'Sorry, there was a problem signing up'
      if ((error as AWSException).name === 'UserLambdaValidationException') {
        const errorMessage = (error as AWSException).message
        const errorParts = errorMessage.split('failed with error ')

        if (errorParts[1]) {
          message = errorParts[1].replace('.', '')
        }
      } else if ((error as AWSException).name === 'UsernameExistsException') {
        message = 'An account already exists'
      } else if (isNotifiable(error)) {
        // We don't want to notify on the signup validation exception errors so notify here
        Bugsnag.notify(error)
      }

      dispatch(addNotification({
        type: 'error',
        message,
      }))
    } finally {
      setIsLoading(false)
    }
  }

  const handleNextStep = async (values?: Partial<CreateUserPayload & { country: string }>): Promise<void> => {
    const currentIndex = STEP_ORDER.indexOf(currentStep)
    const nextIndex = currentIndex + 1

    dispatch(storeSignUp(values))

    if (nextIndex >= STEP_ORDER.length) {
      await handleSignUp(values); return
    }

    setCurrentStep(STEP_ORDER[nextIndex])
  }

  const renderStep = (): JSX.Element => {
    switch (currentStep) {
      case STEP.SCHEME_SELECT:
        return <SchemeSelector country={details.country} onNextStep={handleNextStep} />
      case STEP.EMAIL_AND_PASSWORD:
        return <EmailAndPassword onNextStep={handleNextStep} />
      case STEP.PERSONAL_INFORMATION:
        return <PersonalInformation onNextStep={handleNextStep} />
      case STEP.AGREEMENTS:
        return <Agreements onNextStep={handleNextStep} isLoading={isLoading} />
    }

    return <CountrySelector onNextStep={handleNextStep} />
  }

  return <>
    <Helmet>
      <title>Sign Up! | Recycle at Boots</title>
    </Helmet>
    {renderStep()}
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 2 }}>
      <Typography>Already have an account?</Typography>
      <Link component={RouterLink} to={ROUTE_LOGIN} variant="button">
          Sign in
      </Link>
    </Box>
  </>
}

export default SignUpView
