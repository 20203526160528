import { Link as RouterLink, useLocation } from 'react-router-dom'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material'
import { Helmet } from 'react-helmet-async'

import application from '~/config/application'
import TermsOfferBanner from '~/components/Terms/OfferBanner/TermsOfferBanner'
import { BrandColor } from '~/config/theme'
import { ROUTE_ACCOUNT } from '~/routes/Routes'
import type { LocationState } from '~/types/location/Location'

import FAQList from './FAQList'

const FAQContent = styled(Box)(({ theme }) => ({ padding: `${theme.spacing(2)} ${theme.spacing(2)}` }))

const FAQView: React.FunctionComponent = () => {
  const location = useLocation()

  return <>
    <Helmet>
      <title>FAQs | Recycle at Boots</title>
    </Helmet>
    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >FAQs</Typography>
    </Breadcrumbs>}

    <Box>
      <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>FAQs</Typography>
      <TermsOfferBanner />
      <TreeView
        defaultCollapseIcon={<ExpandLessIcon sx={{ color: BrandColor.MINT_GREEN }} />}
        defaultExpandIcon={<ExpandMoreIcon sx={{ color: BrandColor.MINT_GREEN }} />}
      >
        {FAQList.map((faq, index) => (<TreeItem key={index} nodeId={index.toString()} label={<Box sx={{ ml: 2 }}>{faq.question}</Box>}>
          <FAQContent>{faq.answer}</FAQContent>
        </TreeItem>))}
      </TreeView>
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>Contact Us</Typography>
        <Typography variant="body1" gutterBottom>If you have any questions or concerns please contact us at:</Typography>
        <Typography variant="body1">Email: <Link href={`mailto:${application.email}`}>{application.email}</Link></Typography>
        <Typography variant="body1">Phone: <Link href={`tel:${application.csPhoneNumber}`}>{application.csPhoneNumber}</Link></Typography>
        <Typography variant="caption">Monday to Friday 9am - 5pm excluding public and bank holidays (Standard UK geographic number, normal phone provider call rates apply, if calling from the ROI calls will be charged at standard international rates)</Typography>
      </Box>
    </Box>
  </>
}

export default FAQView
