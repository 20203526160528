import { Box, Checkbox, FormLabel, type SxProps, Typography } from '@mui/material'
import React from 'react'

import { theme } from '~/config/theme'

interface LabelWithContentCheckboxProps {
  key?: string
  name: string
  label: string
  value: string | number | boolean
  content: React.ReactNode
  checked: boolean
  icon?: React.ReactNode
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps
}

const LabelWithContentCheckbox: React.FC<LabelWithContentCheckboxProps> = ({ key, name, label, value, content, checked, icon, onChange, sx = {} }) => {
  return <FormLabel
    key={key}
    sx={{ display: 'block', background: theme.palette.grey[100], cursor: 'pointer', mb: 2, p: 2, borderRadius: 2, '&:last-of-type': { mb: 0 }, ...sx }}
  >
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {icon}
        <Typography sx={{ fontWeight: 400, pr: 2 }}>{label}</Typography>
        <Checkbox
          name={name}
          onChange={onChange}
          value={value}
          checked={checked}
          sx={{ ml: 'auto', p: 0 }}
        />
      </Box>
      <Box sx={{ userSelect: 'none' }}>
        {typeof content === 'string' ? <Typography sx={{ fontSize: '0.8rem' }}>{content}</Typography> : content}
      </Box>
    </Box>
  </FormLabel>
}

export default LabelWithContentCheckbox
