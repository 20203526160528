import { CircularProgress, Typography, Grid, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import StarIcon from '~/components/Icons/StarIcon'
import type { ActiveReward } from '~/types/reward/ActiveReward'
import type { Reward } from '~/types/reward/Reward'
import type { Prize } from '~/types/Prize'

import RewardListItem from './RewardListItem'

interface RewardListProps {
  rewards: Reward[]
  activeRewards: ActiveReward[]
  selections: Record<string, Prize[]>
  fetching: boolean
  fetched: boolean
  hasMore: boolean
  loadMore: () => Promise<void>
  fetchRewardSelections: (claimId: string) => Promise<void>
  onRewardSelect: (reward: Reward, prizeId: string) => Promise<void>
}

const RewardList: React.FC<RewardListProps> = ({ rewards, activeRewards, selections, fetching, fetched, hasMore, loadMore, fetchRewardSelections, onRewardSelect }) => {
  const handleLoadMore = (): void => {
    void loadMore()
  }

  if (!fetched && fetching) {
    return <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
      <CircularProgress />
    </Box>
  }

  if (rewards.length === 0) {
    return <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4, color: '#bbb' }}>
      <StarIcon sx={{ width: 50, height: 50, mb: 2 }} />
      <Typography>No Rewards found</Typography>
    </Box>
  }

  return (
    <Box>
      <Grid
        sx={{ overflow: 'auto', px: { sm: 1.5 }, pt: 2 }}
        container
        rowSpacing={2}
        columnSpacing={{ xxs: 0, sm: 2 }}
      >
        {rewards.map((reward) => <RewardListItem
          key={reward.rewardId}
          reward={reward}
          activeRewards={activeRewards}
          selections={selections}
          fetchRewardSelections={fetchRewardSelections}
          onRewardSelect={onRewardSelect}
        />)}
      </Grid>

      {hasMore && <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <LoadingButton variant='contained' sx={{ width: { xxs: '100%', sm: '50%' }, mt: 2 }} loading={fetching} onClick={handleLoadMore}>Load More</LoadingButton>
      </Box>}
    </Box>
  )
}

export default RewardList
