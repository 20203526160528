import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import application from '~/config/application'
import { recyclableScanSteps } from '~/config/recyclable'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ScanFlow } from '~/enum/ScanFlow'
import { hasSeenRecyclableChecklistToday } from '~/helpers/recyclable'
import type { RootState } from '~/redux/reducers/root'
import { useAppDispatch } from '~/redux/store'
import { getRecyclables } from '~/redux/features/recyclables/actions'
import RecyclableService from '~/services/RecyclableService'
import { type RecyclableScan, RecyclableScanStep, type ValidatedRecyclableScan } from '~/types/recyclable/recyclableScan'
import type { CreateRecyclableResponse } from '~/types/recyclable/response/CreateRecyclableResponse'

import AddRecyclable from './AddRecyclable'

const initialState: RecyclableScan = {
  confirmed: false,
  prescription: null,
  photo: {
    imageKey: null,
    storageURI: null,
  },
  scan: null,
  product: '',
  brand: '',
  category: '',
  form: '',
}

const AddRecyclableView: React.FC = () => {
  const [recyclable, setRecyclable] = useState({ ...initialState })
  const flowId = useRef<string>(uuid())
  const [recyclableResponse, setRecyclableResponse] = useState<CreateRecyclableResponse | null>(null)
  const [currentStep, setCurrentStep] = useState<RecyclableScanStep>(recyclableScanSteps[0].step)
  const [preChecksComplete, setPreChecksComplete] = useState<boolean>(false)
  const [recyclableLimit, setRecyclableLimit] = useState<boolean>(false)
  const activeUserPromotion = useSelector((state: RootState) => state.auth.promotionId)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentStep === recyclableScanSteps[0].step) {
      void initiate()
    }
  }, [currentStep])

  useEffect(() => {
    handleReset()
  }, [activeUserPromotion])

  const initiate = async (): Promise<void> => {
    flowId.current = uuid()
    const recyclables = await dispatch(getRecyclables({ promotionId: Scan2RecyclePromotion.id })).unwrap()

    if (Scan2RecyclePromotion.maxActiveRecyclables && recyclables.length >= Scan2RecyclePromotion.maxActiveRecyclables) {
      setRecyclableLimit(true)
      return
    }

    const currentStepIndex = findCurrentStepIndex()
    if (currentStepIndex === 0 && hasSeenRecyclableChecklistToday()) {
      const step = findNextStep(recyclable)
      setCurrentStep(step)
    }
    setPreChecksComplete(true)
  }

  const handleReset = (state: Partial<typeof initialState> = {}): void => {
    flowId.current = uuid()
    setPreChecksComplete(false)
    setRecyclable({ ...initialState, ...state })
    setRecyclableResponse(null)
    setCurrentStep(recyclableScanSteps[0].step)
  }

  const handleRepeat = (): void => {
    handleReset({
      prescription: recyclable.prescription,
      product: recyclable.product,
      brand: recyclable.brand,
      category: recyclable.category,
      form: recyclable.form,
    })
  }

  const findCurrentStepIndex = (): number => {
    return recyclableScanSteps.findIndex(step => step.step === currentStep)
  }

  const findNextStep = (recyclable: RecyclableScan): RecyclableScanStep => {
    let nextStepIndex = findCurrentStepIndex()
    let nextStep

    do {
      nextStepIndex += 1
      const potentialStep = recyclableScanSteps[nextStepIndex]

      if (!potentialStep.condition || (potentialStep?.condition(recyclable))) {
        nextStep = potentialStep.step
      }
    } while (!nextStep)

    return nextStep
  }

  const goToNextStep = async (values?: Partial<RecyclableScan>, id?: string): Promise<void> => {
    if (id && flowId.current !== id) return
    let recyclableWithNewValues = recyclable

    if (values) {
      recyclableWithNewValues = { ...recyclable, ...values }
      setRecyclable(recyclableWithNewValues)
    }

    const nextStep = findNextStep(recyclableWithNewValues)

    if (nextStep === RecyclableScanStep.RESULT) {
      await createRecyclable(recyclableWithNewValues)
    }

    setCurrentStep(nextStep)
  }

  const validateRecyclable = (payload: RecyclableScan): ValidatedRecyclableScan => {
    if (!payload.photo.storageURI) {
      throw new Error('Recyclable must have a photo')
    }

    if (!payload.scan) {
      throw new Error('Recyclable must have been scanned')
    }

    ['brand', 'category', 'form'].forEach(field => {
      if (!payload[field]) {
        throw new Error(`Recyclable must have a ${field}`)
      }
    })

    return {
      storageURI: payload.photo.storageURI,
      scan: payload.scan,
      brand: payload.brand,
      category: payload.category,
      form: payload.form,
    }
  }

  const createRecyclable = async (payload: RecyclableScan): Promise<void> => {
    const validatedRecyclable = validateRecyclable(payload)

    const response = await RecyclableService.createRecyclable(Scan2RecyclePromotion.id, validatedRecyclable.storageURI, validatedRecyclable.scan, {
      ...(application.scanFlow === ScanFlow.Product ? { productName: payload.product } : {}),
      brand: validatedRecyclable.brand,
      category: validatedRecyclable.category,
      form: validatedRecyclable.form,
    })

    setRecyclableResponse(response)
  }

  return <>
    <Helmet>
      <title>Add Recyclable | Recycle at Boots</title>
    </Helmet>
    {preChecksComplete
      ? <AddRecyclable
          step={currentStep}
          recyclable={recyclable}
          recyclableLimit={recyclableLimit}
          recyclableResponse={recyclableResponse}
          onNextStep={goToNextStep}
          onReset={handleReset}
          onAddRepeat={handleRepeat}
          flowId={flowId.current}
        />
      : null }
  </>
}

export default AddRecyclableView
