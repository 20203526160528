import Bugsnag from '@bugsnag/js'
import { useEffect, useState } from 'react'

import releaseVersion from '~/config/application'
import { getAppVersion } from '~/services/VersionService'
import { isNotifiable } from '~/types/guards/errors'

import { usePageVisibility } from './usePageVisibility'

export const checkCurrentVersion = async (): Promise<string | null> => {
  try {
    return await getAppVersion()
  } catch (error) {
    if (isNotifiable(error)) Bugsnag.notify(error)
  }

  return null
}

export const useVersionCheck = (): boolean => {
  const [version, setVersion] = useState<string | null>('')

  const isVisible = usePageVisibility(document)

  const checkReleaseVersion = async (): Promise<void> => {
    setVersion(await checkCurrentVersion())
  }

  useEffect(() => {
    void checkReleaseVersion()
  }, [])

  useEffect(() => {
    if (isVisible) {
      void checkReleaseVersion()
    }
  }, [isVisible])

  return version ? version === releaseVersion.releaseVersion : true
}
