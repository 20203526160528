import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Box, Button, TextField, Typography } from '@mui/material'

import { PasswordField } from '~/components/TextFields/PasswordField'
import { createMuiRegister } from '~/helpers/hookForm'

interface FormValues {
  email: string
  password: string
  confirmPassword: string
}

interface EmailAndPasswordProps {
  onNextStep: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  email: z.string().trim().min(1, 'Email is required').email(),
  password: z.string({
    required_error: 'Password is required',
  })
    .min(8, 'Must be at least 8 characters')
    .regex(/[a-z]+/, { message: 'Must have at least one lowercase character' })
    .regex(/[A-Z]+/, { message: 'Must have at least one uppercase character' })
    .regex(/\d+/, { message: 'Must have at least one number' })
    .regex(/^[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`\\]+$/, { message: 'Password is using an invalid character' }),
  confirmPassword: z.string({
    required_error: 'Confirm Password is required',
  }),
}).strict().refine((values) => values.password === values.confirmPassword, {
  message: 'Passwords must match',
  path: ['confirmPassword'],
})

const EmailAndPassword: React.FC<EmailAndPasswordProps> = ({ onNextStep }) => {
  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  return (
    <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={handleSubmit(onNextStep)} autoComplete="off">
      <Box>
        <Typography variant="h4">Email & Password</Typography>
      </Box>
      <TextField
        {...muiRegister('email')}
        fullWidth
        id="email"
        label="Email"
        margin="normal"
        autoComplete="username"
        error={Boolean(errors.email)}
        helperText={errors.email?.message}
      />
      <PasswordField
        muiRegister={muiRegister('password')}
        id="password"
        label="Password"
        autoComplete="new-password"
        error={Boolean(errors.password)}
        helperText={errors.password?.message}
      />
      <PasswordField
        muiRegister={muiRegister('confirmPassword')}
        id="confirmPassword"
        label="Confirm Password"
        autoComplete="new-password"
        error={Boolean(errors.confirmPassword)}
        helperText={errors.confirmPassword?.message}
      />
      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}>Next</Button>
    </Box>
  )
}

export default EmailAndPassword
