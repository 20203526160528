import Bugsnag from '@bugsnag/js'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { getRecyclables } from '~/redux/features/recyclables/actions'
import { useAppDispatch } from '~/redux/store'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import type { Recyclable } from '~/types/recyclable/Recyclable'
import RecyclableList from '~/views/Recyclable/MyRecyclables/RecyclableList'

interface SelectRecyclablesStepProps {
  recyclables: Recyclable[]
  selectedRecyclables: Recyclable[]
  onSelect: (recyclables: Recyclable) => void
  onNextStep: () => void
  onCancelDeposit: () => void
}

const SelectRecyclablesStep: React.FC<SelectRecyclablesStepProps> = ({ recyclables, selectedRecyclables, onSelect, onNextStep, onCancelDeposit }) => {
  const [fetching, setFetching] = useState<boolean>(true)
  const [fetched, setFetched] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const abort = useRef(new AbortController())

  useEffect(() => {
    void fetchRecyclables()
  }, [])

  const fetchRecyclables = async (): Promise<void> => {
    if (recyclables.length > 0) {
      setFetching(false)
      setFetched(true)
      return
    }

    try {
      if (!fetching) setFetching(true)

      await dispatch(getRecyclables({ promotionId: Scan2RecyclePromotion.id, init: { signal: abort.current.signal } })).unwrap()

      setFetching(false)
      setFetched(true)
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(addNotification({ type: 'error', message: 'Unable to fetch recyclables' }))
        setFetching(false)
        setFetched(true)
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  const handleNext = (): void => {
    if (selectedRecyclables.length === 0) {
      dispatch(addNotification({ type: 'error', message: 'Please select at least one recyclable' }))
      return
    }

    onNextStep()
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, mt: 2 }}>
    <Typography variant="h5" sx={{ mb: 2 }}>Select Recyclables</Typography>
    <Box sx={{ height: 0, flexGrow: 1, overflow: 'auto', position: 'relative', left: { xxs: -16, sm: -24, md: 0 }, px: { xxs: 2, sm: 4, md: 0 }, width: '100%' }}>
      <RecyclableList recyclables={recyclables} selectedRecyclables={selectedRecyclables} fetching={fetching} fetched={fetched} onRecyclableClick={onSelect} />
    </Box>
    <Box sx={{ flexShrink: 0, mt: 2 }}>
      <Button variant="contained" onClick={handleNext} fullWidth sx={{ mb: 1 }}>Next</Button>
      <Button variant="contained" color="secondary" onClick={onCancelDeposit} fullWidth>Cancel</Button>
    </Box>
  </Box>
}

export default SelectRecyclablesStep
