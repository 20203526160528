import { Box, Container, Link, Stack } from '@mui/material'
import { Link as RouterLink, Outlet } from 'react-router-dom'

import application from '~/config/application'
import { ROUTE_FAQS, ROUTE_LANDING, ROUTE_PRIVACY, ROUTE_TERMS } from '~/routes/Routes'

import Logo from '~/assets/images/recycle-at-boots-blue.svg'

const AuthPage: React.FC = () => {
  return <>
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    }}>
      <Container component="main" maxWidth="md" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', minHeight: 'fill-available', minWidth: '280px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 2, pt: 2 }}>
          <Link component={RouterLink} to={ROUTE_LANDING}>
            <Box
              component="img"
              sx={{
                maxWidth: '100%',
                height: { xxs: 120, sm: 130 },
                mb: 2,
              }}
              alt={application.name}
              src={Logo}
            />
          </Link>
        </Box>
        <Outlet />
      </Container>
    </Box>
    <Box sx={{ width: '100%', py: 2, mt: 'auto' }}>
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          divider={null}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Link component={RouterLink} to={ROUTE_FAQS} variant="body2" sx={{ textDecoration: 'none' }}>FAQs</Link>
          <Link component={RouterLink} to={ROUTE_TERMS} variant="body2" sx={{ textDecoration: 'none' }}>Terms</Link>
          <Link component={RouterLink} to={ROUTE_PRIVACY} variant="body2" sx={{ textDecoration: 'none' }}>Privacy</Link>
        </Stack>
      </Container>
    </Box>
  </>
}

export default AuthPage
