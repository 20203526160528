import { Box, TextField, Autocomplete } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { matchSorter } from 'match-sorter'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import type { RecyclableScan, RecyclableTags } from '~/types/recyclable/recyclableScan'
import type { S2RProduct } from '~/types/recyclable/S2RProduct'

interface FormValues {
  product: string
}

interface OnNextStepPayload {
  brand: string
  category: string
  product: string
}

interface DefineProductProps {
  definition: RecyclableTags & { product: string }
  recyclable: RecyclableScan
  products: S2RProduct[]
  loadingProducts: boolean
  creating: boolean
  onNextStep: (values?: OnNextStepPayload) => Promise<void>
}

const validationSchema = z.object({
  product: z.string({
    required_error: 'Product is required',
  }).trim().min(1, {
    message: 'Product is required',
  }),
}).strict()

const filterOptions = (options: string[], { inputValue }: { inputValue: string }): string[] => {
  return matchSorter(options, inputValue, {
    sorter: rankedItems => {
      rankedItems.sort((a, b) => {
        return a.rank > b.rank ? -1 : 1
      })

      const noProductIndex = rankedItems.findIndex(item => item.rankedValue === 'No Product')
      rankedItems.push(...rankedItems.splice(noProductIndex, 1))

      const otherIndex = rankedItems.findIndex(item => item.rankedValue === 'Other')
      rankedItems.push(...rankedItems.splice(otherIndex, 1))

      return rankedItems
    },
  })
}

const DefineProduct: React.FC<DefineProductProps> = ({ definition, recyclable, products, loadingProducts, creating, onNextStep }) => {
  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      product: definition.product ?? '',
    },
    mode: 'onChange',
  })

  const handleNext = async (values: FormValues): Promise<void> => {
    const { product } = values
    const selectedProduct = products.find(({ product: p }) => p === product)

    if (!selectedProduct) {
      throw new Error('Unable to find product')
    }

    const { brand, category } = selectedProduct

    await onNextStep({ brand, category, product })
  }

  const handleProductSelect = (_: React.SyntheticEvent<Element, Event>, value: string[]): void => {
    const product = value ? value.pop() ?? '' : ''

    setValue('product', product)
  }

  watch('product')

  return <Box component="form" noValidate onSubmit={handleSubmit(handleNext)} >
    <Autocomplete
      onChange={handleProductSelect}
      disablePortal
      multiple
      id="product"
      options={products.map(({ product }) => product)}
      value={getValues('product') ? [getValues('product')] : []}
      filterOptions={filterOptions}
      loading={loadingProducts}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Product"
          placeholder="Type to search products"
          error={Boolean(errors.product)}
          helperText={errors.product?.message}
          required
        />
      )}
    />
    <Box sx={{ width: '100%', mt: 2 }}>
      <LoadingButton type="submit" variant="contained" color="primary" loading={creating} fullWidth>{recyclable.form ? 'Submit' : 'Next'}</LoadingButton>
    </Box>
  </Box>
}

export default DefineProduct
