import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Breadcrumbs, Link, Typography, Divider, Box } from '@mui/material'

import type { LocationState } from '~/types/location/Location'
import { ROUTE_ACCOUNT } from '~/routes/Routes'

import DontRecycleIcon from '~/assets/images/recyclingSymbols/dont-recycle-icon.svg'
import DontRecycleAtHomeIcon from '~/assets/images/recyclingSymbols/dont-recycle-at-home-icon.svg'
import CapSleeveIcon from '~/assets/images/recyclingSymbols/cap-sleeve-icon.svg'
import RecycleIcon from '~/assets/images/recyclingSymbols/recycle-icon.svg'
import RecycleRinseIcon from '~/assets/images/recyclingSymbols/recycle-rinse-icon.svg'
import RecycleLidOnIcon from '~/assets/images/recyclingSymbols/recycle-lid-on-icon.svg'

import PlasticCode3Icon from '~/assets/images/recyclingSymbols/plastic-code3.svg'
import PlasticCode4Icon from '~/assets/images/recyclingSymbols/plastic-code4.svg'
import PlasticCode6Iscon from '~/assets/images/recyclingSymbols/plastic-code6.svg'
import PlasticCode7Icon from '~/assets/images/recyclingSymbols/plastic-code7.svg'

import WasteElectricalsIcon from '~/assets/images/recyclingSymbols/waste-electricals-icon.svg'
import CompostableIcon from '~/assets/images/recyclingSymbols/compostable-icon.svg'
import HomeCompostableIcon from '~/assets/images/recyclingSymbols/home-compostable-icon.svg'
import GreenDotIcon from '~/assets/images/recyclingSymbols/green-dot-icon.svg'
import PlasticCode1Icon from '~/assets/images/recyclingSymbols/plastic-code1.svg'
import PlasticCode2Icon from '~/assets/images/recyclingSymbols/plastic-code2.svg'
import GlassIcon from '~/assets/images/recyclingSymbols/glass-icon.svg'
import FscIcon from '~/assets/images/recyclingSymbols/fsc-icon.svg'
import TidymanIcon from '~/assets/images/recyclingSymbols/tidyman-icon.svg'
import AluminiumIcon from '~/assets/images/recyclingSymbols/aluminium-icon.svg'

import { CircleIcon, GreenCheckIcon, RedCancelIcon } from './CircleIcons'
import RecyclingSymbolsSection from './RecyclingSymbolsSection'
import OtherSymbolsSection from './OtherSymbolsSection'

const RecyclableTypesView: React.FC = () => {
  const location = useLocation()

  return <>
    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT &&
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 1, mb: 1 }}>
        <Link component={RouterLink} to={ROUTE_ACCOUNT}>
          Account
        </Link>
        <Typography color="text.primary">
          Recycling Symbols
        </Typography>
      </Breadcrumbs>
    }

    <Typography variant="h4" component="h1" sx={{ mt: 1 }} gutterBottom>
      Recycling Symbols
    </Typography>
    <Typography sx={{ pb: 4 }} color="text.primary">
      It can be very confusing trying to decipher if your product is hard to recycle. Below are the common recycling symbols.
    </Typography>

    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <GreenCheckIcon sx={{ pr: 1 }} />
      <Typography color="text.primary" sx={{ mt: 1 }} gutterBottom>
        Deposit if included in the scheme
      </Typography>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <RedCancelIcon sx={{ pr: 1 }} />
      <Typography color="text.primary" sx={{ mt: 1 }} gutterBottom>
        Recycle through local recycling channels
      </Typography>
    </Box>

    <Divider sx={{ my: 2, mx: 0 }} />

    <RecyclingSymbolsSection title='Hard to recycle' recyclingSymbols={[
      { image: DontRecycleIcon, circleIcon: CircleIcon.CHECK },
      { image: DontRecycleAtHomeIcon, circleIcon: CircleIcon.CHECK },
    ]} />

    <RecyclingSymbolsSection title='Elements are non-hard to recycle' recyclingSymbols={[
      { image: CapSleeveIcon, circleIcon: CircleIcon.BOTH },
    ]} />

    <RecyclingSymbolsSection title='Non-hard to recycle' recyclingSymbols={[
      { image: RecycleIcon, circleIcon: CircleIcon.CROSS },
      { image: RecycleRinseIcon, circleIcon: CircleIcon.CROSS },
      { image: RecycleLidOnIcon, circleIcon: CircleIcon.CROSS },
    ]} />

    <Divider sx={{ my: 2, mx: 0 }} />

    <OtherSymbolsSection title='Other symbols' otherSymbols={[
      { image: PlasticCode3Icon, circleIcon: CircleIcon.CHECK },
      { image: PlasticCode4Icon, circleIcon: CircleIcon.CHECK },
      { image: PlasticCode6Iscon, circleIcon: CircleIcon.CHECK },
      { image: PlasticCode7Icon, circleIcon: CircleIcon.CHECK },
      { image: PlasticCode1Icon, circleIcon: CircleIcon.CROSS },
      { image: PlasticCode2Icon, circleIcon: CircleIcon.CROSS },
    ]} />

    <OtherSymbolsSection title='Information only Symbols' otherSymbols={[
      { image: WasteElectricalsIcon },
      { image: CompostableIcon },
      { image: HomeCompostableIcon },
      { image: GreenDotIcon },
      { image: GlassIcon },
      { image: FscIcon },
      { image: TidymanIcon },
      { image: AluminiumIcon },
    ]}/>
  </>
}

export default RecyclableTypesView
