import { Auth } from 'aws-amplify'
import { startsWith } from 'lodash'

import { signOut } from '~/redux/features/auth/signOut'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import store from '~/redux/store'

class Service {
  protected uri: string

  constructor (uri: string) {
    this.uri = uri
  }

  protected getPath (path: string): string {
    if (startsWith(path, '/')) path = path.substring(1, path.length)

    return `${this.uri}${path}`
  }

  protected async request (input: RequestInfo, init: RequestInit = {}): Promise<Response> {
    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
    const defaultHeaders = {
      Authorization: `Bearer ${jwt}`,
      ...this.getClientHeaders(),
    }

    init.headers = init.headers ? { ...defaultHeaders, ...init.headers } : defaultHeaders

    return await this.send(input, init)
  }

  protected async publicRequest (input: RequestInfo, init: RequestInit = {}): Promise<Response> {
    const defaultHeaders = {
      ...this.getClientHeaders(),
    }

    init.headers = init.headers ? { ...defaultHeaders, ...init.headers } : defaultHeaders

    return await this.send(input, init)
  }

  protected buildQueryString (params: Record<string, string | number | string[]>): string {
    return `?${Object.keys(params)
      .map(key => {
        if (Array.isArray(params[key])) {
          return (params[key] as string[]).map((value: string) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string | number)}`
      })
      .join('&')}`
  }

  private async send (input: RequestInfo, init: RequestInit = {}): Promise<Response> {
    input = typeof input === 'string' ? this.getPath(input) : input

    try {
      const response = await fetch(input, init)

      if (response.status === 401) {
        await store.dispatch(signOut()).unwrap()
      }

      return response
    } catch (error) {
      if ((error as Error).name === 'NetworkError') {
        store.dispatch(addNotification({ type: 'error', message: 'A network error occured when trying to fetch resource. Please check your connection' }))
      }
      throw (error)
    }
  }

  protected getClientHeaders (): Record<string, string> {
    return {}
  }
}

export default Service
