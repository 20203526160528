import { useEffect, useRef, useState } from 'react'

import { getGeoLocationWithinDistanceThreshold } from '~/helpers/geolocation/getDistanceBetweenGeographicPoints'
import type { GeoPoint } from '~/types/location/GeoPoint'

interface useGeoLocationWatcherItems {
  isWatching: boolean
  watchGeolocation: () => void
  stopWatchingGeolocation: () => void
  getGeoPointSamples: () => GeoPoint[]
}

const metersFromPreviousPointTheshold = 10

export const useGeolocationWatcher = (): useGeoLocationWatcherItems => {
  const watcher = useRef<null | number>(null)
  const [geoPointSamples, setGeoPointSamples] = useState<GeoPoint[]>([])
  const [isWatching, setIsWatching] = useState(false)

  useEffect(() => {
    return () => {
      stopWatchingGeolocation()
      setGeoPointSamples([])
    }
  }, [])

  const watchGeolocation = (): void => {
    if (!isWatching) setIsWatching(true)

    if (watcher.current) {
      setGeoPointSamples([])
      navigator.geolocation.clearWatch(watcher.current)
    }

    watcher.current = navigator.geolocation.watchPosition((position) => {
      const convertedPosition: GeoPoint = {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        accuracy: position.coords.accuracy,
      }

      // Adds the latest observed location if the observed location is at least 10m away from the nearest previously observed location
      setGeoPointSamples((currentGeoPointSamples) => {
        const closestPoint = getGeoLocationWithinDistanceThreshold(convertedPosition, currentGeoPointSamples, metersFromPreviousPointTheshold)
        if (closestPoint === undefined) return currentGeoPointSamples.concat(convertedPosition)
        return currentGeoPointSamples
      })
    },
    () => {
      // TODO: Handle error
    },
    {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 1000,
    })
  }

  const stopWatchingGeolocation = (): void => {
    if (isWatching) setIsWatching(false)
    if (watcher.current !== null) {
      navigator.geolocation.clearWatch(watcher.current)
    }
  }

  const getGeoPointSamples = (): GeoPoint[] => {
    return geoPointSamples
  }

  return { isWatching, watchGeolocation, stopWatchingGeolocation, getGeoPointSamples }
}
