import { createAsyncThunk } from '@reduxjs/toolkit'

import RecyclingPointService from '~/services/RecyclingPointService'
import type { RecyclingPoint } from '~/types/deposit/RecyclingPoint'

export const onExtenalRecyclingPointScan = createAsyncThunk(
  'deposits/onExtenalRecyclingPointScan',
  async ({ promotionId, recyclingPointId, init }: { promotionId: string, recyclingPointId: string, init?: { signal?: AbortSignal } }): Promise<RecyclingPoint> => {
    return await RecyclingPointService.getRecyclingPoint(promotionId, recyclingPointId, init)
  },
)
