import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { ScanFlow } from '~/enum/ScanFlow'
import application from '~/config/application'
import type { LocationState } from '~/types/location/Location'
import { ROUTE_ACCOUNT } from '~/routes/Routes'

import Categories from './Categories'
import Products from './Products'

const RecyclableTypesView: React.FC = () => {
  const location = useLocation()

  return <>
    <Helmet>
      <title>Recyclable Types | Recycle at Boots</title>
    </Helmet>
    {(location?.state as LocationState)?.from === ROUTE_ACCOUNT && <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
      <Link component={RouterLink} to={ROUTE_ACCOUNT}>Account</Link>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary"
      >
        Recyclable Types
      </Typography>
    </Breadcrumbs>}
    <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>Recyclable Types</Typography>
    <Typography variant="body1" gutterBottom>The Scan2Recycle scheme provides a way to responsibly recycle otherwise unrecyclable items.</Typography>
    <Typography variant="body1" gutterBottom>Items are listed by their category and the form factor they come in, so for example Deodorant - Stick. This is because we may not accept some forms of a product that are deemed hazardous such as aerosols.</Typography>
    <Typography variant="body1">Below is a list of items that are accepted in our recycling bins and that you can be rewarded for recycling.</Typography>
    <Box sx={{ my: 2 }}>
      {
        application.scanFlow === ScanFlow.Category ? <Categories /> : <Products />
      }
    </Box>
  </>
}

export default RecyclableTypesView
