import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Box, Button, Divider, FormControl, FormHelperText, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material'

import { CUSTOMER_AGE_GROUPS, CUSTOMER_GENDERS, CUSTOMER_TITLES } from '~/constants/user'
import { LOYALTY_CARD_LINK } from '~/constants/externalLinks'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { createMuiRegister } from '~/helpers/hookForm'

interface FormValues {
  title: string
  firstName: string
  lastName: string
  addressLineOne: string
  addressLineTwo: string
  city: string
  county: string
  postalCode: string
  mobileNo: string
  gender: string
  ageGroup: string
  loyaltyCardNo: string
  confirmLoyaltyCardNo: string
}

interface PersonalInformationProps {
  onNextStep: (values: FormValues) => Promise<void>
}

const postcodeLabel = Scan2RecyclePromotion.country === 'IE' ? 'Eircode' : 'Postcode'

const validationSchema = z.object({
  title: z.string({
    required_error: 'Title is required',
  }).min(1, 'Title is required'),
  customTitle: z.string().max(20, 'Title should be no longer than 20 characters'),
  firstName: z.string({
    required_error: 'First Name is required',
  }).max(50, 'First Name should be no longer than 50 characters').min(1, 'First Name is required'),
  lastName: z.string({
    required_error: 'Last Name is required',
  }).max(50, 'Last Name should be no longer than 50 characters').min(1, 'Last Name is required'),
  addressLineOne: z.string({
    required_error: 'Address Line One is required',
  }).max(150, 'Address Line 1 should be no longer than 150 characters').min(1, 'Address Line One is required'),
  addressLineTwo: z.string().max(150, 'Address Line 2 should be no longer than 150 characters'),
  city: z.string({
    required_error: 'City is required',
  }).max(50, 'City should be no longer than 50 characters').min(1, 'City is required'),
  county: z.string({
    required_error: 'County is required',
  }).max(35, 'County should be no longer than 35 characters').min(1, 'County is required'),
  postalCode: z.string({
    required_error: `${postcodeLabel} is required`,
  }).max(10, `${postcodeLabel} should be no longer than 10 characters`).min(1, `${postcodeLabel} is required`),
  mobileNo: z.string({
    required_error: 'Mobile Number is required',
  }).max(20, 'Mobile Number should be no longer than 20 characters').regex(/^\+?[0-9]*$/, 'This is not a valid mobile number').min(1, 'Mobile Number is required'),
  gender: z.string().max(20, 'Gender should be no longer than 20 characters'),
  ageGroup: z.string().max(20, 'Age Group should be no longer than 20 characters'),
  loyaltyCardNo: z.string().max(20, 'Advantage Card Number should be no longer than 20 characters').min(1, 'Advantage Card Number is required'),
  confirmLoyaltyCardNo: z.string({
    required_error: 'Confirmation of Advantage Card Number is required',
  }),
}).strict().partial({
  customTitle: true,
  gender: true,
  addressLineTwo: true,
})

const validationSchemaWithoutLoyaltyCard = validationSchema.omit({ loyaltyCardNo: true, confirmLoyaltyCardNo: true })
const valdiationSchemaWithLoyaltyCard = validationSchema.refine((values) => values.title !== 'Custom' || values.customTitle, {
  path: ['customTitle'],
  message: 'Title is required',
}).refine((values) => values.loyaltyCardNo === values.confirmLoyaltyCardNo, {
  path: ['confirmLoyaltyCardNo'],
  message: 'Advantage Card Numbers must match',
})

const PersonalInformation: React.FC<PersonalInformationProps> = ({ onNextStep }) => {
  const scheme = Scan2RecyclePromotion.scheme
  const handleNext = async (values: FormValues & { customTitle: string }): Promise<void> => {
    const { customTitle, ...rest } = values
    if (values.title === 'Custom') {
      rest.title = customTitle.trim()
    }
    await onNextStep(rest)
  }

  const { handleSubmit, register, getValues, setValue, watch, formState: { errors } } = useForm<FormValues & { customTitle: string }>({
    resolver: zodResolver(scheme.requiresLoyaltyCard ? valdiationSchemaWithLoyaltyCard : validationSchemaWithoutLoyaltyCard),
    defaultValues: {
      title: '',
      customTitle: '',
      firstName: '',
      lastName: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      county: '',
      postalCode: '',
      mobileNo: '',
      gender: '',
      ageGroup: '',
      ...(scheme.requiresLoyaltyCard
        ? {
            loyaltyCardNo: '',
            confirmLoyaltyCardNo: '',
          }
        : {}),
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister(register)

  watch(['title', 'gender', 'ageGroup'])

  return (
    <Box sx={{ mt: 2 }}>
      <Box>
        <Typography variant="h4">Personal Information</Typography>
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit(handleNext)}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="title-label">Title</InputLabel>
          <Select
            fullWidth
            id="title"
            name="title"
            label="Title"
            onChange={(e) => { setValue('title', e.target.value) }}
            value={getValues('title')}
            error={Boolean(errors.title)}
            required>
              {CUSTOMER_TITLES.map(title => (<MenuItem key={title} value={title}>{title}</MenuItem>))}
          </Select>
          {errors.title && <FormHelperText error>{errors.title.message}</FormHelperText>}
        </FormControl>
        {getValues('title') === 'Custom' && (
          <FormControl fullWidth margin="normal">
            <TextField
              {...muiRegister('customTitle')}
              fullWidth
              id="customTitle"
              label="Custom Title"
              error={Boolean(errors.customTitle)}
            />
            {errors.customTitle && <FormHelperText error>{errors.customTitle?.message}</FormHelperText>}
          </FormControl>
        )}
        <TextField
          {...muiRegister('firstName')}
          fullWidth
          id="firstName"
          label="First Name"
          margin="normal"
          error={Boolean(errors.firstName)}
          helperText={errors.firstName?.message}
        />
        <TextField
          {...muiRegister('lastName')}
          fullWidth
          id="lastName"
          label="Last Name"
          margin="normal"
          error={Boolean(errors.lastName)}
          helperText={errors.lastName?.message}
        />
        <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
        <Typography variant="h5">Contact Details</Typography>
        <TextField
          {...muiRegister('addressLineOne')}
          fullWidth
          id="addressLineOne"
          label="Address Line #1"
          margin="normal"
          error={Boolean(errors.addressLineOne)}
          helperText={errors.addressLineOne?.message}
        />
        <TextField
          {...muiRegister('addressLineTwo')}
          fullWidth
          id="addressLineTwo"
          label="Address Line #2"
          margin="normal"
          error={Boolean(errors.addressLineTwo)}
          helperText={errors.addressLineTwo?.message}
        />
        <TextField
          {...muiRegister('city')}
          fullWidth
          id="city"
          label="City"
          margin="normal"
          error={Boolean(errors.city)}
          helperText={errors.city?.message}
        />
        <TextField
          {...muiRegister('county')}
          fullWidth
          id="county"
          label="County"
          margin="normal"
          error={Boolean(errors.county)}
          helperText={errors.county?.message}
        />
        <TextField
          {...muiRegister('postalCode')}
          fullWidth
          id="postalCode"
          label={Scan2RecyclePromotion.country === 'IE' ? 'Eircode' : 'Postcode'}
          margin="normal"
          error={Boolean(errors.postalCode)}
          helperText={errors.postalCode?.message}
        />
        <TextField
          {...muiRegister('mobileNo')}
          fullWidth
          id="mobileNo"
          label="Mobile Number"
          margin="normal"
          error={Boolean(errors.mobileNo)}
          helperText={errors.mobileNo?.message}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="title-label">Gender</InputLabel>
          <Select
            onChange={(e) => { setValue('gender', e.target.value) }}
            value={getValues('gender')}
            fullWidth
            id="gender"
            label="Gender"
            name='gender'
            error={Boolean(errors.gender)}
          >
              {CUSTOMER_GENDERS.map(gender => (<MenuItem key={gender} value={gender}>{gender}</MenuItem>))}
          </Select>
          {errors.gender && <FormHelperText error>{errors.gender?.message}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="title-label">Age Group</InputLabel>
          <Select
            fullWidth
            id="ageGroup"
            name="ageGroup"
            label="Age Group"
            onChange={(e) => { setValue('ageGroup', e.target.value) }}
            value={getValues('ageGroup')}
            error={Boolean(errors.ageGroup)}
          >
              {CUSTOMER_AGE_GROUPS.map(title => (<MenuItem key={title} value={title}>{title}</MenuItem>))}
          </Select>
          {errors.ageGroup && <FormHelperText error>{errors.ageGroup?.message}</FormHelperText>}
        </FormControl>
        {scheme.requiresLoyaltyCard && <>
          <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
          <Typography variant="h5" gutterBottom>Advantage Card</Typography>
          <Typography variant="body1">
            To be rewarded you will need your advantage card number. No advantage card? Click <Link href={LOYALTY_CARD_LINK} target="_blank">here</Link> to register and get your number instantly.
          </Typography>
          <TextField
            {...muiRegister('loyaltyCardNo')}
            fullWidth
            id="loyaltyCardNo"
            label="Advantage Card Number"
            margin="normal"
            error={Boolean(errors.loyaltyCardNo)}
            helperText={errors.loyaltyCardNo?.message}
          />
          <TextField
            {...muiRegister('confirmLoyaltyCardNo')}
            fullWidth
            id="confirmLoyaltyCardNo"
            label="Confirm Advantage Card Number"
            margin="normal"
            error={Boolean(errors.confirmLoyaltyCardNo)}
            helperText={errors.confirmLoyaltyCardNo?.message}
          />
        </>}
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}>Next</Button>
      </Box>
    </Box>
  )
}

export default PersonalInformation
