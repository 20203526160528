import { Box, Button, FormHelperText, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import Prescription from '~/assets/images/prescription/prescription.svg'
import NonPrescription from '~/assets/images/prescription/non-prescription.svg'
import type { RecyclableScan } from '~/types/recyclable/recyclableScan'

interface FormValues {
  prescription: boolean | null
}

interface ChecklistStepProps {
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
}

const validationSchema = z.object({
  prescription: z.boolean({
    errorMap: () => ({ message: 'Choose the type of blister pack you are recycling' }),
  }),
}).strict()

const toggleButtonStyle = { display: 'flex', flexGrow: 1, width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: 'none', p: 3 }

const PrescriptionStep: React.FC<ChecklistStepProps> = ({ onNextStep }) => {
  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      prescription: null,
    },
    mode: 'onChange',
  })

  watch(['prescription'])

  const handleNext = (values: FormValues): void => {
    void onNextStep({
      prescription: values.prescription,
      ...(values.prescription
        ? {
            brand: 'Any',
            category: 'Prescription',
            form: 'Blister Pack',
          }
        : { form: 'Blister Pack' }),
    })
  }

  const handlePrescriptionSelect = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean): void => {
    setValue('prescription', value, { shouldValidate: true })
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(handleNext)} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Typography variant="h5" gutterBottom>Medicine Type</Typography>
      <ToggleButtonGroup
        color="primary"
        value={getValues('prescription')}
        exclusive
        onChange={handlePrescriptionSelect}
        aria-label="Country"
        sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', pt: 4, pb: 2 }}
      >
        <ToggleButton value={true} sx={toggleButtonStyle}>
          <Box component="img" src={Prescription} sx={{ width: '100%', maxHeight: 50, mb: 2 }} />
          Prescription
        </ToggleButton>
        <ToggleButton value={false} sx={toggleButtonStyle}>
          <Box component="img" src={NonPrescription} sx={{ width: '100%', maxHeight: 50, mb: 2 }} />
          Non-Prescription
        </ToggleButton>
      </ToggleButtonGroup>
      <FormHelperText error={true} sx={{ textAlign: 'center' }}>{errors.prescription?.message}</FormHelperText>

      <Box sx={{ width: '100%', mt: 2 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>Next</Button>
      </Box>
    </Box>
  )
}

export default PrescriptionStep
