import { Box, Checkbox, Chip, CircularProgress, Typography } from '@mui/material'
import { CheckCircle, Circle, ErrorOutlineOutlined } from '@mui/icons-material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import S3Image from '~/components/Images/S3Image'
import { BrandColor, theme } from '~/config/theme'
import type { RootState } from '~/redux/reducers/root'
import type { Recyclable } from '~/types/recyclable/Recyclable'
import { RecyclableStatus } from '~/constants/recyclable'

interface RecyclableListItemProps {
  recyclable: Recyclable
  selectedRecyclables?: Recyclable[]
  isFirst: boolean
  isLast: boolean
  onRecyclableClick?: (recyclable: Recyclable) => void
}

const RecycableListItem: React.FC<RecyclableListItemProps> = ({ recyclable, selectedRecyclables, isFirst, isLast, onRecyclableClick }) => {
  const loadingRecyclables = useSelector((state: RootState) => state.recyclable.loading)
  const isRejected = recyclable.status === RecyclableStatus.REJECTED
  const isSelected = useMemo(() => {
    if (!selectedRecyclables) return false
    return Boolean(selectedRecyclables.find(item => item.bankableId === recyclable.bankableId))
  }, [selectedRecyclables])
  const isLoading = useMemo(() => {
    return loadingRecyclables.find(item => item === recyclable.bankableId)
  }, [loadingRecyclables])

  const handleRecyclableClick = (): void => {
    if (!isLoading && onRecyclableClick) onRecyclableClick(recyclable)
  }

  const isPrescription = recyclable.category === 'Prescription'

  return <Box onClick={handleRecyclableClick} sx={{ position: 'relative', display: 'flex' }}>
    {onRecyclableClick && <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox icon={<Circle sx={{ color: '#eee' }} />} size="small" checkedIcon={<CheckCircle />} checked={isSelected} />
    </Box>}
    <Box sx={{ position: 'relative', overflow: 'hidden', flexGrow: 1, width: '100%', display: 'flex', background: '#fafafa', p: 1, cursor: isLoading ? 'pointer' : 'default', opacity: isLoading ? 0.75 : 1, filter: isLoading ? 'blur(1px)' : '', transition: 'all 0.5s ease', minHeight: 70 }}>
      <Box sx={{ height: '100%', width: 110, flexShrink: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 0, mr: 1, borderRadius: 1, borderBottomLeftRadius: isRejected ? 0 : null, borderBottomRightRadius: isRejected ? 0 : null, overflow: 'hidden', objectFit: 'cover' }}>
        <S3Image imageKey={recyclable.imageKey} sx={{ width: '100%', height: 0, flexGrow: 1, maxWidth: '100%', objectFit: 'cover' }} blurhash={recyclable.blurhash} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Typography variant='body2' noWrap sx={{ fontWeight: 500, color: BrandColor.BLACK }}>{isPrescription ? 'Prescription' : recyclable.brand}</Typography>
        {!isPrescription && <Typography variant='body2' noWrap sx={{ color: '#666' }} textOverflow={'ellipsis'}>{recyclable.category}</Typography>}
        <Chip label={recyclable.form ?? '?'} sx={{ height: 'unset', py: 0.25, px: 0.25, mt: 0.5, width: 'fit-content', borderRadius: 2, background: BrandColor.ACCENT_BLUE, color: 'white', fontSize: '0.65rem' }} />
        {isRejected &&
          <Box sx={{ mt: 0.5, flexGrow: 1, alignItems: 'center', width: '100%', position: 'relative', display: 'flex', flexDirection: 'row', borderBottomLeftRadius: 2, borderBottomRightRadius: 2, overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch', mr: 1 }}>
              <ErrorOutlineOutlined sx={{ color: theme.palette.error.light, fontSize: '1rem' }}/>
            </Box>
            <Typography color="text.primary" sx={{ fontSize: '0.75rem' }}>{recyclable.statusReason}</Typography>
          </Box>
        }
      </Box>
      {!isLast && <Box sx={{ position: 'absolute', bottom: 0, left: '5%', height: '1px', width: '90%', background: '#eee' }} />}
    </Box>
    {isLoading && <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 1, background: 'rgba(255,255,255,0.2)' }}><CircularProgress /></Box>}
  </Box>
}

export default RecycableListItem
