import { Box, Typography } from '@mui/material'

import type { Reward } from '~/types/reward/Reward'

interface RewardsListItemHeaderProps {
  reward: Reward
  brand?: string
}

const RewardListItemHeader: React.FC<RewardsListItemHeaderProps> = ({ reward, brand }) => {
  const getSafeBrand = (): string | undefined => {
    const itemBrand = reward?.prize?.brand ?? brand

    return itemBrand?.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
  }

  return <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    {getSafeBrand() && <Box
      component={'img'}
      src={`/assets/images/brands/${getSafeBrand()}.svg`}
      sx={{ mr: 2, maxWidth: 75, maxHeight: 75 }}
    />}
    <Typography variant='h6' gutterBottom sx={{ fontSize: 14, mb: 0 }}>{reward?.prize?.name}</Typography>
  </Box>
}

export default RewardListItemHeader
