import { createListenerMiddleware } from '@reduxjs/toolkit'

import { setLocalStorageItem } from '~/helpers/localStorage'
import type { RootState } from '~/redux/reducers/root'

import { resetNextToken as resetRewardsNextToken } from '../myRewards/rewardSlice'
import { resetNextToken as resetDepositsNextToken } from '../myDeposits/myDepositsSlice'
import { addNotification } from '../notifications/notificationSlice'
import { getRecyclables } from '../recyclables/actions'
import { getMyDeposits } from '../myDeposits/actions'
import { getMyRewards } from '../myRewards/actions'
import { resetRecyclables } from '../recyclables/recyclableSlice'

import { updatePromotion } from './authSlice'

export const promotionSwitchMiddleware = createListenerMiddleware()

promotionSwitchMiddleware.startListening({
  actionCreator: updatePromotion,
  effect: async (action, listenerApi) => {
    const userId = (listenerApi.getState() as RootState).auth.cognitoUser?.sub

    listenerApi.dispatch(resetRewardsNextToken())
    listenerApi.dispatch(resetDepositsNextToken())
    listenerApi.dispatch(resetRecyclables())

    void listenerApi.dispatch(getRecyclables({ promotionId: action.payload.promotionId }))
    void listenerApi.dispatch(getMyDeposits({ promotionId: action.payload.promotionId }))
    void listenerApi.dispatch(getMyRewards({ promotionId: action.payload.promotionId }))

    setLocalStorageItem(`s2r-promotion-${userId}`, action.payload.promotionId)

    listenerApi.dispatch(addNotification({
      type: 'success',
      message: `Switched to ${action.payload.schemeName} scheme`,
      duration: 2000,
    }))
  },
})
