import application from './application'

export const submissionProcessorApi = import.meta.env.VITE_SUBMISSION_PROCESSOR_API as string ?? null

export const amplify = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id: application.awsCognitoIdentityPoolID,
  aws_cognito_region: 'eu-west-1',
  aws_content_delivery_bucket_region: 'eu-west-1',
  aws_user_pools_id: application.awsUserPoolsID,
  aws_user_pools_web_client_id: application.awsUserPoolsWebClientID,
  oauth: {},
  API: {
    endpoints: [
      {
        name: 'submission',
        endpoint: submissionProcessorApi,
      },
    ],
  },
  Storage: {
    // See: https://github.com/aws-amplify/amplify-js/issues/329
    // We will leave S2R as the default bucket for now
    AWSS3: {
      bucket: application.storageS2RBucket,
      region: 'eu-west-1',
    },
  },
  computeChecksums: true,
  correctClockSkew: true,
}
