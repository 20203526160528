import Bugsnag from '@bugsnag/browser'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { RecyclableStatus } from '~/constants/recyclable'
import RecyclableService from '~/services/RecyclableService'
import { isNotifiable } from '~/types/guards/errors'
import type { Recyclable } from '~/types/recyclable/Recyclable'

export const getRecyclables = createAsyncThunk<Recyclable[], { promotionId: string, init?: { signal?: AbortSignal } }>(
  'recyclables/getRecyclables',
  async ({ promotionId, init }) => {
    return await RecyclableService.getRecyclables(promotionId, init)
  },
)

export const deleteRecyclables = createAsyncThunk<{
  deletedRecyclableIds: string[]
  failedRecyclableIds: string[]
  status: RecyclableStatus
}, { recyclableIds: string[], status: RecyclableStatus }>(
  'recyclables/deleteRecyclables',
  async ({ recyclableIds, status }) => {
    const response = await Promise.all(recyclableIds.map(async recyclableId => {
      try {
        await RecyclableService.deleteRecyclable(recyclableId)
        return { status: 'fulfilled', value: recyclableId }
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
        return { status: 'rejected', value: recyclableId, reason: error }
      }
    })) as Array<{ status: 'fulfilled' | 'rejected', value: string, reason?: Error }>

    const fulfilled = response.filter(({ status }) => status === 'fulfilled')
    const rejected = response.filter(({ status }) => status === 'rejected') as Array<{ status: 'fulfilled' | 'rejected', value: string, reason: Error }>

    return {
      deletedRecyclableIds: fulfilled.map(({ value }) => value),
      failedRecyclableIds: rejected.map(({ reason }) => reason.message),
      status,
    }
  },
)
