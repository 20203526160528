import Bugsnag from '@bugsnag/js'
import { Box, Typography, Button } from '@mui/material'
import { differenceInMinutes } from 'date-fns'
import type QrScannerLibrary from 'qr-scanner'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { startsWith } from 'lodash'

import QRCodeScanner from '~/components/QRCodeScanner/QRCodeScanner'
import { schemes } from '~/config/schemes'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import RecyclingPointService from '~/services/RecyclingPointService'
import type { DepositInformation } from '~/types/deposit/customerDeposit'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import { ROUTE_ACCOUNT_CAMERA_PERMISSIONS } from '~/routes/Routes'

interface RecyclingPointScanProps {
  onNextStep: (values?: Partial<DepositInformation>) => Promise<void>
}

enum RecyclingPointScanState {
  SCAN = 'SCAN',
  ERROR = 'ERROR',
}

const RecyclingPointScanStep: React.FC<RecyclingPointScanProps> = ({ onNextStep }) => {
  const [scanState, setScanState] = useState<RecyclingPointScanState>(RecyclingPointScanState.SCAN)
  const abort = useRef(new AbortController())
  const dispatch = useAppDispatch()
  const scannedRecyclingPoint = useSelector((state: RootState) => state.deposit.recyclingPoint)

  useEffect(() => {
    // If the bin has been scanned using the camera of the device automatically set the recycling point
    if (scannedRecyclingPoint?.point && scannedRecyclingPoint?.scannedAt && differenceInMinutes(new Date(), new Date(scannedRecyclingPoint.scannedAt)) < 5) {
      try {
        checkRecyclingPoint(scannedRecyclingPoint.point.depositBinId)
        void onNextStep({ recyclingPoint: scannedRecyclingPoint.point, qrcode: null })
      } catch (error) {
        // If the point is invalid, do nothing
      }
    }

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const checkRecyclingPoint = (recyclingPointId: string): void => {
    if (!startsWith(recyclingPointId, Scan2RecyclePromotion.scheme.recyclingPointPrefix)) {
      const scheme = schemes.find(scheme => startsWith(recyclingPointId, scheme.recyclingPointPrefix))

      if (scheme) {
        dispatch(addNotification({ type: 'error', message: `Please check you are using the correct recycling point. This is for ${scheme?.shortName} items only` }))
      }

      throw new Error('Invalid recycling point')
    }
  }

  const onScan = async (code: QrScannerLibrary.ScanResult, qrcode: Blob | null): Promise<{ valid: boolean | null }> => {
    const recyclingPointId = code.data.split('=')[1]

    try {
      checkRecyclingPoint(recyclingPointId)
    } catch (error) {
      return { valid: false }
    }

    // If the QR code doesn't contain a recycling point ID, return false
    if (!recyclingPointId) return { valid: false }

    try {
      const recyclingPoint = await RecyclingPointService.getRecyclingPoint(Scan2RecyclePromotion.id, recyclingPointId, { signal: abort.current.signal })
      void onNextStep({ recyclingPoint, qrcode })
      // Only return true if the recycling point is known to be valid
      return { valid: true }
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
      if (!isAbortError(error)) dispatch(addNotification({ type: 'error', message: 'Unable to fetch recycling point' }))
      if ((error as Record<string, any>).status) {
        const status: number = (error as Record<string, any>).status
        // If we are certain that the QR code is invalid, return false
        if ([400, 403, 500].includes(status)) return { valid: false }
      }
    }

    // If it is unclear if the QR code is valid or not, return null
    return { valid: null }
  }

  const onCameraFail = (): void => {
    setScanState(RecyclingPointScanState.ERROR)
  }

  switch (scanState) {
    case RecyclingPointScanState.SCAN:
      return <>
        <Box sx={{ my: 2 }}>
          <Typography variant="body1">Scan the QR code on the recycling point</Typography>
        </Box>
        <Box sx={{ position: 'relative', left: { xxs: -16, sm: -24, md: 0 }, width: { xxs: 'calc(100% + 32px)', sm: 'calc(100% + 48px)', md: '100%' }, display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'hidden' }}>
            <QRCodeScanner context={Scan2RecyclePromotion.id} onScan={onScan} onCameraFail={onCameraFail} />
          </Box>
        </Box>
      </>
  }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 800, mt: 3 }}>
      <Typography variant="h4">Camera Failed</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>Please make sure you give us the necessary permissions to be able to use your devices camera.</Typography>
      <Button variant='contained' component={RouterLink} to={ROUTE_ACCOUNT_CAMERA_PERMISSIONS} sx={{ width: '100%', mt: 2 }}>Check Camera Permissions</Button>
    </Box>
  )
}

export default RecyclingPointScanStep
