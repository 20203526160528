import { type AnyAction, combineReducers } from 'redux'
import type { Reducer } from '@reduxjs/toolkit'

import auth from '../features/auth/authSlice'
import notification from '../features/notifications/notificationSlice'
import recyclable from '../features/recyclables/recyclableSlice'
import deposit from '../features/deposits/depositSlice'
import myRewards from '../features/myRewards/rewardSlice'
import myDeposits from '../features/myDeposits/myDepositsSlice'

import { signOut } from '../features/auth/signOut'

const appReducer = combineReducers({
  auth,
  notification,
  recyclable,
  deposit,
  myRewards,
  myDeposits,
})

export type RootState = ReturnType<typeof appReducer>

const rootReducer: Reducer = (state: RootState, action: AnyAction): RootState => {
  let appState: RootState | undefined = state

  if (action.type === signOut.fulfilled.type) {
    appState = undefined
  }

  return appReducer(appState, action)
}

export default rootReducer
