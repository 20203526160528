import Bugsnag from '@bugsnag/js'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { Storage } from 'aws-amplify'
import { Helmet } from 'react-helmet-async'

import application from '~/config/application'
import { depositSteps } from '~/config/deposit'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { getDistanceBetween2GeographicPoints, getGeoLocationWithinDistanceThreshold } from '~/helpers/geolocation/getDistanceBetweenGeographicPoints'
import { getStorageUri } from '~/helpers/s3'
import { useGeolocationWatcher } from '~/hooks/useGeolocationWatcher'
import { addItemToDeposit, removeAllItemsFromDeposit, removeItemFromDeposit, resetDeposit } from '~/redux/features/deposits/depositSlice'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { clearSelectedRecyclables } from '~/redux/features/recyclables/recyclableSlice'
import { ROUTE_RECYCLABLES } from '~/routes/Routes'
import DepositService from '~/services/DepositService'
import { type DepositInformation, DepositStep, type ValidatedDepositInformation } from '~/types/deposit/customerDeposit'
import { DistanceUnits } from '~/types/DistanceUnits'
import { isNotifiable } from '~/types/guards/errors'
import type { Recyclable } from '~/types/recyclable/Recyclable'

import ConfirmStep from './Steps/ConfirmStep/ConfirmStep'
import LocationStep from './Steps/LocationStep'
import RecyclingPointScanStep from './Steps/RecyclingPointScanStep'
import ResultStep from './Steps/ResultStep'
import SelectRecyclablesStep from './Steps/SelectRecyclablesStep'

const intialState: DepositInformation = {
  location: null,
  recyclingPoint: null,
  qrcode: null,
}

const DepositView: React.FC = () => {
  const [depositInformation, setDepositInformation] = useState<DepositInformation>({ ...intialState })
  const [step, setStep] = useState<DepositStep>(depositSteps[0])
  const recyclables = useSelector((state: RootState) => state.recyclable.recyclables.accepted)
  const depositRecyclables = useSelector((state: RootState) => state.deposit.items)
  const activeUserPromotion = useSelector((state: RootState) => state.auth.promotionId)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isWatching, watchGeolocation, stopWatchingGeolocation, getGeoPointSamples } = useGeolocationWatcher()

  useEffect(() => {
    try {
      watchGeolocation()
    } catch (error) {}

    return () => {
      dispatch(removeAllItemsFromDeposit())
      stopWatchingGeolocation()
    }
  }, [])

  useEffect(() => {
    if (depositInformation.recyclingPoint && depositInformation.recyclingPoint.campaignId !== activeUserPromotion) {
      cancelDeposit(false)
    }
  }, [activeUserPromotion])

  const cancelDeposit = (redirect: boolean = true): void => {
    dispatch(resetDeposit())

    if (redirect) {
      navigate(ROUTE_RECYCLABLES)
    } else {
      const step = depositInformation.location
        ? depositSteps[1]
        : depositSteps[0]
      setStep(step)
    }
  }

  const uploadScannedQRCode = async (blob: Blob): Promise<{
    uri: string
    key: string
  }> => {
    const id = uuid()
    const filename = `${format(new Date(), 'yyyy-MM-dd-')}${id}.jpg`
    const bucket = application.binScanS2RBucket

    const response = await Storage.put(filename, blob, {
      contentType: 'application/jpg',
      level: 'public',
      bucket,
    })

    const uri = getStorageUri(bucket, filename)

    return {
      uri,
      key: response.key,
    }
  }

  const validateDeposit = (qrcodeUpload: { uri: string, key: string } | null): ValidatedDepositInformation => {
    if (!depositInformation.recyclingPoint) throw new Error('Recycling point is not set')

    const twoMiles = 3219
    const recyclingPointCoordinates = { latitude: depositInformation.recyclingPoint?.latitude, longitude: depositInformation.recyclingPoint?.longitude }
    let location = getGeoLocationWithinDistanceThreshold(
      recyclingPointCoordinates,
      getGeoPointSamples(),
      twoMiles,
    ) ?? depositInformation.location

    if (location?.accuracy && depositInformation?.location?.accuracy) {
      if (location.accuracy > depositInformation.location.accuracy) {
        location = depositInformation.location
      }
    }

    if (location && !location?.isGps) {
      const distance = getDistanceBetween2GeographicPoints(location, recyclingPointCoordinates, DistanceUnits.MILES)
      if (distance > 3) {
        location = null
      }
    }

    return {
      recyclingPoint: depositInformation.recyclingPoint,
      location,
      recyclables: depositRecyclables,
      scannedBinCode: {
        uploadStatus: qrcodeUpload ? 'success' : 'fail',
        uri: qrcodeUpload?.uri,
        key: qrcodeUpload?.key,
        external: qrcodeUpload === null,
      },
      clickTime: new Date().valueOf(),
      binScannedAt: new Date(),
      locationServicesEnabled: Boolean(location),
      hasBestLocation: Boolean(location),
    }
  }

  const createDeposit = async (): Promise<void> => {
    let qrcodeUpload = null

    try {
      if (depositInformation.qrcode) {
        qrcodeUpload = await uploadScannedQRCode(depositInformation.qrcode)
      }
    } catch (error) {
      if (isNotifiable(error)) Bugsnag.notify(error)
    }

    const validatedDeposit = validateDeposit(qrcodeUpload)

    await DepositService.createDeposit(Scan2RecyclePromotion.id, validatedDeposit)
    dispatch(clearSelectedRecyclables())
  }

  const onNextStep = async (values?: Partial<DepositInformation>): Promise<void> => {
    const currentStepIndex = depositSteps.indexOf(step)
    const nextStep = depositSteps[currentStepIndex + 1]

    if (!isWatching && nextStep === DepositStep.SCAN_RECYCLING_POINT) {
      watchGeolocation()
    }

    if (values) {
      setDepositInformation({ ...depositInformation, ...values })
    }

    if (nextStep) {
      setStep(nextStep)
    }
  }

  const onPreviousStep = (): void => {
    const currentStepIndex = depositSteps.indexOf(step)
    const previousStep = depositSteps[currentStepIndex - 1]

    if (previousStep) {
      setStep(previousStep)
    }
  }

  const handleSelectRecyclable = (recyclable: Recyclable): void => {
    if (depositRecyclables.find(item => item.bankableId === recyclable.bankableId)) {
      dispatch(removeItemFromDeposit(recyclable))
    } else {
      if (Scan2RecyclePromotion.maxDepositItems && depositRecyclables.length >= Scan2RecyclePromotion.maxDepositItems) {
        dispatch(addNotification({ type: 'error', message: `There is a limit of ${Scan2RecyclePromotion.maxDepositItems} items in a single deposit` }))
      } else {
        dispatch(addItemToDeposit(recyclable))
      }
    }
  }

  const renderStep = (): JSX.Element => {
    switch (step) {
      case DepositStep.SCAN_RECYCLING_POINT:
        return <RecyclingPointScanStep onNextStep={onNextStep} />
      case DepositStep.SELECT_RECYCLABLES:
        return <SelectRecyclablesStep recyclables={recyclables} selectedRecyclables={depositRecyclables} onSelect={handleSelectRecyclable} onNextStep={onNextStep} onCancelDeposit={cancelDeposit} />
      case DepositStep.CONFIRM:
        return <ConfirmStep deposit={depositInformation} recyclables={depositRecyclables} onCreateDeposit={createDeposit} onNextStep={onNextStep} onPreviousStep={onPreviousStep} />
      case DepositStep.RESULT:
        return <ResultStep />
    }

    return <LocationStep onNextStep={onNextStep} />
  }

  return <>
    <Helmet>
      <title>Deposit | Recycle at Boots</title>
    </Helmet>
    {renderStep()}
  </>
}

export default DepositView
