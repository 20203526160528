import { Box, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'

interface RewardSelectExpiryProps {
  expiry: string
  duration: Duration
}

const RewardSelectExpiry: React.FC<RewardSelectExpiryProps> = ({ duration, expiry }) => {
  const expiryDate = parseISO(expiry)

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (duration.years || duration.months || duration.days) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'white', mb: 1, p: 1 }}>
      <Typography sx={{ fontSize: { xxs: 20, sm: 25, md: 30 }, textAlign: 'center', fontWeight: 600 }}>
        {format(expiryDate, 'dd MMM yyyy HH:mm')}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: { xxs: 12, sm: 'unset' } }}>
        Please choose before the above date
      </Typography>
    </Box>
  }

  return <Typography sx={{ fontSize: { xxs: 40, md: 60 }, textAlign: 'center', fontFamily: 'Roboto Mono', fontWeight: 600, background: 'white', mb: 1, p: 1 }}>
    {duration.hours?.toString().padStart(2, '0')}:{duration.minutes?.toString().padStart(2, '0')}:{duration.seconds?.toString().padStart(2, '0')}
    <Typography variant="body2">
        Time remaining
      </Typography>
  </Typography>
}

export default RewardSelectExpiry
