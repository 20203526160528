import Bugsnag from '@bugsnag/js'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { differenceInMilliseconds, parseISO } from 'date-fns'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { removeActiveReward } from '~/redux/features/myRewards/rewardSlice'
import { getMyRewards } from '~/redux/features/myRewards/actions'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import PrizeService from '~/services/PrizeService'
import { isAbortError, isNotifiable } from '~/types/guards/errors'
import type { Prize } from '~/types/Prize'
import type { Reward } from '~/types/reward/Reward'

import MyRewards from './MyRewards'

const MyRewardsView: React.FC = () => {
  const [selections, setSelections] = useState<Record<string, Prize[]>>({})
  const { rewards, activeRewards, nextToken, fetching, fetched } = useSelector((state: RootState) => state.myRewards)
  const abort = useRef(new AbortController())
  const dispatch = useAppDispatch()
  const timers = useRef<Array<{ rewardId: string, timer: NodeJS.Timer }>>([])

  const handleVisibilityChange = (): void => {
    if (document.visibilityState === 'visible') {
      verifyActiveRewards()
      void fetchRewards(true)
    }
  }

  const verifyActiveRewards = (): void => {
    activeRewards.forEach((activeReward) => {
      const existingTimer = timers.current.find((timer) => timer.rewardId === activeReward.rewardId)

      if (existingTimer) return

      const lockedUntil = parseISO(activeReward.lockedUntil)

      const timer = setTimeout(() => {
        removeActiveReward(activeReward.rewardId)
      }, differenceInMilliseconds(lockedUntil, new Date()))

      timers.current.push({
        rewardId: activeReward.rewardId,
        timer,
      })
    })
  }

  useEffect(() => {
    void fetchRewards()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      if (abort.current) {
        abort.current.abort()
      }

      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    verifyActiveRewards()
  }, [activeRewards])

  const fetchRewards = async (reset?: boolean): Promise<void> => {
    if (fetching && fetched) return

    try {
      await dispatch(getMyRewards({ promotionId: Scan2RecyclePromotion.id, init: { signal: abort.current.signal, ...(!fetched || reset ? {} : { nextToken }) } })).unwrap()
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(addNotification({ type: 'error', message: 'Unable to fetch rewards' }))
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    }
  }

  const fetchRewardSelections = async (claimId: string): Promise<void> => {
    const items = await PrizeService.getRewardSelections(Scan2RecyclePromotion.id, claimId)
    setSelections({
      ...selections,
      [claimId]: items,
    })
  }

  const handleRewardSelect = async (reward: Reward, prizeId: string): Promise<void> => {
    await PrizeService.setRewardSelection(Scan2RecyclePromotion.id, reward.rewardId, prizeId)
    await fetchRewards(true)
  }

  return <>
    <Helmet>
      <title>My Rewards | Recycle at Boots</title>
    </Helmet>
    <MyRewards
      rewards={rewards}
      activeRewards={activeRewards}
      selections={selections}
      fetching={fetching}
      fetched={fetched}
      hasMore={!!nextToken}
      loadMore={fetchRewards}
      fetchRewardSelections={fetchRewardSelections}
      onRewardSelect={handleRewardSelect}
    />
  </>
}

export default MyRewardsView
