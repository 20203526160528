import { parseISO } from 'date-fns'

import deposit from '~/config/deposit'
import { RecyclableStatus } from '~/constants/recyclable'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import type { Recyclable } from '~/types/recyclable/Recyclable'

import { getLocalStorageItem } from './localStorage'

export const getAcceptedStatuses = (): RecyclableStatus[] => {
  const statuses = [RecyclableStatus.ACCEPTED]

  if (deposit.allowedRecyclableStatus === RecyclableStatus.TAGGED) {
    statuses.push(RecyclableStatus.TAGGED)
  }

  return statuses
}

export const resolveRecyclableActionStatus = (item: Recyclable): RecyclableStatus => {
  return item.status !== RecyclableStatus.TAGGED
    ? item.status
    : deposit.allowedRecyclableStatus === RecyclableStatus.TAGGED ? RecyclableStatus.ACCEPTED : RecyclableStatus.TAGGED
}

export const hasSeenRecyclableChecklistToday = (): boolean => {
  const today = new Date()
  const key = Scan2RecyclePromotion.scheme.shortName === 'Blister' ? 'lastSeenBlisterRecyclableChecklist' : 'lastSeenRecyclableChecklist'
  const lastSeen = getLocalStorageItem(key)

  if (!lastSeen) return false

  const lastSeenDate = parseISO(lastSeen)

  return lastSeenDate.getDate() === today.getDate()
}
