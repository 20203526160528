const localStorageSupported = 'localStorage' in window && localStorage

export const getLocalStorageItem = (key: string): string | null => {
  if (!localStorageSupported) {
    return null
  }

  return localStorage.getItem(key)
}

export const setLocalStorageItem = (key: string, value: string): void => {
  if (!localStorageSupported) {
    return
  }

  localStorage.setItem(key, value)
}

export const removeLocalStorageItem = (key: string): void => {
  if (!localStorageSupported) {
    return
  }

  localStorage.removeItem(key)
}
