import application from '~/config/application'
import type { GoogleGeolocationCoordinates } from '~/types/GoogleGeolocationCoordinates'

import Service from './Service'

class GeolocationService extends Service {
  constructor () {
    super('https://www.googleapis.com/geolocation/v1/')
  }

  public async getLocation (): Promise<GoogleGeolocationCoordinates> {
    const response = await this.request(`geolocate?key=${application.googleApiKey}`, {
      method: 'POST',
    })

    return response.status === 200 ? await response.json() : await Promise.reject(response)
  }
}

export default new GeolocationService()
