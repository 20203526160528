import { styled } from '@mui/material'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { green, red } from '@mui/material/colors'

import { BrandColor } from '~/config/theme'

export const GreenCheckIcon = styled(CheckCircle)({
  background: BrandColor.WHITE,
  color: green[500],
  borderRadius: 24,
})

export const RedCancelIcon = styled(Cancel)({
  background: BrandColor.WHITE,
  color: red[500],
  borderRadius: 24,
})

export enum CircleIcon {
  CHECK = 'CHECK',
  CROSS = 'CROSS',
  BOTH = 'BOTH',
}
