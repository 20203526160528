import { Box, Link, List, ListItem, ListItemText, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import AppStoreBlock from '~/components/AppStore/AppStoreBlock'
import { bothAppsEnabled } from '~/helpers/nativeApp'
import { ROUTE_RECYCLABLE_TYPES, ROUTE_RECYCLING_POINTS, ROUTE_SIGN_UP, ROUTE_TERMS } from '~/routes/Routes'

export default [
  {
    question: 'How does the Scheme work?',
    answer: <>
      {!bothAppsEnabled()
        ? <Typography variant="body1" gutterBottom>To participate in the scheme, you will need to sign up for a Recycle at Boots account <Link component={RouterLink} to={ROUTE_SIGN_UP}>here</Link></Typography>
        : <>
          <Typography variant="body1" gutterBottom>To participate in the scheme, you will need to download the Recycle at Boots App, and then create an account.</Typography>
            <Box sx={{ py: 2 }}>
              <AppStoreBlock />
            </Box>
          </>
      }
      <Typography variant="body1" gutterBottom>Once the account is created, follow these steps:</Typography>
      <List dense>
        <ListItem sx={{ display: 'block' }}><Typography>Upload images of eligible Recyclables into your account - for a full list of eligible recyclables go to your account and select 'Recycling Types'</Typography></ListItem>
        <ListItem><Typography>Go to 'Recyclables'</Typography></ListItem>
        <ListItem><Typography>Select 'Add Recyclables' and take a picture of the product (pictures must be of the whole product with the brand and product name shown - do not upload pictures of the barcode)</Typography></ListItem>
        <ListItem><Typography>Use the drop-down select to label your items with the brand, product, and packaging type</Typography></ListItem>
      </List>
      <Typography variant="body1" gutterBottom>Images are reviewed and verified within 24 hours Monday - Friday, excluding UK public holidays.</Typography>
      <Typography variant="body1" gutterBottom>Once these are verified you will see them in your approved section in 'Recyclables', they are ready to deposit.</Typography>
      <Typography variant="body1" gutterBottom>To deposit the items:</Typography>
      <List dense>
        <ListItem><Typography>Step 1 - Visit a participating store</Typography></ListItem>
        <ListItem sx={{ display: 'block' }}><Typography>Step 2 - Scan the QR code on the applicable scheme recycling points in a <Link component={RouterLink} to={ROUTE_RECYCLING_POINTS}>participating store</Link></Typography></ListItem>
        <ListItem><Typography>Step 3 - Select at least 5 items to deposit in one go for Health Beauty and Wellness and 15 for Blister Pack</Typography></ListItem>
        <ListItem><Typography>Step 4 - Confirm the deposit by selecting deposit on the screen</Typography></ListItem>
        <ListItem><Typography>Step 5 - Deposit items</Typography></ListItem>
      </List>
      <Typography variant="body1" gutterBottom>The deposit will be verified, and if successful you will receive the reward voucher in your account.</Typography>
      <Typography variant="body1">If you don't see the reward voucher immediately, please wait for a notification or check the 'deposits' section in your account.</Typography>
      <Typography variant="body1">If the deposit wasn't approved, check your email including junk/spam folders for an email from us, stating why.</Typography>
    </>,
  },
  {
    question: 'How do I claim my Advantage Card Points?',
    answer: <>
      <List dense>
        <ListItem><Typography>Step 1: Reveal the voucher <strong>only when ready to use in-store</strong> along with your Boots Advantage card.</Typography></ListItem>
        <ListItem><Typography>Step 2: Spend over £10 in one transaction and use the voucher within 30 minutes - keep an eye on the countdown timer displayed.</Typography></ListItem>
        <ListItem>
          <Box>
            <Typography gutterBottom>Step 3: How to redeem</Typography>
            <Typography gutterBottom>Present the barcode at the checkout till, along with your Advantage card and your points will be added on when you spend £/€10 or more in one transaction.</Typography>
          </Box>
        </ListItem>
      </List>
      <Typography variant="body1" gutterBottom>The voucher must be claimed within 3 days (incl. day of issue), in a participating store, subject to store opening times. For full terms and conditions please <Link href="https://www.boots.com/webapp/wcs/stores/servlet/CategoryDisplay?storeId=11352&categoryId=1602601&catalogId=28501&newPreviewSession=true&p13n_test=true&p13n_testcontext=0">click here</Link> and see Coupon: Offer 32.</Typography>
      <Typography variant="body1" gutterBottom>The barcode voucher can only be used by the Boots Scan2Recycle account holder to which it was awarded, and can only be used in one transaction, any misuse of the voucher will be deemed a breach of the <RouterLink to={ROUTE_TERMS}>Terms and Conditions</RouterLink>.</Typography>
      <Typography variant="body1">Reward vouchers can only be redeemed in a <Link component={RouterLink} to={ROUTE_RECYCLING_POINTS}>participating store</Link>.</Typography>
    </>,
  },
  {
    question: 'How do I create an account?',
    answer: <>
      <List dense>
        <ListItem><Typography>Select 'Sign-up' on the home screen and enter the following details:</Typography></ListItem>
        <ListItem sx={{ display: 'block' }}>
          <List dense>
            <ListItem><Typography>Title</Typography></ListItem>
            <ListItem><Typography>First Name</Typography></ListItem>
            <ListItem><Typography>Surname</Typography></ListItem>
            <ListItem><Typography>Email Address</Typography></ListItem>
            <ListItem><Typography>Address</Typography></ListItem>
            <ListItem><Typography>Mobile Number</Typography></ListItem>
            <ListItem><Typography>Advantage Card Number</Typography></ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: 'block' }}><Typography>A <strong>code will be sent to the email</strong>, you signed up with.</Typography></ListItem>
        <ListItem><Typography>Enter the code into the verification box on the website, and then create a password.</Typography></ListItem>
        <ListItem><Typography>Once completed you will be registered.</Typography></ListItem>
      </List>
    </>,
  },
  {
    question: 'How do I upload images to my account?',
    answer: <>
      <Typography variant="body1" gutterBottom>Once your account is created, follow these steps:</Typography>
      <List dense>
        <ListItem><Typography>Tap the Recyclables icon in the bottom navigation bar</Typography></ListItem>
        <ListItem><Typography>Select 'Add Recyclable'</Typography></ListItem>
        <ListItem><Typography>Read and check the boxes in the checklist</Typography></ListItem>
        <ListItem><Typography>Click 'Next'</Typography></ListItem>
        <ListItem><Typography>Click 'Tap here to take a photo'</Typography></ListItem>
      </List>
      <Typography variant="body1" gutterBottom>When prompted, allow access to your camera then:</Typography>
      <List dense>
        <ListItem>
          <Box>
            <Typography variant="body1">Take a photo of the item you want to recycle (one at a time) on a light plain background</Typography>
            <Typography variant="caption">(pictures must be of the front of the whole product with the brand and product name shown - do not upload pictures of the barcode, blister packs must be pictured foil side up and completely empty)</Typography>
          </Box>
        </ListItem>
        <ListItem>
          <Typography variant="body1">Photograph one item at a time and make sure it's the main packaging, not the outer one (e.g., a cardboard box) if the product has one</Typography>
        </ListItem>
        <ListItem sx={{ display: 'block' }}>
          <Typography variant="body1" gutterBottom>Use the drop-down select to label your items with the brand, product, and packaging type</Typography>
        </ListItem>
      </List>
      <Typography variant="body1" gutterBottom>If you cannot find your category or type of product in the drop-down list, you will not be able to label it, as it is not a qualifying item and it will not count towards your items required for the Reward.</Typography>
      <Typography variant="body1" gutterBottom>Any item labelled incorrectly will be rejected, you can re-upload again, using the correct description.</Typography>
    </>,
  },
  {
    question: 'When will my Recyclables be verified?',
    answer: <>
      <Typography variant="body1" gutterBottom>Images are verified within 24 hours Monday - Friday, excluding UK public holidays.</Typography>
      <List dense>
        <ListItem><Typography>Recyclables that require verification are located in your pending recyclables</Typography></ListItem>
        <ListItem><Typography>Approved recyclables are in your approved recyclable section (and available to deposit)</Typography></ListItem>
        <ListItem><Typography>Rejected Recyclables appear in your rejected items, and a reason given for the rejection will be shown</Typography></ListItem>
      </List>
    </>,
  },
  {
    question: 'Which stores are participating and where can I find the recycling points?',
    answer: <>
      <Typography variant="body1" gutterBottom>Health, beauty, and wellness recycling points can usually be found near No7 counters or front of the store.</Typography>
      <Typography variant="body1" gutterBottom>Blister pack recycling points are located near the Pharmacy and Healthcare counters (limited stores only).</Typography>
      <Typography variant="body1">For participating stores <Link component={RouterLink} to={ROUTE_RECYCLING_POINTS}>click here</Link>.</Typography>
    </>,
  },
  {
    question: 'Where and how do I deposit my items?',
    answer: <>
      <List dense>
        <ListItem><Typography>Locate the correct recycle point for the Recycle at Boots Scheme you are using to deposit items</Typography></ListItem>
        <ListItem><Typography>Scan the QR code on the applicable Recycle at Boots recycling point</Typography></ListItem>
        <ListItem><Typography>Select at least 5 items to deposit in one go for the <strong>Health and Beauty Scheme</strong> (you will not receive a reward for less than 5, or if you deposit one item at a time)</Typography></ListItem>
        <ListItem><Typography>Select at least 15 items to deposit in one go for the <strong>Blister Pack Scheme</strong> (you will not receive a reward for less than 15, or if you deposit one item at a time)</Typography></ListItem>
        <ListItem><Typography>Confirm the deposit by selecting deposit on the screen</Typography></ListItem>
        <ListItem><Typography>Deposit items into the applicable Recycle at Boots recycling point in store</Typography></ListItem>
      </List>
      <Typography variant="body1" gutterBottom>The deposit will be verified, and if successful you will receive the reward in your account immediately, an email will also be sent to advise if the reward was issued or not.</Typography>
      <Typography variant="caption">(If you don't see the reward voucher immediately, please wait for a notification or check the 'deposits' section in your account).</Typography>
    </>,
  },
  {
    question: 'How do I upload images of products that are identical (Brand/Make)?',
    answer: <>
      <Typography variant="body1" gutterBottom>If you have multiple identical items, write a number on each of the items i.e. If you have 5 items that are the same, number them from 1 to 5, if you don't have a marker, take a picture of the number of products you have that are the same and upload the equivalent number of images.</Typography>
      <Typography variant="body1">If you have 5 items that are the same brand and product but 2 are one type and 3 another type then, take one image of the 2 products and upload two pictures, then 3 pictures of the 3 together.</Typography>
    </>,
  },
  {
    question: 'How many small dental, make-up, and beauty items do I need?',
    answer: <>
      <Typography variant="body1" gutterBottom>If your products are small dental, make-up, and beauty items that are sold in packs you will need to photograph a minimum of 5 together in one photo, in order for these to qualify as one item.</Typography>
      <Typography variant="body1" gutterBottom>For example, if you have a pack of 5 interdental brushes, include all 5 brushes in one image and deposit them all together for this to count as 1 item. Examples of small items:</Typography>
      <List dense>
        <ListItem><Typography>Interdental flosses</Typography></ListItem>
        <ListItem><Typography>Mini make-up brushes</Typography></ListItem>
        <ListItem><Typography>Make-up sponges</Typography></ListItem>
        <ListItem><Typography>Floss picks</Typography></ListItem>
        <ListItem><Typography>Single dose units (e.g. eye drops)</Typography></ListItem>
        <ListItem><Typography>False Nails & Tray (Nails must be attached to the tray they came on)</Typography></ListItem>
        <ListItem><Typography>False Eyelashes (full lashes) & Tray (Lashes must be attached to the tray they came on)</Typography></ListItem>
      </List>
      <Typography variant="body1">Electric toothbrush heads, toothbrushes for orthodontics and flossing heads only require 1 item, to qualify.</Typography>
    </>,
  },
  {
    question: 'Why is my Product not listed?',
    answer: <>
      <Typography variant="body1" gutterBottom>If the brand is not on the list, choose 'Other' or click 'Brand Not Listed?' and then type in the brand name in the box that appears.</Typography>
      <Typography variant="body1">If the type and form are not listed they not eligible for the scheme.</Typography>
    </>,
  },
  {
    question: 'What products can I recycle?',
    answer: <>
      <Typography variant="body1" gutterBottom>For a full list of products see <Link component={RouterLink} to={ROUTE_RECYCLABLE_TYPES}>here</Link></Typography>
      <Typography variant="body1" gutterBottom>Products that <strong>are not listed are not eligible for the scheme.</strong></Typography>
      <Typography variant="body1">All products will be verified by our team behind the scenes and if we identify discrepancies between the image and the description, it will be rejected.</Typography>
    </>,
  },
  {
    question: 'How many Reward vouchers can I receive?',
    answer: <>
      <Typography variant="body1" gutterBottom>You can only receive 1 voucher reward per day and up to 8 voucher rewards will be given in a calendar month.</Typography>
      <Typography variant="body1" gutterBottom>Each deposit must contain a minimum of 5 approved items in 1 deposit to qualify for the reward.</Typography>
      <Typography variant="body1" gutterBottom>For Blister Pack - you can only receive 1 voucher reward per day and up to 4 voucher rewards will be given in a calendar month.</Typography>
      <Typography variant="body1" gutterBottom>Each deposit must contain the minimum approved items in 1 deposit to qualify for the reward.</Typography>
      <Typography variant="body1" gutterBottom>You can deposit fewer or more items, however, no additional rewards are given no matter how many items are deposited in a day.</Typography>
      <Typography variant="body1" gutterBottom>Deposited items cannot be retrieved or returned once deposited. Please do not remove items from the recycle bins for health and hygiene reasons.</Typography>
      <Typography variant="body1">If the Reward voucher has expired, we are unable to issue a replacement. Reward vouchers can only be used once by the account holder it was awarded to.</Typography>
    </>,
  },
  {
    question: 'Which products are excluded?',
    answer: <>
      <Typography variant="body1" gutterBottom>Any item not in the drop-down list, and for health and safety reasons we cannot accept the following items: aerosol cans, perfume bottles, nail polish bottles, nail polish remover, hair dyes, hair removal cream, hair removal wax; body spray; brow and eyelash tints, and any product or packaging that are labelled with a hazardous or dangerous materials symbol; safety razors and razor blades, disposable razors and razor heads; used PPE (disposable masks, gloves, and visors); blister packs and foils (in health beauty and wellness recycle points); any electrical items such as electric toothbrushes, hairdryers and straighteners; any medical devices, electrical or not, controlled drugs and unused blister packs.</Typography>
      <Typography variant="body1" gutterBottom>In addition to the above, we exclude any item that is:</Typography>
      <List dense sx={{ mb: 0 }}>
        <ListItem><ListItemText>Not beauty, health and wellness, or blister packs or in the drop-down list</ListItemText></ListItem>
        <ListItem><ListItemText>Not used or empty</ListItemText></ListItem>
        <ListItem><ListItemText>Non-qualifying items or items which can be widely recycled</ListItemText></ListItem>
      </List>
    </>,
  },
  {
    question: 'Why is Blister pack recycling not available in my area?',
    answer: <>
      <Typography variant="body1">In October 2023, we launched a trial in selected stores, this initial trial period will help us to understand how best to roll out wider across the UK and make any changes to ensure the scheme is easy to use for both for our customers and stores. We expanded to further stores in February 2024. If further stores are added as Recycle at Boots user you will be one of the first to know.</Typography>
    </>,
  },
  {
    question: 'How do I update personal information and/or remove my account?',
    answer: <>
      <Typography variant="body1" gutterBottom>To update your personal information please send an email to boots@scan2recycle.com with the following information:</Typography>
      <List dense>
        <ListItem><ListItemText>Name on account</ListItemText></ListItem>
        <ListItem><ListItemText>Email address</ListItemText></ListItem>
        <ListItem><ListItemText>Phone number</ListItemText></ListItem>
        <ListItem><ListItemText>Advantage card number</ListItemText></ListItem>
      </List>
      <Typography variant="body1" gutterBottom>Please also confirm the change that you would like to be made, for example, update your email address along with your new email address.</Typography>
      <Typography variant="body1" gutterBottom>To request your account to be deleted please send an email to boots@scan2recycle.com with the following information:</Typography>
      <List dense>
        <ListItem><ListItemText>The email address registered to the account</ListItemText></ListItem>
        <ListItem><ListItemText>Advantage card number</ListItemText></ListItem>
        <ListItem><ListItemText>The reason for the request</ListItemText></ListItem>
        <ListItem><ListItemText>Confirm if you would also like the data associated removed with your account</ListItemText></ListItem>
      </List>
    </>,
  },
  {
    question: 'What happens to the products that are recycled?',
    answer: <>
      <Typography variant="body1" gutterBottom>All packaging that is deposited goes to our recycling partner, MY Group, who is based in the UK to be recycled, reused, or reformed into new products. We guarantee that nothing will end up in landfill or incineration.</Typography>
      <Typography variant="body1" gutterBottom>Hard-to-recycle materials that cannot go through traditional recycling streams are made into other products, such as street furniture or shop fittings. They also go into a proprietary material called 'Storm board'. Originally designed as a replacement for wooden plyboards to help reduce the demand and overuse of forestry products, this versatile material is now used in a huge array of products by many joiners, shop fitters, building merchants, and general home use.</Typography>
      <Typography variant="body1">MY Group offers full traceability - a key element in protecting our seas and environment from plastic waste. You can read more about them <Link href="https://mygroupltd.com/" target="_blank">here</Link>.</Typography>
    </>,
  },
  {
    question: 'Other useful information about the scheme',
    answer: <>
      <Typography variant="body1" gutterBottom>There is currently no end date for the scheme, and we will be providing registered participants with 14 days' notice of any end date to the email address provided on registration.</Typography>
      <Typography variant="body1">In the event of a 14 days' notice period, you will have time to deposit any of your qualifying hard-to-recycle packaging.</Typography>
    </>,
  },
]
