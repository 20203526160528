import { Grid, Typography, type SxProps } from '@mui/material'
import { dinero, toDecimal } from 'dinero.js'

import RouterLink from '~/components/Link/RouterLink'
import { BrandColor } from '~/config/theme'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { currencyFormatter } from '~/helpers/currency'

type ValueFormat = 'currency' | 'number' | 'string'

interface StatTileProps {
  title: string
  to: string
  value: number | string
  valueColor?: string
  subTitle: string
  subValue: string | number
  valueFormat?: ValueFormat
  subValueFormat?: ValueFormat
  sx?: SxProps
}

const StatTile: React.FC<StatTileProps> = ({ title, subTitle, value, subValue, valueColor, to, valueFormat = 'number', subValueFormat = 'string', sx }) => {
  const formatMonetaryValue = (value: number): string => {
    const monetaryValue = dinero({ amount: value, currency: Scan2RecyclePromotion.currency })
    return toDecimal(monetaryValue, currencyFormatter)
  }

  const formatValue = (input: number | string, format: ValueFormat): string | number => {
    switch (format) {
      case 'currency':
        if (typeof input === 'number') return formatMonetaryValue(input)
        return input
      default:
        return input
    }
  }

  return <Grid item xxs={6}>
    <RouterLink href={to} sx={{
      backgroundColor: BrandColor.LIGHT_GREY,
      borderRadius: 4,
      display: 'flex',
      flex: 1,
      p: 2,
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      userSelect: 'none',
      cursor: 'pointer',
      ...sx,
    }}>
      <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xxs: 14, sm: 20, md: 24 } }}>{title}</Typography>
      <Typography variant='h2' sx={{ color: valueColor, fontWeight: 600, fontSize: { xxs: 40, sm: 60, md: 60 } }}>{formatValue(value, valueFormat)}</Typography>
      <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xxs: 12, sm: 14, md: 18 } }}>{subTitle}</Typography>
      <Typography variant="caption">{formatValue(subValue, subValueFormat)}</Typography>
    </RouterLink>
  </Grid>
}

export default StatTile
