import { Box } from '@mui/material'

import type { Prize } from '~/types/Prize'
import type { ActiveReward } from '~/types/reward/ActiveReward'
import type { Reward } from '~/types/reward/Reward'

import RewardList from './RewardsList'

interface MyRewardsProps {
  rewards: Reward[]
  activeRewards: ActiveReward[]
  selections: Record<string, Prize[]>
  fetching: boolean
  fetched: boolean
  hasMore: boolean
  loadMore: () => Promise<void>
  fetchRewardSelections: (claimId: string) => Promise<void>
  onRewardSelect: (reward: Reward, prizeId: string) => Promise<void>
}

const MyRewards: React.FC<MyRewardsProps> = ({
  rewards,
  activeRewards,
  selections,
  fetching,
  fetched,
  hasMore,
  loadMore,
  fetchRewardSelections,
  onRewardSelect,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <RewardList
        rewards={rewards}
        activeRewards={activeRewards}
        selections={selections}
        fetching={fetching}
        fetched={fetched}
        hasMore={hasMore}
        loadMore={loadMore}
        fetchRewardSelections={fetchRewardSelections}
        onRewardSelect={onRewardSelect}
      />
    </Box>
  )
}

export default MyRewards
