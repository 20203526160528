import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import type { Notification } from '~/types/notifications/Notification'

interface NotificationState {
  notifications: Notification[]
}

const initialState: NotificationState = {
  notifications: [],
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: { type: string, payload: Omit<Notification, 'id'> }) => {
      state.notifications.unshift({
        id: uuid(),
        ...action.payload,
      })
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload)
    },
    clearNotifications: (state, action) => {
      state.notifications = state.notifications.filter(notification => notification.id === action.payload)
    },
  },
})

export const { addNotification, removeNotification, clearNotifications } = notificationSlice.actions

export default notificationSlice.reducer
