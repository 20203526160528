import { Box, Button, Link, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import SearchableList from '~/components/Lists/SearchableList'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { ROUTE_DEPOSIT, ROUTE_TERMS } from '~/routes/Routes'
import RecyclableService from '~/services/RecyclableService'
import { isAbortError } from '~/types/guards/errors'
import type { RecyclableType } from '~/types/recyclable/RecyclableType'

import LorealBannerPng from '~/assets/images/offers/loreal/png/banner@1x.png'
import LorealBannerPng2x from '~/assets/images/offers/loreal/png/banner@2x.png'
import LorealBannerWebp from '~/assets/images/offers/loreal/webp/banner@1x.webp'
import LorealBannerWebp2x from '~/assets/images/offers/loreal/webp/banner@2x.webp'

const LorealOfferView: React.FC = () => {
  const [recyclableTypes, setRecyclableTypes] = useState<RecyclableType[]>([])
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
  const abort = useRef<AbortController>(new AbortController())
  const qualifyingItems = useMemo(() => recyclableTypes.filter(({ category }) => category === 'Make-up'), [recyclableTypes])

  useEffect(() => {
    void fetchRecyclableTypes()

    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  const fetchRecyclableTypes = async (): Promise<void> => {
    if (!loadingCategories) setLoadingCategories(true)

    try {
      const response = await RecyclableService.getRecyclableTypes(Scan2RecyclePromotion.id, { signal: abort.current.signal })
      setRecyclableTypes(response)
      setLoadingCategories(false)
    } catch (error) {
      if (!isAbortError(error)) setLoadingCategories(false)
    }
  }

  return <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'flex-start', flexGrow: 1, flexDirection: 'column' }}>
    <Typography variant="h5" gutterBottom sx={{ fontSize: 18 }}>
      £5 off voucher when you purchase 2 products from Maybelline, L’Oréal Paris, NYX Professional makeup, or Essie
    </Typography>
    <Typography variant="body2" gutterBottom>
      When you include 2 or more make-up items in a deposit of 5 or more eligible items.
    </Typography>
    <Box
      component='picture'
      sx={{
        width: { xxs: '100%', sm: 'auto' },
        height: { xxs: 'auto', sm: '300px' },
        margin: '0 auto',
        mb: 1,
        mt: 1,
      }}
    >
      <source srcSet={`${LorealBannerWebp2x} 2x, ${LorealBannerWebp} 1x`} type='image/webp' />
      <source srcSet={`${LorealBannerPng2x} 2x, ${LorealBannerPng} 1x`} type='image/png' />
      <Box component="img" src={LorealBannerPng} alt='Loreal Banner' sx={{ maxWidth: '100%', maxHeight: '100%' }} />
    </Box>
    <Typography variant="caption" gutterBottom>
      <Link component={RouterLink} to={ROUTE_TERMS}>Terms and conditions apply.</Link>
    </Typography>

    <Box sx={{ width: '100%', my: 1 }}>
      <Typography variant="h5" sx={{ fontSize: 18 }} gutterBottom>
        Qualifying Items
      </Typography>

      <SearchableList
        items={qualifyingItems.map(({ subcategory, forms }) => ({ item: subcategory, tags: forms }))}
        loading={loadingCategories}
        listHeight={180}
      />
    </Box>

    <Button component={RouterLink} to={ROUTE_DEPOSIT} variant="contained" fullWidth sx={{ mt: 'auto' }}>
      Deposit
    </Button>
  </Box>
}

export default LorealOfferView
