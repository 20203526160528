import { useEffect, useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, CircularProgress, FormHelperText, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import { promotions } from '~/config/promotion'

import GBFlag from '~/assets/images/flags/gb.svg'
import IEFlag from '~/assets/images/flags/ie.svg'

const countryConfig: Record<string, { label: string, flag: string }> = {
  gb: {
    label: 'UK',
    flag: GBFlag,
  },
  ie: {
    label: 'Ireland',
    flag: IEFlag,
  },
}

interface FormValues {
  country: string
}

interface CountrySelectorProps {
  onNextStep: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  country: z.string({
    invalid_type_error: 'Country is required',
    required_error: 'Country is required',
  }).min(1, 'Country is required'),
}).strict()

const CountrySelector: React.FC<CountrySelectorProps> = ({ onNextStep }) => {
  const [countryCheck, setCountryCheck] = useState(false)
  const countries = [...new Set(promotions.map(promotion => promotion.country))]

  const handleNext = (values: FormValues): void => {
    void onNextStep({
      ...values,
    })
  }

  useEffect(() => {
    if (countries.length === 1) {
      void onNextStep({
        country: countries[0],
      })
    } else {
      setCountryCheck(true)
    }
  }, [])

  const { handleSubmit, getValues, setValue, watch, formState: { errors, isValid } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      country: '',
    },
    mode: 'onChange',
  })

  watch('country')

  const handleCountrySelect = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string): void => {
    setValue('country', value, { shouldValidate: true })
  }

  if (!countryCheck) return <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box>

  return (
    <Box component="form" noValidate sx={{ mt: 6 }} onSubmit={handleSubmit(handleNext)} autoComplete="off">
      <Box>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Country Select</Typography>
      </Box>
      <ToggleButtonGroup
        color="primary"
        value={getValues('country')}
        exclusive
        onChange={handleCountrySelect}
        aria-label="Country"
        sx={{ width: '100%', pt: 4, pb: 2 }}
      >
        {countries.map(country => (<ToggleButton key={country} value={country} sx={{ display: 'flex', width: '100%', flexDirection: 'column', border: 'none', p: 3 }}>
          <Box component="img" src={countryConfig[country.toLowerCase()].flag} sx={{ maxWidth: 300, width: '100%', mb: 2 }} />
          {countryConfig[country.toLowerCase()].label}
        </ToggleButton>))}
      </ToggleButtonGroup>
      <FormHelperText error={true} sx={{ textAlign: 'center' }}>{errors.country?.message}</FormHelperText>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        disabled={!isValid}
        sx={{ mt: 2 }}>Next</Button>
    </Box>
  )
}

export default CountrySelector
