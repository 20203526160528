import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import type { CognitoUser } from '~/types/cognitoUser/CognitoUser'
import type { User, UserSchemes } from '~/types/user/User'

import { checkIfAuthenticated, getUser, getUserSchemes, login, updateUserSchemes } from './actions'
import { signOut } from './signOut'

interface AuthState {
  cognitoUser: CognitoUser | null
  user: User | null
  isAuthenticating: boolean
  isAuthenticated: boolean
  lastLoginAt: number | null
  schemes: UserSchemes
  promotionId: string | null
  signUp: {
    country: string
    campaignId: string
    schemes: UserSchemes
    locale: string
    email: string
    password: string
    title: string
    firstName: string
    lastName: string
    addressLineOne: string
    addressLineTwo?: string
    city: string
    county: string
    postalCode?: string
    mobileNo: string
    loyaltyCardNo: string
    informationOptIn?: boolean
  }
}

const initialState: AuthState = {
  cognitoUser: null,
  user: null,
  isAuthenticating: true,
  isAuthenticated: false,
  lastLoginAt: null,
  schemes: [],
  promotionId: null,
  signUp: {
    country: '',
    campaignId: '',
    schemes: [],
    locale: '',
    email: '',
    password: '',
    title: '',
    firstName: '',
    lastName: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    county: '',
    postalCode: '',
    mobileNo: '',
    loyaltyCardNo: '',
    informationOptIn: false,
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeSignUp: (state, action) => {
      state.signUp = {
        ...state.signUp,
        ...action.payload,
      }
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        firstname: action.payload.firstname,
      }
    },
    updatePromotion: (state, action: PayloadAction<{ promotionId: string, schemeName: string }>) => {
      state.promotionId = action.payload.promotionId
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIfAuthenticated.fulfilled, (state, action) => {
      const { 'custom:userId': userId, 'custom:campaignId': campaignId, ...user } = action.payload

      state.cognitoUser = {
        ...user,
        campaignId: campaignId ?? null,
        userId: userId ?? user.sub,
      }
      state.user = {
        campaignId,
        userId: userId ?? user.sub,
        email: user?.email,
        firstname: user?.given_name,
      }
      state.isAuthenticating = false
      state.isAuthenticated = true
      state.promotionId = Scan2RecyclePromotion.id
    })
    builder.addCase(checkIfAuthenticated.rejected, (state, action) => {
      state.cognitoUser = null
      state.isAuthenticating = false
      state.isAuthenticated = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      const { 'custom:userId': userId, 'custom:campaignId': campaignId, ...user } = action.payload

      state.cognitoUser = {
        ...user,
        campaignId: campaignId ?? null,
        userId: userId ?? user.sub,
      }
      state.user = {
        campaignId,
        userId: userId ?? user.sub,
        email: user?.email,
        firstname: user?.given_name,
      }
      state.lastLoginAt = (new Date()).getSeconds()
      state.isAuthenticating = false
      state.isAuthenticated = true
    })
    builder.addCase(signOut.fulfilled, (state, action) => {
      state.cognitoUser = null
      state.user = null
      state.isAuthenticating = false
      state.isAuthenticated = false
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(getUserSchemes.fulfilled, (state, action) => {
      state.schemes = action.payload
    })
    builder.addCase(updateUserSchemes.fulfilled, (state, action) => {
      state.schemes = action.payload
      state.promotionId = Scan2RecyclePromotion.id
    })
  },
})

export const { storeSignUp, updateUser, updatePromotion } = authSlice.actions

export default authSlice.reducer
