class IsAppUserException extends Error {
  constructor () {
    super('Is app user')

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, IsAppUserException)
    }

    this.name = 'IsAppUserException'
  }
}

export default IsAppUserException
