import { useEffect, useRef, useState } from 'react'
import QrScanner from 'qr-scanner'
import { Box, CircularProgress } from '@mui/material'
import { uniqueId } from 'lodash'

import { BrandColor } from '~/config/theme'

interface QRCodeScannerProps {
  context?: string
  onScan: (code: QrScanner.ScanResult, scannedCode: Blob | null) => Promise<{ valid: boolean | null }>
  onCameraFail: (error: any) => void
}

/**
 * QRCodeScanner component, using the qr-scanner library
 *
 * The QRCodeScanner component tries to limit the amount it calls it's `onScan` callback function. It handles rescans differently based on the validity of the scanned QR code.
 *
 * If valid = true, this component will be about to hit it's end-of-life. This might be a nice time to play an animation in the future
 * If valid = false, the component will change the overlay around the QR code to red, and will not call the `onScan` callback for the same QR code unless another QR code is presented to it first
 * If valid = null, the component will change the overlay around the QR code to yellow. A null state indicates that the QR code callback handler was uncertain if the code was valid or not (e.g. in the case of a network error)
 *
 * @param {function} onScan - Callback function called when a QR code is scanned
 * @param {function} onCameraFail - Callback function called when the camera fails to start
 * @returns {JSX.Element}
 */
const QRCodeScanner: React.FC<QRCodeScannerProps> = ({ context, onScan, onCameraFail }) => {
  const [loadingCamera, setLoadingCamera] = useState<boolean>(true)
  const lastScan = useRef<{ data: null | string, valid: boolean | null, scanId: string | null }>({ data: null, valid: null, scanId: null })
  const videoEl = useRef<HTMLVideoElement>(null)
  const scannerEl = useRef<HTMLDivElement>(null)
  let timeout: NodeJS.Timeout | null = null
  let qrScanner: QrScanner | null = null

  useEffect(() => {
    if (lastScan) {
      lastScan.current = { data: null, valid: null, scanId: null }
    }
  }, [context])

  const getBlob = async (): Promise<Blob | null> => {
    return await new Promise((resolve) => {
      try {
        if (qrScanner?.$canvas) {
          qrScanner.$canvas.toBlob((blob) => { resolve(blob) }, 'image/jpeg')
        } else {
          resolve(null)
        }
      } catch (error) {
        resolve(null)
      }
    })
  }

  const initiateCamera = async (): Promise<any> => {
    if (!navigator.mediaDevices?.getUserMedia) {
      onCameraFail({ name: 'DeviceNotSupported' }); return
    }

    if (videoEl.current) {
      await startQr()
      setLoadingCamera(false)
    }
  }

  const deactivateCamera = (): void => {
    if (qrScanner) {
      qrScanner.destroy()
      qrScanner = null
    }
  }

  const setOverlayColour = (valid: boolean | null, scanId?: string | null): void => {
    if (timeout) clearTimeout(timeout)
    if (scanId && lastScan.current?.scanId !== scanId) return

    if (qrScanner?.$overlay) {
      const svgChildren = qrScanner.$overlay.querySelectorAll('svg')
      svgChildren.forEach((svgChild) => {
        switch (valid) {
          case true:
            svgChild.style.stroke = BrandColor.ACCENT_BLUE
            break
          case false:
            svgChild.style.stroke = BrandColor.RED
            break
          default:
            svgChild.style.stroke = BrandColor.YELLOW
        }
      })
    }

    if (valid === null) {
      timeout = setTimeout(() => {
        setOverlayColour(true, scanId)
      }, 750)
    }

    if (valid === false) {
      timeout = setTimeout(() => {
        setOverlayColour(null, scanId)
      }, 750)
    }
  }

  const handleScan = async (code: QrScanner.ScanResult): Promise<void> => {
    const scanId = uniqueId()
    let valid: boolean | null = null

    if (code.data === lastScan.current?.data) {
      lastScan.current = {
        ...lastScan.current,
        scanId,
      }
      valid = lastScan.current.valid
    } else {
      lastScan.current = {
        data: code.data,
        valid: null,
        scanId,
      }
      const blob = await getBlob()
      valid = (await onScan(code, blob)).valid
      lastScan.current = { data: code.data, valid, scanId }
    }

    setOverlayColour(valid)
  }

  const startQr = async (): Promise<void> => {
    if (videoEl.current) {
      qrScanner = new QrScanner(
        videoEl.current,
        handleScan,
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          onDecodeError: (error) => {
            if (typeof error === 'string') {
              if (!error.includes('No QR code found')) {
                // TODO: Should we do something here?
              }
            }
          },
        },
      )

      await qrScanner.start()
      setOverlayColour(true)
    }
  }

  useEffect(() => {
    void initiateCamera()

    return () => {
      deactivateCamera()
      if (timeout) clearTimeout(timeout)
    }
  }, [videoEl])

  return (
    <Box ref={scannerEl} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', overflow: 'hidden', justifyContent: 'center', alignItems: 'center', background: 'black' }}>
      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }}>
        <video
          ref={videoEl}
          playsInline
          muted
          onContextMenu={(event) => { event.preventDefault() }}
          style={{ width: '100%', maxHeight: '100%', opacity: loadingCamera ? 0 : 1, transition: 'opacity 0.5s ease-in-out', transitionDelay: '1s', pointerEvents: 'none' }}
        />
        {loadingCamera && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default QRCodeScanner
