import md5 from 'js-md5'
import { Auth } from 'aws-amplify'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'

import { amplify } from '~/config/aws'
import { readBlobAsArrayBuffer } from '~/helpers/file/file'
import { getStorageUri } from '~/helpers/s3'
import { isAwsExceptionV3 } from '~/types/guards/errors'

class S3Service {
  private skewRetries = 0

  async upload (file: File, fileName: string, bucket: string, options?: {
    systemClockOffset?: number
  }): Promise<{ storageURI: string, imageKey: string }> {
    const imageKey = fileName
    const session = await Auth.currentSession()
    const COGNITO_ID = `cognito-idp.${amplify.aws_cognito_region}.amazonaws.com/${amplify.aws_user_pools_id}`
    const client = new S3Client({
      region: amplify.Storage.AWSS3.region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: amplify.Storage.AWSS3.region }),
        identityPoolId: amplify.aws_cognito_identity_pool_id,
        logins: { [COGNITO_ID]: session.getIdToken().getJwtToken() },
      }),
      systemClockOffset: options?.systemClockOffset,
    })

    try {
      await client.send(
        new PutObjectCommand({
          ContentType: file.type,
          Bucket: bucket,
          Body: file,
          Key: `public/${fileName}`,
          ContentMD5: md5
            .create()
            .update(await readBlobAsArrayBuffer(file))
            .base64(),
        }),
      )

      const storageURI = getStorageUri(bucket, imageKey)

      return {
        storageURI,
        imageKey,
      }
    } catch (error) {
      if (isAwsExceptionV3(error) && error.name === 'RequestTimeTooSkewed') {
        if (error.ServerTime && this.skewRetries === 0) {
          this.skewRetries++
          const serverTime = new Date(error.ServerTime)
          const clientTime = new Date()

          return await this.upload(file, fileName, bucket, {
            systemClockOffset: (clientTime.getTime() - serverTime.getTime()) * -1,
          })
        }
      }

      this.skewRetries = 0
      throw error
    }
  }
}

export default new S3Service()
