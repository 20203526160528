import { SvgIcon, type SxProps } from '@mui/material'

interface LockIconProps {
  sx?: SxProps
}

const LockIcon: React.FC<LockIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 126.89489 169.36206' sx={sx}>
    <path d='M126.15454,74.62993a15.89876,15.89876,0,0,0-14.7384-11.05377c-1.80784-.05171-3.61568-.01724-5.6646-.01724V42.89761A42.36549,42.36549,0,0,0,69.62861.5075C47.50384-3.09106,25.36183,12.86984,21.8321,34.99462a57.003,57.003,0,0,0-.65428,8.2129c-.08607,6.715-.03436,13.42983-.03436,20.3514H16.64965C6.8355,63.57616.01723,70.36,0,80.15686v72.22841c0,8.85,4.58,14.65233,13.15439,16.71848a1.72325,1.72325,0,0,1,.396.25831h99.79415c.82642-.24107,1.67008-.44768,2.47937-.74046A15.85293,15.85293,0,0,0,126.53328,156.862a10.7894,10.7894,0,0,1,.36161-1.05026V77.10929C126.65382,76.28287,126.41285,75.45634,126.15454,74.62993ZM91.51242,63.47285h-56.13c-.03436-.551-.1033-1.08472-.1033-1.61847,0-6.33612-.01713-12.67235,0-19.00847.06894-14.6351,9.76254-25.98154,24.191-28.37483,14.63509-2.42766,29.45956,8.35057,31.74955,23.07183a14.90552,14.90552,0,0,1,.29267,2.11776C91.52966,47.54643,91.51242,55.41485,91.51242,63.47285Z'/>
  </SvgIcon>
}

export default LockIcon
