import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from 'react'
import { format, intervalToDuration, parseISO } from 'date-fns'
import { Box, Button, Typography } from '@mui/material'
import { CalendarToday } from '@mui/icons-material'

import { BrandColor } from '~/config/theme'
import type { Reward } from '~/types/reward/Reward'

import RewardListItemHeader from '../../RewardListItemHeader'

import RewardSelectExpiry from './RewardSelectExpiry'

interface RewardSelectCardProps {
  reward: Reward
  onOpen: Dispatch<SetStateAction<boolean>>
}

const RewardSelectCard: React.FC<RewardSelectCardProps> = ({ reward, onOpen }) => {
  const interval = useRef<NodeJS.Timeout | null>(null)
  const expiryTime = useRef<Date | null>(null)
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const duration = intervalToDuration({ start: 0, end: timeLeft * 1000 })
  const [initialCheck, setInitialCheck] = useState<boolean>(false)

  useEffect(() => {
    if (reward?.prize?.voucherUntil) {
      const expiry = parseISO(reward.prize.voucherUntil)
      expiryTime.current = expiry
      const newTimeLeft = getTimeLeft(expiryTime.current)
      const newTimeLeftDuration = intervalToDuration({ start: 0, end: newTimeLeft * 1000 })

      if (expiryTime.current.valueOf() > new Date().valueOf()) {
        if (newTimeLeftDuration.days === 0) {
          countdown()
          interval.current = setInterval(countdown, 900)
        } else {
          setTimeLeft(newTimeLeft)
        }
      } else {
        setIsExpired(true)
      }
    }

    setInitialCheck(true)

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [reward])

  const getTimeLeft = (expiryTime: Date): number => {
    return Math.floor((expiryTime.getTime() - new Date().getTime()) / 1000)
  }

  const countdown = (): void => {
    if (expiryTime.current) {
      const newTimeLeft = getTimeLeft(expiryTime.current)
      setTimeLeft(newTimeLeft)

      if (newTimeLeft <= 0) {
        setIsExpired(true)
        if (interval.current) clearInterval(interval.current)
      }
    }
  }

  const handleChooseReward = (): void => {
    onOpen(true)
  }

  if (!initialCheck) return null

  return <Box sx={{ p: 2, background: BrandColor.LIGHT_GREY, borderRadius: 2 }}>
    <RewardListItemHeader reward={reward} brand='boots' />
    {!isExpired && reward?.prize?.voucherUntil && <RewardSelectExpiry expiry={reward?.prize?.voucherUntil} duration={duration} />}
    {reward?.createdAt && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff', px: 1, borderRadius: 1 }}>
      <CalendarToday sx={{ mr: 1, width: 15, color: BrandColor.ACCENT_BLUE }} />
      <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
        Received on
      </Typography>
      <Box sx={{ ml: 'auto' }}>
        <Typography variant='caption' sx={{ color: BrandColor.BLACK }}>
          {format(new Date(reward.createdAt), "PP 'at' HH:mm a")}
        </Typography>
      </Box>
    </Box>}
    <Button variant="contained" fullWidth onClick={handleChooseReward} disabled={isExpired} sx={{ mt: 2 }}>
      {isExpired ? 'Expired' : 'Choose Reward'}
    </Button>
  </Box>
}

export default RewardSelectCard
