import type { GeoPoint } from '@app/types/location/GeoPoint'
import { DistanceUnits } from '@app/types/DistanceUnits'

const toRadians = (coordinate: number): number => {
  return coordinate * (Math.PI / 180)
}
// haversine formula
// a = sin^2((latB - latA)/2) + cos(latA) * cos(latB) * sin^2((lngB - lngA)/2)
// c = 2 * atan2(√a, √(1-a))
// distance = R * c where R is radius of earth

export const getDistanceBetween2GeographicPoints = (pointA: GeoPoint, pointB: GeoPoint, unit = DistanceUnits.METERS): number => {
  const R = 6371000 // radius of earth in meters

  // angles need to be in radians for trigonometry
  const latAInRads = toRadians(pointA.latitude)
  const latBInRads = toRadians(pointB.latitude)
  const deltaLatInRads = toRadians(pointB.latitude - pointA.latitude)
  const deltaLongInRads = toRadians(pointB.longitude - pointA.longitude)

  const a = (Math.sin(deltaLatInRads / 2) * Math.sin(deltaLatInRads / 2)) +
  (Math.cos(latAInRads) * Math.cos(latBInRads)) *
  (Math.sin(deltaLongInRads / 2) * Math.sin(deltaLongInRads / 2))

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c

  if (unit === DistanceUnits.KILOMETERS) {
    return distance * 0.001 // 1m = 0.001km
  }

  if (unit === DistanceUnits.MILES) {
    return distance * 0.000621371 // 1m = 0.000621371 miles
  }

  return distance
}

/**
 * Get Geo Location
 *
 * To save on processing power, this returns the first coordinates it sees within a threshold (10m) from the entered point
 * if no points are under that threshold, it returns undefined
*/
export const getGeoLocationWithinDistanceThreshold = (pointA: GeoPoint, arrayOfPoints: GeoPoint[], distanceThreshold: number): GeoPoint | null => {
  if (arrayOfPoints.length === 0) return null

  for (let i = 0; i < arrayOfPoints.length; i += 1) {
    const point = arrayOfPoints[i]
    const distance = getDistanceBetween2GeographicPoints(pointA, point)
    if (distance < distanceThreshold) return point
  }

  return null
}
