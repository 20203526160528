import { createSlice } from '@reduxjs/toolkit'
import { orderBy, uniqBy } from 'lodash'

import type { Deposit } from '~/types/deposit/Deposit'

import { getMyDeposits } from './actions'

interface MyDepositsState {
  deposits: Deposit[]
  nextToken: string | null
  fetched: boolean
  fetching: boolean
}

const initialState: MyDepositsState = {
  deposits: [],
  nextToken: null,
  fetched: false,
  fetching: false,
}

export const myDepositsSlice = createSlice({
  name: 'myDeposits',
  initialState,
  reducers: {
    resetNextToken: (state) => {
      state.deposits = []
      state.nextToken = null
      state.fetched = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyDeposits.pending, (state) => {
      state.fetching = true
    })
    builder.addCase(getMyDeposits.fulfilled, (state, action) => {
      state.fetched = true
      state.fetching = false
      if (action.payload.initialFetch) {
        state.deposits = orderBy(action.payload.response.items, ['createdAt'], ['desc'])
      } else {
        state.deposits = uniqBy(orderBy(state.deposits.concat(action.payload.response.items), ['createdAt'], ['desc']), (item) => item.SK)
      }
      state.nextToken = action.payload.initialFetch && state.nextToken ? state.nextToken : action.payload.response.nextToken
    })
    builder.addCase(getMyDeposits.rejected, (state) => {
      state.fetching = false
      state.fetched = true
    })
  },
})

export const { resetNextToken } = myDepositsSlice.actions

export default myDepositsSlice.reducer
