import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { differenceInMinutes } from 'date-fns'

import { getLocalStorageItem, setLocalStorageItem } from '~/helpers/localStorage'
import { ROUTE_HOME, ROUTE_LOGIN } from '~/routes/Routes'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import { confirmSignUp, login, resendSignUpCode } from '~/redux/features/auth/actions'
import { addNotification } from '~/redux/features/notifications/notificationSlice'

import type { AWSException } from '~/types/errors/AWSException'

import ConfirmSignUp, { type FormValues } from './ConfirmSignUp'

const ConfirmSignUpView: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state: RootState) => state.auth.user)
  const signUp = useSelector((state: RootState) => state.auth.signUp)

  const handleConfirm = async (values: FormValues): Promise<void> => {
    const username = signUp.email ?? user?.email

    setLoading(true)

    try {
      await dispatch(confirmSignUp({
        username,
        code: values.code,
      })).unwrap()
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: 'There was a problem confirming your account',
      }))

      setLoading(false)

      return
    }

    if (!user) {
      try {
        await dispatch(login({
          email: username,
          password: signUp.password,
        })).unwrap()
      } catch (error) {
        navigate(ROUTE_LOGIN); return
      }
    }

    setLoading(false)

    navigate(ROUTE_HOME)
  }

  const resendCode = async (): Promise<void> => {
    const lastRequest = getLocalStorageItem('resendSignUpCode')
    const timeElapsed = differenceInMinutes(new Date(), lastRequest ? new Date(Number(lastRequest)) : new Date())

    if (lastRequest && timeElapsed < 5) {
      dispatch(addNotification({
        type: 'error',
        message: 'Limit reached, wait 5 minutes before requesting a new code',
      }))
      return
    }

    setLocalStorageItem('resendSignUpCode', Date.now().toString())

    try {
      await dispatch(resendSignUpCode(signUp.email)).unwrap()
    } catch (error) {
      if ((error as AWSException).name === 'LimitExceededException') {
        dispatch(addNotification({
          type: 'error',
          message: 'Limit reached, wait 30 minutes before requesting a new code',
        }))
      } else {
        dispatch(addNotification({
          type: 'error',
          message: 'There was a problem resending the code',
        }))
      }
    }
  }

  return <>
    <Helmet>
      <title>Confirm Sign Up | Recycle at Boots</title>
    </Helmet>
    <ConfirmSignUp email={signUp.email} isLoading={loading} onResendCode={resendCode} onConfirm={handleConfirm} />
  </>
}

export default ConfirmSignUpView
