import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, FormHelperText, FormLabel } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { theme } from '~/config/theme'
import { createMuiRegister } from '~/helpers/hookForm'
import type { PromotionSchemes } from '~/types/promotion/Promotion'

export interface SchemeSelectorFormValues {
  schemes: string[]
}

interface SchemeSelectorFormProps {
  schemes: PromotionSchemes
  userSchemes?: string[]
  loading?: boolean
  submitLabel?: string
  onSubmit: (values: SchemeSelectorFormValues) => void
}

const validationSchema = z.object({
  schemes: z.array(z.string()).nonempty('Please select at least one scheme'),
}).strict()

const SchemeSelectorForm: React.FC<SchemeSelectorFormProps> = ({ schemes, userSchemes, loading = false, submitLabel, onSubmit }) => {
  const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm<SchemeSelectorFormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      schemes: userSchemes ?? [],
    },
    mode: 'onChange',
  })
  const muiRegister = createMuiRegister(register)

  watch('schemes')

  return <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
    {schemes.map(scheme => <FormLabel
      key={scheme.scheme.shortName}
      sx={{ display: 'block', background: theme.palette.grey[100], cursor: 'pointer', mb: 2, p: 2, borderRadius: 2 }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 30, height: 30, background: scheme.scheme.icon.color, borderRadius: 6, mr: 2 }}>
            {<scheme.scheme.icon.component sx={{ color: 'white', fontSize: 18 }} />}
          </Box>
          {scheme.scheme.name}
          <Checkbox
            {...muiRegister('schemes')}
            value={scheme.promotion.id}
            checked={watch('schemes')?.includes(scheme.promotion.id)}
            sx={{ ml: 'auto', p: 0 }}
          />
        </Box>
        <Box sx={{ userSelect: 'none' }}>
          {scheme.scheme.description}
        </Box>
      </Box>
    </FormLabel>)}
    <FormHelperText error>{errors.schemes?.message}</FormHelperText>
    <LoadingButton
      fullWidth
      type="submit"
      variant="contained"
      disabled={!isValid}
      loading={loading}>{submitLabel ?? 'Next'}</LoadingButton>
  </Box>
}

export default SchemeSelectorForm
