import { Accordion, AccordionDetails, AccordionSummary, Link, List, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import application from '~/config/application'
import { ROUTE_RECYCLABLE_TYPES } from '~/routes/Routes'

import TermsListItem from '../components/TermsListItem'

export const BlisterUKTerms: React.FC = () => (
  <Accordion sx={{ '&:before': { display: 'none' } }}>
    <AccordionSummary expandIcon={<ExpandMore />}>Boots Blister Recycling UK</AccordionSummary>
    <AccordionDetails>
      <Typography variant="h6" gutterBottom>RECYCLE AT BOOTS - BLISTER PACK RECYCLING - FULL CUSTOMER TERMS & CONDITIONS</Typography>
      <List component="ol" sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          <Typography variant="body1">
            The Recycle at Boots Blister Pack Recycling Scheme (“the Scheme”) is operated by Boots UK Limited (“Boots”) using Scan2Recycle technology, open to UK residents aged 16 years and over only, and is aimed exclusively at recycling 'hard to recycle' blister pack product packaging which cannot typically be recycled through household kerbside collections, for example; vitamins, lozenges, tablets, prescription and non-prescription medication. Packaging from controlled drugs, or from products not manufactured for human consumption are excluded.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Widely recyclable packaging that can be recycled via household waste recycling channels are excluded from the Scheme. Please check your local council for details.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            The Scheme rewards Boots Advantage Card members with a total of 150 Advantage Card points when you spend £10 or more for recycling a minimum of fifteen Qualifying Items (“the Reward”). Details of the voucher Terms & Conditions can be found <Link href="https://www.boots.com/webapp/wcs/stores/servlet/CategoryDisplay?storeId=11352&categoryId=1602601&catalogId=28501&newPreviewSession=true&p13n_test=true&p13n_testcontext=0" target="_blank">here</Link> coupon number 32.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Details of the Boots Advantage Card Terms & Conditions can be found by <Link href="https://www.boots.com/floating-editorial/editorial-legal/terms-conditions/advantage-card-terms-and-conditions" target="_blank">clicking here</Link>.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
          Boots reserve the right to determine which items qualify for the Scheme within the meaning of a qualifying item (“Qualifying Item”) and in the event of a dispute, Boots decision is final.
          </Typography>
        </TermsListItem>
      </List>

      <Typography variant="h6" gutterBottom>Qualifying Items</Typography>
      <List component="ol" start={6} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          <Typography variant="body1">For the avoidance of doubt, a Qualifying Item must be used and empty;</Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">The full list of Qualifying Items is detailed <Link href={`${ROUTE_RECYCLABLE_TYPES}?scheme=Blister`}>here</Link> when in the Blister Pack view if they are in hard-to-recycle packaging.</Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            For health and safety reasons, we cannot accept packaging from controlled drugs, and any products or packaging that are labelled with a hazardous or dangerous materials symbol.
          </Typography>
        </TermsListItem>
      </List>

      <Typography variant="h6" gutterBottom>General Recycle at Boots - Blister Pack Recycling Scheme Rules</Typography>
      <List component="ol" start={9} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          <Typography variant="body1">
            In order to take part in the Scheme and qualify for the Reward, you must first create a Recycle at Boots account (“Account Holder”) and opt into the Blister Pack Scheme.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            It is a condition of participation that location services are enabled on the device(s) used for Banking and Depositing.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Deposits must only be made in participating stores using a valid QR code located on the official Blister Pack recycling box Take a picture of the product (“Scan”) the items you want to recycle (“Bank”). The picture must be of the foil side, with all compartments emptied, including the whole product with the brand and product type clearly shown. Only one image per product must be uploaded.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            For non-prescription products, label (“Tag”) the items by selecting the Brand, then Product Type. For Prescription products, Tag as Prescription only.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            If you cannot find your category in the drop-down list, you will not be able to Bank it, as it is not a Qualifying Item and it will not count towards your 15 items required for the Reward.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Banked items must be validated before being deposited. The validation will only take place during working hours (Monday to Friday 9am to 5pm, excluding public holidays “Working Day”). Items Banked out of hours will be validated by close of play on the next Working Day. Items Banked during a Working Day will be validated by the same time on the next Working Day. For example, an item banked at 12:30pm on a Monday will be validated by 12:30pm on a Tuesday. An item banked at 7:30pm Friday will be validated by 5.00pm the following Monday.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Once Banked items have been verified you will be able to Deposit the items. Notification of the status of your items will be detailed in the 'Recyclables' section of your Boots Scan2Recycle Account.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            You must then bring at least 15 Qualifying items to any of the participating stores (full list here) and deposit them in the Blister Pack boxes provided (“Deposit”). You will find the boxes located near our pharmacy counters.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Each box has a unique QR code on the front or wings, which must be scanned to confirm the location of the Deposit.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Once you have confirmed the Deposit by scanning the QR code on the box, and deposited your Qualifying Items, the Reward will be issued in accordance with the Terms & Conditions.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            The Reward will be issued as a barcode and displayed in your Boots Scan2Recycle account in the 'Rewards' section.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            In order to claim the reward, you must make a total purchase of £10 or more in one transaction in a participating store normal legal and pharmacy exclusions apply. The barcode must be scanned at a store till point (from the screen of your mobile phone) together with your Advantage Card and the products being purchased in order to claim the reward.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Temporary Advantage Cards are not accepted.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Registrations for Boots Scan2Recycle are strictly limited to one per person, and the registered details must match the Boots Scan2Recycle account holder's Boots Advantage Card.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            The Reward cannot be used at a self-check-out till.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            The Reward must be claimed within 3 days, including the day it was issued, at a participating store, subject to store opening times. For example, if the barcode was issued on a Monday, it must be used by Wednesday at midnight.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Each Reward can only be used once. A maximum of 4 rewards per account will be issued in any calendar month. All subsequent deposits will be accepted but not rewarded.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Only the customer to which the Boots Scan2Recycle account and Advantage Card are registered can claim the Reward.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Only one Reward can be claimed per calendar day, regardless of how many items have been recycled over the 15 items threshold.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Unused Reward barcodes that have expired will not be honoured or replaced.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            If you do not have an Advantage Card you can still use the recycling point, but you will not receive a Reward.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            No cash or other alternatives are available.
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Items once Banked cannot be submitted again for a further Reward as these are counted as part of the previous Deposit. Items cannot be removed from the recycle bin and resubmitted - these will be classed as duplicate (“Duplicate”).
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            You can cancel your Boots Scan2Recycle account at any time by emailing: <Link href="mailto:boots@scan2recycle.com">boots@scan2recycle.com</Link> or contacting Boots Scan2Recycle Customer Care Team.
          </Typography>
        </TermsListItem>
      </List>

      <Typography variant="h6" gutterBottom>Amending or Withdrawal of the Scheme</Typography>
      <List component="ol" start={33} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
      <TermsListItem>
        <Typography variant="body1">
          Boots reserves the right to amend or withdraw this Scheme at any time giving registered participants 14-day notice to their registered email address.
        </Typography>
      </TermsListItem>
      <TermsListItem>
        <Typography variant="body1">Boots can take such action it considers appropriate, including removing or suspending a customer's Boots Scan2Recycle account and any Boots Advantage Card account held by the customer along with any points accrued, if it has reason to believe there has been:</Typography>
          <List component="ol" sx={{ listStyle: 'lower-alpha', paddingLeft: 2 }} dense>
            <TermsListItem>
              <Typography variant="body1">
                Abuse or misuse of the Scheme;
              </Typography>
            </TermsListItem>
            <TermsListItem>
              <Typography variant="body1">
                Activity or conduct which would give rise to a breach of the Scheme's Terms and Conditions on the part of the {application.client} Scan2Recycle Account Holder;
              </Typography>
            </TermsListItem>
            <TermsListItem>
              <Typography variant="body1">
                Activity or conduct which would give rise to a breach of the Boots Advantage Card Terms and Conditions or any associated clubs and offers.
              </Typography>
            </TermsListItem>
          </List>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            For the avoidance of doubt, any decision {application.client} makes on such an issue is final.
          </Typography>
        </TermsListItem>
      </List>

      <Typography variant="h6" gutterBottom>Activity or conduct which would give rise to a breach of the Scheme Terms and Conditions include but are not limited to:</Typography>
      <List component="ol" start={36} sx={{ listStyle: 'decimal', paddingLeft: 4 }} dense>
        <TermsListItem>
          <Typography variant="body1">
            Repeated failure to adhere to the Scheme's Terms and Conditions;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Uploading product images that appear to have been taken from in-store shelf;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Uploading parts of product packaging and not the complete whole product packaging;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Multiple accounts or Boots Advantage Card registrations being used per individual;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Uploading duplicate images or images of the same product multiple times;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Entering product descriptions ('Tagging') manually (from the drop-down list) that are inaccurate and do not reflect the image uploaded;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Uploading same images or combination of products by separate accounts;
          </Typography>
        </TermsListItem>
        <TermsListItem>
          <Typography variant="body1">
            Using a QR code that is not a {application.client} Scan2Recycle code, on an official collection box, in a participating location.
          </Typography>
        </TermsListItem>
      </List>
      <Typography variant="body1" gutterBottom>Participating stores can be found at: <Link href="https://www.boots.com/boots-recycling-scheme" target="_blank">https://www.boots.com/boots-recycling-scheme</Link></Typography>
      <Typography variant="body1">Participating stores reserve the right to remove the Recycle Bin due to health and safety concerns.</Typography>
    </AccordionDetails>
  </Accordion>
)
