import { useEffect } from 'react'
import { useInView, defaultFallbackInView } from 'react-intersection-observer'
import Bugsnag from '@bugsnag/js'
import { Grid } from '@mui/material'

import { PrizeType } from '~/enum/prize/PrizeType'
import RewardService from '~/services/RewardService'
import type { ActiveReward } from '~/types/reward/ActiveReward'
import type { Reward } from '~/types/reward/Reward'
import { isNotifiable } from '~/types/guards/errors'
import type { Prize } from '~/types/Prize'

import Voucher from './RewardTypes/Voucher/Voucher'
import RewardSelect from './RewardTypes/RewardSelect/RewardSelect'
import Donation from './RewardTypes/Donation/Donation'

interface RewardListItemProps {
  reward: Reward
  activeRewards: ActiveReward[]
  selections: Record<string, Prize[]>
  fetchRewardSelections: (claimId: string) => Promise<void>
  onRewardSelect: (reward: Reward, prizeId: string) => Promise<void>
}

const RewardListItem: React.FC<RewardListItemProps> = ({ reward, activeRewards, selections, fetchRewardSelections, onRewardSelect }) => {
  defaultFallbackInView(false)
  const { ref, inView } = useInView({
    skip: !!reward.viewedAt,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) void handleVisible()
  }, [inView])

  const handleVisible = async (): Promise<void> => {
    if (!reward.viewedAt) {
      try {
        await RewardService.setRewardViewed(reward.rewardId)
      } catch (error) {
        if (isNotifiable(error)) Bugsnag.notify(error)
      }
    }
  }

  const renderReward = (): JSX.Element => {
    switch (reward.prize.type) {
      case PrizeType.VOUCHER:
        return <Voucher reward={reward} activeRewards={activeRewards} />
      case PrizeType.REWARD_SELECTION:
        return <RewardSelect reward={reward} selections={selections[reward.submissionId] ?? []} fetchRewardSelections={fetchRewardSelections} onRewardSelect={onRewardSelect} />
      case PrizeType.DONATION:
        return <Donation reward={reward} />
    }

    return <></>
  }

  return <Grid ref={ref} item xxs={12} md={6}>
    {renderReward()}
  </Grid>
}

export default RewardListItem
