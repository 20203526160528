import { Box, type SxProps, Typography } from '@mui/material'

import { RecyclableStatus } from '~/constants/recyclable'

import FlagCircleIcon from '../Icons/FlagCircleIcon'
import TickCircleIcon from '../Icons/TickCircleIcon'

interface RecyclableNotificationProps {
  status: RecyclableStatus
  statusReason: string
  sx: SxProps
}

export const RecyclableNotification: React.FC<RecyclableNotificationProps> = ({ status, statusReason, sx }) => {
  const baseColour = status !== RecyclableStatus.REJECTED ? '#48F045' : '#FF1020'
  const iconStyle = { width: 25, height: 25, display: 'block', mt: 'auto', mb: 'auto', color: '#ffffff' }

  return <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', borderStyle: 'solid', borderWidth: 2, borderRadius: 2, borderColor: `${baseColour}AA`, backgroundColor: `${baseColour}08`, ...sx }}>
    <Box sx={{ width: 25, height: 25, padding: 0.8, mb: 'auto', mt: 'auto', ml: 1, backgroundColor: `${baseColour}97`, borderRadius: 100 }}>
      {status !== RecyclableStatus.REJECTED ? <TickCircleIcon sx={iconStyle}/> : <FlagCircleIcon sx={iconStyle}/>}
    </Box>
    <Box sx={{ p: 1, pl: 1.5 }}>
      <Typography variant='h6' sx={{ fontWeight: '800', fontSize: 15 }}>
        {status !== RecyclableStatus.REJECTED ? 'Bankable accepted for verification' : statusReason}
      </Typography>
      <Typography variant='h6' sx={{ fontWeight: '500', fontSize: 12, color: '#00000080' }}>
        View this in your bank
      </Typography>
    </Box>
  </Box>
}
