import { Box, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import SchemeSelectorForm, { type SchemeSelectorFormValues } from '~/components/Form/User/SchemeSelectorForm'
import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'
import { findClosestLocale } from '~/helpers/locale'
import { getPromotionSchemes } from '~/helpers/promotion'

interface SchemeSelectorProps {
  country: string
  onNextStep: (values: { schemes: Array<{ id: string, name: string }> } & { campaignId: string, locale: string }) => void
}

const SchemeSelector: React.FC<SchemeSelectorProps> = ({ country, onNextStep }) => {
  const [promotionCheck, setPromotionCheck] = useState(false)
  const validSchemes = getPromotionSchemes((promotion) => promotion.country === country)

  const handleNext = (values: SchemeSelectorFormValues): void => {
    const schemes = validSchemes
      .filter(scheme => values.schemes.includes(scheme.promotion.id))
      .sort((a, b) => a.scheme.priority - b.scheme.priority)
    const selectedScheme = schemes[0]
    const promotion = Scan2RecyclePromotion.setPromotion(selectedScheme.promotion.id)

    onNextStep({
      campaignId: promotion.id,
      locale: findClosestLocale(promotion.locales),
      schemes: schemes.map(scheme => ({ id: scheme.promotion.id, name: scheme.scheme.name })),
    })
  }

  useEffect(() => {
    if (validSchemes.length === 1) {
      handleNext({ schemes: [validSchemes[0].promotion.id] })
    } else {
      setPromotionCheck(true)
    }
  }, [])

  if (!promotionCheck) return <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box>

  return <>
    <Typography variant="h5" gutterBottom>Choose your scheme</Typography>
    <Typography variant="body1" gutterBottom>Please check the boxes below to opt-in to our recycling schemes</Typography>
    <SchemeSelectorForm schemes={validSchemes} onSubmit={handleNext} />
  </>
}

export default SchemeSelector
