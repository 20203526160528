import { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { Close } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import { BrandColor, theme } from '~/config/theme'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { isAbortError, isErrorResponse, isNotifiable } from '~/types/guards/errors'
import type { Prize } from '~/types/Prize'

import { RewardSelectOption } from './RewardSelectOption'

interface RewardSelectModalProps {
  rewards: Prize[]
  open: boolean
  fetchRewardSelections: () => Promise<void>
  onSelect: (prizeId: string) => Promise<void>
  onCancel: () => void
}

const RewardSelectModal: React.FC<RewardSelectModalProps> = ({ rewards, open, fetchRewardSelections, onSelect, onCancel }) => {
  const [selectedReward, setSelectedReward] = useState<string | null>(null)
  const [fetching, setFetching] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (open) {
      void fetchRewardOptions()
    }
  }, [open])

  const fetchRewardOptions = async (): Promise<void> => {
    if (rewards.length === 0) {
      setFetching(true)
      try {
        await fetchRewardSelections()
      } catch (error) {
        if (isErrorResponse(error)) {
          const { message } = await error.json()
          if (message === 'Prize selection expired') {
            dispatch(addNotification({ type: 'error', message }))
            onCancel()
            return
          }
        }

        if (!isAbortError(error)) {
          dispatch(addNotification({ type: 'error', message: 'Unable to fetch rewards' }))
        }
        if (isNotifiable(error)) Bugsnag.notify(error)
      } finally {
        setFetching(false)
      }
    } else {
      setFetching(false)
    }
  }

  const handleRewardSelect = (event: React.MouseEvent<HTMLElement>, prizeId: string): void => {
    setSelectedReward(prizeId)
  }

  const handleConfirm = async (): Promise<void> => {
    if (!selectedReward) {
      dispatch(addNotification({ type: 'error', message: 'Please select a reward' }))
      return
    }

    setLoading(true)

    try {
      await onSelect(selectedReward)
      onCancel()
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(addNotification({ type: 'error', message: 'Unable to select reward' }))
      }
      if (isNotifiable(error)) Bugsnag.notify(error)
    } finally {
      setLoading(false)
    }
  }

  return <Dialog fullScreen open={open} sx={{ m: { xxs: 2, md: 4 } }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, background: BrandColor.WHITE, outline: 0, p: 4, borderRadius: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h5'>Reward Select</Typography>
        <IconButton onClick={onCancel} sx={{ ml: 'auto' }}>
          <Close />
        </IconButton>
      </Box>
      {fetching && <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box>}
      {!fetching && <ToggleButtonGroup
        exclusive
        value={selectedReward}
        onChange={handleRewardSelect}
        sx={{
          width: '100%',
          my: 2,
          flexGrow: 1,
          flexWrap: 'wrap',
          '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderRadius: 2,
            borderWidth: 2,
          },
          '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            my: 1,
            mx: { md: 1 },
            borderRadius: 2,
            borderWidth: 2,
            '&.Mui-selected': {
              borderColor: BrandColor.ACCENT_BLUE,
            },
          },
        }}
      >
        {rewards?.map((reward) => <ToggleButton key={reward.prizeId} value={reward.prizeId} sx={{
          width: { xxs: '100%', md: `calc(50% - ${theme.spacing(2)})` },
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          my: 1,
          mx: { md: 1 },
          borderRadius: 2,
          borderWidth: 2,
          borderColor: theme.palette.grey[100],
          background: theme.palette.grey[100],
          '&.Mui-selected': {
            borderColor: BrandColor.ACCENT_BLUE,

            '&:hover': {
              background: 'rgba(102, 102, 102, 0.04)',
            },
          },
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'none',
          py: { xxs: 1, md: 4 },
          px: { xxs: 2, md: 4 },
        }}><RewardSelectOption reward={reward} /></ToggleButton>)}
      </ToggleButtonGroup>}
      <Button variant="contained" fullWidth onClick={handleConfirm} disabled={fetching || loading}>
        {rewards && loading ? <CircularProgress size={24} /> : 'Confirm'}
      </Button>
    </Box>
  </Dialog>
}

export default RewardSelectModal
