import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { theme } from '~/config/theme'
import type { Prize } from '~/types/Prize'

interface RewardSelectOptionProps {
  reward: Prize
}

export const RewardSelectOption: React.FC<RewardSelectOptionProps> = ({ reward }) => {
  return <>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 125, p: 2, width: '100%' }}>
      <Box
        component='picture'
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 125,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 0.2s ease-in-out',
          flexGrow: 1,
          objectFit: 'contain',
          mb: 2,
        }}
      >
        <source srcSet={`/assets/images/rewards/${reward.type.toLowerCase()}/webp/${reward.SKU}.webp 1x, /assets/images/rewards/${reward.type.toLowerCase()}/webp/${reward.SKU}@2x.webp 2x`} type='image/webp' />
        <source srcSet={`/assets/images/rewards/${reward.type.toLowerCase()}/png/${reward.SKU}.png 1x, /assets/images/rewards/${reward.type.toLowerCase()}/png/${reward.SKU}@2x.png 2x`} type='image/png' />
        <Box component="img" src={`/assets/images/rewards/${reward.type.toLowerCase()}/png/${reward.SKU}.png`} alt={reward.name} sx={{ maxWidth: '100%', maxHeight: '100%' }} />
      </Box>
    </Box>
    <Box sx={{ pb: 1, width: '100%', maxWidth: 800 }}>
      <Typography variant='body1' gutterBottom sx={{ fontWeight: 600, textAlign: 'center', width: '100%', color: theme.palette.common.black }}>
        {reward.name}
      </Typography>
      {reward.summary && <Typography variant='body2' gutterBottom sx={{ textAlign: 'left', width: '100%' }}>
        {reward.summary}
      </Typography>}
      {reward.description && <Typography variant='caption' sx={{ display: 'block', textAlign: 'left', width: '100%' }}>
        {reward.description}
      </Typography>}
    </Box>
  </>
}
