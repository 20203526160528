import { formatISO } from 'date-fns'
import { Box, Button, FormGroup, FormHelperText, Typography } from '@mui/material'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import blister from '~/assets/images/checklist/blister.svg'
import prescription from '~/assets/images/checklist/prescription.svg'
import camera from '~/assets/images/checklist/blister-camera.svg'
import { setLocalStorageItem } from '~/helpers/localStorage'
import type { RecyclableScan } from '~/types/recyclable/recyclableScan'
import LabelWithContentCheckbox from '~/components/Form/Checkbox/LabelWithContentCheckbox'

interface FormValues {
  clean: boolean
  safe: boolean
  hardToRecycle: boolean
}

interface BlisterChecklistStepProps {
  onNextStep: (values?: Partial<RecyclableScan>) => Promise<void>
}

const validationSchema = z.object({
  clean: z.literal(true),
  safe: z.literal(true),
  hardToRecycle: z.literal(true),
}).strict()

const ListIcon: React.FC<{ image: string }> = ({ image }) => {
  return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 40, mr: 2, flexShrink: 0 }}>
    <Box component='img' sx={{ width: '100%' }} src={image}/>
  </Box>
}

const BlisterChecklistStep: React.FC<BlisterChecklistStepProps> = ({ onNextStep }) => {
  const handleNext = (): void => {
    setLocalStorageItem('lastSeenBlisterRecyclableChecklist', formatISO(new Date()))

    void onNextStep()
  }

  const { handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      clean: false,
      safe: false,
      hardToRecycle: false,
    },
    mode: 'onChange',
  })

  watch(['hardToRecycle', 'safe', 'clean'])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event?.target.name as any, event?.target.checked)
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(handleNext)} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Typography variant="h5" gutterBottom>Recyclable Preparation</Typography>
      <Typography variant="body2" sx={{ fontSize: { sm: 20 }, mb: { sm: 4, xxs: 2 } }} gutterBottom>Before you scan your items, please make sure they follow the below criteria.</Typography>
      <FormGroup sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: { xxs: 'space-evenly', md: 'center' } }}>
        <LabelWithContentCheckbox
          icon={<ListIcon image={blister} />}
          name='hardToRecycle'
          label="Your Blister Pack is empty"
          content="Your blister pack is completely empty and foil side up ready to photograph. You will only use the dedicated Blister Pack recycle points for recycling"
          checked={getValues('hardToRecycle')}
          value={true}
          onChange={handleChange}
        />
        <LabelWithContentCheckbox
          icon={<ListIcon image={prescription} />}
          name='safe'
          label="Your Blister Pack is eligible"
          content="Your blister pack is a prescription or non-prescription medicine for human consumption and not a controlled drug. Any unused medicines should be handed to the Boots pharmacy for disposal"
          checked={getValues('safe')}
          value={true}
          onChange={handleChange}
        />
        <LabelWithContentCheckbox
          icon={<ListIcon image={camera}/>}
          name='clean'
          label="Your Blister Pack is foil side up"
          content="Your blister pack is foil side up and is on a plain white background ready to photograph"
          checked={getValues('clean')}
          value={true}
          onChange={handleChange}
        />
        {Object.keys(errors).length > 0 && <FormHelperText error>Please confirm you have acknowleged the above</FormHelperText>}
      </FormGroup>

      <Box sx={{ width: '100%', mt: 2 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>Next</Button>
      </Box>
    </Box>
  )
}

export default BlisterChecklistStep
