import { SvgIcon, type SxProps } from '@mui/material'

interface ScanIconProps {
  sx?: SxProps
}

const ScanIcon: React.FC<ScanIconProps> = ({ sx }) => {
  return <SvgIcon viewBox='0 0 30 28.125' sx={sx}>
    <g>
      <path d="m28.36,18.5475c-.9056,0-1.64.7141-1.64,1.5948v3.9156c0,.484-.3947.8776-.88.8776h-5.0653c-.9056,0-1.6399.7141-1.6399,1.5948,0,.8806.7343,1.5947,1.6399,1.5947h5.0653c2.2938,0,4.16-1.8245,4.16-4.0671v-3.9156c0-.8807-.7343-1.5948-1.64-1.5948Z"/>
      <path d="m9.2721,24.9355h-5.11211c-.48526,0-.88004-.3936-.88004-.8776v-3.918c0-.8806-.73431-1.5947-1.63998-1.5947s-1.63997.7141-1.63997,1.5947v3.918c0,2.2426,1.86619,4.0671,4.15999,4.0671h5.11211c.9057,0,1.64-.7141,1.64-1.5948s-.7343-1.5947-1.64-1.5947Z"/>
      <path d="m1.63997,9.57707c.90567,0,1.63998-.71406,1.63998-1.59475v-3.91524c0-.48395.39478-.87758.88004-.87758h5.06526c.90565,0,1.63995-.71406,1.63995-1.59475s-.7343-1.59475-1.63995-1.59475h-5.06526C1.86619,0,0,1.82446,0,4.06708v3.91524c0,.88069.73431,1.59475,1.63997,1.59475Z"/>
      <path d="m25.84,0h-5.1121c-.9057,0-1.64.71406-1.64,1.59475s.7343,1.59475,1.64,1.59475h5.1121c.4852,0,.88.39363.88.87758v3.91796c0,.8807.7343,1.59475,1.64,1.59475s1.64-.71405,1.64-1.59475v-3.91796c0-2.24262-1.8662-4.06708-4.16-4.06708Z"/>
      <path d="m21.7749,5.69376h-13.55262c-1.1515,0-2.088.91067-2.088,2.03042v4.71732c0,.8807.7343,1.5947,1.63997,1.5947s1.63998-.714,1.63998-1.5947v-3.55824h11.16867v3.55824c0,.8807.7344,1.5947,1.64,1.5947.9057,0,1.64-.714,1.64-1.5947v-4.71732c0-1.11975-.9365-2.03042-2.088-2.03042Z"/>
      <path d="m9.0259,22.4138h12.0215c1.2809,0,2.3191-1.0097,2.3191-2.2551v-2.0075h1.1775c.9057,0,1.64-.7141,1.64-1.5948s-.7343-1.5947-1.64-1.5947H5.43245c-.90566,0-1.63997.714-1.63997,1.5947s.73431,1.5948,1.63997,1.5948h1.27443v2.0075c0,1.2454,1.0382,2.2551,2.31902,2.2551Z"/>
    </g>
  </SvgIcon>
}

export default ScanIcon
