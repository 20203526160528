import { useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Bugsnag from '@bugsnag/js'

import { ROUTE_LOGIN } from '~/routes/Routes'

import { useAppDispatch } from '~/redux/store'
import { addNotification } from '~/redux/features/notifications/notificationSlice'
import { isAwsException, isNotifiable } from '~/types/guards/errors'
import { createMuiRegister } from '~/helpers/hookForm'

export interface FormValues {
  email: string
}

interface RequestPasswordResetProps {
  onRequestReset: (values: FormValues) => Promise<void>
}

const validationSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Email is invalid'),
}).strict()

const RequestPasswordReset: React.FC<RequestPasswordResetProps> = ({ onRequestReset }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const handleRequest = async (values: FormValues): Promise<void> => {
    setIsLoading(true)

    try {
      await onRequestReset(values)
    } catch (error) {
      if (isAwsException(error)) {
        switch (error.code) {
          case 'UserNotFoundException':
          case 'ResourceNotFoundException':
            dispatch(addNotification({ type: 'error', message: 'Did you mean to sign up?' }))
            break
          case 'LimitExceededException':
            dispatch(addNotification({ type: 'error', message: 'Too many attempts, try again later' }))
            break
          default:
            dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
            if (isNotifiable(error)) Bugsnag.notify(error)
        }
      } else {
        dispatch(addNotification({ type: 'error', message: 'Failed to initiate password reset' }))
        if (isNotifiable(error)) Bugsnag.notify(error)
      }

      setIsLoading(false)
    }
  }

  const { handleSubmit, register, formState: { errors } } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })

  const muiRegister = createMuiRegister<FormValues>(register)

  return (
      <Box>
        <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={handleSubmit(handleRequest)}>
          <Typography variant="h4" gutterBottom>Forgot your password?</Typography>
          <Typography variant="body2" gutterBottom>Please enter your email below and you will receive a code to your inbox to reset your password.</Typography>
          <TextField
            {...muiRegister('email')}
            fullWidth
            id="email"
            label="Email"
            margin="normal"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            loading={isLoading}
            sx={{ mt: 2 }}>Send</LoadingButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
            <Link component={RouterLink} to={ROUTE_LOGIN} variant="body2">
              Back to Sign In
            </Link>
          </Box>
    </Box>
  )
}

export default RequestPasswordReset
