import { useEffect } from 'react'
import {
  Route,
  Routes,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { checkIfAuthenticated } from '~/redux/features/auth/actions'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'

import application from '~/config/application'
import OnRouteChange from '~/routes/OnRouteChange'

import HomeView from '~/views/Home/HomeView'
import MyRecyclablesView from '~/views/Recyclable/MyRecyclables/MyRecyclablesView'
import AddRecyclableView from '~/views/Recyclable/AddRecyclable/AddRecyclableView'
import DepositView from '~/views/Deposit/Deposit/DepositView'
import MyDepositsView from '~/views/Deposit/MyDeposits/MyDepositsView'
import MyRewardsView from '~/views/Rewards/MyRewardsView'
import PrizeDrawView from '~/views/Prizes/PrizeDrawView'
import RecyclingPointScanView from '~/views/RecyclingPoint/RecyclingPointScan/RecyclingPointScanView'

import AccountView from '~/views/Account/AccountView'
import PersonalDetailsView from '~/views/Account/PersonalDetails/PersonalDetailsView'
import PasswordView from '~/views/Account/Password/PasswordView'
import SchemesView from '~/views/Account/Schemes/SchemesView'
import LocationServicesView from '~/views/Account/LocationServices/LocationServicesView'
import CameraPermissionsView from '~/views/Account/CameraPermissions/CameraPermissionsView'
import FundraiserView from '~/views/Donation/Fundraiser/FundraiserView'

import LorealOfferView from '~/views/Offer/LorealOffer/LorealOfferView'

import NotFound from '~/views/Error/NotFound'

import AuthRouteGuard from './AuthRouteGuard'
import AuthenticatedRouteGuard from './AuthenticatedRouteGuard'
import AuthRoutes from './AuthRoutes'
import PublicRoutes from './PublicRoutes'
import WebViewRoutes from './WebViewRoutes'

import { ROUTE_ACCOUNT, ROUTE_ACCOUNT_DETAILS, ROUTE_ACCOUNT_LOCATION_SERVICES, ROUTE_ACCOUNT_PASSWORD, ROUTE_ADD_RECYCLABLE, ROUTE_DEPOSIT, ROUTE_DEPOSITS, ROUTE_HOME, ROUTE_RECYCLABLES, ROUTE_RECYCLING_POINT_SCAN, ROUTE_REWARDS, ROUTE_PRIZES, ROUTE_ACCOUNT_CAMERA_PERMISSIONS, ROUTE_OFFER_LOREAL, ROUTE_ACCOUNT_SCHEMES, ROUTE_DONATION_FUNDRAISER } from './Routes'

const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const isAuthenticating = useSelector((state: RootState) => state.auth.isAuthenticating)

  useEffect(() => {
    void dispatch(checkIfAuthenticated())
  }, [])

  return (
    <>
      <OnRouteChange />
      <Routes>
        <Route element={<AuthenticatedRouteGuard isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated} />}>
          <Route path={ROUTE_HOME} element={<HomeView />} />
          <Route path={ROUTE_RECYCLABLES} element={<MyRecyclablesView />} />
          <Route path={ROUTE_ADD_RECYCLABLE} element={<AddRecyclableView />} />
          <Route path={ROUTE_DEPOSIT} element={<DepositView />} />
          <Route path={ROUTE_DEPOSITS} element={<MyDepositsView />} />
          { application.prizeDraw
            ? <Route path={ROUTE_PRIZES} element={<PrizeDrawView />} />
            : <Route path={ROUTE_REWARDS} element={<MyRewardsView />} />
          }
          <Route path={ROUTE_ACCOUNT} element={<AccountView />} />
          <Route path={ROUTE_ACCOUNT_DETAILS} element={<PersonalDetailsView />} />
          <Route path={ROUTE_ACCOUNT_PASSWORD} element={<PasswordView />} />
          <Route path={ROUTE_ACCOUNT_SCHEMES} element={<SchemesView />} />
          <Route path={ROUTE_ACCOUNT_LOCATION_SERVICES} element={<LocationServicesView />} />
          <Route path={ROUTE_ACCOUNT_CAMERA_PERMISSIONS} element={<CameraPermissionsView />} />
          <Route path={ROUTE_RECYCLING_POINT_SCAN} element={<RecyclingPointScanView />} />

          <Route path={ROUTE_OFFER_LOREAL} element={<LorealOfferView />} />

          <Route path={ROUTE_DONATION_FUNDRAISER} element={<FundraiserView />} />
        </Route>

        <Route element={<AuthRouteGuard isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated} />}>
          {AuthRoutes}
        </Route>

        {PublicRoutes}
        {WebViewRoutes}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default AppRoutes
