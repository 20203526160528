import { Grid } from '@mui/material'

import deposit from '~/config/deposit'
import { RecyclableStatus } from '~/constants/recyclable'
import type { RecyclablesByStatus } from '~/types/recyclable/RecyclablesByStatus'

import { Status } from './Status'

interface StatusesProps {
  recyclables: RecyclablesByStatus
  onClick: (status: RecyclableStatus) => void
}

export const Statuses: React.FC<StatusesProps> = ({ recyclables, onClick: handleRecyclableClick }) => (
  <Grid container spacing={{ xxs: 1, xs: 2, md: 4 }}>
    <Grid item xxs={4}>
      <Status
        onClick={() => { handleRecyclableClick(RecyclableStatus.ACCEPTED) }}
        recyclableCount={recyclables.accepted?.length ?? 0}
        status={RecyclableStatus.ACCEPTED}
      />
    </Grid>
    {deposit.allowedRecyclableStatus === RecyclableStatus.ACCEPTED && <Grid item xxs={4}>
      <Status
        onClick={() => { handleRecyclableClick(RecyclableStatus.TAGGED) }}
        recyclableCount={recyclables.tagged?.length ?? 0}
        status={RecyclableStatus.TAGGED}
      />
    </Grid>}
    <Grid item xxs={4}>
      <Status
        onClick={() => { handleRecyclableClick(RecyclableStatus.REJECTED) }}
        recyclableCount={recyclables.rejected?.length ?? 0}
        status={RecyclableStatus.REJECTED}
      />
    </Grid>
  </Grid>
)
