import { GEOLOCATION_ERROR_CODES } from '~/constants/geolocation'

import GeolocationTimeoutException from '~/exception/GeoLocationTimeoutException'
import GoogleGeolocationService from '~/services/GoogleGeolocationService'
import type { GoogleGeolocationCoordinates } from '~/types/location/GoogleGeolocationCoordinates'
import type { GeoPoint } from '~/types/location/GeoPoint'

export const getGeoPoint = (coordinates: GeolocationCoordinates, isGps: boolean = true): GeoPoint => {
  return {
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    accuracy: coordinates.accuracy,
    isGps,
  }
}

export const hasLocationEnabled = async (): Promise<boolean> => {
  return await new Promise((resolve) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        () => {
          resolve(true)
        },
        (error) => {
          if (error.code === GEOLOCATION_ERROR_CODES.PERMISSION_DENIED) {
            resolve(false)
          } else resolve(true)
        },
        { timeout: 5000, maximumAge: Infinity },
      )
    } else {
      resolve(true)
    }
  })
}

const transformGoogleLocationToGeolocationCoordinates = (geolocation: GoogleGeolocationCoordinates): GeolocationCoordinates => ({
  latitude: geolocation.location.lat,
  longitude: geolocation.location.lng,
  accuracy: geolocation.accuracy,
  speed: null,
  heading: null,
  altitude: null,
  altitudeAccuracy: null,
})

const getCurrentPosition = async (options: PositionOptions): Promise<GeolocationCoordinates> => {
  const tenSeconds = 10000

  return await new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new GeolocationTimeoutException())
    }, tenSeconds)

    navigator.geolocation.getCurrentPosition(
      (position) => {
        clearTimeout(timeout)
        resolve(position.coords)
      },
      (error) => {
        clearTimeout(timeout)
        reject(error)
      },
      options,
    )
  })
}

export const getCurrentLocationNavigator = async (): Promise<GeolocationCoordinates | null> => {
  let position: GeolocationCoordinates | null = null
  const maximumAge = 0
  const tenSeconds = 10000

  try {
    // First try with high accuracy, allow for 10 seconds
    position = await getCurrentPosition({ timeout: tenSeconds, maximumAge, enableHighAccuracy: true })
  } catch (error) {
    if (error instanceof GeolocationTimeoutException || (error as GeolocationPositionError).code === GEOLOCATION_ERROR_CODES.TIMEOUT) {
      try {
        // If it times out fall back and try without high accuracy
        position = await getCurrentPosition({ timeout: tenSeconds, maximumAge, enableHighAccuracy: false })
      } catch (error) {
        // If it still fails return null (likely location disabled)
        return null
      }
    }

    throw error
  }

  return position
}

export const getCurrentLocation = async (): Promise<{ coordinates: GeolocationCoordinates | null, isGps: boolean }> => {
  const navigatorResponse: GeolocationCoordinates | null = await getCurrentLocationNavigator()
  const twoMilesInMeters = 3219

  // First check if navigator has an accurate location
  if (navigatorResponse?.accuracy && navigatorResponse.accuracy < twoMilesInMeters) return { coordinates: navigatorResponse, isGps: true }

  // If navigator doesn't have an accurate location, try Google
  const googleResponse = await GoogleGeolocationService.getLocation()
  const googleLocation = transformGoogleLocationToGeolocationCoordinates(googleResponse)

  if (googleLocation.accuracy) {
    if (navigatorResponse?.accuracy && navigatorResponse.accuracy < googleLocation.accuracy) return { coordinates: navigatorResponse, isGps: true }

    return { coordinates: googleLocation, isGps: false }
  }

  return { coordinates: navigatorResponse, isGps: true }
}
