import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import Scan2RecyclePromotion from '~/domain/Promotion/Scan2RecyclePromotion'

import { getUser } from '~/redux/features/auth/actions'
import { getMyDeposits } from '~/redux/features/myDeposits/actions'
import { getRecyclables } from '~/redux/features/recyclables/actions'
import { getMyRewards } from '~/redux/features/myRewards/actions'
import { useAppDispatch } from '~/redux/store'
import type { RootState } from '~/redux/reducers/root'
import UserService from '~/services/UserService'
import type { UserPromotionStatistics } from '~/types/user/UserPromotionStatistics'

import Home from './Home'

const HomeView: React.FC = () => {
  const dispatch = useAppDispatch()
  const [statistics, setStatistics] = useState<UserPromotionStatistics>()
  const recyclables = useSelector((state: RootState) => state.recyclable.recyclables)
  const { user, cognitoUser, promotionId } = useSelector((state: RootState) => state.auth)
  const abort = useRef(new AbortController())

  useEffect(() => {
    return () => {
      if (abort.current) {
        abort.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    void dispatch(getRecyclables({ promotionId: Scan2RecyclePromotion.id }))
    if (cognitoUser?.userId) {
      void dispatch(getUser({
        userId: cognitoUser?.userId,
        promotionId: Scan2RecyclePromotion.parentId ?? Scan2RecyclePromotion.id,
      }))
    }
    void dispatch(getMyDeposits({ promotionId: Scan2RecyclePromotion.id }))
    void dispatch(getMyRewards({ promotionId: Scan2RecyclePromotion.id }))
  }, [cognitoUser?.userId])

  useEffect(() => {
    void fetchStatistics()
  }, [cognitoUser?.userId, promotionId])

  const fetchStatistics = async (): Promise<void> => {
    if (promotionId && cognitoUser?.userId) {
      const statistics = await UserService.getUserPromotionStatistics(promotionId, { signal: abort.current.signal })
      setStatistics(statistics)
    }
  }

  return <>
    <Helmet>
      <title>Home | Recycle at Boots</title>
    </Helmet>
    <Home
      recyclables={recyclables}
      givenName={user?.firstname}
      statistics={statistics}
    />
  </>
}

export default HomeView
