import {
  Route,
} from 'react-router-dom'

import PublicPage from '~/components/Page/PublicPage'
import application from '~/config/application'
import FAQView from '~/views/Public/FAQ/FAQView'
import PrivacyPolicyView from '~/views/Public/Privacy/PrivacyPolicyView'
import { RecyclingPointsView } from '~/views/Public/RecyclingPoints/RecyclingPointsView'
import RecyclingSymbolsView from '~/views/Public/RecyclingSymbols/RecyclingSymbolsView'
import RecyclableTypesView from '~/views/Public/RecyclingTypes/RecyclableTypesView'
import TermsView from '~/views/Public/Terms/TermsView'

import { ROUTE_FAQS, ROUTE_TERMS, ROUTE_RECYCLABLE_TYPES, ROUTE_PRIVACY, ROUTE_RECYCLING_SYMBOLS, ROUTE_RECYCLING_POINTS } from './Routes'

const PublicRoutes = (
  <Route element={<PublicPage />}>
    <Route path={ROUTE_FAQS} element={<FAQView />} />
    <Route path={ROUTE_TERMS} element={<TermsView />} />
    <Route path={ROUTE_PRIVACY} element={<PrivacyPolicyView />} />
    <Route path={ROUTE_RECYCLABLE_TYPES} element={<RecyclableTypesView />} />
    <Route path={ROUTE_RECYCLING_SYMBOLS} element={<RecyclingSymbolsView />} />
    {application.hasRecyclingPointsRoute && <Route path={ROUTE_RECYCLING_POINTS} element={<RecyclingPointsView />} />}
  </Route>
)

export default PublicRoutes
