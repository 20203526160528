import { BrandColor } from '~/config/theme'

export const defaultStyle = {
  defaultIcon: 'marker-15',
  userLocationIcon: 'circle-15',
  clusterPaint: {
    'circle-color': BrandColor.GREEN,
    'circle-radius': 20,
  },
  clusterOptions: {
    maxZoom: 14,
    radius: 50,
  },
  clusterCountLayout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Poppins Regular'],
    'text-size': 16,
  },
  clusterCountPaint: {
    'text-color': BrandColor.BLACK,
  },
}
