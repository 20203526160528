import { createTheme } from '@mui/material'
import type {} from '@mui/lab/themeAugmentation'
import type {} from '@mui/x-tree-view/themeAugmentation'

import { RecyclableStatus } from '~/constants/recyclable'

// TODO: Figure out how to load this from an external types file
declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties
  }

  interface ButtonPropsVariants {
    selected?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlinedInverse: true
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    black: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true
    xs: true
    sm: true
    md: true
    mdl: true
    lg: true
    xl: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    black: { main: string }
  }

  interface PaletteOptions {
    black: { main: string }
  }
}

export enum BrandColor {
  BLACK = '#333333',
  TEXT = '#666666',
  TITLE = '#414141',
  BLUE = '#05054b',
  MID_BLUE = '#15357A',
  ACCENT_BLUE = '#124c8f',
  LIGHT_BLUE = '#004990',
  PINK = '#D03569',
  PURPLE = '#AA3379',
  WHITE = '#ffffff',
  GREEN = '#19b155',
  MINT_GREEN = '#9FD2BA',
  LIGHT_GREY = '#f5f5f5',
  BUTTON_BLUE = '#121d58',
  BUTTON_GREY = '#dddddd',
  BUTTON_GREY_LIGHT = '#eeeeee',
  NAV_GREY = '#fbfbfb',
  RED = '#e4509dcc',
  YELLOW = '#f5b56ecc',
  LILAC = '#e19ac3',
}

export enum BrandFontFamily {
  REGULAR = "'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
  BOLD = "'Poppins'",
}

interface SchemeColor {
  recyclable: {
    background: string
    backgroundContrast?: string
    color?: string
    status: {
      [key in RecyclableStatus]: {
        color: string
      }
    }
  }
  deposit: {
    color?: string
    background: string
    backgroundContrast?: string
  }
}

export const schemeColors: Record<string, SchemeColor> = {
  Beauty: {
    recyclable: {
      background: BrandColor.MID_BLUE,
      status: {
        [RecyclableStatus.TAGGED]: {
          color: BrandColor.LILAC,
        },
        [RecyclableStatus.ACCEPTED]: {
          color: BrandColor.LIGHT_BLUE,
        },
        [RecyclableStatus.REJECTED]: {
          color: BrandColor.PINK,
        },
      },
    },
    deposit: {
      background: BrandColor.PINK,
    },
  },
  Blister: {
    recyclable: {
      background: '#78B99A',
      backgroundContrast: '#569E7C',
      status: {
        [RecyclableStatus.TAGGED]: {
          color: '#78B99A',
        },
        [RecyclableStatus.ACCEPTED]: {
          color: '#84C3CB',
        },
        [RecyclableStatus.REJECTED]: {
          color: BrandColor.PINK,
        },
      },
    },
    deposit: {
      color: BrandColor.BLUE,
      background: '#D4E9EB',
      backgroundContrast: '#84C3CB',
    },
  },
}

export const getSchemeColor = (scheme: string): SchemeColor => {
  return schemeColors[scheme]
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 380,
      sm: 600,
      md: 900,
      mdl: 1000,
      lg: 1200,
      xl: 1536,
    },
  },

  palette: {
    mode: 'light',
    primary: {
      light: BrandColor.ACCENT_BLUE,
      main: BrandColor.ACCENT_BLUE,
      dark: BrandColor.BLUE,
      contrastText: BrandColor.WHITE,
    },
    secondary: {
      light: BrandColor.GREEN,
      main: BrandColor.GREEN,
      dark: BrandColor.GREEN,
      contrastText: BrandColor.WHITE,
    },
    text: {
      primary: BrandColor.TEXT,
    },
    black: { main: BrandColor.BLACK },
  },

  typography: {
    fontFamily: BrandFontFamily.REGULAR,
    allVariants: {
      color: BrandColor.TEXT,
    },
    ...(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((acc, header) => {
      return {
        ...acc,
        [header]: {
          fontFamily: BrandFontFamily.BOLD,
          color: BrandColor.TITLE,
        },
      }
    }, {})),

    // Custom
    title: {
      fontFamily: BrandFontFamily.BOLD,
    },
  },
  components: {
    // TYPOGRAPHY
    MuiTypography: {
      styleOverrides: {
        button: {
          fontFamily: BrandFontFamily.BOLD,
          fontSize: '1.1rem',
        },
      },
    },

    // NAVIGATION
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: BrandColor.WHITE,
        },
      },
    },

    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#292929',
        },
      },
    },

    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: BrandColor.WHITE,
          minWidth: 'auto',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          '.MuiSvgIcon-root': {
            transition: 'font-size 0.2s ease-in-out',
            height: '0.9em',
            width: '0.9em',
          },

          '&.Mui-selected': {
            color: BrandColor.LILAC,
          },
        },
        label: {
          opacity: 1,
          marginTop: 4,
          fontSize: '0.6rem',
          color: '#999',
          '@media (max-width:360px)': {
            display: 'none',
          },

          '&.Mui-selected': {
            fontSize: '0.6rem',
            color: BrandColor.LILAC,
          },
        },
      },
    },

    // LINK
    MuiLink: {
      styleOverrides: {
        root: {
          color: BrandColor.BLACK,
          textDecoration: 'none',
        },
      },
    },

    // List
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: BrandFontFamily.REGULAR,
        },
      },
    },

    // FORM

    // Inputs
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: BrandColor.BLACK,
          '&.Mui-focused': {
            color: BrandColor.BLUE,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: BrandColor.ACCENT_BLUE,
          },
        },
        notchedOutline: {
          borderColor: BrandColor.BLACK,
          color: BrandColor.BLACK,
        },
      },
    },

    MuiCheckbox: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            '&.Mui-checked': {
              color: BrandColor.ACCENT_BLUE,
            },
          },
        },
      ],
    },

    // Buttons
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          boxShadow: 'none',
          color: BrandColor.BLACK,
          fontFamily: BrandFontFamily.BOLD,
          textDecoration: 'underline',
          textTransform: 'none',

          '&:hover': {
            boxShadow: 'none',
            textDecoration: 'underline',
          },
        },
        sizeMedium: {
          padding: '0.75rem 1rem',
        },
        sizeSmall: {
          padding: '0.5rem 0.75rem',
        },
        containedPrimary: {
          backgroundColor: BrandColor.BUTTON_BLUE,
          color: BrandColor.WHITE,
          textDecoration: 'none',
          borderColor: BrandColor.BUTTON_BLUE,

          '&:hover': {
            textDecoration: 'none',
            backgroundColor: BrandColor.ACCENT_BLUE,
            borderColor: BrandColor.ACCENT_BLUE,
          },

          '.MuiLoadingButton-loadingIndicator': {
            color: '#ffffff',
          },
        },
        containedSecondary: {
          backgroundColor: BrandColor.BUTTON_GREY,
          color: BrandColor.BLACK,
          textDecoration: 'none',
          borderColor: BrandColor.BUTTON_GREY,

          '&:hover': {
            textDecoration: 'none',
            backgroundColor: BrandColor.BUTTON_GREY_LIGHT,
            borderColor: BrandColor.BUTTON_GREY_LIGHT,
          },
        },
        outlinedPrimary: {
          color: BrandColor.BUTTON_BLUE,
          textDecoration: 'none',
          borderColor: BrandColor.BUTTON_BLUE,

          '&:hover': {
            textDecoration: 'none',
            backgroundColor: BrandColor.ACCENT_BLUE,
            borderColor: BrandColor.ACCENT_BLUE,
            color: BrandColor.WHITE,
          },
        },
        text: {
          textDecoration: 'none',

          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlinedInverse' },
          style: {
            background: 'transparent',
            color: BrandColor.WHITE,
            textDecoration: 'none',
            border: `2px solid ${BrandColor.WHITE}`,
            borderColor: BrandColor.WHITE,

            '&:hover': {
              background: 'transparent',
              color: BrandColor.WHITE,
              textDecoration: 'none',
            },
          },
        },
      ],
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: BrandColor.BLACK,
        },
      },
    },

    // Alerts
    MuiSnackbar: {
      styleOverrides: {
        root: {
          backgroundColor: BrandColor.BLACK,
          color: BrandColor.WHITE,

          '.MuiButtonBase-root': {
            fontSize: 10,
            padding: '0.5rem 1rem',
            color: BrandColor.WHITE,
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: BrandColor.BLACK,
          color: BrandColor.WHITE,

          '.MuiButtonBase-root': {
            fontSize: 10,
            padding: '0.5rem 1rem',
            color: BrandColor.WHITE,
          },
        },
      },
    },

    // Dialogs
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: BrandColor.WHITE,
        },
      },
    },

    // Loaders
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        bar: {
          backgroundColor: BrandColor.ACCENT_BLUE,
        },
      },
    },

    MuiMobileStepper: {
      styleOverrides: {
        root: {
          backgroundColor: BrandColor.WHITE,
          justifyContent: 'center',

          '.MuiLinearProgress-root': {
            width: '100%',
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        icon: {
          color: 'inherit',
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          '&:not([hidden])': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          bottom: '4px',
          opacity: 0.3,

          '> span': {
            width: '80%',
            height: '0.2rem',
            borderRadius: '0.5rem',
            zIndex: 2,
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          background: '#fafafa',
          marginLeft: '0.25rem',
          marginRight: '0.25rem',
          fontSize: '0.65rem',
          color: '#999',
          flexGrow: 1,
          maxWidth: 'unset',
          borderRadius: 4,
          padding: '0.5rem 0.75rem',
          minHeight: 'unset',
          opacity: 0.6,

          '&:first-of-type': {
            marginLeft: 0,
          },

          '&:last-child': {
            marginRight: 0,
          },

          '&.Mui-selected': {
            opacity: 1,
          },

          '.MuiSvgIcon-root': {
            fontSize: '1.25rem',
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: BrandColor.LIGHT_GREY,
          padding: '0.5rem 1rem',
          borderRadius: 8,
          marginBottom: 8,

          '&.Mui-expanded': {
            backgroundColor: BrandColor.BLACK,
            color: BrandColor.WHITE,
          },

          'MuiSvgIcon-root': {
            color: BrandColor.MINT_GREEN,
          },
        },
        expandIconWrapper: {
          color: BrandColor.MINT_GREEN,
        },
      },
    },

    MuiTreeItem: {
      styleOverrides: {
        label: {
          '&.Mui-expanded': {
            color: BrandColor.WHITE,
          },
        },
        content: {
          backgroundColor: BrandColor.LIGHT_GREY,
          padding: '0.5rem 1rem',
          borderRadius: 8,
          marginBottom: 8,

          '&.Mui-selected': {
            backgroundColor: '#444',
            color: BrandColor.WHITE,

            '&.Mui-focused': {
              backgroundColor: '#444',
              color: BrandColor.WHITE,
            },

            '&:hover': {
              backgroundColor: '#444',
              color: BrandColor.WHITE,
            },

            '.MuiTreeItem-label': {
              color: BrandColor.WHITE,
            },
          },

          '&.Mui-expanded': {
            backgroundColor: BrandColor.BLACK,
            color: BrandColor.WHITE,

            '.MuiTreeItem-label': {
              color: BrandColor.WHITE,
            },
          },
        },
      },
    },
  },
})
